import {
  Box,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TextField,
  TableRow,
  Button,
  Typography,
  Paper,
  //   IconButton,
  //   Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import React, { useContext, useState } from "react";
import Notify from "../../notification/Notify";
import { UsersData } from "../../ContextAPI";

const LaboratoryTestList = ({ list, getLabTest }) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });

  const handleEditTest = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);

    var error = [];

    // if (formdata.get('test').trim() === '' || formdata.get('test').length === 0) {
    //     error = 'error'
    //     Notify.fieldRequired('test')
    // }

    if (
      formdata.get("rate").trim() === "" ||
      formdata.get("rate").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("rate");
    }

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      Axios.post("laboratory/test/edit-test", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            Notify.successRequest("new doctor");
            getLabTest();
            setEdit({ data: null, open: true });
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Box component={Paper} variant="outlined" p={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong> Category </strong>
              </TableCell>

              <TableCell align="center">
                <strong> Lab Order </strong>
              </TableCell>

              <TableCell align="center">
                <strong> Rate </strong>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list.ready ? (
              list.data.length > 0 ? (
                list.data.map((data, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      <span style={{ textTransform: "capitalize" }}>
                        {data.category === "clinical-chemistry"
                          ? "blood-chemistry"
                          : data.category}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ textTransform: "uppercase" }}>
                        {data.laborder}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      {Notify.convertToNumber(data.rate)}
                    </TableCell>
                    {/* <TableCell align="center">
                        <Tooltip arrow title="Edit Laboratory Test">
                            <IconButton
                                color="primary"
                                onClick={() =>
                                    setEdit({
                                        data: data,
                                        open: true
                                    })
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography variant="subtitle2" color="secondary">
                      {" "}
                      No laboratory test added.{" "}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography variant="subtitle2" color="primary">
                    {" "}
                    Please wait...{" "}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* edit dialog */}
      {edit.data && edit.open && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={edit.open}
          onClose={() => setEdit({ data: null, open: true })}
        >
          <DialogTitle> Edit Laboratory Test </DialogTitle>
          <form onSubmit={handleEditTest}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="id"
                  label="Laboratory Test"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.lt_id}
                />
                <TextField
                  fullWidth
                  required
                  name="test"
                  label="Laboratory Test"
                  variant="outlined"
                  disabled
                  defaultValue={edit.data.laboratory_test}
                />
              </Box>

              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="department"
                  label="Department"
                  variant="outlined"
                  disabled
                  defaultValue={edit.data.department}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="rate"
                  label="Laboratory Rate"
                  variant="outlined"
                  type="number"
                  defaultValue={edit.data.laboratory_rate}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 10000,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEdit({ data: null, open: true })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Box>
  );
};

export default LaboratoryTestList;
