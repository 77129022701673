import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import Axios from "axios";
import {
  Button,
  Box,
  Typography,
  Paper,
  TextField,
  CircularProgress,
  // Badge,
  Grid,
  useTheme,
  TableContainer,
  TableCell,
  IconButton,
  TableRow,
  Table,
  TableBody,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { UsersData } from "src/ContextAPI";
// import { MenuOutlined } from "@material-ui/icons";
// import CashierImgUnsaveOrder from "./CashierImgUnsaveOrder";
import Select from "react-select";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

const CashierCreateImgOrder = ({
  patient_id,
  getPatientBillingDetails,
  trace_number,
}) => {
  const [exams, setExams] = useState({ data: [], ready: false });
  // const [examsDetails, setExamsDetails] = useState({ data: [], ready: false });
  // const isProcess = false;
  const { users } = React.useContext(UsersData);
  const [unsaveList, setUnsateList] = useState({ data: [], ready: false });
  // const [unsaveDialog, setUnsaveDialog] = useState(false);
  const theme = useTheme();
  const [processImaging, setProcessImaging] = useState(false);
  const [labProcess, setLabProcess] = useState(false);

  const getImagingExams = () => {
    var params = { vmanagementId: users.management_id };
    Axios.get("cashier/imaging/local/imaging-orderlist", { params })
      .then((response) => {
        const data = response.data;
        setExams({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const getImagingExamsDetails = (e) => {
  //   var order_id = e.target.value;

  //   Axios.get("cashier/imaging/local/imaging-orderlist/details", {
  //     params: {
  //       order_id,
  //     },
  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       setExamsDetails({ data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  const getUnsaveList = () => {
    Axios.get("cashier/imaging/local/imaging-orderlist/unsave", {
      params: {
        patient_id: patient_id,
        management_id: users.management_id,
      },
    })
      .then((response) => {
        const data = response.data;
        setUnsateList({ data, ready: true });
        // setExamsDetails({ data: [], ready: false });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const deleteOrder = (data) => {
    var formdata = new FormData();
    formdata.set("icu_id", data.icu_id);

    Axios.post("imaging/order/local/unsave/delete-order", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getUnsaveList();
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const handleAddOrder = (e) => {
  //   e.preventDefault();
  //   e.persist();

  //   var formdata = new FormData(e.target);
  //   formdata.set("user_id", users.user_id);
  //   formdata.set("management_id", users.management_id);
  //   formdata.set("main_mgmt_id", users.main_mgmt_id);
  //   formdata.set("patient_id", patient_id);

  //   var error = [];

  //   if (
  //     formdata.get("imaging_center").length === 0 ||
  //     formdata.get("imaging_center").trim() === ""
  //   ) {
  //     error = "error";
  //     Notify.fieldRequired("imaging center");
  //   }
  //   if (
  //     formdata.get("imaging_order_id").length === 0 ||
  //     formdata.get("imaging_order_id").trim() === ""
  //   ) {
  //     error = "error";
  //     Notify.fieldRequired("imaging order");
  //   }
  //   if (error.length > 0) {
  //     console.warn("Form has an error, observe please");
  //   } else {
  //     Axios.post("cashier/imaging/local/order-add", formdata)
  //       .then((response) => {
  //         const data = response.data;

  //         if (data === "test-exist") {
  //           Notify.customToast("Test existed", "the test already exist");
  //         }

  //         if (data === "success") {
  //           Notify.successRequest("order added");
  //           getUnsaveList();
  //           e.target.reset();
  //         }
  //       })
  //       .catch((error) => {
  //         Notify.requestError(error);
  //       });
  //   }
  // };

  const addImagingOrder = (orderdetails) => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    formdata.set("trace_number", trace_number);

    formdata.set("imaging_order_id", orderdetails.order_id);
    formdata.set("order", orderdetails.order_desc);
    formdata.set("remarks", "Order is available, Now processing...");
    formdata.set("amount", orderdetails.order_cost);
    formdata.set("imaging_center", users.management_id);

    Axios.post("cashier/imaging/local/order-add", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getUnsaveList();
          Notify.successRequest("Imaging Order Added.");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleProcess = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("patient_id", patient_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldInvalid("password");
    }

    if (
      formdata.get("remarks").length === 0 ||
      formdata.get("remarks").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("remarks");
    }

    if (error.length > 0) {
      console.log("Error sample and more and more and more....");
    } else {
      setLabProcess(true);
      Axios.post("cashier/order/local/unsave/process-order", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            setProcessImaging(false);
            getPatientBillingDetails();
            getUnsaveList();
            Notify.successRequest("order process");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setLabProcess(false));
    }
  };

  useEffect(() => {
    getImagingExams();
    getUnsaveList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper component={Box} elevation={2} p={2} mb={2}>
            <Box mb={2}>
              <Typography variant="h6" color="textSecondary">
                Select Order
              </Typography>
            </Box>

            <Box>
              <Box>
                <Select
                  options={exams.data}
                  onChange={(data) => addImagingOrder(data)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99999,
                      color: "#000",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                      backgroundColor: "transparent",
                      color: theme.palette.type === "dark" ? "#fff" : "#fff",
                    }),
                    singleValue: () => ({
                      color: theme.palette.type === "dark" ? "#fff" : "#000",
                    }),
                  }}
                />
              </Box>

              {/* <RenderAvailableTest
                addProcess={addProcess}
                deptDetails={deptDetails}
                addLabOrder={addLabOrder}
              /> */}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Paper component={Box} elevation={2} p={2} mb={2}>
            <Box mb={2}>
              <Typography variant="h6" color="textSecondary">
                Unsave Imaging Order
              </Typography>
            </Box>
            <Box mb={1}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {unsaveList.ready ? (
                      unsaveList.data.length > 0 ? (
                        unsaveList.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ textTransform: "capitalize" }}>
                              <b style={{ fontSize: 16 }}>
                                {data.imaging_order}
                              </b>
                              <br />
                              X-ray Department
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "bolder" }}
                            >
                              &#8369; {Notify.convertToNumber(data.amount)}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  deleteOrder(data);
                                  // setCancelDialog({
                                  //   open: true,
                                  //   removeid: data.id,
                                  // });
                                }}
                              >
                                <DeleteSweepIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>{Notify.loading()}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box display={"flex"}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  hidden={unsaveList.data.length === 0}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setProcessImaging(true)}
                >
                  Process Order
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* <Paper elevation={2} component={Box} p={2}>
        <Box>
          <Typography variant="h6" color="textSecondary">
            Create Local Order
          </Typography>
        </Box>

        <Box>
          <form onSubmit={handleAddOrder}>
            {exams.ready ? (
              exams.data.length > 0 ? (
                <>
                  <Box my={3}>
                    <TextField
                      label="Imaging Center"
                      variant="outlined"
                      fullWidth
                      value={
                        exams.data[0].name !== null ? exams.data[0].name : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                    <TextField
                      name="imaging_center"
                      label="Imaging Center"
                      variant="outlined"
                      fullWidth
                      value={
                        exams.data[0].management_id !== null
                          ? exams.data[0].management_id
                          : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      hidden
                    />
                  </Box>

                  <Box my={3}>
                    <TextField
                      select
                      label="Imaging Order"
                      onChange={(e) => getImagingExamsDetails(e)}
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                      name="imaging_order_id"
                    >
                      <option value=""> Select </option>
                      {exams.ready ? (
                        exams.data.length > 0 ? (
                          exams.data.map((data, index) => (
                            <option key={index} value={data.order_id}>
                              {data.order_desc}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            No imaging order
                          </option>
                        )
                      ) : (
                        <option value="" disabled>
                          Select imaging center first
                        </option>
                      )}
                    </TextField>
                  </Box>

                  <Box mb={3}>
                    <TextField
                      name="order"
                      label="Order"
                      variant="outlined"
                      fullWidth
                      hidden
                      value={
                        examsDetails.data ? examsDetails.data.order_desc : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>

                  <Box mb={3}>
                    <TextField
                      name="amount"
                      label="Amount"
                      variant="outlined"
                      fullWidth
                      value={
                        examsDetails.data ? examsDetails.data.order_cost : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>

                  <Box mb={3}>
                    <TextField
                      name="remarks"
                      label="Remarks"
                      variant="outlined"
                      fullWidth
                      rows={4}
                    />
                  </Box>

                  <Box display="flex">
                    <Box mr={2}>
                      <Button
                        variant="contained"
                        startIcon={<HighlightOffIcon />}
                        color="default"
                        type="reset"
                      >
                        No
                      </Button>
                    </Box>

                    <Box mr={2}>
                      <Button
                        type="submit"
                        disabled={
                          exams.data.length > 0
                            ? isProcess
                              ? true
                              : false
                            : true
                        }
                        variant="contained"
                        startIcon={
                          isProcess ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckCircleOutlineIcon />
                          )
                        }
                        color="primary"
                      >
                        Add Order
                      </Button>
                    </Box>

                    <Badge
                      badgeContent={unsaveList.data.length}
                      color="primary"
                    >
                      <Button
                        type="button"
                        variant="contained"
                        startIcon={
                          isProcess ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <MenuOutlined />
                          )
                        }
                        color="secondary"
                        onClick={() => setUnsaveDialog(true)}
                        disabled={!unsaveDialog && unsaveList.data.length === 0}
                      >
                        Unsave Order
                      </Button>
                    </Badge>
                  </Box>
                </>
              ) : (
                <Box>{Notify.noRecord()}</Box>
              )
            ) : (
              <Box>{Notify.loading()}</Box>
            )}
          </form>
        </Box> */}

      {/* dialog  unsave list */}
      {/* {unsaveDialog && unsaveList.data.length > 0 && (
          <CashierImgUnsaveOrder
            unsaveList={unsaveList.data}
            open={unsaveDialog}
            close={() => setUnsaveDialog(false)}
            getUnsaveList={() => getUnsaveList()}
            patient_id={patient_id}
            getPatientBillingDetails={() => getPatientBillingDetails()}
          />
        )}
      </Paper> */}

      <Dialog
        open={processImaging}
        onClose={() => setProcessImaging(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={"xs"}
        fullWidth
      >
        <form onSubmit={handleProcess}>
          <DialogTitle>Process Order</DialogTitle>
          <DialogContent dividers>
            <Box>
              <TextField
                defaultValue="Order is available, Now processing..."
                label="Order Remarks"
                margin="normal"
                variant="outlined"
                name="remarks"
                multiline
                fullWidth
                rows={4}
              />
            </Box>
            <Box>
              <TextField
                label="Password"
                margin="normal"
                variant="outlined"
                name="password"
                type="password"
                fullWidth
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => setProcessImaging(false)}
              startIcon={<HighlightOffIcon />}
            >
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={labProcess}
              startIcon={
                labProcess ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <CheckCircleOutlineIcon />
                )
              }
            >
              Process
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CashierCreateImgOrder;
