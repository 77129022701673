import {
  Grid,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React, { Fragment } from "react";
// import HemathologyRef from '../references/Ref_Hemathology';
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicalMicroscopyOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          {/* order details */}
          <Box>
            <Typography variant="h6" align="center">
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
                style={{ fontWeight: "bolder", color: "#000" }}
              >
                CLINICAL MICROSCOPY
              </span>
            </Typography>
          </Box>

          <Box>
            {orderDetails.data.map((data, index) => (
              <Fragment key={index}>
                <Box mb={2}>
                  {Boolean(parseInt(data.chemical_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          CHEMICAL TEST
                        </Typography>
                      </Box>
                      <Box ml={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4}>
                            <Box mt={1} display="flex">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                COLOR:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_color}
                                </span>
                              </Box>
                            </Box>

                            <Box mt={1} display="flex">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                TRANSPARENCY:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_transparency}
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <Box mt={1} display="flex">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                PH:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_ph}
                                </span>
                              </Box>
                            </Box>
                            <Box mt={1} display="flex">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                SPECIFIC GRAVITY:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_spicific_gravity}
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <Box mt={1} display="flex">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                GLUCOSE:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_glucose}
                                </span>
                              </Box>
                            </Box>
                            <Box mt={1} display="flex">
                              <Typography
                                variant="caption"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                ALBUMIN:
                              </Typography>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.chemical_test_albumin}
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.microscopic_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          MICROSCOPIC TEST
                        </Typography>
                      </Box>
                      <Box ml={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6}>
                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CELLS
                                </Typography>
                              </Box>
                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Squamous Cells
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_squamous}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Pus Cells
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_pus}
                                  </span>
                                </Box>
                                /HPF
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Red Blood Cells
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_redblood}
                                  </span>
                                </Box>
                                /HPF
                              </Box>
                            </>

                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CASTS
                                </Typography>
                              </Box>
                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Hyaline Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_hyaline}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  WBC Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_wbc}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  RBC Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_rbc}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Fine Granualar Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_fine_granular}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Coarse Granualar Cast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_coarse_granular}
                                  </span>
                                </Box>
                              </Box>
                            </>
                          </Grid>

                          <Grid item xs={6} sm={6}>
                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CRYSTALS
                                </Typography>
                              </Box>
                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Calcium Oxalate
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_calcium_oxalate}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Triple Phosphate
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_triple_phospahte}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Leucine/Tyrosine
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_leucine_tyrosine}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Ammonium Biurate
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_ammonium_biurate}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Amorphous Urates
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_amorphous_urates}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  <b> Amorphous Phosphates </b>
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_amorphous_phosphates}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Uric Acid
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_uricacid}
                                  </span>
                                </Box>
                              </Box>
                            </>
                            <>
                              <Box mt={1}>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  OTHERS
                                </Typography>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Mucus Thread
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_mucus_thread}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Bacteria
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_bacteria}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Yeast
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.microscopic_test_yeast}
                                  </span>
                                </Box>
                              </Box>
                            </>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.pregnancy_test_hcg)) && (
                    <>
                      <Box mt={1} display="flex">
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          PREGNANCY TEST (HCG):
                        </Typography>
                        <Box
                          mx={2}
                          align="center"
                          minWidth={100}
                          borderBottom={1}
                        >
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.pregnancy_test_hcg_result}
                          </span>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.micral_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          MICRAL TEST
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.micral_test_result}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.seminalysis_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> SEMENALYSIS </b>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.seminalysis_result}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {Boolean(parseInt(data.occult_blood_test)) && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          OCCULT BLOOD TEST
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.occult_blood_test_result}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  {/* clinical microscopy */}
                  {index + 1 === orderDetails.data.length && (
                    <>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          REMARKS
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          {data.result_remarks}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Fragment>
            ))}
          </Box>

          <Box my={3} align="center">
            <Footer formheader={formheader} orderDetails={orderDetails} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ClinicalMicroscopyOrder;
