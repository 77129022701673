import React from "react";
import { Box, Typography, Grid, CardMedia } from "@material-ui/core";

const HeaderPrintAll = () => {
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box align="center">
              <CardMedia
                component="img"
                src="/bmcdc_logo.png"
                alt="bmcdc"
                style={{ width: 100 }}
              />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box align="center">
              <Typography variant="h3">
                <b>BMCDC HEALTHCARE, INC.</b>
              </Typography>
              <Typography>
                <b>DOOR G/F, GIMENES BLGD., KM. 5 BUHANGIN, DAVAO CITY</b>
              </Typography>
              <Typography>
                <b>Tel. 285-4324/293-9684, Telefax: 241-0925</b>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box align="center">
              <CardMedia
                component="img"
                src="/bmcdc_medical.png"
                alt="bmcdc"
                style={{ width: 100 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HeaderPrintAll;
