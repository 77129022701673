import React, { Fragment } from "react";
import {
  Typography,
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Grid,
} from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const MiscellaneousPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  miscellaneousOrderDetails,
}) => {
  return (
    <>
      {miscellaneousOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                MISCELLANEOUS
              </span>
            </Typography>
          </Box>
          <Box>
            {miscellaneousOrderDetails.data.map((data, key) => (
              <Fragment key={key}>
                {Boolean(parseInt(data.miscellaneous_test)) && (
                  <Box>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              SPICEMEN :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.speciment}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              TEST :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.test}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              RESULT :
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                {data.result}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}

                {Boolean(parseInt(data.pregnancy_test_urine)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PREGNANCY TEST (URINE) </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pregnancy_test_urine_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {Boolean(parseInt(data.pregnancy_test_serum)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PREGNANCY TEST (SERUM) </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.pregnancy_test_serum_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {Boolean(parseInt(data.papsmear_test)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          <b> PAPSMEAR TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.papsmear_test_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {Boolean(parseInt(data.papsmear_test_with_gramstain)) && (
                  <Box mt={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          PAPSMEAR WITH GRAMSTAIN TEST
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RESULT
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            {data.papsmear_test_with_gramstain_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Fragment>
            ))}
          </Box>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {miscellaneousOrderDetails.data.length > 0 &&
                        miscellaneousOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {miscellaneousOrderDetails.data.length > 0 &&
                          miscellaneousOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {miscellaneousOrderDetails.data.length > 0 &&
                        miscellaneousOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {miscellaneousOrderDetails.data.length > 0 &&
                          miscellaneousOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {miscellaneousOrderDetails.data.length > 0 &&
                          miscellaneousOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {miscellaneousOrderDetails.data.length > 0 &&
                            miscellaneousOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default MiscellaneousPrintAll;
