import {
  Box,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import ItemForApprovalDetails from "./ItemForApprovalDetails";

const ItemForApproval = () => {
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const mounted = IsMountedRef();
  const [forApprovalItems, setForApprovalItems] = useState({
    data: [],
    ready: false,
  });
  const [selectedRequestID, setSelectedRequestID] = useState(null);
  const [stateOpenDetails, setStateOpenDetails] = useState("no-selected");

  const getItemForApprovalList = React.useCallback(async () => {
    try {
      let response = await axios.get(
        "accounting/warehouse/account/deliver/list-producttotemp",
        {
          params: {
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            status: "approval-for-coo",
          },
        }
      );

      let result = response.data;
      if (mounted.current) {
        setForApprovalItems({ data: result, ready: true });
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, mounted]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getItemForApprovalList();
  }, [getItemForApprovalList]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Request For Item",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={"Request For Item"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box mb={2}>
                <Typography color="primary" variant="h5">
                  <strong>Item Request List </strong>
                </Typography>
              </Box>
              <Box>
                <List component="div">
                  {forApprovalItems.ready
                    ? forApprovalItems.data.length > 0
                      ? forApprovalItems.data.map((data, index) => (
                          <ListItem
                            button
                            key={index}
                            className="mb-2"
                            selected={selectedRequestID === data.request_id}
                            onClick={() => {
                              setStateOpenDetails("item-approval-details");
                              setSelectedRequestID(data.request_id);
                            }}
                          >
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`Req. No.: ${data.request_id.replace(
                                "req-",
                                ""
                              )}`}
                              secondary={Notify.dateTimeConvert(
                                data.created_at
                              )}
                            />
                          </ListItem>
                        ))
                      : Notify.noRecord()
                    : Notify.loading()}
                </List>

                <TablePagination
                  component="div"
                  count={forApprovalItems.data.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={9} md={9}>
            {stateOpenDetails === "item-approval-details" ? (
              <Box component={Paper} variant="outlined" p={2}>
                <ItemForApprovalDetails
                  request_id={selectedRequestID}
                  getItemForApprovalList={() => getItemForApprovalList()}
                  closeDetails={() => setStateOpenDetails("no-selected")}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default ItemForApproval;
