import {
  Grid,
  Card,
  // CardHeader,
  CardContent,
  // Box,
  // TextField,
  // CardActions,
  // Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
// import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const PsychologyTest = () => {
  const { users } = useContext(UsersData);
  const [test, setTest] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();

  const getPsychologytest = () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("psychology/test/new-get", { params })
      .then((res) => {
        const data = res.data;
        if (mounted.current) {
          setTest({ data, ready: true });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  // const handleNewTest = async (e) => {
  //   e.preventDefault();
  //   e.persist();

  //   var formdata = new FormData(e.target);
  //   formdata.set("username", users.username);
  //   formdata.set("user_id", users.user_id);

  //   let response = await axios.post("psychology/test/new-test", formdata);

  //   if (response.data.message === "pass-invalid") {
  //     Notify.fieldInvalid("pass-invalid");
  //   }

  //   if (response.data.message === "success") {
  //     getPsychologytest();
  //     e.target.reset();
  //     Notify.successRequest("new test");
  //   }
  // };

  useEffect(() => {
    getPsychologytest();
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Psychology Test",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={`Psychology Test`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Card>
            {/* <CardHeader title="List of Test" /> */}
            <CardContent>
              <Box mb={2}>
                <Typography color="textSecondary" variant="h5">
                  <strong>List of Test</strong>
                </Typography>
              </Box>

              <Divider light />

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <strong>Department</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Test</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Rate</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    {test.ready ? (
                      test.data.length > 0 ? (
                        test.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell> {data.department} </TableCell>
                            <TableCell> {data.test} </TableCell>
                            <TableCell align="right"> {data.rate} </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography color="primary">
                            Loading, Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <Card>
            <form onSubmit={handleNewTest}>
              <CardHeader title="Create Test" />
              <CardContent>
                <Box mb={2}>
                  <TextField
                    required
                    name="test"
                    label="Test"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    required
                    name="rate"
                    label="Rate"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Box>
                  <TextField
                    required
                    name="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                  />
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
                <Button size="large" variant="contained" color="default">
                  No
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default PsychologyTest;
