import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  Card,
  CardContent,
  Box,
  TextField,
  InputAdornment,
  TablePagination,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { ArrowLeft, Search } from "react-feather";
import PatientDetails from "../patients/PatientDetails";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PatientListByCompany = ({
  selectedCompany,
  selectedCompanyName,
  getCompanyList,
}) => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [selectedView, setSelectedView] = useState("list");
  const [patient, setPatient] = useState({ data: [], ready: false });
  const [selectedPatient, setSelectedPatient] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getAllPatientByCompanyId = useCallback(async () => {
    var params = {
      main_mgmt_id: users.main_mgmt_id,
      company_id: selectedCompany,
    };
    let response = await axios.get("admission/get/patients/by-company-id", {
      params,
    });
    const data = response.data;
    setPatient({ data, ready: true });
  }, [selectedCompany, users.main_mgmt_id]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getAllPatientByCompanyId();
  }, [getAllPatientByCompanyId]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      {selectedView === "list" ? (
        <>
          <Card>
            <CardContent>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={1.4}
              >
                <Box flexGrow={1}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>{selectedCompanyName} Patients</strong>
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    label="Search Patient"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Divider light />

              <Box>
                {patient.ready ? (
                  searchable.length > 0 ? (
                    <List component="div">
                      {searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <ListItem
                            key={index}
                            button
                            className="mb-2"
                            onClick={() => {
                              setSelectedPatient(data.patient_id);
                              setSelectedView(data.patient_id);
                            }}
                          >
                            <ListItemIcon>
                              {data.image === null ? (
                                <Avatar className={classes.primary}>
                                  {data.lastname.charAt().toUpperCase()}
                                </Avatar>
                              ) : (
                                <Avatar
                                  className={classes.primary}
                                  src={`${imageLocation}patients/${data.image}`}
                                  alt=""
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.lastname}, ${data.firstname} ${
                                Boolean(data.middle) ? data.middle : ``
                              } `}
                              secondary={`Added from ${
                                data.branchAdded !== null
                                  ? data.branchAdded
                                  : "BMCDC VAN"
                              }`}
                            />
                          </ListItem>
                        ))}
                    </List>
                  ) : (
                    Notify.noRecord()
                  )
                ) : (
                  Notify.loading()
                )}
              </Box>
              <TablePagination
                component="div"
                count={searchable.length}
                rowsPerPageOptions={[10, 50, 100]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Box mb={1}>
            <Button
              onClick={() => {
                setSelectedView("list");
                setSelectedPatient("");
              }}
              startIcon={<ArrowLeft />}
              variant="contained"
              color="primary"
            >
              BACK
            </Button>
          </Box>
          <PatientDetails
            patient_id={selectedPatient}
            getDoctorsPatient={() => getAllPatientByCompanyId()}
          />
        </>
      )}
    </Fragment>
  );
};

export default PatientListByCompany;
