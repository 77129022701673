import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
  //   TableFooter,
} from "@material-ui/core";
import { Cancel, Print } from "@material-ui/icons";
import Notify from "src/notification/Notify";

const ClinicSalePrint = ({
  report,
  recordNotGroup,
  close,
  grandTotal,
  dateFrom,
  dateTo,
}) => {
  const calculate = (group, notGroup) => {
    var total = 0;
    for (let x = 0; x < notGroup.length; x++) {
      if (
        parseInt(group.status) === 1 &&
        group.receipt_number === notGroup[x].receipt_number
      ) {
        total +=
          parseFloat(notGroup[x].bill_amount) +
          parseFloat(notGroup[x].bill_amount) *
            parseFloat(
              notGroup[x].home_service === null ? 0 : notGroup[x].home_service
            ) -
          parseFloat(notGroup[x].bill_amount) *
            parseFloat(
              notGroup[x].discount !== null
                ? parseInt(notGroup[x].can_be_discounted) === 1
                  ? notGroup[x].discount
                  : 0
                : 0
            );
      }
      if (
        parseInt(group.status) === 0 &&
        group.receipt_number === notGroup[x].receipt_number
      ) {
        total = 0;
      }
    }
    return total;
  };

  return (
    <Box m={2}>
      <Box align="center">
        <Box>
          <Typography>Order Slip</Typography>
        </Box>
        <Box>
          <Typography>
            {dateFrom === null && dateTo === null
              ? `Covering All Dates`
              : `For The Period Covering From ${
                  dateFrom !== null ? Notify.createdAt(dateFrom) : ""
                } to ${dateTo !== null ? Notify.createdAt(dateTo) : ""}`}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} mb={2} className="d-print-none">
        <Box flexGrow={1} />
        <Box mr={1}>
          <Button
            size="small"
            color="secondary"
            onClick={() => close()}
            startIcon={<Cancel />}
            variant="contained"
          >
            Close
          </Button>
        </Box>
        <Box>
          <Button
            size="small"
            color="primary"
            onClick={() => window.print()}
            startIcon={<Print />}
            variant="contained"
          >
            Print
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table size="small" className="table-bordered">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Date</b>
              </TableCell>
              <TableCell align="center">
                <b>Control #</b>
              </TableCell>
              <TableCell align="center">
                <b>Encoder</b>
              </TableCell>
              <TableCell align="center">
                <b>Client Name - Procedure</b>
              </TableCell>
              <TableCell align="center">
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.ready ? (
              report.data.length > 0 ? (
                report.data.map((a, x) => (
                  <Fragment key={x}>
                    <TableRow>
                      <TableCell>{Notify.createdAt(a.created_at)}</TableCell>
                      <TableCell>
                        {a.receipt_number.replace("receipt-", "")}
                      </TableCell>
                      <TableCell>{a.cashierName}</TableCell>
                      <TableCell>{`${a.lname}, ${a.fname}`}</TableCell>
                      <TableCell />
                    </TableRow>
                    {recordNotGroup.data.length > 0 &&
                      recordNotGroup.data
                        .filter((x) => x.receipt_number === a.receipt_number)
                        .map((y, z) => (
                          <TableRow key={z}>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>
                              <span
                                style={{
                                  color:
                                    parseInt(y.can_be_discounted) === 0
                                      ? "red"
                                      : "#000",
                                }}
                              >
                                {`P${Notify.convertToNumber(
                                  parseInt(y.status) === 1 ? y.bill_amount : 0
                                )} - ${y.bill_name}`}
                              </span>
                            </TableCell>
                            <TableCell align="right">
                              <b>
                                {parseInt(y.status) === 1
                                  ? Notify.convertToNumber(
                                      parseFloat(y.bill_amount) +
                                        parseFloat(y.bill_amount) *
                                          parseFloat(
                                            y.home_service === null
                                              ? 0
                                              : y.home_service
                                          ) -
                                        parseFloat(y.bill_amount) *
                                          parseFloat(
                                            y.discount !== null
                                              ? parseInt(
                                                  y.can_be_discounted
                                                ) === 1
                                                ? y.discount
                                                : 0
                                              : 0
                                          )
                                    )
                                  : "CANCELLED"}
                              </b>
                            </TableCell>
                          </TableRow>
                        ))}
                    <TableRow
                      style={{
                        backgroundColor:
                          a.home_service !== null
                            ? "#a9f5b3"
                            : a.discount !== null
                            ? "#7ae0ff"
                            : "#e4ebe4",
                      }}
                    >
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell align="right">
                        <b>
                          {Notify.convertToNumber(
                            calculate(a, recordNotGroup.data)
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    No record
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  Loading
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="right">
                <b style={{ fontSize: 16 }}>GRAND TOTAL:</b>
              </TableCell>
              <TableCell align="right">
                <b style={{ fontSize: 16 }}>
                  {Notify.convertToNumber(grandTotal)}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClinicSalePrint;
