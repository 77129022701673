import React, { useContext, useState, useRef, Fragment } from "react";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Button,
  TableBody,
  Menu,
  Dialog,
  Divider,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Search } from "react-feather";
import axios from "axios";
import { UsersData, UsersHeader } from "src/ContextAPI";
import {
  FilterList,
  Print,
  AddCircleOutlineOutlined,
} from "@material-ui/icons";
import ReactToPrint from "react-to-print";

const CashierSalesPrint = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { users } = useContext(UsersData);
  let printRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addExpenseDialog, setAddExpenseDialog] = useState(false);
  const { sidebarHeader } = React.useContext(UsersHeader);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalReportCash, setTotalReportCash] = useState(0);
  const [totalReportCharge, setTotalReportCharge] = useState(0);
  const [totalReportChargeCorporate, setTotalReportChargeCorporate] =
    useState(0);
  const [totalReportChargeHMO, setTotalReportChargeHMO] = useState(0);
  const [totalReportCashWalkin, setTotalReportCashWalkin] = useState(0);
  const [totalReportCashCorporate, setTotalReportCashCorporate] = useState(0);
  const [totalReportCashHomeService, setTotalReportCashHomeService] =
    useState(0);

  const [report, setReport] = useState({
    data: [],
    ready: false,
  });

  const [expense, setExpense] = useState({
    data: [],
    ready: false,
  });

  const type = [
    { name: "Doctor's PF" },
    { name: "Withholding Tax" },
    { name: "Laboratory Share" },
    { name: "OFW Rebates" },
    { name: "Send-outs" },
    { name: "Refund" },
    { name: "Others" },
  ];

  const handleReportByDate = async () => {
    var params = {
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      management_id: users.management_id,
      date_start: selectedDate,
    };
    setReport({ data: [], ready: false });
    axios
      .get("cashier/get/filter-by-date/sales", { params })
      .then((res) => {
        const data = res.data;
        setReport({ data, ready: true });
        setTotalReportCash(parseFloat(data[0].totalCash));
        setTotalReportCharge(parseFloat(data[0].totalCharge));

        setTotalReportChargeCorporate(
          data[0].totalChargeCorporate !== null
            ? parseFloat(data[0].totalChargeCorporate)
            : 0
        );
        setTotalReportChargeHMO(
          data[0].totalChargeHMO !== null
            ? parseFloat(data[0].totalChargeHMO)
            : 0
        );
        setTotalReportCashWalkin(
          data[0].totalCashWalkin !== null
            ? parseFloat(data[0].totalCashWalkin)
            : 0
        );
        setTotalReportCashCorporate(
          data[0].totalCashCorporate !== null
            ? parseFloat(data[0].totalCashCorporate)
            : 0
        );
        setTotalReportCashHomeService(
          data[0].totalCashHomeService !== null
            ? parseFloat(data[0].totalCashHomeService)
            : 0
        );
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleExpenseByDate = async () => {
    var params = {
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      management_id: users.management_id,
      date_start: selectedDate,
    };
    setExpense({ data: [], ready: false });
    axios
      .get("cashier/get/filter-by-date/sales-expenses", { params })
      .then((res) => {
        const data = res.data;
        setExpense({ data, ready: true });
        setTotalExpense(calculateExpense(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  //add expense every date
  const handleAddExpense = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("date_start", selectedDate);

    setIsSubmitting(true);
    axios
      .post("cashier/create/sales-expenses", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "pass-invalid") {
          Notify.fieldInvalid("password");
        }
        if (data === "success") {
          handleExpenseByDate();
          setAddExpenseDialog(false);
          Notify.successRequest("create expenses");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  //----------- computation ----------
  // expense
  const calculateExpense = (expense) => {
    var total = 0;
    for (let x = 0; x < expense.length; x++) {
      total += parseFloat(expense[x].amount) * 1;
    }
    return total;
  };
  //report
  // const calculateReport = (report) => {
  //   var total = 0;

  //   for (let x = 0; x < report.length; x++) {
  //     cash +=
  //       parseInt(report[x].is_charged) === 0
  //         ? parseFloat(report[x].bill_amount) * 1
  //         : 0;
  //   }
  //   return true;
  // };

  return (
    <>
      <Box component={Paper} p={2} ref={printRef}>
        <Box display="flex" className="d-print-none" mb={1}>
          <Box flexGrow={1}>
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<FilterList />}
                style={{ height: 38 }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                FILTER
              </Button>
            </Box>
          </Box>
          <Box>
            <Box>
              <Box display="flex">
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<AddCircleOutlineOutlined />}
                    style={{ height: 38 }}
                    onClick={(e) => setAddExpenseDialog(true)}
                    disabled={report.data.length > 0 ? false : true}
                  >
                    EXPENSES
                  </Button>
                </Box>
                <Box ml={1}>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          type="submit"
                          startIcon={<Print />}
                          style={{ height: 38 }}
                          disabled={report.data.length > 0 ? false : true}
                        >
                          PRINT
                        </Button>
                      );
                    }}
                    content={() => printRef.current}
                  />
                </Box>
              </Box>

              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={(e) => setAnchorEl(null)}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                getContentAnchorEl={null}
              >
                <Box m={1}>
                  <Box mb={1}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      style={{ fontWeight: "bolder" }}
                    >
                      Search Date
                    </Typography>
                  </Box>

                  <Divider />

                  <Box mt={1} mb={1}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="Date"
                      name="date_start"
                      fullWidth
                      margin="dense"
                      onChange={(e) => setSelectedDate(e.target.value)}
                      value={selectedDate !== null ? selectedDate : ""}
                    />
                  </Box>

                  <Box mb={1} display="flex">
                    <Box flexGrow={1} />
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Search />}
                      disabled={isSubmitting}
                      fullWidth
                      onClick={() => {
                        handleExpenseByDate();
                        handleReportByDate();
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Box>
        {report.ready && report.data.length > 0 && (
          <Box>
            <Box>
              <Typography variant="h5">
                <b>
                  MONTH:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {new Date(selectedDate).toLocaleString("en-US", {
                    month: "long",
                  })}
                </b>
              </Typography>
            </Box>
            <Box display={"flex"}>
              <Box flexGrow={1} />
              <Typography>
                {new Date(selectedDate).toLocaleDateString()}
              </Typography>
            </Box>
            <TableContainer>
              <Table size="small" className="table-bordered">
                <TableBody>
                  {report.data.length ? (
                    <>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>Daily Cash Sales</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{Notify.convertToNumber(totalReportCash)}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>Daily Changes Sales</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{Notify.convertToNumber(totalReportCharge)}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>TOTAL GROSS SALES:</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(
                              totalReportCash + totalReportCharge
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <b>
                            <span style={{ color: "red" }}>
                              CHARGE SALES DETAILS
                            </span>
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>Corporate</TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(totalReportChargeCorporate)}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>HMO</TableCell>
                        <TableCell align="right">
                          <b>{Notify.convertToNumber(totalReportChargeHMO)}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>OFW</TableCell>
                        <TableCell align="right">
                          {/* <b>{Notify.convertToNumber(0)}</b> */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>Total Charge Sales</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(
                              totalReportChargeCorporate + totalReportChargeHMO
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <b>
                            <span style={{ color: "red" }}>
                              CASH SALES DETAILS
                            </span>
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>Walk-in</TableCell>
                        <TableCell align="right">
                          <b>{Notify.convertToNumber(totalReportCashWalkin)}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>Corporate</TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(totalReportCashCorporate)}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>(Cash) Home Service</TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(totalReportCashHomeService)}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>(Cheque) Home Service</TableCell>
                        <TableCell align="right">
                          {/* <b>{Notify.convertToNumber(0)}</b> */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>(GCash) Home Service</TableCell>
                        <TableCell align="right">
                          {/* <b>{Notify.convertToNumber(0)}</b> */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>Total Cash Sales</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(
                              totalReportCashCorporate +
                                totalReportCashWalkin +
                                totalReportCashHomeService
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Box>
                            <b>
                              <span style={{ color: "red" }}>
                                Less EXPENSES
                              </span>
                            </b>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {type.map((data, index) => (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell width={150}>
                              <b>{data.name}</b>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                          {expense.data.length > 0 &&
                            expense.data
                              .filter((x) => x.type === data.name)
                              .map((y, a) => (
                                <TableRow key={a}>
                                  <TableCell />
                                  <TableCell>{y.name_desc}</TableCell>
                                  <TableCell align="right">
                                    <b>{Notify.convertToNumber(y.amount)}</b>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </Fragment>
                      ))}
                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>
                            <span style={{ fontSize: 16 }}>Total Expense</span>
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{Notify.convertToNumber(totalExpense)}</b>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width={150} />
                        <TableCell>
                          <b>
                            <span style={{ fontSize: 20, color: "red" }}>
                              TOTAL NET CASH SALES
                            </span>
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {Notify.convertToNumber(
                              totalReportCash + totalReportCharge - totalExpense
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        {Notify.noRecord()}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Signature and name */}
            <Box my={2} mt={5}>
              <Box>
                <Box width={"100%"} mb={2}>
                  <Typography
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                    style={{ marginLeft: "20%" }}
                  >
                    <b>Prepared by:</b>
                  </Typography>

                  <Box align="center">
                    <Typography
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                    >
                      <b> {sidebarHeader.name}</b>
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Box style={{ borderTop: "1px solid #888" }} maxWidth={350}>
                      <Typography
                        suppressContentEditableWarning={true}
                        contentEditable={true}
                      >
                        <b>Cashier</b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box width={"100%"}>
                  <Typography
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                    style={{ marginLeft: "20%" }}
                  >
                    <b>Checked & Verified by:</b>
                  </Typography>
                  <Box align="center">
                    <Typography
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                    >
                      <b>OCI FULLNAME</b>
                    </Typography>
                  </Box>
                  <Box align="center">
                    <Box style={{ borderTop: "1px solid #888" }} maxWidth={350}>
                      <Typography
                        suppressContentEditableWarning={true}
                        contentEditable={true}
                      >
                        <b>Clinic Coordinator(OIC)</b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Dialog
          open={addExpenseDialog}
          onClose={() => setAddExpenseDialog(false)}
        >
          <DialogContent>
            <form onSubmit={handleAddExpense}>
              <Box>
                <Typography variant="h6">Add Expenses</Typography>
              </Box>
              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="type"
                  label="Select Type"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  {type.map((item, x) => (
                    <option key={x} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </TextField>
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="name_desc"
                  label="Description/Name"
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  required
                  fullWidth
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0.01,
                      max: 9999999999999,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              <Box display={"flex"}>
                <Box flexGrow={1} />

                <Box mr={1}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => setAddExpenseDialog(false)}
                  >
                    Cancel
                  </Button>
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default CashierSalesPrint;
