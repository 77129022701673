import {
  Box,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Zoom,
  Typography,
  Button,
  Divider,
  TextField,
  Dialog,
} from "@material-ui/core";
import React, { useState, useEffect, useContext, useCallback } from "react";
import Container from "src/layout/Container";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import IsMountedRef from "src/utils/IsMountedRef";
import { Filter, Search, X } from "react-feather";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Notify from "src/notification/Notify";

const ClinicalSummary = () => {
  const [branchList, setBranchList] = useState({ data: [], ready: false });
  const [accreditedCompany, setAccreditedCompany] = useState([]);
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [docuFilter, setDocuFilter] = useState(false);
  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const getAllBranches = React.useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_management_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("hr/get/all-branches", { params });
      const data = response.data;
      setBranchList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await axios.get("admission/accredited/company/get-list", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setAccreditedCompany(response.data);
    }
  }, [mounted, users]);

  const handleSearchPatient = useCallback(() => {
    var params = {
      company: selectedCompany,
      management_id: selectedBranch,
      main_mgmt_id: users.main_mgmt_id,
    };
    axios
      .get("van/mobile-queue/clinical-summary/patient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
        setDocuFilter(false);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [users, selectedCompany, selectedBranch]);

  // const calculateBMI = (height, weight) => {
  //   var mtr = (Number(height) / 3.2808).toFixed(2);
  //   var mSqr = (mtr * mtr).toFixed(2);
  //   var bmi = (weight / mSqr).toFixed(2);
  //   return bmi;
  // };

  // const calculateBMIRange = (height, weight) => {
  //   var mtr = (Number(height) / 3.2808).toFixed(2);
  //   var mSqr = (mtr * mtr).toFixed(2);
  //   var bmi = (weight / mSqr).toFixed(2);
  //   if (bmi >= 0 && bmi <= 18.5) {
  //     return "Underweight";
  //   }
  //   if (bmi >= 18.6 && bmi <= 25.9) {
  //     return "Healthy";
  //   }
  //   if (bmi >= 26 && bmi <= 30.9) {
  //     return "Overweight";
  //   }
  //   if (bmi >= 31 && bmi <= 40.9) {
  //     return "Obese";
  //   }
  //   if (bmi >= 41) {
  //     return "Morbidly Obese";
  //   }
  // };

  useEffect(() => {
    getAllBranches();
    getAccreditedCompanyList();
  }, [getAllBranches, getAccreditedCompanyList]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Clinical Summary",
          items: [{ name: "Dashboard", path: "/bmcdc/app/documentation" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Clinical Summary</Box>
          </Box>
        }
      >
        <Card>
          <CardHeader
            title={
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box flexGrow={1} />
                <Box mx={1}>
                  <ReactHTMLTableToExcel
                    className="btn btn-info"
                    table="expense-report"
                    filename="Expense Report"
                    sheet="Expense Report"
                    buttonText="Download Excel"
                  />
                </Box>
                <Box>
                  <IconButton onClick={() => setDocuFilter(true)}>
                    <Filter fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            }
          />
          <CardContent>
            <TableContainer>
              <Table
                id="expense-report"
                size={"small"}
                className="table-bordered"
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={34} align="center">
                      <Typography variant="h6">
                        BMCDC Health Care Inc.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ background: "yellow" }}>
                    <TableCell colSpan={34} align="center">
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        ANNUAL PHYSICAL EXAMINATION RESULTS: TP
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={34} align="center">
                      {new Date().toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ background: "yellow" }}>
                    <TableCell colSpan={34} align="center">
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        CLINICAL SUMMARY
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell colSpan={2} align="center">
                      <strong>CHEST XRAY SUMMARY</strong>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>NO.</strong>
                    </TableCell>
                    <TableCell>
                      <strong>NAME</strong>
                    </TableCell>
                    <TableCell>
                      <strong>AGE/SEX</strong>
                    </TableCell>
                    <TableCell>
                      <strong>HT</strong>
                    </TableCell>
                    <TableCell>
                      <strong>WT</strong>
                    </TableCell>
                    <TableCell>
                      <strong>BMI</strong>
                    </TableCell>
                    <TableCell>
                      <strong>BMI RANGE</strong>
                    </TableCell>
                    <TableCell>
                      <strong>BP</strong>
                    </TableCell>
                    <TableCell>
                      <strong>PAST MEDICAL HISTORY</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DOCTORS NAME</strong>
                    </TableCell>
                    <TableCell>
                      <strong>LIC NO.</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DATE</strong>
                    </TableCell>
                    <TableCell>
                      <strong>HEPA A</strong>
                    </TableCell>
                    <TableCell>
                      <strong>HBSAG</strong>
                    </TableCell>
                    <TableCell>
                      <strong>FBS(4.2-6.4 mmol/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>CHOLE(2.99-5.2 mmol/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>TRI(0.41-1.88 mmol/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>HDL(0.9-1.42 mmol/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>LDL(0-3.4 mmol/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>
                        URIC ACID(Male:203-417 mmol/L) (Female:143-340 mmol/L)
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong>CREA(44.2-144.4 mmol/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>SGPT(0-40 U/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>SGOT(0-40 U/L)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>HBA1C(4.2-6.4 %)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>COMPLETE BLOOD COUNT</strong>
                    </TableCell>
                    <TableCell>
                      <strong>URINALYSIS</strong>
                    </TableCell>
                    <TableCell>
                      <strong>FECALYSIS</strong>
                    </TableCell>
                    <TableCell>
                      <strong>FILE NO.</strong>
                    </TableCell>
                    <TableCell>
                      <strong>IMPRESSION</strong>
                    </TableCell>
                    <TableCell>
                      <strong>ECG</strong>
                    </TableCell>
                    <TableCell>
                      <strong>MEDICATION</strong>
                    </TableCell>
                    <TableCell>
                      <strong>CLINICAL DIAGNOSIS</strong>
                    </TableCell>
                    <TableCell>
                      <strong>RECOMMENDATIONS</strong>
                    </TableCell>
                    <TableCell>
                      <strong>REMARKS</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patient.ready &&
                    (patient.data.length > 0 ? (
                      patient.data.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{`${data.firstname} ${
                            data.lastname
                          } ${
                            data.middle !== null ? data.middle : ""
                          }`}</TableCell>
                          <TableCell align="center">{`${Notify.calculateAge(
                            data.birthday
                          )}/${data.gender}`}</TableCell>
                          <TableCell align="center">{data.pe_ht}</TableCell>
                          <TableCell align="center">{data.pe_wt}</TableCell>
                          <TableCell align="center">{data.pe_bmi}</TableCell>
                          <TableCell align="center">{data.pe_range}</TableCell>
                          <TableCell align="center">{data.pe_bp}</TableCell>
                          <TableCell align="center">None</TableCell>
                          <TableCell align="center">
                            {data.doctorName}
                          </TableCell>
                          <TableCell align="center">{data.doctorLic}</TableCell>
                          <TableCell align="center">
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell align="center">
                            {data.hepaResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.hbsagResult}
                          </TableCell>
                          <TableCell align="center">{data.fbsResult}</TableCell>
                          <TableCell align="center">
                            {data.cholesterolResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.triglycerideResult}
                          </TableCell>
                          <TableCell align="center">{data.hdlResult}</TableCell>
                          <TableCell align="center">{data.ldlResult}</TableCell>
                          <TableCell align="center">
                            {data.uricResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.creaResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.sgptResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.sgotResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.hba1cResult}
                          </TableCell>
                          <TableCell align="center">
                            {data.cbcRemarks}
                          </TableCell>
                          <TableCell align="center">
                            {data.urinalysisRemarks}
                          </TableCell>
                          <TableCell align="center">
                            {data.fecalysisRemarks}
                          </TableCell>
                          <TableCell align="center">
                            {data.xray_file_no}
                          </TableCell>
                          <TableCell>
                            <Typography style={{ whiteSpace: "pre-line" }}>
                              {data.xray_impression}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {data.ecgRemarks}
                          </TableCell>
                          <TableCell align="center">
                            {data.pe_medication}
                          </TableCell>
                          <TableCell align="center">
                            {data.med_diagnosis}
                          </TableCell>
                          <TableCell align="center">
                            {data.med_recommendation}
                          </TableCell>
                          <TableCell align="center">
                            {data.med_remarks}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={22} align="center">
                          {Notify.noRecord()}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>

      <Dialog
        open={docuFilter}
        onClose={() => setDocuFilter(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box m={2}>
          <Box mb={1}>
            <Typography variant="h5">Filter</Typography>
          </Box>
          <Divider light />
          <Box mt={2} mb={2}>
            <TextField
              select
              SelectProps={{ native: true }}
              onChange={(e) => {
                setSelectedBranch(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              name="branch"
              label="Branch"
              variant="outlined"
              value={selectedBranch}
            >
              <option value={""}> Select </option>
              {branchList.data.length > 0 ? (
                branchList.data.map((data, index) => (
                  <option key={index} value={data.value}>
                    {data.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No branches found.
                </option>
              )}
            </TextField>
          </Box>

          <Box mb={2}>
            <TextField
              select
              SelectProps={{ native: true }}
              onChange={(e) => setSelectedCompany(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              name="company"
              label="Company"
              variant="outlined"
              value={selectedCompany}
            >
              <option value={""}> Select </option>
              {accreditedCompany.length > 0 ? (
                accreditedCompany.map((data, index) => (
                  <option key={index} value={data.company_id}>
                    {data.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No company found.
                </option>
              )}
            </TextField>
          </Box>

          <Box display="flex">
            <Box flexGrow={1} />
            <Box mr={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<X />}
                onClick={() => setDocuFilter(false)}
              >
                Close
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Search />}
                disabled={selectedBranch === null || selectedCompany === null}
                onClick={handleSearchPatient}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ClinicalSummary;
