import {
  Box,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import AccountingRequestDetails from "./AccountingRequestDetails";
import AccountingSelectedRequestDetails from "./AccountingSelectedRequestDetails";

const AccountingRequest = () => {
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [requestConfirm, setRequestConfirm] = useState("");
  const [requestConfirmRdy, setRequestConfirmRdy] = useState(false);
  const [selectedUnpaid, setSelectedUnpaid] = useState({
    data: null,
    ready: false,
  });

  const [stateOpenDetails, setStateOpenDetails] = useState(
    "request-confirm-list"
  );

  const getItemRequestConfirmList = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const response = await axios.get("laboratory/get/request-confirm", {
        params,
      });
      setRequestConfirm(response.data);
      setRequestConfirmRdy(true);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getItemRequestConfirmList();
  }, [getItemRequestConfirmList]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Request For Item",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={"Request For Item"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <Box component={Paper} variant="outlined" p={2}>
              {stateOpenDetails === "request-confirm-list" ? (
                <>
                  <Box mb={2}>
                    <Typography color="primary" variant="h5">
                      <strong>Clinic Request List </strong>
                    </Typography>
                  </Box>
                  <Box>
                    <List component="div">
                      {requestConfirmRdy
                        ? requestConfirm.length > 0
                          ? requestConfirm.map((data, index) => (
                              <ListItem
                                button
                                key={index}
                                className="mb-2"
                                selected={
                                  selectedUnpaid.data &&
                                  selectedUnpaid.data.request_id ===
                                    data.request_id
                                }
                                onClick={() => {
                                  setStateOpenDetails("request-confirm-spec");
                                  setSelectedUnpaid({
                                    data: data,
                                    ready: true,
                                  });
                                }}
                              >
                                <ListItemText
                                  className={`gtc-capitalize`}
                                  primary={Notify.dateTimeConvert(
                                    data.created_at
                                  )}
                                  secondary={data.request_id.replace(
                                    "req-",
                                    ""
                                  )}
                                />
                              </ListItem>
                            ))
                          : Notify.noRecord()
                        : Notify.loading()}
                    </List>

                    <TablePagination
                      component="div"
                      count={requestConfirm.length}
                      rowsPerPageOptions={[10, 50, 100]}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      labelRowsPerPage="List"
                    />
                  </Box>
                </>
              ) : (
                <AccountingRequestDetails
                  request_id={selectedUnpaid.data?.request_id}
                  close={() => {
                    setStateOpenDetails("request-confirm-list");
                    setSelectedUnpaid({
                      data: null,
                      ready: false,
                    });
                  }}
                />
              )}
            </Box>
          </Grid>

          {stateOpenDetails === "request-confirm-list" ? null : (
            <Grid item xs={12} sm={9} md={9}>
              <AccountingSelectedRequestDetails
                selectedUnpaid={selectedUnpaid}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default AccountingRequest;
