import React, { useEffect, Fragment, useCallback } from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  // faIdCardAlt,
  faPrescription,
  faVial,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";
// import PatientHeaderDetails from "./PatientHeaderDetails";
// import PatientSetAsDone from "./setasdone/PatientSetAsDone";
// import PDAppointmentList from "./patientlistdetails/PDAppointmentList";
import {
  CardMembership,
  EventAvailable,
  EventNote,
  // History,
  LocalHospital,
  PermContactCalendar,
  Timeline,
} from "@material-ui/icons";
// import PDProfile from "./patientlistdetails/PDProfile";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import PatientCard from "src/doctor/patients/PatientCard";
// import Vitals from "src/doctor/patients/Vitals";
// import Health from "src/doctor/patients/Health";
// import PDPrescription from "./patientlistdetails/PDPrescription";
// import PDNotes from "./patientlistdetails/PDNotes";
// import PDTreatmentPlan from "./patientlistdetails/PDTreatmentPlan";
// import PDMedCert from "./patientlistdetails/PDMedCert";
// import PatientHistory from "src/doctor/patients/PatientHistory";
// import NSPrescriptionTabs from "../tabs/prescription/NSPrescriptionTabs";
// import NSLaboratoryTabs from "../tabs/laboratory/NSLaboratoryTabs";
// import ImagingProcessed from "src/doctor/patients/imaging/ImagingProcessed";
// import PDHistory from "./patientlistdetails/PDHistory";
import PatientHeaderDetails from "../queue/PatientHeaderDetails";
import NSPatientAppointmentList from "./NSPatientAppointmentList";
import NSPatientProfile from "./NSPatientProfile";
import Vitals from "src/doctor/patients/Vitals";
import Health from "src/doctor/patients/Health";
import ImagingProcessed from "src/doctor/patients/imaging/ImagingProcessed";
import NSLaboratoryTabs from "./laboratory/NSLaboratoryTabs";
import NSPrescriptionTabs from "./prescription/NSPrescriptionTabs";
import NSPatientMedCertList from "./NSPatientMedCertList";
import NSPatientTreatmentPlan from "./NSPatientTreatmentPlan";
import NSPatientDoctorNotes from "./NSPatientDoctorNotes";
import NSPatientHistoryIllness from "./NSPatientHistoryIllness";

const NSPatientDetails = ({ patient_id, getAllPatientList, onClose }) => {
  const [category, setCategory] = React.useState(0);
  const [info, setInfo] = React.useState({
    data: null,
    ready: false,
  });

  const getPatientInformation = useCallback(async () => {
    var params = { patient_id: patient_id };
    await Axios.get("nurse/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [patient_id]);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {
    getPatientInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id, getPatientInformation]);

  return (
    <>
      {info.ready ? (
        <Fragment>
          <PatientHeaderDetails
            getPatientInformation={() => getPatientInformation()}
            patient_id={patient_id}
            info={info}
          />

          <Box mt={2} m={0} p={0}>
            <Box component={Paper} elevation={1} mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  wrapped
                  label="Appointment"
                  icon={<PermContactCalendar />}
                />

                <Tab wrapped label="Profile" icon={<AccountCircle />} />

                <Tab
                  wrapped
                  label="History"
                  icon={<FontAwesomeIcon icon={faFolderOpen} size={"2x"} />}
                />

                <Tab wrapped label="Vitals" icon={<Timeline />} />

                <Tab wrapped label="Health" icon={<LocalHospital />} />

                <Tab
                  wrapped
                  label="Laboratory"
                  icon={<FontAwesomeIcon icon={faVial} size={"2x"} />}
                />

                <Tab
                  wrapped
                  label="Imaging"
                  icon={<FontAwesomeIcon icon={faXRay} size={"2x"} />}
                />

                {/* <Tab
                  wrapped
                  label="Psychology"
                  icon={<FontAwesomeIcon icon={faCheckDouble} size={"2x"} />}
                /> */}

                <Tab
                  wrapped
                  label="Prescription"
                  icon={<FontAwesomeIcon icon={faPrescription} size={"2x"} />}
                />

                <Tab wrapped label="Notes" icon={<EventNote />} />

                <Tab wrapped label="Treatment Plan" icon={<EventAvailable />} />

                <Tab wrapped label="Med Cert" icon={<CardMembership />} />

                {/* <Tab wrapped label="History" icon={<History />} /> */}
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <NSPatientAppointmentList patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <NSPatientProfile
                patient_id={patient_id}
                getPatientInformation={() => getPatientInformation()}
                info={info}
              />
            </TabPanel>

            <TabPanel value={category} index={2}>
              <NSPatientHistoryIllness patient_id={patient_id} info={info} />
            </TabPanel>

            <TabPanel value={category} index={3}>
              <Vitals patient_id={patient_id} connection={"local"} />
            </TabPanel>

            <TabPanel value={category} index={4}>
              <Health
                patient_id={patient_id}
                connection={"local"}
                // user={"nurse"}
              />
            </TabPanel>

            <TabPanel value={category} index={5}>
              <NSLaboratoryTabs patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={6}>
              <ImagingProcessed
                patient_id={patient_id}
                imagingType="local-imaging"
              />
            </TabPanel>

            <TabPanel value={category} index={7}>
              <NSPrescriptionTabs patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={8}>
              <NSPatientDoctorNotes patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={9}>
              <NSPatientTreatmentPlan patient_id={patient_id} />
            </TabPanel>

            <TabPanel value={category} index={10}>
              <NSPatientMedCertList patient_id={patient_id} />
            </TabPanel>

            {/* <TabPanel value={category} index={11}>
              <PatientHistory patient_id={patient_id} connection={connection} />
            </TabPanel> */}
          </Box>
        </Fragment>
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default NSPatientDetails;
