import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TextField,
  Grid,
  CardMedia,
} from "@material-ui/core";
// import FormHeader from "./FormHeader";
import { useReactToPrint } from "react-to-print";
import { XCircle, CheckSquare, Printer, Edit } from "react-feather";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import moment from "moment";
import { DateTimePicker } from "@material-ui/pickers";

const NSPatientHistoryDetails = ({
  patient_id,
  info,
  details,
  chi_id,
  getAllIllnessPE,
}) => {
  const [isProcess, setIsProcess] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const printableRef = React.useRef();
  const { users } = React.useContext(UsersData);
  const [chartAdmissionDate, setChartAdmissionDate] = useState(null);
  const [chartDischargeDate, setChartDischargeDate] = useState(null);
  const [chartAttendingPhysician, setChartAttendingPhysician] = useState("");
  const [chartCoPhysician, setChartCoPhysician] = useState("");
  const [chartAdmittingImpression, setChartAdmittingImpression] = useState("");
  const [chartChieComplaint, setChartChieComplaint] = useState("");
  const [chartPertinentPE, setChartPertinentPE] = useState("");
  const [chartPasthistory, setChartPastHistory] = useState("");
  const [chartFamilyHistory, setChartFamilyHistory] = useState("");
  const [chartMedication, setChartMedication] = useState("");
  const [chartProcedure, setChartProcedure] = useState("");
  const [chartLabResult, setChartLabResult] = useState("");
  const [chartCourseInWard, setChartCourseInWard] = useState("");
  const [chartRecommendation, setChartRecommendation] = useState("");
  const [chartConditionOnDischarge, setChartConditionOnDischarge] =
    useState("");
  const [chartFinalDiagnosis, setChartFinalDiagnosis] = useState("");
  const [chartRd, setChartRd] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const handleHistoryDetails = (e) => {
    e.preventDefault();
    e.persist();

    var form = new FormData(e.target);
    form.append("user_id", users.user_id);
    form.append("main_mgmt_id", users.main_mgmt_id);
    form.append("management_id", users.management_id);
    form.append("chi_id", chi_id);
    form.append("patient_id", patient_id);
    form.append(
      "datetime_admission",
      moment(chartAdmissionDate).format("YYYY-MM-DD HH:mm:ss")
    );
    form.append(
      "datetime_discharge",
      moment(chartDischargeDate).format("YYYY-MM-DD HH:mm:ss")
    );
    form.append("attending_physician", chartAttendingPhysician);
    form.append("co_mgt_physician", chartCoPhysician);
    form.append("admitting_impression", chartAdmittingImpression);
    form.append("chief_complaint", chartChieComplaint);
    form.append("pertinent_pe", chartPertinentPE);
    form.append("past_history", chartPasthistory);
    form.append("fam_history", chartFamilyHistory);
    form.append("medications", chartMedication);
    form.append("procedures", chartProcedure);
    form.append("laboratory_results", chartLabResult);
    form.append("course_inthe_ward", chartCourseInWard);
    form.append("recommendation", chartRecommendation);
    form.append("condition_on_discharge", chartConditionOnDischarge);
    form.append("final_diagnosis", chartFinalDiagnosis);

    let err = [];

    if (form.get("chief_complaint") === null) {
      err = "error";
      Notify.fieldRequired("chief complaint");
    }

    if (err.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      axios
        .post("nurse/update/history-illness-pe/patient-id", form)
        .then((response) => {
          let data = response.data;
          if (data === "success") {
            setEnableEdit(false);
            getAllIllnessPE();
            Notify.successRequest("history updated");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsProcess(false));
    }
  };

  const generateClinicalSummaryData = (data) => {
    setChartAdmissionDate(
      data.datetime_admission === null ? "" : data.datetime_admission
    );
    setChartDischargeDate(
      data.datetime_discharge === null ? "" : data.datetime_discharge
    );
    setChartAttendingPhysician(
      data.attending_physician === null ? "" : data.attending_physician
    );
    setChartCoPhysician(
      data.co_mgt_physician === null ? "" : data.co_mgt_physician
    );
    setChartAdmittingImpression(
      data.admitting_impression === null ? "" : data.admitting_impression
    );
    setChartChieComplaint(
      data.chief_complaint === null ? "" : data.chief_complaint
    );
    setChartPertinentPE(data.pertinent_pe === null ? "" : data.pertinent_pe);
    setChartPastHistory(data.past_history === null ? "" : data.past_history);
    setChartFamilyHistory(data.fam_history === null ? "" : data.fam_history);
    setChartMedication(data.medications === null ? "" : data.medications);
    setChartProcedure(data.procedures === null ? "" : data.procedures);
    setChartLabResult(
      data.laboratory_results === null ? "" : data.laboratory_results
    );
    setChartCourseInWard(
      data.course_inthe_ward === null ? "" : data.course_inthe_ward
    );
    setChartRecommendation(
      data.recommendation === null ? "" : data.recommendation
    );
    setChartConditionOnDischarge(
      data.condition_on_discharge === null ? "" : data.condition_on_discharge
    );
    setChartFinalDiagnosis(
      data.final_diagnosis === null ? "" : data.final_diagnosis
    );
    setChartRd(true);
  };

  useEffect(() => {
    generateClinicalSummaryData(details);
  }, [details, patient_id]);

  return (
    <div>
      <Card ref={printableRef}>
        <CardContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_logo.png"
                    alt="bmcdc logo"
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box align="center">
                  <Typography variant="h3">
                    <b>BMCDC HEALTHCARE, INC.</b>
                  </Typography>
                  <Typography>
                    <b>DOOR G/F, GIMENES BLGD., KM. 5 BUHANGIN, DAVAO CITY</b>
                  </Typography>
                  <Typography>
                    <b>Tel. 285-4324/293-9684, Telefax: 241-0925</b>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_medical.png"
                    alt="medical logo"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box my={1}>
            <Typography align="center">
              <b>HISTORY OF PRESENT ILLNESS AND PHYSICAL EXAMINATION</b>
            </Typography>
          </Box>
          {chartRd ? (
            <form onSubmit={handleHistoryDetails}>
              <Box>
                <Box my={1}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="d-print-none"
                    mb={1}
                  >
                    {enableEdit ? (
                      <>
                        <Box>
                          <Button
                            color="secondary"
                            onClick={() => setEnableEdit(false)}
                            startIcon={<XCircle />}
                          >
                            Close
                          </Button>
                        </Box>

                        <Box ml={2}>
                          <Button
                            color="primary"
                            startIcon={
                              isProcess ? (
                                <CircularProgress size={22} color="inherit" />
                              ) : (
                                <CheckSquare />
                              )
                            }
                            type="submit"
                            disabled={isProcess}
                          >
                            Save
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box mr={2}>
                          <Button
                            color="primary"
                            onClick={() => setEnableEdit(true)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        </Box>

                        <Box>
                          <Button
                            color="primary"
                            onClick={handlePrint}
                            startIcon={<Printer />}
                          >
                            Print
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box my={2}>
                <Table className="table-bordered">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="caption">Name:</Typography>
                        <Typography>
                          {info.ready
                            ? `${info.data.lastname}, ${info.data.firstname} ${
                                info.data.middle === null
                                  ? ""
                                  : info.data.middle
                              }`
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Typography variant="caption"> Age </Typography>
                        <Typography>
                          {info.ready
                            ? info.data.birthday === null
                              ? ""
                              : Notify.calculateAge(info.data.birthday)
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={4}>
                        <Typography variant="caption"> Gender </Typography>
                        <Typography>
                          {info.ready ? info.data.gender : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography variant="caption">
                          Date/Time of Admission:
                        </Typography>
                        <Box>
                          {enableEdit ? (
                            <DateTimePicker
                              label=""
                              value={chartAdmissionDate}
                              onChange={(date) => setChartAdmissionDate(date)}
                              autoOk
                              fullWidth
                            />
                          ) : (
                            <Typography>
                              {chartAdmissionDate
                                ? Notify.dateTimeConvert(
                                    moment(chartAdmissionDate).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  )
                                : ""}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={6}>
                        <Typography variant="caption">
                          Date/Time of Discharge:
                        </Typography>
                        <Box>
                          {enableEdit ? (
                            <DateTimePicker
                              label=""
                              value={chartDischargeDate}
                              onChange={(date) => setChartDischargeDate(date)}
                              autoOk
                              fullWidth
                            />
                          ) : (
                            <Typography>
                              {chartDischargeDate
                                ? Notify.dateTimeConvert(
                                    moment(chartDischargeDate).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  )
                                : ""}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography variant="caption">
                          Attending Physician:
                        </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              name=""
                              value={chartAttendingPhysician}
                              onChange={(e) =>
                                setChartAttendingPhysician(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartAttendingPhysician}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={6}>
                        <Typography variant="caption">
                          Admitting Impression:
                        </Typography>
                        <Box>
                          {enableEdit ? (
                            <TextField
                              fullWidth
                              name=""
                              value={chartAdmittingImpression}
                              onChange={(e) =>
                                setChartAdmittingImpression(e.target.value)
                              }
                            />
                          ) : (
                            <Typography className="text-capitalize">
                              {chartAdmittingImpression}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box>
                <Box my={2}>
                  <Typography variant="subtitle2">
                    Brief Clinical History / Chief Complaint
                  </Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartChieComplaint}
                      onChange={(e) => setChartChieComplaint(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartChieComplaint}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">
                    Pertinent Physical Exam
                  </Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartPertinentPE}
                      onChange={(e) => setChartPertinentPE(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartPertinentPE}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">
                    Past Medical History
                  </Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartPasthistory}
                      onChange={(e) => setChartPastHistory(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartPasthistory}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">Family History</Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartFamilyHistory}
                      onChange={(e) => setChartFamilyHistory(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartFamilyHistory}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">Medications</Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartMedication}
                      onChange={(e) => setChartMedication(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartMedication}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">Procedures</Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartProcedure}
                      onChange={(e) => setChartProcedure(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartProcedure}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">
                    {" "}
                    Laboratory Results{" "}
                  </Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartLabResult}
                      onChange={(e) => setChartLabResult(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartLabResult}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">
                    Course in the Ward
                  </Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartCourseInWard}
                      onChange={(e) => setChartCourseInWard(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartCourseInWard}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">Recommendation</Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartRecommendation}
                      onChange={(e) => setChartRecommendation(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartRecommendation}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">
                    Condition on Discharge
                  </Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartConditionOnDischarge}
                      onChange={(e) =>
                        setChartConditionOnDischarge(e.target.value)
                      }
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartConditionOnDischarge}
                    </Typography>
                  )}
                </Box>

                <Box my={2}>
                  <Typography variant="subtitle2">Final Diagnosis</Typography>
                  {enableEdit ? (
                    <TextField
                      fullWidth
                      name=""
                      value={chartFinalDiagnosis}
                      onChange={(e) => setChartFinalDiagnosis(e.target.value)}
                    />
                  ) : (
                    <Typography className="text-capitalize">
                      {chartFinalDiagnosis}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box my={3} display={"flex"} justifyContent={"flex-start"}>
                <Box align="center">
                  <Box borderBottom={1} px={1}>
                    <Typography className="text-capitalize">
                      {chartAttendingPhysician}
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    Signature Over Printed Name of Physician
                  </Typography>
                </Box>
              </Box>
            </form>
          ) : (
            Notify.loading()
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default NSPatientHistoryDetails;
