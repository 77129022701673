import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import React from "react";
import Notify from "src/notification/Notify";

const BMIComputation = ({ weight, height }) => {
  const [bmi, setBmi] = React.useState(0);

  const handleCalculateBMI = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var kg = formdata.get("weight");
    var ft = formdata.get("height");

    if (kg === 0 && ft === 0) {
      Notify.customToast("Field Invalid", "weight and height cannot be zero");
      setBmi(0);
      return;
    }

    var mtr = (Number(ft) / 3.2808).toFixed(2);
    var mSqr = (mtr * mtr).toFixed(2);
    var bmi = (kg / mSqr).toFixed(2);

    setBmi(bmi);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <Typography variant="h6" color="textSecondary">
              <strong>BMI Computation</strong>
            </Typography>
          </Box>

          <Box align="center">
            <Typography variant="h1">{bmi}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              BODY MASS INDEX RESULT
            </Typography>
          </Box>

          <form onSubmit={handleCalculateBMI}>
            <Box my={2}>
              <TextField
                label="Weight (Kg)"
                fullWidth
                margin="normal"
                name="weight"
                variant="outlined"
                defaultValue={!Boolean(parseInt(weight)) ? 0 : weight}
              />
            </Box>

            <Box mb={2}>
              <TextField
                label="Height (Ft)"
                fullWidth
                margin="normal"
                name="height"
                variant="outlined"
                defaultValue={!Boolean(parseInt(height)) ? 0 : height}
              />
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
              >
                Calculate
              </Button>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box>
            <Box>
              <Typography variant="h6" color="textSecondary">
                <strong>BMI Basic Table</strong>
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> BMI </TableCell>
                    <TableCell align="center"> ASSESSMENT </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow hover>
                    <TableCell align="center"> Below 18.5 </TableCell>
                    <TableCell align="center"> Underweight </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="center"> 18.5 - 25 </TableCell>
                    <TableCell align="center"> Healthy </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="center"> 25 - 30 </TableCell>
                    <TableCell align="center"> Overweight </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="center"> 30 - 40 </TableCell>
                    <TableCell align="center"> Obese </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="center"> 40+ </TableCell>
                    <TableCell align="center"> Morbidly Obese </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BMIComputation;
