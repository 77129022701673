import {
  Box,
  Grid,
  Card,
  // CardHeader,
  CardContent,
  TextField,
  InputAdornment,
  TablePagination,
  ListItemText,
  ListItem,
  List,
  CardMedia,
  Typography,
  IconButton,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState, useCallback, useEffect } from "react";
import { Search } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import PatientListByCompany from "./PatientListByCompany";

const AdmissionCompanyList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [companyList, setCompanyList] = useState({ data: [], ready: false });
  const { users } = useContext(UsersData);
  const [selectedCompany, setSelectedCompany] = useState("no-company");
  const [selectedCompanyName, setSelectedCompanyName] = useState("no-company");
  const [searchDisplay, setSearchDisplay] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getCompanyList = useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    axios
      .get("admission/get/company-list", { params })
      .then((response) => {
        const data = response.data;
        setCompanyList({ data: data, ready: true });
      })
      .catch((error) => {
        console.log("error: ", error);
        // Notify.requestError(error);
      });
  }, [users]);

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  const searchable = companyList.data.filter((data) => {
    return data.company.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "company list for APE",
          items: [{ name: "dashboard", path: "/bmcdc/app/registration" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Company List for APE</Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Card>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                m={2}
              >
                <Box flexGrow={1}>
                  <Typography variant="h5" color="textSecondary">
                    <strong>Company List</strong>
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    onClick={() => setSearchDisplay(!searchDisplay)}
                  >
                    <Search fontSize={"small"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider light />
              <CardContent>
                <Box mb={1} hidden={!searchDisplay}>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box>
                  {companyList.ready ? (
                    searchable.length > 0 ? (
                      <List component="div">
                        {searchable
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data, index) => (
                            <ListItem
                              key={index}
                              button
                              className="mb-2"
                              onClick={() => {
                                setSelectedCompany(data.company_id);
                                setSelectedCompanyName(data.company);
                              }}
                              selected={selectedCompany === data.company_id}
                            >
                              <ListItemText
                                className={`gtc-capitalize`}
                                primary={`${data.company}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      Notify.noRecord()
                    )
                  ) : (
                    Notify.loading()
                  )}
                </Box>

                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} lg={9}>
            {selectedCompany === "no-company" ? (
              <RenderSelectPatient />
            ) : (
              <PatientListByCompany
                selectedCompany={selectedCompany}
                selectedCompanyName={selectedCompanyName}
                getCompanyList={() => getCompanyList()}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdmissionCompanyList;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 250 }}
    />
    <Typography color="secondary" variant="h3">
      NO COMPANY SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select company in the list to view details.
    </Typography>
  </Box>
);
