import {
  Box,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import PrintIcon from "@material-ui/icons/Print";
import { UsersData } from "src/ContextAPI";
import { XCircle } from "react-feather";
// import ChemistryOrder from "src/laboratory/print/ChemistryOrder";
// import HemaOrder from "src/laboratory/print/HemaOrder";
// import SorologyOrder from "src/laboratory/print/SorologyOrder";
// import ClinicalMicroscopyOrder from "src/laboratory/print/ClinicalMicroscopyOrder";
// import FecalAnalysisOrder from "src/laboratory/print/FecalAnalysisOrder";
// import UrinalysisOrder from "src/laboratory/print/UrinalysisOrder";
// import StooltestOrder from "src/laboratory/print/StooltestOrder";
// import EcgOrder from "src/laboratory/print/EcgOrder";
// import MedicalExam from "src/laboratory/print/MedicalExam";
// import PapsmearOrder from "src/laboratory/print/PapsmearOrder";
// import OralGlucoseOrder from "src/laboratory/print/OralGlucoseOrder";
// import ThyroidProfileOrder from "src/laboratory/print/ThyroidProfileOrder";
// import ImmunologyOrder from "src/laboratory/print/ImmunologyOrder";
// import MiscellaneousOrder from "src/laboratory/print/MiscellaneousOrder";
// import HepatitisProfileOrder from "src/laboratory/print/HepatitisProfileOrder";
// import CBCOrder from "src/laboratory/print/CBCOrder";
// import CovidTestOrder from "src/laboratory/print/CovidTestOrder";
// import TumorMakerOrder from "src/laboratory/print/TumorMakerOrder";
// import DrugTestOrder from "src/laboratory/print/DrugTestOrder";

//new
import CBCPrintAll from "./printall/forms/CBCPrintAll";
import HematologyPrintAll from "./printall/forms/HematologyPrintAll";
import SerologyPrintAll from "./printall/forms/SerologyPrintAll";
import ClinicalMicroscopyPrintAll from "./printall/forms/ClinicalMicroscopyPrintAll";
import ChemistryPrintAll from "./printall/forms/ChemistryPrintAll";
import StoolPrintAll from "./printall/forms/StoolPrintAll";
import UrinalysisPrintAll from "./printall/forms/UrinalysisPrintAll";
import ThyroidProfilePrintAll from "./printall/forms/ThyroidProfilePrintAll";
import MiscellaneousPrintAll from "./printall/forms/MiscellaneousPrintAll";
import HepatitisProfilePrintAll from "./printall/forms/HepatitisProfilePrintAll";
import Covid19PrintAll from "./printall/forms/Covid19PrintAll";
import TumorMakerPrintAll from "./printall/forms/TumorMakerPrintAll";
import DrugTestPrintAll from "./printall/forms/DrugTestPrintAll";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PrintMobileVanOrders = ({
  close,
  selectedTraceNoData,
  handleSearchPatient,
  selectedBranch,
}) => {
  const classes = useStyles();
  const { users } = React.useContext(UsersData);
  const [print, setPrint] = useState("");
  const [printProcess, setPrintProcess] = useState(false);

  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  const [patients, setPatients] = useState({
    data: [],
    ready: false,
  });

  const [hemaOrderDetails, setHemaOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [serologyOrderDetails, setSerologyOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [clinicalMicroscopyOrderDetails, setClinicalMicroscopyOrderDetails] =
    useState({
      data: [],
      ready: false,
    });

  // const [fecalAnalysisOrderDetails, setFecalAnalysisOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  const [chemistryOrderDetails, setChemistryOrderDetails] = useState({
    data: [],
    ready: false,
  });

  // const [papsmearOrderDetails, setPapsmearOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  const [urinalysicOrderDetails, setUrinalysicOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [stooltestOrderDetails, setStooltestOrderDetails] = useState({
    data: [],
    ready: false,
  });

  // const [ecgOrderDetails, setEcgOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  // const [medicalExamOrderDetails, setMedicalExamOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  // const [oralGlucoseOrderDetails, setOralGlucoseOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  const [thyroidProfOrderDetails, setThyroidProfOrderDetails] = useState({
    data: [],
    ready: false,
  });

  // const [immunologyOrderDetails, setImmunologyOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  const [miscellaneousOrderDetails, setMiscellaneousOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [hepatitisProfOrderDetails, setHepatitisProfOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [cbcHemaOrderDetails, setCBCHemaOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [covidOrderDetails, setCovidOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [tumorMakerOrderDetails, setTumorMakerOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [drugTestOrderDetails, setDrugTestOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const getLabFormHeader = () => {
    var params = { management_id: selectedBranch };
    Axios.get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getPatientInformation = () => {
    var params = { patient_id: selectedTraceNoData.patient_id };
    Axios.get("documentation/get/patient-info/patient-id", { params })
      .then((response) => {
        const data = response.data;
        setPatients({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getHemaOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-hemathology/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setHemaOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getSerologyOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-sorology/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setSerologyOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getClinicalMicroscopyOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get(
      "laboratory/order/ordernew-clinicalmicroscopy/complete/details-print",
      { params }
    )
      .then((response) => {
        const data = response.data;
        setClinicalMicroscopyOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const getFecalAnalysisOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: selectedTraceNoData.trace_number,
  //   };
  //   Axios.get(
  //     "laboratory/order/ordernew-fecalanalysis/complete/details-print",
  //     { params }
  //   )
  //     .then((response) => {
  //       const data = response.data;
  //       setFecalAnalysisOrderDetails({ data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  const getChemistryOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-chemistry/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setChemistryOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getStooltestOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-stooltest/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setStooltestOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const getPapsmearOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: selectedTraceNoData.trace_number,
  //   };
  //   Axios.get("laboratory/order/ordernew-papsmear/complete/details-print", {
  //     params,
  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       setPapsmearOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  const getUrinalysisOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-urinalysis/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setUrinalysicOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const getEcgOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: selectedTraceNoData.trace_number,
  //   };
  //   Axios.get("laboratory/order/ordernew-ecg/complete/details-print", {
  //     params,
  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       setEcgOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  // const getMedicalExamOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: selectedTraceNoData.trace_number,
  //   };
  //   Axios.get("laboratory/order/ordernew-medicalexam/complete/details-print", {
  //     params,
  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       setMedicalExamOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  // const getOralGlucoseOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: selectedTraceNoData.trace_number,
  //   };
  //   Axios.get("laboratory/order/ordernew-oralglucose/complete/details-print", {
  //     params,
  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       setOralGlucoseOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  const getThyroidProfileOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get(
      "laboratory/order/ordernew-thyroidprofile/complete/details-print",
      {
        params,
      }
    )
      .then((response) => {
        const data = response.data;
        setThyroidProfOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const getImmunologyOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: selectedTraceNoData.trace_number,
  //   };
  //   Axios.get("laboratory/order/ordernew-immunology/complete/details-print", {
  //     params,
  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       setImmunologyOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  const getMiscellaneousOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get(
      "laboratory/order/ordernew-miscellaneous/complete/details-print",
      {
        params,
      }
    )
      .then((response) => {
        const data = response.data;
        setMiscellaneousOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getHepatitisProfileOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get(
      "laboratory/order/ordernew-hepatitisprofile/complete/details-print",
      {
        params,
      }
    )
      .then((response) => {
        const data = response.data;
        setHepatitisProfOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getCBCHemaOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-cbc/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setCBCHemaOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getCovidTestOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-covidtest/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setCovidOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getTumorMakerOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-tumormaker/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setTumorMakerOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getDrugTestOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
      management_id: selectedBranch,
    };
    Axios.get("laboratory/order/ordernew-drugtest/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setDrugTestOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getLabFormHeader();
    getPatientInformation();
    getHemaOrderDetails();
    getSerologyOrderDetails();
    getClinicalMicroscopyOrderDetails();
    // getFecalAnalysisOrderDetails();
    getChemistryOrderDetails();
    // getPapsmearOrderDetails();
    // getEcgOrderDetails();
    // getMedicalExamOrderDetails();
    getUrinalysisOrderDetails();
    getStooltestOrderDetails();
    // getOralGlucoseOrderDetails();
    getThyroidProfileOrderDetails();
    // getImmunologyOrderDetails();
    getMiscellaneousOrderDetails();
    getHepatitisProfileOrderDetails();
    getCBCHemaOrderDetails();
    getCovidTestOrderDetails();
    getTumorMakerOrderDetails();
    getDrugTestOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTraceNoData]);

  const handlePrint = (e) => {
    setPrint(e.currentTarget.id);
    setPrintProcess(true);
    setTimeout(() => {
      setPrintProcess(false);
      window.print();
    }, 4000);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        disableBackdropClick
        disableEscapeKeyDown
        open={true}
        onClose={() => console.log("dialog unclosable")}
      >
        <Box display="flex" className="gtc-hideonprint">
          <Box flexGrow={1}>
            <DialogTitle> Print Result Report </DialogTitle>
          </Box>
          <Box>
            <IconButton
              // onClick={() => history.push("/bmcdc/app/laboratory/lab-report")}
              onClick={() => close()}
              color="secondary"
            >
              <XCircle />
            </IconButton>
          </Box>
        </Box>

        <DialogContent>
          <Box m={2}>
            <Backdrop open={printProcess} className={classes.backdrop}>
              <Box alignItems="center" align="center">
                <CircularProgress color="inherit" />
                <Typography>Please wait...</Typography>
              </Box>
            </Backdrop>

            <Grid container spacing={2}>
              <Grid className="gtc-hideonprint" item xs={12} sm={3} lg={2}>
                <Box>
                  <Button
                    hidden={hemaOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="hema"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Hemathology
                  </Button>

                  <Button
                    hidden={cbcHemaOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="hema"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    CBC
                  </Button>

                  <Button
                    hidden={serologyOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="sero"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Serology
                  </Button>

                  <Button
                    hidden={clinicalMicroscopyOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="c-micro"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Clinical Microscopy
                  </Button>

                  {/* <Button
                    hidden={fecalAnalysisOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="fecal"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Fecal Analysis
                  </Button> */}

                  <Button
                    hidden={chemistryOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="c-chem"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Blood Chemistry
                  </Button>
                  <Button
                    hidden={stooltestOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="c-stooltest"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Fecalysis
                  </Button>

                  <Button
                    hidden={urinalysicOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="c-urinalysis"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Urinalysis
                  </Button>

                  {/* <Button
                    hidden={papsmearOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="papsmear"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Papsmear Result
                  </Button> */}

                  {/* <Button
                    hidden={ecgOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="c-ecg"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Electrocardiogram (ecg)
                  </Button> */}

                  {/* <Button
                    hidden={medicalExamOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="c-medical"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Medical Examination
                  </Button> */}

                  {/* <Button
                    hidden={oralGlucoseOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="oral-glucose"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Oral Glucose Tolerance
                  </Button> */}

                  <Button
                    hidden={thyroidProfOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="thyroid-profile"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Thyroid Profile
                  </Button>

                  {/* <Button
                    hidden={immunologyOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="immunology"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Immunology
                  </Button> */}

                  <Button
                    hidden={miscellaneousOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="miscellaneous"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Miscellaneous
                  </Button>

                  <Button
                    hidden={hepatitisProfOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="hepatitis-profile"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Hepatitis Profile
                  </Button>

                  <Button
                    hidden={covidOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="covid-test"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Covid Test
                  </Button>

                  <Button
                    hidden={tumorMakerOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="tumor-maker"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Tumor Maker
                  </Button>

                  <Button
                    hidden={drugTestOrderDetails.data.length === 0}
                    variant="outlined"
                    onClick={handlePrint}
                    id="drug-test"
                    color="primary"
                    startIcon={<PrintIcon />}
                    fullWidth
                    size="large"
                    component={Box}
                    my={1}
                  >
                    Drug Test
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={9} lg={10}>
                {Object.keys(formHeader).length > 0 ? (
                  <>
                    <Box
                      mb={2}
                      className={
                        print === "hema" ? "gtc-showonprint" : "gtc-hideonprint"
                      }
                    >
                      {hemaOrderDetails.ready &&
                        hemaOrderDetails.data.length > 0 && (
                          <HematologyPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            hemaOrderDetails={hemaOrderDetails}
                          />
                        )}

                      {cbcHemaOrderDetails.ready &&
                        cbcHemaOrderDetails.data.length > 0 && (
                          <CBCPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            CBCTestOrderDetails={cbcHemaOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "sero" ? "gtc-showonprint" : "gtc-hideonprint"
                      }
                    >
                      {serologyOrderDetails.ready &&
                        serologyOrderDetails.data.length > 0 && (
                          <SerologyPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            serologyOrderDetails={serologyOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "c-micro"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {clinicalMicroscopyOrderDetails.ready &&
                        clinicalMicroscopyOrderDetails.data.length > 0 && (
                          <ClinicalMicroscopyPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            clinicalMicroscopyOrderDetails={
                              clinicalMicroscopyOrderDetails
                            }
                          />
                        )}
                    </Box>

                    {/* <Box
                      mb={2}
                      className={
                        print === "fecal"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {fecalAnalysisOrderDetails.ready &&
                        fecalAnalysisOrderDetails.data.length > 0 && (
                          <FecalAnalysisOrder
                            formheader={formHeader}
                            orderDetails={fecalAnalysisOrderDetails}
                          />
                        )}
                    </Box> */}

                    <Box
                      mb={2}
                      className={
                        print === "c-chem"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {chemistryOrderDetails.ready &&
                        chemistryOrderDetails.data.length > 0 && (
                          <ChemistryPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            chemistryOrderDetails={chemistryOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "c-stooltest"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {stooltestOrderDetails.ready &&
                        stooltestOrderDetails.data.length > 0 && (
                          <StoolPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            stooltestOrderDetails={stooltestOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "c-urinalysis"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {urinalysicOrderDetails.ready &&
                        urinalysicOrderDetails.data.length > 0 && (
                          <UrinalysisPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            urinalysicOrderDetails={urinalysicOrderDetails}
                          />
                        )}
                    </Box>

                    {/* <Box
                      mb={2}
                      className={
                        print === "papsmear"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {papsmearOrderDetails.ready &&
                        papsmearOrderDetails.data.length > 0 && (
                          <PapsmearOrder
                            formheader={formHeader}
                            papsmearOrderDetails={papsmearOrderDetails}
                          />
                        )}
                    </Box> */}

                    {/* <Box
                      mb={2}
                      className={
                        print === "c-ecg"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {ecgOrderDetails.ready &&
                        ecgOrderDetails.data.length > 0 && (
                          <EcgOrder
                            formheader={formHeader}
                            orderDetails={ecgOrderDetails}
                          />
                        )}
                    </Box> */}

                    {/* <Box
                      mb={2}
                      className={
                        print === "c-medical"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {medicalExamOrderDetails.ready &&
                        medicalExamOrderDetails.data.length > 0 && (
                          <MedicalExam
                            formheader={formHeader}
                            orderDetails={medicalExamOrderDetails}
                          />
                        )}
                    </Box> */}

                    {/* <Box
                      mb={2}
                      className={
                        print === "oral-glucose"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {oralGlucoseOrderDetails.ready &&
                        oralGlucoseOrderDetails.data.length > 0 && (
                          <OralGlucoseOrder
                            formheader={formHeader}
                            orderDetails={oralGlucoseOrderDetails}
                          />
                        )}
                    </Box> */}

                    <Box
                      mb={2}
                      className={
                        print === "thyroid-profile"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {thyroidProfOrderDetails.ready &&
                        thyroidProfOrderDetails.data.length > 0 && (
                          <ThyroidProfilePrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            thyroidProfOrderDetails={thyroidProfOrderDetails}
                          />
                        )}
                    </Box>

                    {/* <Box
                      mb={2}
                      className={
                        print === "immunology"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {immunologyOrderDetails.ready &&
                        immunologyOrderDetails.data.length > 0 && (
                          <ImmunologyOrder
                            formheader={formHeader}
                            orderDetails={immunologyOrderDetails}
                          />
                        )}
                    </Box> */}

                    <Box
                      mb={2}
                      className={
                        print === "miscellaneous"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {miscellaneousOrderDetails.ready &&
                        miscellaneousOrderDetails.data.length > 0 && (
                          <MiscellaneousPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            miscellaneousOrderDetails={
                              miscellaneousOrderDetails
                            }
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "hepatitis-profile"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {hepatitisProfOrderDetails.ready &&
                        hepatitisProfOrderDetails.data.length > 0 && (
                          <HepatitisProfilePrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            hepatitisProfOrderDetails={
                              hepatitisProfOrderDetails
                            }
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "covid-test"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {covidOrderDetails.ready &&
                        covidOrderDetails.data.length > 0 && (
                          <Covid19PrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            covidOrderDetails={covidOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "tumor-maker"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {tumorMakerOrderDetails.ready &&
                        tumorMakerOrderDetails.data.length > 0 && (
                          <TumorMakerPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            tumorMakerOrderDetails={tumorMakerOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "drug-test"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {drugTestOrderDetails.ready &&
                        drugTestOrderDetails.data.length > 0 && (
                          <DrugTestPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            drugTestOrderDetails={drugTestOrderDetails}
                          />
                        )}
                    </Box>
                  </>
                ) : (
                  "Laboratory information not set in database."
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PrintMobileVanOrders;
