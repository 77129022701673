import React, { useEffect, useState, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  Collapse,
  IconButton,
  TablePagination,
  useTheme,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import axios from "axios";
import ClinicalMicroscopy from "src/doctor/patients/laboratory_new/orderform/ClinicalMicroscopy";
import Hemathology from "src/doctor/patients/laboratory_new/orderform/Hemathology";
import SerologyOrder from "src/doctor/patients/laboratory_new/orderform/SerologyOrder";
import ChemistryOrder from "src/doctor/patients/laboratory_new/orderform/ChemistryOrder";
import FecalOrder from "src/doctor/patients/laboratory_new/orderform/FecalOrder";
import { UsersData } from "src/ContextAPI";
import {
  MinusCircle,
  PlusCircle as PlusCircleIcon,
  // Printer as PrinterIcon,
} from "react-feather";
import UrinalysisOrder from "src/doctor/patients/laboratory_new/orderform/UrinalysisOrder";
import ECGOrder from "src/doctor/patients/laboratory_new/orderform/ECGOrder";
import MedicalExamOrder from "src/doctor/patients/laboratory_new/orderform/MedicalExamOrder";
import PapsmearOrder from "src/doctor/patients/laboratory_new/orderform/PapsmearOrder";
import OralGlucoseOrder from "src/doctor/patients/laboratory_new/orderform/OralGlucoseOrder";
import MiscellaneousOrder from "src/doctor/patients/laboratory_new/orderform/MiscellaneousOrder";
import HepatitisOrder from "src/doctor/patients/laboratory_new/orderform/HepatitisOrder";
import ThyroidProfileOrder from "src/doctor/patients/laboratory_new/orderform/ThyroidProfileOrder";
import StooltestOrder from "src/doctor/patients/laboratory_new/orderform/StooltestOrder";
import ImmunologyOrder from "src/doctor/patients/laboratory_new/orderform/ImmunologyOrder";
import CBCOrderForm from "src/doctor/patients/laboratory_new/orderform/CBCOrderForm";
import CovidTestOrder from "src/doctor/patients/laboratory_new/orderform/CovidTestOrder";
import TumorMakerOrder from "src/doctor/patients/laboratory_new/orderform/TumorMakerOrder";
import DrugTestOrder from "src/doctor/patients/laboratory_new/orderform/DrugTestOrder";

const NSPaidLaboratoryOrder = ({ connection, patient_id }) => {
  const theme = useTheme();
  const [paid, setPaid] = useState({ data: [], ready: false });

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  // const unread = useContext(PatientsUnRead);
  const { users } = useContext(UsersData);

  // const [printableOrder, setPrintableOrder] = useState({
  //   order_id: null,
  //   labmngId: null,
  // });

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const getpaidLaboratoryOrder = () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    axios
      .post("doctor/patient/laboratory/order/paid-list", formdata)
      .then((res) => {
        const data = res.data;
        setPaid({ data, ready: true });
      })
      .catch((er) => Notify.requestError(er));
  };

  // const getpaidLaboratoryOrderDetails = (orderid) => {
  //     setSelectedOrderId(selectedOrderId === orderid ? null : orderid)
  //     var formdata = new FormData();
  //     formdata.set('token', token);
  //     formdata.set('user_id', user_id);
  //     formdata.set('patient_id', patient_id);
  //     formdata.set('order_id', orderid);
  //     formdata.set('connection', connection)

  //     axios.post('doctor/patient/doc-notif-update', formdata)
  //     .then((res) => {
  //         getPatientsList()
  //         getPermission()
  //         getpaidLaboratoryOrder()
  //     }).catch((er) => Notify.requestError(er))

  // }

  const handleSelectedTraceNumber = (trace_number) => {
    if (selectedOrderId === trace_number) {
      console.log("trace number currently selected");
      return false;
    } else {
      setSelectedOrderId(trace_number);
    }
  };

  useEffect(() => {
    getpaidLaboratoryOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  const applyPagination = (order, page, limit) => {
    return order.slice(page * limit, page * limit + limit);
  };

  const paginatedOrder = applyPagination(paid.data, page, rowsPerPage);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          <strong>Laboratory List</strong>
        </Typography>
      </Box>

      {paid.ready
        ? paid.data.length > 0
          ? paginatedOrder.map((data, index) => (
              <Paper elevation={2} key={index} component={Box} mb={2} p={2}>
                <Box display="flex">
                  <Box flexGrow={1} mt={1}>
                    <Typography variant="h6">
                      {data.created_at === null
                        ? "None"
                        : Notify.dateTimeConvert(data.created_at)}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() =>
                        handleSelectedTraceNumber(data.trace_number)
                      }
                    >
                      {selectedOrderId === data.trace_number ? (
                        <MinusCircle color={theme.palette.error.main} />
                      ) : (
                        <PlusCircleIcon color={theme.palette.primary.main} />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                {/* collapse for order details */}
                {selectedOrderId && (
                  <Collapse in={selectedOrderId === data.trace_number}>
                    {selectedOrderId === data.trace_number && (
                      <Box>
                        <Hemathology
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <ClinicalMicroscopy
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <SerologyOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <ChemistryOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                        <FecalOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <UrinalysisOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <ECGOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <MedicalExamOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <PapsmearOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <OralGlucoseOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <MiscellaneousOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <HepatitisOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <ThyroidProfileOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <StooltestOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <ImmunologyOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <CBCOrderForm
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <CovidTestOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <TumorMakerOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />

                        <DrugTestOrder
                          order_id={selectedOrderId}
                          patient_id={patient_id}
                        />
                      </Box>
                    )}
                  </Collapse>
                )}
              </Paper>
            ))
          : Notify.noRecord()
        : Notify.loading()}

      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={paid.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default NSPaidLaboratoryOrder;
