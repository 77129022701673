import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  CardMedia,
  Divider,
} from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import { Printer } from "react-feather";
import Notify from "src/notification/Notify";
import { CancelOutlined } from "@material-ui/icons";

const BMCDCMedCert = ({ details, close }) => {
  const printableRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  return (
    <>
      <Box ref={printableRef}>
        <Box m={2}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_logo.png"
                    alt="bmcdc"
                    style={{ width: 120 }}
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box align="center">
                  <Typography variant="h3">
                    <b>BMCDC HEALTHCARE, INC.</b>
                  </Typography>
                  <Typography>
                    <b>DOOR G/F, GIMENES BLGD., KM. 5 BUHANGIN, DAVAO CITY</b>
                  </Typography>
                  <Typography>
                    <b>Tel. 285-4324/293-9684, Telefax: 241-0925</b>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src="/bmcdc_medical.png"
                    alt="bmcdc"
                    style={{ width: 120 }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box m={5}>
            <Box my={2}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className="d-print-none"
              >
                <Box mr={1}>
                  <Button
                    color="secondary"
                    onClick={() => close()}
                    startIcon={<CancelOutlined />}
                  >
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    onClick={handlePrint}
                    startIcon={<Printer />}
                  >
                    Print
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box align="center" mt={2} mb={2}>
              <Typography
                variant="h4"
                style={{ fontWeight: "bolder", textDecoration: "underline" }}
              >
                MEDICAL CERTIFICATE
              </Typography>
            </Box>

            <Box mt={10}>
              <Grid container>
                <Grid item xs={8}>
                  <Box display={"flex"} mb={2}>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        <span
                          style={{
                            marginLeft: 50,
                          }}
                        ></span>
                        This is to certify that
                      </Typography>
                    </Box>
                    <Box align="center" borderBottom={1} width={"100%"}>
                      {details && details.firstname !== null
                        ? `${details.firstname} ${details.lastname}`
                        : ""}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display={"flex"} mb={2}>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        , a
                      </Typography>
                    </Box>
                    <Box align="center" borderBottom={1} width={"100%"}>
                      {details && details.birthday !== null
                        ? Notify.calculateAge(details.birthday)
                        : ""}
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        year old,
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display={"flex"} mb={2}>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        presently residing at
                      </Typography>
                    </Box>
                    <Box align="center" borderBottom={1} width={"100%"}>
                      {details && details.city !== null
                        ? `${details.street !== null ? details.street : ""}, ${
                            details.barangay
                          }, ${details.city}`
                        : ""}
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                      >
                        ,
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display={"flex"} mb={2}>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        was examined on
                      </Typography>
                    </Box>
                    <Box align="center" borderBottom={1} width={"100%"}>
                      {details && details.created_at !== null
                        ? new Date(details.created_at).toLocaleString("en-US", {
                            day: "numeric",
                          })
                        : ""}
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        day of
                      </Typography>
                    </Box>
                    <Box align="center" borderBottom={1} width={"100%"}>
                      {details && details.created_at !== null
                        ? new Date(details.created_at).toLocaleString("en-US", {
                            month: "long",
                          })
                        : ""}
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        .
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Box>
                  <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                    Diagnosis/Laboratory findings:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {details && details.diagnosis_findings !== null
                      ? details.diagnosis_findings
                      : ""}
                  </Typography>
                </Box>
              </Box>
              <Box mt={5}>
                <Box>
                  <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                    Recommendations:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {details && details.recommendation !== null
                      ? details.recommendation
                      : ""}
                  </Typography>
                </Box>
              </Box>
              <Box mt={5}>
                <Box>
                  <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                    Remarks:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {details && details.remarks !== null ? details.remarks : ""}
                  </Typography>
                </Box>
              </Box>
              <Box mt={5}>
                <Box display={"flex"} mb={2}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      Issued on the
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {details && details.issued_at !== null
                      ? new Date(details.issued_at).toLocaleString("en-US", {
                          day: "numeric",
                        })
                      : ""}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      day of
                    </Typography>
                  </Box>
                  <Box align="center" borderBottom={1} width={"100%"}>
                    {details && details.issued_at !== null
                      ? new Date(details.issued_at).toLocaleString("en-US", {
                          month: "long",
                        })
                      : ""}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                      }}
                      variant="h6"
                      noWrap
                    >
                      at Davao City, Philippines.
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box mt={20}>
                <Box display={"flex"}>
                  <Box flexGrow={1} />
                  <Box width={400} display={"flex"}>
                    <Box align="center" width={"100%"}>
                      <Typography variant="h6" noWrap>
                        {details && details.doctor_name !== null
                          ? details.doctor_name
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"}>
                  <Box flexGrow={1} />
                  <Box width={400} display={"flex"}>
                    <Box align="center" width={"100%"}>
                      <Typography variant="h6" noWrap>
                        {details && details.doctor_specialization !== null
                          ? details.doctor_specialization
                          : "SPECIALIZATION"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"}>
                  <Box flexGrow={1} />
                  <Box width={400} display={"flex"}>
                    <Box align="center" width={"100%"}>
                      <Typography
                        style={{
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                        noWrap
                      >
                        Lic. No.{" "}
                        {details && details.doctor_lic !== null
                          ? details.doctor_lic
                          : "932573593"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BMCDCMedCert;
