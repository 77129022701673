import { Typography, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const DrugTestOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);
  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_drug_test");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_drug_test table error", error);
    }
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box>
              <Typography variant="h6" align="center">
                <b> DRUG TEST </b>
              </Typography>
            </Box>

            <Box>
              {details.map((data, key) => {
                return (
                  <Box my={2} key={key}>
                    <Box mb={3} hidden={!Boolean(parseInt(data.two_panels))}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="subtitle1">
                            <b> DRUG TEST (2 PANELS) </b>
                          </Typography>
                        </Box>
                      </Box>

                      {Boolean(parseInt(data.is_processed)) && (
                        <Box my={1}>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.two_panels_result}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box mb={3} hidden={!Boolean(parseInt(data.three_panels))}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="subtitle1">
                            <b> DRUG TEST (3 PANELS)</b>
                          </Typography>
                        </Box>
                      </Box>

                      {Boolean(parseInt(data.is_processed)) && (
                        <Box my={1}>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.three_panels_result}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box mb={3} hidden={!Boolean(parseInt(data.five_panels))}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="subtitle1">
                            <b> DRUG TEST (5 PANELS)</b>
                          </Typography>
                        </Box>
                      </Box>

                      {Boolean(parseInt(data.is_processed)) && (
                        <Box my={1}>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.five_panels_result}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* <Footer formheader={formheader} /> */}
        </>
      )}
    </>
  );
};

export default DrugTestOrder;
