import {
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue } from "@material-ui/core/colors";
import IsMountedRef from "src/utils/IsMountedRef";
import DocuPatientDetailsMedJabeliDetails from "./DocuPatientDetailsMedJabeliDetails";

const DocuPatientDetailsMedJabeli = ({
  selectedBranch,
  patient_id,
  updatePatientList,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [medCert, setMedCert] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();

  const getCompletedMedCert = React.useCallback(async () => {
    let response = await axios.get("van/patient/get-neworderMedCert", {
      params: {
        patient_id,
        management_id: selectedBranch,
      },
    });
    const data = response.data;
    if (mounted.current) {
      setMedCert({ data, ready: true });
    }
  }, [patient_id, selectedBranch, mounted]);

  useEffect(() => {
    getCompletedMedCert();
    // eslint-disable-next-line
  }, [patient_id]);

  return (
    <>
      <Box my={1}>
        <Typography color="textSecondary" variant="h6">
          Medical Certificate Order
        </Typography>
      </Box>

      {medCert.ready
        ? medCert.data.length > 0
          ? medCert.data.map((data, index) => {
              return (
                <Fragment key={index}>
                  <Paper component={Box} my={2} p={2}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Box display="flex" alignItems="center">
                          <Box mt={1} mr={2}>
                            <ListAltIcon
                              style={{ fontSize: "2rem", color: blue[500] }}
                            />
                          </Box>

                          <Box>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                className={`gtc-uppercase`}
                              >
                                {/* {Notify.dateTimeConvert(data.created_at)} */}
                                Medical Certificate
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className={`gtc-uppercase`}
                              >
                                {/* {data.trace_number} */}
                                {Notify.dateTimeConvert(data.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            setSelectedOrder(
                              selectedOrder &&
                                selectedOrder.lmc_id === data.lmc_id
                                ? null
                                : data
                            )
                          }
                        >
                          {selectedOrder &&
                          selectedOrder.lmc_id === data.lmc_id ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <>
                      {selectedOrder && (
                        <Collapse in={selectedOrder.lmc_id === data.lmc_id}>
                          <Box mt={2}>
                            <DocuPatientDetailsMedJabeliDetails
                              selectedBranch={selectedBranch}
                              patient_id={patient_id}
                              details={selectedOrder}
                              getCompletedMedCert={() => {
                                getCompletedMedCert();
                                setSelectedOrder(null);
                              }}
                              updatePatientList={() => updatePatientList()}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </>
                  </Paper>
                </Fragment>
              );
            })
          : Notify.noRecord()
        : Notify.loading()}
    </>
  );
};

export default DocuPatientDetailsMedJabeli;
