import React, { Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Grid,
} from "@material-ui/core";
import SorologyRef from "src/laboratory/references/Ref_Sorology";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const SerologyPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  serologyOrderDetails,
}) => {
  return (
    <>
      {serologyOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                SEROLOGY
              </span>
            </Typography>
          </Box>
          <Box>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <b style={{ color: "#000" }}> EXAMINATION REQUESTED </b>
                    </TableCell>
                    <TableCell align="center">
                      <b style={{ color: "#000" }}> NORMAL </b>
                    </TableCell>
                    <TableCell align="center">
                      <b style={{ color: "#000" }}> TEST RESULT </b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serologyOrderDetails.data.map((data, index) => (
                    <Fragment key={index}>
                      {data.hbsag && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Hepatitis B surface Antigen (HBsAg)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.hbsag}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {data.hav && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              HAV (Hepatitis A Virus) lgG/lgM
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.hav}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.vdrl_rpr && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              VDRL/RPR
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.vdrl_rpr}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.anti_hbc_igm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              ANTI-HBC IGM
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.anti_hbc_igm}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.hcv && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              HCV (Hepatitis C Virus)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.hcv}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.beta_hcg_quali && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              BETA HCG (QUALI)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.beta_hcg_quali}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.h_pylori && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              H. PYLORI
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.h_pylori}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.typhidot && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              TYPHIDOT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.typhidot}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {Boolean(parseInt(data.syphilis_test)) && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              VDRL/Syphilis Test
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.syphilis_test_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.hact && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              HACT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.hact}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ana && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              ANA
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.ana}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {Boolean(parseInt(data.dengue_test)) && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              DENGUE TEST
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.dengue_test_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box my={1} align="center">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {serologyOrderDetails.data.length > 0 &&
                          serologyOrderDetails.data[0].pathologist}
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}> Pathologist </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          {serologyOrderDetails.data.length > 0 &&
                            serologyOrderDetails.data[0].pathologist_lcn}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={4}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {serologyOrderDetails.data.length > 0 &&
                          serologyOrderDetails.data[0].chief_medtech}
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}>
                          {" "}
                          Chief Med Technologist{" "}
                        </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          {serologyOrderDetails.data.length > 0 &&
                            serologyOrderDetails.data[0].chief_medtech_lci}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {serologyOrderDetails.data.length > 0 &&
                            serologyOrderDetails.data[0].medtech}
                        </span>
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}> Medical Technologist </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          <span
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                          >
                            {serologyOrderDetails.data.length > 0 &&
                              serologyOrderDetails.data[0].lic_no}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default SerologyPrintAll;
