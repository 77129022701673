import { Typography, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const CovidTestOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);
  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_covid19_test");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_covid19_test table error", error);
    }
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box>
              <Typography variant="h6" align="center">
                <b> COVID TEST </b>
              </Typography>
            </Box>

            <Box>
              {details.map((data, key) => {
                return (
                  <Box my={2} key={key}>
                    <Box hidden={Boolean(parseInt(data.rapid_test))}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="subtitle1">
                            <b> RAPID TEST </b>
                          </Typography>
                        </Box>
                      </Box>

                      {Boolean(parseInt(data.is_processed)) && (
                        <Box mt={1}>
                          <Box my={2}>
                            <Box mb={1}>
                              <Typography variant="caption">
                                <b> RESULT </b>
                              </Typography>

                              <Typography>{data.rapid_test_result}</Typography>
                            </Box>

                            <Box>
                              <Typography variant="caption">
                                <b> REMARKS </b>
                              </Typography>

                              <Typography>
                                {data.rapid_test_result_remarks}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box hidden={Boolean(parseInt(data.antigen_test))}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="subtitle1">
                            <b> ANTIGEN TEST </b>
                          </Typography>
                        </Box>
                      </Box>

                      {Boolean(parseInt(data.is_processed)) && (
                        <Box mt={1}>
                          <Box my={2}>
                            <Box mb={1}>
                              <Typography variant="caption">
                                <b> RESULT </b>
                              </Typography>
                              <Typography>
                                {data.antigen_test_result}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography variant="caption">
                                <b> REMARKS</b>
                              </Typography>
                              <Typography>
                                {data.antigen_test_result_remarks}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* <Footer formheader={formheader} /> */}
        </>
      )}
    </>
  );
};

export default CovidTestOrder;
