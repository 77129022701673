import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
import ImmunologyRef from "src/laboratory/references/Ref_Immunology";
// import Footer from "src/laboratory/print/Footer";

const ImmunologyOrder = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_immunology");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box>
              <Typography variant="h6" align="center">
                <strong> IMMUNOLOGY </strong>
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">TEST</TableCell>
                    <TableCell align="center">
                      <b> RESULT </b>
                    </TableCell>
                    <TableCell align="center">
                      <b> REFERENCE VALUES </b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {details.map((data, index) => (
                    <Fragment key={index}>
                      {data.alpha_fetoprotein && (
                        <TableRow>
                          <TableCell align="center">
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              ALPHA FETOPROTEIN
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.alpha_fetoprotein)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ImmunologyRef.alphaFetoprotein()}
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* <Footer formheader={formheader} /> */}
        </>
      )}
    </>
  );
};

export default ImmunologyOrder;
