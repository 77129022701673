import {
  Box,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TextField,
  TableRow,
  Button,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Axios from "axios";
import React, { useContext, useState } from "react";
import Notify from "../../notification/Notify";
import { UsersData } from "../../ContextAPI";

const HMOList = ({ list, getHMOListFetch }) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });

  const handleEditHMOName = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      Axios.post("cashier/update/hmo-by-id", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            Notify.successRequest("update hmo");
            getHMOListFetch();
            setEdit({ data: null, open: true });
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Box component={Paper} variant="outlined" p={2}>
      <Box display="flex" mb={2}>
        <Box flexGrow={1}>
          <Typography variant="h5" color="primary">
            <strong>HMO List</strong>
          </Typography>
        </Box>
      </Box>

      <Box mt={1}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong> HMO Name </strong>
                </TableCell>
                <TableCell align="center">
                  <strong> Action </strong>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.ready ? (
                list.data.length > 0 ? (
                  list.data.map((data, index) => (
                    <TableRow key={index} hover>
                      <TableCell> {data.name} </TableCell>
                      <TableCell align="center">
                        <Tooltip arrow title="Edit HMO">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              setEdit({
                                data: data,
                                open: true,
                              })
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="subtitle2" color="secondary">
                        No HMO added.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="subtitle2" color="primary">
                      Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {edit.data && edit.open && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={edit.open}
          onClose={() => setEdit({ data: null, open: true })}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle> Edit HMO </DialogTitle>
          <form onSubmit={handleEditHMOName}>
            <DialogContent dividers>
              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="id"
                  label="HMO Id"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.hl_id}
                />

                <TextField
                  fullWidth
                  required
                  name="name"
                  label="HMO Name"
                  variant="outlined"
                  defaultValue={edit.data.name}
                />
              </Box>

              <Box mb={1}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEdit({ data: null, open: true })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Box>
  );
};

export default HMOList;
