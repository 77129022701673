import {
  Box,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  CardMedia,
  InputAdornment,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
// import FormvalidationFecalAnalysis from "./validation/FormvalidationFecalAnalysis";
import { UsersData } from "src/ContextAPI";
import Specimen from "./Specimen";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const FecalAnaylysisOrderDetails = ({
  queue,
  formheader,
  order,
  getLabFecalAnalysisOrder,
  resetDisplay,
  process_for,
}) => {
  const { users } = React.useContext(UsersData);
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState({
    data: null,
    dialog: false,
  });

  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);

  const [orderItems, setOrderItems] = useState([]);

  const getFecalOrderByTraceNumber = () => {
    var params = { user_id: users.user_id, trace_number: order.trace_number };
    Axios.get("laboratory/order/ordernew-fecalanalysis/details", { params })
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    getFecalOrderByTraceNumber();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", order.trace_number);
    formdata.set("queue", queue);
    formdata.set("process_for", process_for);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setResultSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-fecalanalysis/save-process-result",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added.");
            setTimeout(() => {
              history.push(
                `/bmcdc/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);
            setSavebtnDisabled(true);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("trace_number", order.trace_number);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-fecalanalysis/save-setpending",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabFecalAnalysisOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("order_id", processDialog.data.order_id);
    formdata.set("trace_number", processDialog.data.trace_number);

    var error = [];
    setProcessSubmitting(true);

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      let response = await Axios.post(
        "laboratory/fecalanalysis/order-setprocess/custom-qty",
        formdata
      );

      if (response.data.message === "reagent-error") {
        Notify.customToast("Reagent Not Error", "Reagent/items not available.");
      }

      if (response.data.message === "pass-invalid") {
        Notify.fieldInvalid("password");
      }

      if (response.data.message === "success") {
        Notify.successRequest("process");
        setProcessDialog({ data: null, dialog: false });
        getFecalOrderByTraceNumber();
      }
    }

    setTimeout(() => {
      setProcessSubmitting(false);
    }, 2000);
  };

  const handleProcessSelectedOrder = async (data) => {
    let response = await Axios.get(
      "laboratory/items/laborder/list-available-items",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
          order_id: data.order_id,
        },
      }
    );

    setOrderItems(response.data);

    setProcessDialog({ data: data, dialog: true });
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <Box display="flex" justifyContent="center">
            <Box mt={2}>
              {formheader && (
                <CardMedia
                  style={{ width: 70 }}
                  component={"img"}
                  src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                />
              )}
            </Box>
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={formheader && formheader.name}
                subheader={formheader && formheader.address}
              />
            </Box>
          </Box>

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      NAME:
                    </Typography>
                    {`${order.lastname}, ${order.firstname} ${
                      order.middle === null ? "" : order.middle
                    }`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        SEX:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      ADDRESS:
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="caption" className="font-weight-bold">
                    PATIENT CONDITION:
                  </Typography>
                  {order.patient_condition === null
                    ? " none"
                    : order.patient_condition}
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:
                    </Typography>
                    {Notify.createdAt(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            <Specimen
              patient_id={order.patient_id}
              trace_number={order.trace_number}
            />
          </CardContent>

          <CardContent>
            {/* order details */}
            <Box>
              {orderDetails.ready ? (
                orderDetails.data.length > 0 ? (
                  <Box mb={2}>
                    {/* clinical microscopy */}
                    {orderDetails.data.map((data, index) => (
                      <Fragment key={index}>
                        {/* hidden inputs */}
                        <Box>
                          <TextField
                            margin="dense"
                            fullWidth
                            name="order_id[]"
                            label="order_id"
                            hidden
                            value={data.order_id}
                          />
                          <TextField
                            margin="dense"
                            fullWidth
                            name="patient_id"
                            label="patient_id"
                            hidden
                            value={data.patient_id}
                          />

                          <TextField
                            margin="dense"
                            fullWidth
                            name="doctors_id"
                            label="doctor_id"
                            hidden
                            value={
                              data.doctor_id === null ? "" : data.doctor_id
                            }
                          />
                        </Box>

                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="subtitle1">
                              <b> FECAL ANALYSIS </b>
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              disabled={Boolean(parseInt(data.is_processed))}
                              color="primary"
                              variant="contained"
                              onClick={() => handleProcessSelectedOrder(data)}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box
                          mt={2}
                          hidden={
                            Boolean(parseInt(data.is_processed)) ? false : true
                          }
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Box>
                                <Typography variant="subtitle2">
                                  <b> Cellular Elements </b>
                                </Typography>
                              </Box>

                              <Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> COLOR: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="color[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> CONSISTENCY: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="consistency[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> PUS: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="pus[]"
                                          margin="dense"
                                          variant="outlined"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                /HPF
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> RBC: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="rbc[]"
                                          margin="dense"
                                          variant="outlined"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                /HPF
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Grid container>
                                <Grid item xs={12} sm={3} />
                                <Grid item xs={12} sm={9}>
                                  <Box>
                                    <Box>
                                      <Typography variant="subtitle2">
                                        <b> Others </b>
                                      </Typography>
                                    </Box>
                                    <Box display="flex">
                                      <Box mt={2} mx={1}>
                                        <Typography variant="caption">
                                          <b> FAT GLOBULES: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="fat[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box mt={2} mx={1}>
                                        <Typography variant="caption">
                                          <b> OCCULT BLOOD: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="occult[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>

                                    <Box display="flex">
                                      <Box mt={2} mx={1}>
                                        <Typography variant="caption">
                                          <b> BACTERIA: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="bacteria[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Box mt={2}>
                            <TextField
                              fullWidth
                              label="Result"
                              name="fecal_result[]"
                              margin="dense"
                              variant="outlined"
                              multiline
                              rows={3}
                              required={Boolean(parseInt(data.fecal_analysis))}
                            />
                          </Box>
                        </Box>
                      </Fragment>
                    ))}
                  </Box>
                ) : (
                  "Order not found."
                )
              ) : (
                "please wait..."
              )}
            </Box>
          </CardContent>

          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  startIcon={
                    resultSubmitting && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="outlined"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.dialog}
        onClose={() => setProcessDialog({ data: null, dialog: false })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="draggable-handle">Process order</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            {orderItems.length > 0
              ? orderItems.map((data, index) => (
                  <Box key={index} mb={2}>
                    <Box>
                      <TextField
                        fullWidth
                        name="item_id[]"
                        value={data.item_id}
                        variant="outlined"
                        hidden
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        required
                        label={`${data.description} qty to process`}
                        name="qty[]"
                        variant="outlined"
                        defaultValue={1}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // InputProps={{
                        //   inputProps: {
                        //     min: 0,
                        //     max: data._total_qty_available,
                        //   },
                        // }}
                        type="number"
                      />
                    </Box>
                  </Box>
                ))
              : "No reagent/item found."}

            <Box mt={2}>
              <TextField
                fullWidth
                required
                defaultValue="ok"
                name="remarks"
                label={`Remarks`}
                variant="outlined"
              />
            </Box>

            <Box mt={2}>
              <TextField
                fullWidth
                required
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ data: null, dialog: false })}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default FecalAnaylysisOrderDetails;
