import React, { Fragment, useEffect } from "react";
import ImagingOrder from "./ImagingOrder";

const Imaging = ({ patient_id, connection, trace_number }) => {
  useEffect(() => {}, [patient_id]);
  return (
    <Fragment>
      <ImagingOrder
        patient_id={patient_id}
        connection={connection}
        trace_number={trace_number}
      />
    </Fragment>
  );
};

export default Imaging;
