import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
import PerfectScrollbar from "react-perfect-scrollbar";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import RescheduleAppointment from "./appointment/RescheduleAppointment";
import AppointmentDetails from "./appointment/AppointmentDetails";

function NSPatientAppointmentList({ patient_id }) {
  const { users } = useContext(UsersData);
  const [detailsDialog, setDetailsDialog] = useState({
    data: null,
    open: false,
  });
  const [reschedDialog, setReschedDialog] = useState({
    data: null,
    open: false,
  });
  const [appointment, setAppointment] = useState({
    data: [],
    ready: false,
  });

  const getAppointmentListLocal = useCallback(async () => {
    var params = { user_id: users.user_id, patient_id: patient_id };
    await Axios.get("encoder/patients/appointment/local/list", { params })
      .then((response) => {
        const data = response.data;
        setAppointment({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id]);

  useEffect(() => {
    getAppointmentListLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <Fragment>
      <Grid item xs={12} sm={12}>
        <Box component={Paper} elevation={2} p={2}>
          <TableContainer>
            <PerfectScrollbar>
              <Box display="flex" mb={2}>
                <Box flexGrow={1}>
                  <Typography variant="h6" color="textSecondary">
                    <strong>Appointments List</strong>
                  </Typography>
                </Box>
              </Box>

              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> Date </TableCell>
                    <TableCell align="center"> Complaint </TableCell>
                    <TableCell align="center"> Service </TableCell>
                    <TableCell align="center"> Amount </TableCell>
                    <TableCell align="center"> Status </TableCell>
                    <TableCell align="center"> Rescheduled? </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {appointment.ready ? (
                    appointment.data.length > 0 ? (
                      appointment.data.map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell align="right">
                            {Boolean(data.is_reschedule)
                              ? Notify.dateTimeConvert(data.is_reschedule_date)
                              : Notify.dateTimeConvert(data.app_date)}
                          </TableCell>
                          <TableCell> {data.app_reason} </TableCell>
                          <TableCell> {data.services} </TableCell>
                          <TableCell align="right"> {data.amount} </TableCell>
                          <TableCell align="center">
                            <Label
                              color={
                                Boolean(data.is_complete) ? "success" : "error"
                              }
                            >
                              {Boolean(data.is_complete)
                                ? "Complete"
                                : "Incomplete "}
                            </Label>
                          </TableCell>
                          <TableCell align="center">
                            <Label
                              color={
                                Boolean(data.is_reschedule)
                                  ? "success"
                                  : "primary"
                              }
                            >
                              {Boolean(data.is_reschedule) ? "Yes" : "No "}
                            </Label>
                          </TableCell>
                          <TableCell>
                            <Box display="flex">
                              <Tooltip arrow title="Reschedule Appointment">
                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      setReschedDialog({
                                        data: data,
                                        open: true,
                                      });
                                    }}
                                    disabled={Boolean(data.is_complete)}
                                  >
                                    <ScheduleIcon />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                              <Tooltip arrow title="Appointment Details">
                                <IconButton
                                  onClick={() =>
                                    setDetailsDialog({
                                      data: data,
                                      open: true,
                                    })
                                  }
                                >
                                  <ArrowRightAltIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography color="secondary">
                            No appointment found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography color="primary">Please wait...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </TableContainer>
        </Box>
      </Grid>

      {/* details appointment */}
      <Dialog
        open={detailsDialog.open}
        onClose={() => setDetailsDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle disableTypography>
          <Typography variant="subtitle2" className="gtc-uppercase">
            Appointment Details
          </Typography>
        </DialogTitle>
        {detailsDialog.data && (
          <AppointmentDetails
            app={detailsDialog.data}
            close={() => setDetailsDialog({ data: null, open: false })}
          />
        )}
      </Dialog>

      {/* appointment reschedule */}
      <Dialog
        open={reschedDialog.open}
        onClose={() => setReschedDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle disableTypography>
          <Typography variant="subtitle2" className="gtc-uppercase">
            Appointment Reschedule
          </Typography>
        </DialogTitle>
        {reschedDialog.data && (
          <RescheduleAppointment
            app={reschedDialog.data}
            close={() => setReschedDialog({ data: null, open: false })}
            reload={getAppointmentListLocal}
          />
        )}
      </Dialog>
    </Fragment>
  );
}

export default NSPatientAppointmentList;
