import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const CovidTestOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
                style={{ fontWeight: "bolder", color: "#000" }}
              >
                Covid Test
              </span>
            </Typography>
          </Box>

          <Box>
            <Box>
              {orderDetails.ready ? (
                orderDetails.data.length > 0 ? (
                  orderDetails.data.map((data, key) => {
                    return (
                      <Box my={2} key={key}>
                        <Box hidden={!Boolean(parseInt(data.rapid_test))}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                RAPID TEST
                              </Typography>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box mt={1}>
                              <Box my={2}>
                                <Box mb={1}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    Result
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rapid_test_result}
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    <b> Remarks </b>
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.rapid_test_result_remarks}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.antigen_test))}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: "bolder", color: "#000" }}
                              >
                                ANTIGEN TEST
                              </Typography>
                            </Box>
                          </Box>

                          {Boolean(parseInt(data.is_processed)) && (
                            <Box mt={1}>
                              <Box my={2}>
                                <Box mb={1}>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    Result
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.antigen_test_result}
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    Remarks
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.antigen_test_result_remarks}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Typography color="secondary">No record found.</Typography>
                  </Box>
                )
              ) : (
                <Box>
                  <Typography color="primary">
                    Loading, Please wait...
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box my={3} align="center">
            <Footer formheader={formheader} orderDetails={orderDetails} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CovidTestOrder;
