import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import AddOnTestCreateOrder from "./AddOnTestCreateOrder";
import IsMountedRef from "src/utils/IsMountedRef";
import axios from "axios";
import { Edit } from "@material-ui/icons";

const AddOnTest = () => {
  const { users } = React.useContext(UsersData);
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [edit, setEdit] = useState({
    data: null,
    open: false,
  });

  const getOtherTest = React.useCallback(async () => {
    let response = await axios.get("accounting/other/order/get-othertest", {
      params: {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [mounted, users]);

  const handleEditTest = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    // formdata.append("management_id", users.management_id);

    var error = [];
    if (
      formdata.get("order_amount").trim() === "" ||
      formdata.get("order_amount").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("rate");
    }

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsSubmitting(true);
      axios
        .post("accounting/update/other/edit-rate", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            Notify.successRequest("edit rate");
            getOtherTest();
            setEdit({ data: null, open: false });
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    getOtherTest();
  }, [getOtherTest]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardHeader title="Other Test List" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell> Date </TableCell> */}
                      {/* <TableCell> Department </TableCell> */}
                      <TableCell> Order </TableCell>
                      <TableCell> Amount </TableCell>
                      <TableCell align="center"> Action </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {list.ready ? (
                      list.data.length > 0 ? (
                        list.data.map((data, key) => (
                          <TableRow key={key}>
                            {/* <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell> */}
                            {/* <TableCell> {data.department} </TableCell> */}
                            <TableCell> {data.order_name} </TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(data.order_amount)}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip arrow title="Edit Other Test">
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    setEdit({
                                      data: data,
                                      open: true,
                                    })
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography color="primary">
                            Loading, please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AddOnTestCreateOrder getOtherTest={() => getOtherTest()} />
        </Grid>
      </Grid>

      {/* edit dialog */}
      {edit.data && edit.open && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={edit.open}
          onClose={() => setEdit({ data: null, open: true })}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle> Edit Other Test </DialogTitle>
          <form onSubmit={handleEditTest}>
            <DialogContent dividers>
              <Box my={2}>
                <TextField
                  fullWidth
                  required
                  name="order_id"
                  label="Order Id"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.order_id}
                />
                <TextField
                  fullWidth
                  required
                  name="management_id"
                  label="Management ID"
                  variant="outlined"
                  hidden
                  defaultValue={edit.data.management_id}
                />
                <TextField
                  fullWidth
                  required
                  name="order_name"
                  label="Order Name"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={edit.data.order_name}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="order_amount"
                  label="Order Rate"
                  variant="outlined"
                  type="number"
                  defaultValue={edit.data.order_amount}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 100000,
                      step: 0.01,
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => setEdit({ data: null, open: true })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default AddOnTest;
