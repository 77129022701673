import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  Box,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import axios from "axios";
import Notify from "../../notification/Notify";
import { useHistory } from "react-router-dom";
import { UsersData } from "../../ContextAPI";
import {
  faBoxOpen,
  faBrain,
  faSpellCheck,
  faUserMd,
  faVials,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PatientProfileHeader from "../queue/PatientProfileHeader";
import CashierCreateLabOrder from "../addons/CashierCreateLabOrder";
import CashierCreateImgOrder from "../addons/CashierCreateImgOrder";
import CashierCreatePsyOrder from "../addons/CashierCreatePsyOrder";
import CashierCreatePackageOrder from "../addons/CashierCreatePackageOrder";
// import PatientsPE from "../addons/P.E";
import AddOnOtherTest from "../addons/others";
import AddDoctorService from "../addons/doctorservices";

const BillingDetails = ({
  details,
  getAllCashierOnQueue,
  closePatientDetails,
  getPatientsOnQueue,
  branches,
}) => {
  const { users } = useContext(UsersData);
  const history = useHistory();
  const [isprocessCancel, setIsProcessCancel] = useState(false);
  const [isprocessPaid, setIsProcessPaid] = useState(false);
  // const [paymentType, setPaymentType] = useState("");
  const [paymentTypeWalkIn, setPaymentTypeWalkIn] = useState("");
  const [transactionCategory, setTransactionCategory] = useState("");
  const [discount, setDiscount] = useState("");
  const [homeService, setHomeService] = useState("");
  // const [hmoList, setHmoList] = useState({ data: [], ready: false });
  const [hmo, setHMO] = useState({ data: [], ready: false });
  const [companyHMOList, setCompanyHMOList] = useState({
    data: [],
    ready: false,
  });
  const [addonsTab, setAddonsTab] = useState("bills");
  const [category, setCategory] = React.useState("laboratory");
  const [doctorList, setDoctorList] = useState({
    data: [],
    ready: false,
  });

  // console.log("details", details);

  let totalorder = 0;
  let totaldiscount = 0;

  const [patientInfo, setPatientInfo] = useState({
    data: [],
    ready: false,
  });

  const [billingDetails, setBillingDetails] = useState({
    data: [],
    ready: false,
  });

  const [cancelOrder, setCancelOrder] = useState({
    data: null,
    dialog: false,
  });

  const [paidDialog, setPaidDialog] = useState({
    data: null,
    open: false,
    discount: null,
  });

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  //calculate all
  const calculateOrder = (amount) => {
    totalorder += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  //calculate discount
  const calculateOrderDiscount = (amount) => {
    totaldiscount += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  const getPatientInformation = useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      patient_id: details.patient_id,
    };
    axios
      .get("cashier/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setPatientInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [details, users]);

  const getPatientBillingDetails = useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      patient_id: details.patient_id,
    };
    axios
      .get("cashier/get/patient/biling-details", { params })
      .then((response) => {
        const data = response.data;
        setBillingDetails({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [details, users]);

  const handleCancelOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("patient_id", details.patient_id);
    formdata.set("username", users.username);

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("form error.");
    } else {
      setIsProcessCancel(true);
      axios
        .post("cashier/billing/cancel-bill", formdata)
        .then((response) => {
          const data = response.data;

          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            setCancelOrder({ data: null, dialog: false });
            getPatientBillingDetails();
            Notify.successRequest("billing cancel");
          }
          if (data === "db-error") {
            Notify.warnRequest("billing cancel");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcessCancel(false));
    }
  };

  const handleSetAsPaid = (e) => {
    e.preventDefault();
    e.persist();

    var receipt_number = `receipt-${Math.floor(
      100000 + Math.random() * new Date().getTime()
    )}`;
    var formdata = new FormData(e.target);
    var error = [];
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", details.patient_id);
    formdata.set("username", users.username);
    formdata.set("receipt_number", receipt_number);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    if (
      formdata.get("amountto_pay").length === 0 ||
      formdata.get("amountto_pay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("amountto_pay");
    }

    if (paymentTypeWalkIn === "cash") {
      if (
        formdata.get("payment").length === 0 ||
        formdata.get("payment").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("payment");
      }
    }

    if (paymentTypeWalkIn === "hmo") {
      if (
        formdata.get("hmo").length === 0 ||
        formdata.get("hmo").trim() === ""
      ) {
        error = "error";
        Notify.fieldRequired("hmo");
      }
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      Notify.consoleLog("form error.");
    } else {
      setIsProcessPaid(true);
      axios
        .post("cashier/billing/setaspaid-bill", formdata)
        .then((response) => {
          const data = response.data;

          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            e.target.reset();
            setPaidDialog({ data: null, open: false, discount: null });
            setHomeService("");
            setDiscount("");
            setTransactionCategory("");
            setPaymentTypeWalkIn("");

            getPatientBillingDetails();
            getAllCashierOnQueue();
            Notify.successRequest("billing cancel");
            history.push(
              `/bmcdc/app/cashier/billing/receipt/${receipt_number}`
            );
          }
          if (data === "db-error") {
            Notify.warnRequest("billing cancel");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setIsProcessPaid(false));
    }
  };

  // const handleSetHMO = async (data) => {
  //   setPaymentType(data.value);
  //   if (data.value !== "cash") {
  //     var params = {
  //       company_id: data.value,
  //       management_id: users.management_id,
  //     };
  //     try {
  //       let response = await axios.get("cashier/get/hmo-by-company-id", {
  //         params,
  //       });
  //       const data = response.data;
  //       setHmoList({ data, ready: true });
  //     } catch (error) {
  //       console.log("error: ", error);
  //     }
  //   }
  // };

  const handleSetHMOWalkIn = async (data) => {
    setPaymentTypeWalkIn(data.value);
    if (data.value === "hmo") {
      var params = {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
      };
      try {
        let response = await axios.get("cashier/get/all/hmo-list", {
          params,
        });
        const data = response.data;
        setHMO({ data, ready: true });
      } catch (error) {
        console.log("error: ", error);
      }
    }
    if (data.value === "van") {
      var paramssss = {
        management_id: users.management_id,
        main_mgmt_id: users.main_mgmt_id,
        company_id: details.company,
      };
      try {
        let response = await axios.get("cashier/get/hmo-by-company-id", {
          paramssss,
        });
        const data = response.data;
        setCompanyHMOList({ data, ready: true });
      } catch (error) {
        console.log("error: ", error);
      }
    }
    if (data.value === "cash") {
      setHMO({ data: [], ready: false });
      setCompanyHMOList({ data: [], ready: false });
    }
  };

  const fetchAllDoctors = useCallback(() => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    axios
      .get("nurse/get-all-doctors", { params })
      .then((response) => {
        const data = response.data;
        setDoctorList({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.user_id, users.management_id]);

  useEffect(() => {
    getPatientBillingDetails();
    getPatientInformation();
    fetchAllDoctors();
  }, [getPatientBillingDetails, getPatientInformation, fetchAllDoctors]);

  return (
    <>
      {patientInfo.ready && (
        <PatientProfileHeader
          patient_id={details.patient_id}
          info={patientInfo}
          addonsTab={addonsTab}
          close={() =>
            setAddonsTab(addonsTab === "bills" ? "add-ons" : "bills")
          }
          closePatientDetails={() => closePatientDetails()}
          billingDetails={billingDetails}
          getPatientsOnQueue={() => getPatientsOnQueue()}
        />
      )}

      {addonsTab === "bills" ? (
        <Box mt={2} m={0} p={0}>
          <Box component={Paper} elevation={1} mb={2}>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> Order </TableCell>
                      <TableCell align="center"> Order Rate </TableCell>
                      <TableCell align="center"> Action </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {billingDetails.ready ? (
                      billingDetails.data.length > 0 ? (
                        billingDetails.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography className="text-capitalize">
                                {data.bill_name}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(data.bill_amount)}
                              {/* discount total */}
                              <span hidden>
                                {/* {calculateOrderDiscount(
                                  data.bill_from === "laboratory"
                                    ? parseInt(data.can_be_discounted) !== 1
                                      ? 0
                                      : data.bill_amount
                                    : data.bill_amount
                                )} */}
                                {calculateOrderDiscount(
                                  parseInt(data.can_be_discounted) === 0
                                    ? 0
                                    : data.bill_amount
                                )}
                              </span>
                              {/* total amount */}
                              <span hidden>
                                {calculateOrder(data.bill_amount)}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip arrow title="Cancel Order">
                                <IconButton
                                  color="secondary"
                                  hidden={data.bill_from === "appointment"}
                                  onClick={() =>
                                    setCancelOrder({ data: data, dialog: true })
                                  }
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Typography color="secondary">
                              No unpaid order.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={2} align={"center"}>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            setPaidDialog({
                              data: totalorder,
                              open: true,
                              discount: totaldiscount,
                            })
                          }
                        >
                          {details.transaction_type !== null
                            ? "CONFIRM"
                            : "PAYMENT"}{" "}
                          - PHP {Notify.convertToNumber(totalorder)}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>

            {/* cancel order dialog */}
            <Dialog
              open={cancelOrder.dialog}
              onClose={() => setCancelOrder({ data: null, dialog: false })}
              disableBackdropClick
              disableEscapeKeyDown
            >
              {cancelOrder.data && cancelOrder.dialog && (
                <form onSubmit={handleCancelOrder}>
                  <DialogTitle>Verify cancel</DialogTitle>
                  <DialogContent dividers>
                    <Box mb={2}>
                      <TextField
                        label={"Order Id"}
                        variant="outlined"
                        name="cancel_id"
                        defaultValue={cancelOrder.data.cpb_id}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        hidden
                      />

                      <TextField
                        label={"Name"}
                        variant="outlined"
                        name="bill_name"
                        defaultValue={cancelOrder.data.bill_name}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Box>

                    <Box>
                      <TextField
                        label={"Enter password"}
                        variant="outlined"
                        name="password"
                        type="password"
                        fullWidth
                      />
                    </Box>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() =>
                        setCancelOrder({ data: null, dialog: false })
                      }
                    >
                      No
                    </Button>

                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isprocessCancel}
                      startIcon={
                        isprocessCancel && (
                          <CircularProgress size={20} color="inherit" />
                        )
                      }
                    >
                      Cancel Order
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Dialog>

            {/* setas paid bills dialog */}
            <Dialog
              open={paidDialog.open}
              onClose={() =>
                setPaidDialog({ data: null, open: false, discount: null })
              }
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              fullWidth
            >
              <form onSubmit={handleSetAsPaid}>
                <DialogTitle>Bills Payment Dialog</DialogTitle>

                <DialogContent dividers>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Amount to pay"
                      variant="outlined"
                      value={
                        paidDialog.data !== null
                          ? discount !== ""
                            ? paidDialog.data - paidDialog.discount * discount
                            : homeService !== ""
                            ? paidDialog.data + paidDialog.data * homeService
                            : paidDialog.data
                          : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                    <TextField
                      required
                      fullWidth
                      label="Amount to pay"
                      variant="outlined"
                      value={paidDialog.data !== null ? paidDialog.data : ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="amountto_pay"
                      hidden
                    />

                    <TextField
                      required
                      fullWidth
                      label="Company"
                      variant="outlined"
                      value={details.company !== null ? details.company : ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="patient_company"
                      hidden
                    />
                  </Box>

                  {/* <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Transaction type"
                      variant="outlined"
                      name="transaction_type"
                    />
                  </Box> */}

                  {billingDetails.data.length > 0 &&
                    billingDetails.data[0].totalOtherToDoctor > 0 && (
                      <Box mb={2}>
                        <TextField
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name="doctor"
                          label="Doctor"
                          variant="outlined"
                          required={
                            parseFloat(
                              billingDetails.data[0].totalOtherToDoctor
                            ) > 0
                              ? true
                              : false
                          }
                        >
                          <option value={""}> Select </option>
                          {doctorList.data.length > 0 &&
                            doctorList.data &&
                            doctorList.data.map((data, index) => {
                              return (
                                <option key={index} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                        </TextField>
                      </Box>
                    )}

                  {/* {console.log("details", details)} */}

                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Transaction type"
                      variant="outlined"
                      value={
                        details.transaction_type !== null
                          ? details.transaction_type
                          : "walk-in"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      name="transaction_type"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Payment type"
                      variant="outlined"
                      name="payment_type"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={paymentTypeWalkIn}
                      onChange={(e) => handleSetHMOWalkIn(e.target)}
                    >
                      <option value=""> Select </option>
                      <option value="cash"> CASH </option>
                      <option value="hmo"> HMO </option>
                      <option value="branch"> BILL TO BRANCH </option>
                      <option value="van"> MOBILE CONTINUATION HMO</option>
                      <option
                        value="direct-company"
                        // hidden={
                        //   details.transaction_type !== null ? false : true
                        // }
                      >
                        MOBILE CONTINUATION DIRECT TO COMPANY
                        {/* {billingDetails.data.length > 0
                          ? billingDetails.data[0].accredited_company
                          : ""} */}
                      </option>
                    </TextField>
                  </Box>

                  {paymentTypeWalkIn === "branch" && (
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Branch"
                        variant="outlined"
                        name="bill_out_branch"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Select </option>
                        {branches.map((data, index) => (
                          <option
                            value={data.management_id}
                            disabled={
                              data.management_id === users.management_id
                            }
                            key={index}
                          >
                            {data.name}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  )}

                  {paymentTypeWalkIn === "hmo" && (
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="HMO"
                        variant="outlined"
                        name="hmo"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Select </option>
                        {hmo.ready &&
                          hmo.data.map((data, index) => (
                            <option value={data.hl_id} key={index}>
                              {data.name}
                            </option>
                          ))}
                      </TextField>
                    </Box>
                  )}

                  {paymentTypeWalkIn === "van" && (
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label={`${
                          billingDetails.data.length > 0
                            ? billingDetails.data[0].accredited_company
                            : ""
                        } HMO`}
                        variant="outlined"
                        name="company_hmo"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Select </option>
                        {companyHMOList.ready &&
                          companyHMOList.data.map((data, index) => (
                            <option value={data.mach_id} key={index}>
                              {data.hmo}
                            </option>
                          ))}
                      </TextField>
                    </Box>
                  )}

                  {/* <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Payment Type"
                      variant="outlined"
                      name="payment_type"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={paymentType}
                      onChange={(e) => handleSetHMO(e.target)}
                    >
                      <option value=""> Select </option>
                      <option value="cash"> Cash </option>
                      <option
                        // value={"charge"}
                        // hidden={
                        //   details.company === null &&
                        //   details.transaction_type === null
                        //     ? true
                        //     : false
                        // }
                        value={
                          billingDetails.data.length > 0
                            ? billingDetails.data[0].companyyy !== null
                              ? billingDetails.data[0].companyyy
                              : "charge"
                            : ""
                        }
                        hidden={
                          details.transaction_type === null ? true : false
                        }
                      >
                        Charge to{" "}
                        {billingDetails.data.length > 0
                          ? billingDetails.data[0].accredited_company
                          : ""}
                      </option>
                    </TextField>
                  </Box> */}

                  {paymentTypeWalkIn === "cash" && (
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Payment"
                        variant="outlined"
                        name="payment"
                        type="number"
                        InputProps={{
                          readOnly: paymentTypeWalkIn !== "cash" ? true : false,
                          inputProps: {
                            min:
                              paidDialog.data &&
                              parseFloat(
                                discount !== ""
                                  ? paidDialog.data -
                                      paidDialog.discount * discount
                                  : homeService !== ""
                                  ? paidDialog.data +
                                    paidDialog.data * homeService
                                  : paidDialog.data
                              ),
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>
                  )}

                  {/* {paymentType !== "cash" && paymentType !== "" && (
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="HMO"
                        variant="outlined"
                        name="hmo"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Select </option>
                        <option value={"direct"}>Direct</option>
                        {hmoList.ready &&
                          hmoList.data.length &&
                          hmoList.data.map((data, index) => (
                            <option key={index} value={data.mach_id}>
                              {data.hmo}
                            </option>
                          ))}
                      </TextField>
                    </Box>
                  )} */}

                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Request Physician"
                      variant="outlined"
                      name="request_physician"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Transaction Category"
                      variant="outlined"
                      name="transaction_category"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={transactionCategory}
                      onChange={(e) => setTransactionCategory(e.target.value)}
                    >
                      <option value=""> Select </option>
                      <option value="home-service"> Home Service </option>
                      <option value="discount"> Discount </option>
                    </TextField>
                  </Box>

                  {transactionCategory === "discount" && (
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Discount"
                        variant="outlined"
                        name="discount"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        required
                      >
                        <option value=""> Select </option>
                        <option value={0.05}> 5% </option>
                        <option value={0.1}> 10% </option>
                        <option value={0.15}> 15% </option>
                        <option value={0.2}> 20% </option>
                        <option value={0.25}> 25% </option>
                        <option value={0.3}> 30% </option>
                        <option value={0.35}> 35% </option>
                        <option value={0.4}> 40% </option>
                        <option value={0.45}> 45% </option>
                        <option value={0.5}> 50% </option>
                      </TextField>
                    </Box>
                  )}

                  {transactionCategory === "home-service" && (
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Home Service Rate"
                        variant="outlined"
                        name="home_service"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={homeService}
                        onChange={(e) => setHomeService(e.target.value)}
                        required
                      >
                        <option value=""> Select </option>
                        <option value={0.2}> 20% </option>
                        <option value={0.35}> 35% </option>
                      </TextField>
                    </Box>
                  )}

                  {discount !== "" && (
                    <Box mb={2}>
                      <TextField
                        required
                        fullWidth
                        label="Discount Reason"
                        variant="outlined"
                        name="discount_reason"
                      />
                    </Box>
                  )}

                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Note"
                      variant="outlined"
                      name="note"
                      defaultValue="Service must be availed within 7 days"
                    />
                  </Box>

                  <Box>
                    <TextField
                      required
                      fullWidth
                      label="Enter password"
                      variant="outlined"
                      type="password"
                      name="password"
                    />
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      setPaidDialog({
                        data: null,
                        open: false,
                        discount: null,
                      });
                      setHomeService("");
                      setDiscount("");
                      setTransactionCategory("");
                      setPaymentTypeWalkIn("");
                    }}
                  >
                    No
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isprocessPaid}
                    startIcon={
                      isprocessPaid && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    Yes
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </Box>
        </Box>
      ) : (
        <Box mt={2} m={0} p={0}>
          <Box component={Paper} elevation={1} mb={2}>
            <Tabs
              value={category}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
            >
              <Tab
                wrapped
                value="laboratory"
                label="Laboratory"
                icon={
                  <FontAwesomeIcon icon={faVials} style={{ fontSize: 18 }} />
                }
              />

              <Tab
                wrapped
                label="Imaging"
                value="imaging"
                icon={
                  <FontAwesomeIcon icon={faXRay} style={{ fontSize: 18 }} />
                }
              />

              <Tab
                wrapped
                label="Doctor"
                value="doctor"
                icon={
                  <FontAwesomeIcon icon={faUserMd} style={{ fontSize: 18 }} />
                }
              />

              <Tab
                wrapped
                label="Psychology Test"
                value="ishihara"
                icon={
                  <FontAwesomeIcon icon={faBrain} style={{ fontSize: 18 }} />
                }
              />

              <Tab
                wrapped
                label="Other Test"
                value="other-test"
                icon={
                  <FontAwesomeIcon
                    icon={faSpellCheck}
                    style={{ fontSize: 18 }}
                  />
                }
              />

              <Tab
                wrapped
                label="Package"
                value="package"
                icon={
                  <FontAwesomeIcon icon={faBoxOpen} style={{ fontSize: 18 }} />
                }
              />
            </Tabs>
          </Box>

          <TabPanel value={category} index={"laboratory"}>
            <CashierCreateLabOrder
              trace_number={details.trace_number}
              patient_id={details.patient_id}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            />
          </TabPanel>

          <TabPanel value={category} index={"imaging"}>
            <CashierCreateImgOrder
              patient_id={details.patient_id}
              trace_number={details.trace_number}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            />
          </TabPanel>

          <TabPanel value={category} index={"doctor"}>
            {/* based on the utils like examination or med cert */}
            {/* <PatientsPE
              patient_id={details.patient_id}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            /> */}
            <AddDoctorService
              trace_number={details.trace_number}
              patient_id={details.patient_id}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            />
          </TabPanel>

          <TabPanel value={category} index={"ishihara"}>
            <CashierCreatePsyOrder
              trace_number={details.trace_number}
              patient_id={details.patient_id}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            />
          </TabPanel>

          <TabPanel value={category} index={"other-test"}>
            <AddOnOtherTest
              trace_number={details.trace_number}
              patient_id={details.patient_id}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            />
          </TabPanel>

          <TabPanel value={category} index={"package"}>
            <CashierCreatePackageOrder
              trace_number={details.trace_number}
              patient_id={details.patient_id}
              getPatientBillingDetails={() => getPatientBillingDetails()}
            />
          </TabPanel>
        </Box>
      )}
    </>
  );
};

export default BillingDetails;
