import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersData, UsersHeader } from "../ContextAPI";
import Radiologist from "src/radiologist/Radiologist";
import RadiologistPatients from "src/radiologist/RadiologistPatients";
import { Badge } from "@material-ui/core";
import Account from "src/radiologist/account";
import RadiologistReport from "src/radiologist/RadiologistReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faFolderOpen,
  faHome,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import LeaveForm from "src/radiologist/leave/LeaveForm";
import IsMountedRef from "src/utils/IsMountedRef";
import RadiologistPatientsUltraSound from "src/radiologist/RadiologistPatientsUltraSound";

const RouteRadiologist = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const { users } = useContext(UsersData);
  var interval = null;
  const [newPatientCount, setNewPatientCount] = useState(0);
  const [newPatientCountUltra, setNewPatientCountUltra] = useState(0);
  const mounted = IsMountedRef();
  const [patient, setPatients] = useState({ data: [], ready: false });
  const [patientUltra, setPatientsUltra] = useState({ data: [], ready: false });

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: users.user_id };
      const response = await axios.get(
        "radiologist/sidebar/header-infomartion",
        { params }
      );
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [users, mounted]);

  const getNewPatientCount = React.useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      type: "xray",
    };
    const request = await axios.get(
      "radiologist/patients/getpatient-forreview",
      { params }
    );
    if (mounted.current) {
      setPatients({ data: request.data, ready: true });
      if (request.data.length > 0) {
        setNewPatientCount(request.data.length);
      } else {
        setNewPatientCount(0);
      }
    }
  }, [users, mounted]);

  const getNewPatientCountForUltraSound = React.useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      type: "ultra-sound",
    };
    const request = await axios.get(
      "radiologist/patients/ultra-sound/getpatient-forreview",
      { params }
    );
    if (mounted.current) {
      setPatientsUltra({ data: request.data, ready: true });
      if (request.data.length > 0) {
        setNewPatientCountUltra(request.data.length);
      } else {
        setNewPatientCountUltra(0);
      }
    }
  }, [users, mounted]);

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/radiologist",
      subitem: [],
    },
    {
      name: "Patients for X-ray",
      show: true,
      icon: (
        <Badge badgeContent={newPatientCount} color="error">
          <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
        </Badge>
      ),
      path: "/bmcdc/app/radiologist/patient",
      subitem: [],
    },
    {
      name: "Patients for UTZ",
      show: true,
      icon: (
        <Badge badgeContent={newPatientCountUltra} color="error">
          <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
        </Badge>
      ),
      path: "/bmcdc/app/radiologist/patient/ultra-sound",
      subitem: [],
    },
    {
      name: "Report",
      show: true,
      icon: <FontAwesomeIcon icon={faFolderOpen} color="white" size={"2x"} />,
      path: "/bmcdc/app/radiologist/report",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/radiologist/leave-application",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/radiologist/account",
      subitem: [],
    },
  ];

  const intializeInterval = () => {
    interval = setInterval(() => {
      getNewPatientCount();
      getNewPatientCountForUltraSound();
    }, 20000);
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getNewPatientCount();
    getNewPatientCountForUltraSound();
  }, [
    getSidebarHeaderInfo,
    getNewPatientCount,
    getNewPatientCountForUltraSound,
  ]);

  useEffect(() => {
    intializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
          queue: {
            patient: patient,
            getPatientsOnQueue: () => getNewPatientCount(),

            patientUltra: patientUltra,
            getPatientsOnQueueUltra: () => getNewPatientCountForUltraSound(),
          },
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "radiologist",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"Radiologist"}
          />

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/bmcdc/app/radiologist" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/bmcdc/app/radiologist" />}
            />
            <Route
              exact
              path="/bmcdc/app"
              component={() => <Redirect to="/bmcdc/app/radiologist" />}
            />

            <Route
              exact
              path="/bmcdc/app/radiologist"
              component={Radiologist}
            />

            <Route
              exact
              path="/bmcdc/app/radiologist/patient"
              component={RadiologistPatients}
            />

            <Route
              exact
              path="/bmcdc/app/radiologist/patient/ultra-sound"
              component={RadiologistPatientsUltraSound}
            />

            <Route
              exact
              path="/bmcdc/app/radiologist/report"
              component={RadiologistReport}
            />

            <Route
              exact
              path="/bmcdc/app/radiologist/leave-application"
              component={LeaveForm}
            />

            <Route
              exact
              path="/bmcdc/app/radiologist/account"
              component={Account}
            />

            <Route exact path="/bmcdc/app/logout" component={Logout} />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteRadiologist;
