import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import LaboratoryDelivery from "./delivery";
import ItemMoniroting from "./monitoring";
import LaboratoryInventory from "./inventory";
// import LaboratoryCreateOrder from "./orders";
import Items from "./items";
import ExpiryMoniroting from "./expiry-monitoring";
import LabOrderTest from "./orders/LabOrderTest";

const LaboratoryTest = () => {
  const [category, setCategory] = useState("delivery");

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "monitoring",
        items: [{ name: "Dashboard", path: "/bmcdc/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Laboratory</Box>
          <Box maxWidth={450}>
            <Paper>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab value="delivery" label="Delivery" />
                <Tab value="inventory" label="Inventory" />
                <Tab value="monitoring" label="Monitoring" />
                <Tab value="item" label="Item" />
                <Tab value="test" label="Order" />
                <Tab value="expiry" label="Expiry Monitoring" />
              </Tabs>
            </Paper>
          </Box>
        </Box>
      }
    >
      <Box>
        <Box>
          <TabPanel value={category} index="delivery">
            <LaboratoryDelivery />
          </TabPanel>
          <TabPanel value={category} index="inventory">
            <LaboratoryInventory />
          </TabPanel>
          <TabPanel value={category} index="monitoring">
            <ItemMoniroting />
          </TabPanel>
          <TabPanel value={category} index="item">
            <Items />
          </TabPanel>
          <TabPanel value={category} index="test">
            {/* <LaboratoryCreateOrder /> */}
            <LabOrderTest />
          </TabPanel>

          <TabPanel value={category} index="expiry">
            <ExpiryMoniroting />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};

export default LaboratoryTest;
