import axios from "axios";
import React, { useContext, useState } from "react";
import { Fragment } from "react";
import { UsersData } from "src/ContextAPI";
import { Box, TextField, Button, CircularProgress } from "@material-ui/core";
import Notify from "src/notification/Notify";
import { CheckSquare, XCircle } from "react-feather";

const EditRate = ({
  details,
  getAllPackage,
  getPackageOrderList,
  close,
  closeUpdate,
}) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitEditRatePackage = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", details.management_id);
    formdata.set("username", users.username);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    var error = [];

    if (
      formdata.get("password").trim() === "" ||
      formdata.get("password").length === 0
    ) {
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("Form has an error, observe please");
    } else {
      setIsSubmitting(true);

      axios
        .post("accounting/package/update/edit-rate", formdata)
        .then((res) => {
          if (res.data.message === "success") {
            e.target.reset();
            Notify.successRequest("edit rate of package");
            getAllPackage();
            close();
            closeUpdate();
          }

          if (res.data.message === "pass-invalid") {
            Notify.fieldInvalid("password");
          }

          if (res.data.message === "db-error") {
            Notify.warnRequest("edit rate of package");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmitEditRatePackage}>
        <Box>
          <TextField
            fullWidth
            required
            name="package_id"
            variant="outlined"
            value={details && details?.package_id}
            hidden
          />

          <TextField
            fullWidth
            required
            name="package_name"
            variant="outlined"
            value={details && details?.package_name}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box mt={2}>
          <TextField
            fullWidth
            required
            name="order_amount"
            label="Order Rate"
            variant="outlined"
            type="number"
            defaultValue={details && details?.order_amount}
            InputProps={{
              inputProps: {
                min: 1,
                max: 100000,
                step: 0.01,
              },
            }}
          />
        </Box>

        <Box mt={2}>
          <TextField
            type="password"
            name="password"
            label="Password"
            fullWidth
            variant="outlined"
          />
        </Box>

        <Box my={2} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="contained"
              size="large"
              color="default"
              startIcon={<XCircle />}
              onClick={close}
            >
              No
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              startIcon={
                isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckSquare />
                )
              }
              disabled={isSubmitting}
            >
              Add
            </Button>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default EditRate;
