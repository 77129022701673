import {
  Grid,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const FecalAnalysisOrder = ({ formheader, orderDetails }) => {
  console.log("orderDetails", orderDetails);

  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          {/* order details */}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              FECAL ANALYSIS
            </Typography>
          </Box>

          <Box>
            <>
              <Box mb={2}>
                {/* clinical microscopy */}
                {orderDetails.data.map((data, index) => (
                  <Box mt={2} key={index}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Cellular Elements
                            </Typography>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  COLOR:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_color}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  CONSISTENCY:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_consistency}
                                  </span>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  PUS:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_pus}
                                  </span>
                                </Box>
                                /HPF
                              </Box>
                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  RBC:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_rbc}
                                  </span>
                                </Box>
                                /HPF
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Grid container>
                          <Grid item xs={3} sm={3} />
                          <Grid item xs={9} sm={9}>
                            <Box>
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  <b> Others </b>
                                </Typography>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  <b> FAT GLOBULES: </b>
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_fat_globules}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  <b> OCCULT BLOOD:: </b>
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_occultblood}
                                  </span>
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  BACTERIA:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                      color: "#000",
                                    }}
                                  >
                                    {data.cellular_elements_bacteria}
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box mt={1}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bolder", color: "#000" }}
                      >
                        <b> RESULT: </b>
                      </Typography>
                      <Box mx={2} minWidth={100}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          {data.cellular_elements_result}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          </Box>

          <Box>
            <Footer formheader={formheader} orderDetails={orderDetails} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default FecalAnalysisOrder;
