import React, { Component, Fragment } from "react";
import Axios from "axios";
import Notify from "../../notification/Notify";
import { Col } from "react-bootstrap";
import { Box, Grid, Paper, TextField, Typography } from "@material-ui/core";

export default class PatientHistory extends Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      _user_id: localStorage.getItem("user_id"),
      _token: localStorage.getItem("token"),
      _management_id: localStorage.getItem("management_id"),
      _type: localStorage.getItem("type"),
      _username: localStorage.getItem("username"),
      _patient_id: this.props.patient_id,

      _history: [],
      _history_ready: false,

      // pagination setup
      _currentPage: 1,
      _historyPerPage: 1,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.getPatientHistory();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getPatientHistory() {
    var formdata = new FormData();
    formdata.set("patient_id", this.props.patient_id);

    Axios.post("doctor/patient/history/patient-history", formdata)
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            this.setState({
              _history: data,
              _history_ready: true,
            });
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  handlePage(event) {
    this.setState({
      _historyPerPage: Number(event.currentTarget.value),
    });
  }

  handleClick(event) {
    this.setState({
      _currentPage: Number(event.currentTarget.id),
    });
  }

  componentDidUpdate() {
    if (this.state._patient_id !== this.props.patient_id) {
      this.setState({
        _patient_id: this.props.patient_id,
        _history_ready: false,
      });
      this.componentDidMount();
    }
  }

  render() {
    const { _history, _currentPage, _historyPerPage } = this.state;

    const indexOfLastTodo = _currentPage * _historyPerPage;
    const indexOfFirstTodo = indexOfLastTodo - _historyPerPage;
    const current_history = _history.slice(indexOfFirstTodo, indexOfLastTodo);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(_history.length / _historyPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Fragment>
        <Paper component={Box} elevation={2} p={2}>
          <Box mb={2}>
            <Typography variant="h6" color="textSecondary">
              Family History
            </Typography>
          </Box>

          {this.state._history_ready
            ? current_history.length > 0
              ? current_history.map((data, index) => {
                  return (
                    <Box key={index} className={`gtc-textfield-noborder`}>
                      <Box mb={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Weight"
                                InputProps={{ readOnly: true }}
                                value={
                                  data.weight === null
                                    ? "None"
                                    : `${data.weight} kg`
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Height"
                                InputProps={{ readOnly: true }}
                                value={
                                  data.height === null
                                    ? "None"
                                    : `${data.height} ft`
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mb={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Occupation"
                                InputProps={{ readOnly: true }}
                                value={
                                  data.occupation === null
                                    ? "None"
                                    : `${data.occupation}`
                                }
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Zip"
                                InputProps={{ readOnly: true }}
                                value={
                                  data.zip === null ? "None" : `${data.zip}`
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mb={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Allergies"
                                InputProps={{ readOnly: true }}
                                value={
                                  data.allergies === null
                                    ? "None"
                                    : `${data.allergies}`
                                }
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Medication"
                                InputProps={{ readOnly: true }}
                                value={
                                  data.medication === null
                                    ? "None"
                                    : `${data.medication}`
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid item xs={12} sm={12}>
                        <Box>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Addresss"
                            InputProps={{ readOnly: true }}
                            value={
                              data.city === null
                                ? `${data.street} ${data.barangay} ${data.city}`
                                : "None"
                            }
                          />
                        </Box>
                      </Grid>
                    </Box>
                  );
                })
              : Notify.noRecord()
            : Notify.loading()}
        </Paper>
        {/* pagination */}
        <Col sm={12} className="mt-3">
          {pageNumbers.length > 1 ? (
            <ul className="pagination justify-content-center m-0">
              {pageNumbers.map((page) => {
                return (
                  <li
                    className={
                      page === _currentPage
                        ? "page-item pointer active"
                        : "page-item pointer"
                    }
                    key={page}
                    id={page}
                    onClick={this.handleClick.bind(this)}
                  >
                    <span className="page-link">{page}</span>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </Col>
      </Fragment>
    );
  }
}
