import React, { useEffect, useCallback, useContext, useState } from "react";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  // TextField,
  // CircularProgress,
  // Divider,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Notify from "src/notification/Notify";
import {
  ArrowLeft,
  Trash,
  Check,
  X,
  // ArrowRight,
  Send,
  // CornerRightUp,
  // CornerUpRight,
  // DollarSign,
} from "react-feather";
// import IsMountedRef from "src/utils/IsMountedRef";

const BillingEditOrders = ({ selectedRecord, getBillingRecords, close }) => {
  const { users } = useContext(UsersData);
  const [orderList, setOrderList] = useState({ data: [], ready: false });
  const [selectedTest, setSelectedTest] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSendOut, setOpenDialogSendOut] = useState(false);
  const [openDialogBillingTo, setOpenDialogBillingTo] = useState(false);
  // const [branches, setBranches] = useState([]);
  // const mounted = IsMountedRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAllRecords = useCallback(async () => {
    var params = {
      trace_number: selectedRecord.trace_number,
      patient_id: selectedRecord.patient_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    let response = await axios.get(
      "cashier/get/all/order-details/by-trace-no",
      { params }
    );
    const data = response.data;
    setOrderList({ data, ready: true });
  }, [
    selectedRecord.trace_number,
    selectedRecord.patient_id,
    users.management_id,
    users.main_mgmt_id,
  ]);

  const handleRemoveTest = () => {
    // e.persist();
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("cpr_id", selectedTest.cpr_id);
    formdata.append("trace_number", selectedTest.trace_number);

    axios
      .post("cashier/delete/patient-test/by-id", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setOpenDialog(false);
          getBillingRecords();
          getAllRecords();
          Notify.successRequest("delete receipt");
        } else {
          Notify.customToast("Something went wrong", "Please try again...");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const handleDeleteTest = (data) => {
  //   console.log("data", data);
  // };

  const handleSendOut = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("cpr_id", selectedTest.cpr_id);
    formdata.append("trace_number", selectedTest.trace_number);

    axios
      .post("cashier/update/send-out-test/by-id", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setOpenDialogSendOut(false);
          getBillingRecords();
          getAllRecords();
          Notify.successRequest("updated receipt");
        } else {
          Notify.customToast("Something went wrong", "Please try again...");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleBillTo = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("cpr_id", selectedTest.cpr_id);
    formdata.append("trace_number", selectedTest.trace_number);
    setIsSubmitting(true);

    axios
      .post("cashier/update/bill-out-test/by-id", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setOpenDialogBillingTo(false);
          getBillingRecords();
          getAllRecords();
          Notify.successRequest("updated receipt");
        } else {
          Notify.customToast("Something went wrong", "Please try again...");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  // const getBranchesByMainMngtId = useCallback(async () => {
  //   var params = { main_management_id: users.main_mgmt_id, type: "clinic" };
  //   let response = await axios.get("general/management/get-branches", {
  //     params,
  //   });
  //   if (mounted.current) {
  //     setBranches(response.data);
  //   }
  // }, [users, mounted]);

  useEffect(() => {
    getAllRecords();
    // getBranchesByMainMngtId();
  }, [
    getAllRecords,
    // getBranchesByMainMngtId
  ]);

  return (
    <>
      <Box display={"flex"} alignItems={"center"} m={1}>
        <Button
          startIcon={<ArrowLeft />}
          color="primary"
          variant="contained"
          onClick={() => close()}
        >
          BACK
        </Button>
      </Box>
      <TableContainer>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b> Department </b>
                </TableCell>
                <TableCell>
                  <b> Order </b>
                </TableCell>
                <TableCell align="center">
                  <b> Amount </b>
                </TableCell>
                <TableCell align="center">
                  <b> Action </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.ready ? (
                orderList.data.length > 0 ? (
                  orderList.data.map((item, key) => (
                    <TableRow key={key}>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {item.bill_from}
                      </TableCell>
                      <TableCell>{item.bill_name}</TableCell>
                      <TableCell align="right">{item.bill_amount}</TableCell>
                      <TableCell align="center">
                        <Tooltip arrow title="Delete">
                          <IconButton
                            onClick={() => {
                              setSelectedTest(item);
                              setOpenDialog(true);
                            }}
                            color="secondary"
                          >
                            <Trash size={20} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Send Out">
                          <IconButton
                            onClick={() => {
                              setSelectedTest(item);
                              setOpenDialogSendOut(true);
                            }}
                            // color="primary"
                            disabled={
                              parseInt(item.is_send_out) !== 1 ? false : true
                            }
                          >
                            <Send size={20} />
                          </IconButton>
                        </Tooltip>
                        {/* <Tooltip arrow title="Bill Out">
                          <IconButton
                            onClick={() => {
                              setSelectedTest(item);
                              setOpenDialogBillingTo(true);
                            }}
                            color="primary"
                            disabled={
                              parseInt(item.is_bill_out) !== 1 ? false : true
                            }
                          >
                            <DollarSign size={20} />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}> {Notify.noRecord()}</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}> {Notify.loading()}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogContent>
            <Box>
              <Typography>
                Are you sure to remove this test and print final receipt
              </Typography>
              <Typography>for the patient?</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Check style={{ width: 15 }} />}
              onClick={handleRemoveTest}
            >
              Yes
            </Button>

            <Button
              variant="contained"
              color="default"
              startIcon={<X style={{ width: 15 }} />}
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialogSendOut}
          onClose={() => setOpenDialogSendOut(false)}
        >
          <DialogContent>
            <Box>
              <Typography>Are you sure to send out this test?</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Check style={{ width: 15 }} />}
              onClick={handleSendOut}
            >
              Yes
            </Button>

            <Button
              variant="contained"
              color="default"
              startIcon={<X style={{ width: 15 }} />}
              onClick={() => {
                setOpenDialogSendOut(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialogBillingTo}
          onClose={() => setOpenDialogBillingTo(false)}
        >
          <DialogContent>
            <Box>
              <Typography>Are you sure to add this to bill out?</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Check style={{ width: 15 }} />}
              onClick={handleBillTo}
              disabled={isSubmitting}
            >
              Yes
            </Button>

            <Button
              variant="contained"
              color="default"
              startIcon={<X style={{ width: 15 }} />}
              onClick={() => {
                setOpenDialogBillingTo(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </>
  );
};

export default BillingEditOrders;
