import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { ArrowLeft } from "react-feather";

const AccountingRequestDetails = ({ request_id, close }) => {
  const { users } = useContext(UsersData);
  const [specRequestIdInfo, setSpecRequestIdInfo] = useState({
    data: [],
    ready: false,
  });

  const getRequestDetails = async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
        request_id,
      };
      const response = await axios.get(
        "accounting/get/request-confirm-info-by-id",
        {
          params,
        }
      );
      setSpecRequestIdInfo({ data: response.data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  };

  useEffect(() => {
    getRequestDetails();

    // eslint-disable-next-line
  }, [request_id]);

  return (
    <>
      <Box mb={2}>
        <Typography color="primary" variant="h5">
          <IconButton onClick={() => close()}>
            <ArrowLeft />
          </IconButton>
          <strong>Request Info</strong>
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Item Brand</b>
              </TableCell>
              <TableCell>
                <b>Item Description</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specRequestIdInfo ? (
              specRequestIdInfo.data.length > 0 ? (
                specRequestIdInfo.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.item}</TableCell>
                    <TableCell>{data.description}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {Notify.noRecord()}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  {Notify.loading()}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccountingRequestDetails;
