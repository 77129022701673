import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Send } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const AccountingDisapproveList = ({ list, request_id }) => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [tempProduct, setTempProduct] = useState({ data: [], ready: false });
  // let total = 0;

  const calculateTotal = (qty, srp) => {
    // total += parseInt(qty) * parseFloat(srp);
    return parseInt(qty) * parseFloat(srp);
  };

  const getTempDrProductsList = React.useCallback(async () => {
    try {
      let response = await axios.get(
        "accounting/warehouse/account/deliver/list-producttotemp",
        {
          params: {
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            status: "disapprove",
            request_id,
          },
        }
      );

      let result = response.data;
      if (mounted.current) {
        setTempProduct({ data: result, ready: true });
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, mounted, request_id]);

  const handleRestore = async () => {
    var formdata = new FormData();
    formdata.set(
      "request_id",
      tempProduct.ready &&
        tempProduct.data.length > 0 &&
        tempProduct.data[0].request_id
    );

    let response = await axios.post(
      "accounting/warehouse/update/restore-disapprove-request",
      formdata
    );

    let result = response.data;

    if (result.message === "success") {
      getTempDrProductsList();
      list();
      Notify.successRequest("restore");
    }
  };

  useEffect(() => {
    getTempDrProductsList();
  }, [getTempDrProductsList]);

  return (
    <Fragment>
      <Box component={Paper} variant="outlined" p={2}>
        <Box
          mb={2}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <Box flexGrow={1}>
            <Typography color="primary" variant="h5">
              <strong>Disapproved Order</strong>
            </Typography>
          </Box>
          <Box
            hidden={
              tempProduct.ready && tempProduct.data.length > 0 ? false : true
            }
          >
            <Button
              startIcon={<Send />}
              color="primary"
              variant="contained"
              onClick={() => handleRestore()}
            >
              Restore
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Brand</b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>
                  <b>Unit</b>
                </TableCell>
                <TableCell>
                  <b>Batch</b>
                </TableCell>
                <TableCell>
                  <b>Exp. Date</b>
                </TableCell>
                <TableCell>
                  <b>Mfg. Date</b>
                </TableCell>
                <TableCell>
                  <b>Dr. Qty</b>
                </TableCell>
                <TableCell>
                  <b>Srp</b>
                </TableCell>
                <TableCell>
                  <b>Amount</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempProduct.ready ? (
                tempProduct.data.length > 0 ? (
                  tempProduct.data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data.product_name} </TableCell>
                      <TableCell>{data.product_generic}</TableCell>
                      <TableCell> {data.unit} </TableCell>
                      <TableCell> {data.batch_number} </TableCell>
                      <TableCell>{data.expiration_date}</TableCell>
                      <TableCell>{data.manufactured_date}</TableCell>
                      <TableCell align="right">{data.qty}</TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(data.srp)}
                      </TableCell>
                      <TableCell align="center">
                        {Notify.convertToNumber(
                          calculateTotal(data.qty, data.srp)
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    <Typography color="primary">
                      Loading, Please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box
            mt={2}
            hidden={
              tempProduct.ready && tempProduct.data.length > 0 ? false : true
            }
          >
            <Typography
              style={{
                whiteSpace: "pre-line",
              }}
              variant="h6"
            >
              Reason:{" "}
              {tempProduct.ready &&
                tempProduct.data.length > 0 &&
                tempProduct.data[0].owner_disapprove_reason}
            </Typography>
          </Box>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default AccountingDisapproveList;
