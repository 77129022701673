import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TablePagination,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import axios from "axios";

const NSPatientTreatmentPlan = ({ patient_id }) => {
  const { users } = useContext(UsersData);
  const [treatment, setTreatment] = useState({ data: [], ready: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTreatmentPlan = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
      patient_id,
      type: "text",
    };
    try {
      let response = await axios.get("nurse/patient/patient-treatmentplan", {
        params,
      });
      const data = response.data;
      setTreatment({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, patient_id]);

  useEffect(() => {
    getTreatmentPlan();
  }, [getTreatmentPlan]);

  return (
    <Box p={2} mt={2} component={Paper} elevation={2}>
      <Box mb={2}>
        <Typography color="textSecondary" variant="h6">
          <strong>Treatment Plan</strong>
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>Schedule</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Treatment Plan</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {treatment.data.length > 0 ? (
              (rowsPerPage > 0
                ? treatment.data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : treatment.data
              ).map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {data.date === null
                        ? Notify.dateTimeConvert(data.created_at)
                        : Notify.dateTimeConvert(data.date)}
                    </TableCell>
                    <TableCell>
                      <Typography
                        className="pointer"
                        style={{ width: "20vw" }}
                        noWrap
                      >
                        {data.treatment_plan}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3} className="text-danger text-center">
                  <Typography color="error">
                    No treatment plan created.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 20, 50, 100]}
          component="div"
          count={treatment.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default NSPatientTreatmentPlan;
