import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SorologyRef from "../references/Ref_Sorology";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const SorologyOrder = ({ formheader, serologyOrderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={serologyOrderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b style={{ color: "#000" }}> SEROLOGY </b>
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b style={{ color: "#000" }}> EXAMINATION REQUESTED </b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ color: "#000" }}> NORMAL </b>
                  </TableCell>
                  <TableCell align="center">
                    <b style={{ color: "#000" }}> TEST RESULT </b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {serologyOrderDetails.data.map((data, index) => (
                  <Fragment key={index}>
                    {data.hbsag && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            Hepatitis B surface Antigen (HBsAg)
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.hbsag}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.hav && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            HAV (Hepatitis A Virus) lgG/lgM
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.hav}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.vdrl_rpr && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            VDRL/RPR
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.vdrl_rpr}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.anti_hbc_igm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            ANTI-HBC IGM
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.anti_hbc_igm}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.hcv && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            HCV (Hepatitis C Virus)
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {SorologyRef.normal()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.hcv}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.beta_hcg_quali && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            BETA HCG (QUALI)
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.beta_hcg_quali}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.h_pylori && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            H. PYLORI
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.h_pylori}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.typhidot && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            TYPHIDOT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.typhidot}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {Boolean(parseInt(data.syphilis_test)) && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            VDRL/Syphilis Test
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.syphilis_test_result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.hact && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            HACT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.hact}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ana && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            ANA
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.ana}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {Boolean(parseInt(data.dengue_test)) && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            DENGUE TEST
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* {SorologyRef.normal()} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ color: "#000", fontWeight: "bolder" }}
                          >
                            {data.dengue_test_result}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={3} align="center">
            <Footer
              formheader={formheader}
              orderDetails={serologyOrderDetails}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default SorologyOrder;
