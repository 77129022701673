import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "../notification/Notify";
import { useHistory } from "react-router-dom";
import { UsersData } from "../ContextAPI";
import Container from "../layout/Container";
import { Edit, Search } from "react-feather";
import Print from "@material-ui/icons/Print";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";

const LaboratoryReport = () => {
  const themes = useTheme();
  const { users } = React.useContext(UsersData);
  const [order, setOrder] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const history = useHistory();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const getAllLaboratoryReport = async () => {
    var params = {
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    let response = await axios.get("/laboratory/get/all/lab-report", {
      params,
    });
    const data = response.data;
    setOrder({ data, ready: true });
  };

  const handleReportByReport = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    if (
      formdata.get("date_from").trim() === "" ||
      formdata.get("date_to").trim() === ""
    ) {
      Notify.customToast("Date Invalid", "Date is a required field.");
      return false;
    }
    let response = await axios.post(
      "/laboratory/get/all/lab-report/filter",
      formdata
    );
    setOrder({ data: response.data, ready: true });
  };

  useEffect(() => {
    getAllLaboratoryReport();
    // eslint-disable-next-line
  }, []);

  const searchable = order.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "report",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Reports"
      >
        <Box component={Paper} p={2}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Box maxWidth={300}>
                <TextField
                  label="Search Patient"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            <Box>
              <form onSubmit={handleReportByReport}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="From"
                      name="date_from"
                      margin="dense"
                    />
                  </Box>

                  <Box mx={1}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="To"
                      name="date_to"
                      margin="dense"
                    />
                  </Box>
                  <Box>
                    <IconButton
                      type="submit"
                      style={{
                        width: 40,
                        height: 40,
                        backgroundColor: themes.palette.primary.main,
                        color: "#fff",
                      }}
                    >
                      <Search />
                    </IconButton>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> Date </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Patient </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Order Id </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Actions </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.ready ? (
                  searchable.length ? (
                    searchable.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {Notify.dateTimeConvert(data.created_at)}
                        </TableCell>
                        <TableCell>
                          {data.lastname}, {data.firstname}
                        </TableCell>
                        <TableCell>{data.trace_number}</TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box>
                              <IconButton
                                onClick={() =>
                                  history.push(
                                    `/bmcdc/app/laboratory/record/print/order/${data.trace_number}`
                                  )
                                }
                                color="primary"
                              >
                                <Print />
                              </IconButton>
                            </Box>

                            <Box>
                              <IconButton
                                onClick={() =>
                                  history.push(
                                    `/bmcdc/app/laboratory/lab-report/edit/order/${data.trace_number}`
                                  )
                                }
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow align="center">
                      <TableCell colSpan={4}>{Notify.noRecord()}</TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow align="center">
                    <TableCell colSpan={4}>{Notify.loading()}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={Box}
              count={searchable.length}
              labelRowsPerPage="List"
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        </Box>
      </Container>
    </Fragment>
  );
};

export default LaboratoryReport;
