import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { UsersHeader, UsersData } from "../ContextAPI";
import AdmissionDashboard from "src/admission/AdmissionDashboard";
import Account from "src/admission/account/Account";
import AdmissionPatientList from "src/admission/AdmissionPatientList";
import {
  faAddressCard,
  faBuilding,
  faHome,
  faSortNumericDown,
  // faSortAmountUpAlt,
  faStopwatch20,
  faUserInjured,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdmissionGraphLaboratory from "src/admission/patients/walkin/laboratory/AdmissionGraphLaboratory";
import LeaveForm from "src/admission/leave/LeaveForm";
import AdmissionNews from "src/admission/AdmissionNews";
import AdmissionCensus from "src/admission/census/AdmissionCensus";
import AdmissionQueuingList from "src/admission/AdmissionQueuingList";
import AdmissionCompanyList from "src/admission/company-list";

const RouteAdmission = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("admission/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/registration",
      subitem: [],
    },

    {
      name: "Company List",
      show: true,
      icon: <FontAwesomeIcon icon={faBuilding} color="white" size={"2x"} />,
      path: "/bmcdc/app/registration/company-list",
      subitem: [],
    },

    // {
    //   name: "Contact Tracing Queue",
    //   show: true,
    //   icon: (
    //     <FontAwesomeIcon icon={faSortAmountUpAlt} color="white" size={"2x"} />
    //   ),
    //   path: "/bmcdc/app/registration/contact-tracing-queue",
    //   subitem: [],
    // },

    {
      name: "Patient List",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/bmcdc/app/registration/patients-list",
      subitem: [],
    },

    {
      name: "Census",
      show: true,
      icon: <FontAwesomeIcon icon={faStopwatch20} color="white" size={"2x"} />,
      path: "/bmcdc/app/registration/census",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/registration/leave-application",
      subitem: [],
    },

    {
      name: "Queuing List",
      show: true,
      icon: (
        <FontAwesomeIcon icon={faSortNumericDown} color="white" size={"2x"} />
      ),
      path: "/bmcdc/app/registration/queuing-list",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/registration/account",
      subitem: [],
    },
  ];

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              owner: "registration",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"registration"}
          />

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/bmcdc/app/registration" />}
            />

            <Route
              exact
              path="/bmcdc"
              component={() => <Redirect to="/bmcdc/app/registration" />}
            />

            <Route
              exact
              path="/bmcdc/app"
              component={() => <Redirect to="/bmcdc/app/registration" />}
            />

            <Route
              exact
              path="/bmcdc/app/registration"
              component={AdmissionNews}
            />

            <Route
              exact
              path="/bmcdc/app/registration/contact-tracing-queue"
              component={AdmissionDashboard}
            />

            <Route
              exact
              path="/bmcdc/app/registration/company-list"
              component={AdmissionCompanyList}
            />

            <Route
              exact
              path="/bmcdc/app/registration/patients-list"
              component={AdmissionPatientList}
            />

            <Route
              exact
              path="/bmcdc/app/registration/census"
              component={AdmissionCensus}
            />

            <Route
              exact
              path="/bmcdc/app/registration/leave-application"
              component={LeaveForm}
            />

            <Route
              exact
              path="/bmcdc/app/registration/queuing-list"
              component={AdmissionQueuingList}
            />

            <Route
              exact
              path="/bmcdc/app/registration/account"
              component={Account}
            />

            <Route
              exact
              path="/bmcdc/app/registration/patient/laboratory/:type/:patient_id"
              component={AdmissionGraphLaboratory}
            />
            <Route exact path="/bmcdc/app/logout" component={Logout} />
            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </UsersHeader.Provider>
    </Fragment>
  );
};

export default RouteAdmission;
