import {
  Box,
  // CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";

// const imageLocation = process.env.REACT_APP_API_IMAGE;

const Footer = ({ formheader, orderDetails }) => {
  if (!Boolean(orderDetails)) {
    return null;
  }

  return (
    <>
      {/* <Box display="flex" my={3}>
        <Box flexGrow={1}>
          <Box align="center" width={250}>
            <Box>
              {formheader.pathologist_signature ? (
                <CardMedia
                  component="img"
                  src={`${imageLocation}laboratory/pathologist/${formheader.pathologist_signature}`}
                  style={{
                    width: 100,
                    top: 20,
                    position: "relative",
                  }}
                />
              ) : (
                <Box mt={5} />
              )}
            </Box>
            <Typography className={`gtc-uppercase`} variant="subtitle2">
              {formheader && formheader.pathologist}
            </Typography>
            <Box borderTop={1}>
              <b>
                Pathologist (LIC No. {formheader && formheader.pathologist_lcn})
              </b>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box align="center" width={250}>
            <Box mt={5}>
              <Typography className={`gtc-uppercase`} variant="subtitle2">
                {formheader && formheader.medtech}
              </Typography>
              <Box borderTop={1}>
                <b> Medical Technologist </b>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box align="center" width={250}>
            <Box mt={5}>
              <Typography
                className={`gtc-uppercase`}
                variant="subtitle2"
                style={{ color: "#000", fontWeight: "bolder" }}
              >
                {formheader && formheader.pathologist}
              </Typography>
              <Box borderTop={1}>
                <b style={{ color: "#000" }}> Pathologist </b>
              </Box>
              <Box>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  {formheader && formheader.pathologist_lcn}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box align="center" width={250}>
            <Box mt={5}>
              <Typography
                className={`gtc-uppercase`}
                variant="subtitle2"
                style={{ color: "#000", fontWeight: "bolder" }}
              >
                {formheader && formheader.chief_medtech}
              </Typography>
              <Box borderTop={1}>
                <b style={{ color: "#000" }}> Chief Med Technologist </b>
              </Box>
              <Box>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  {formheader && formheader.chief_medtech_lci}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box align="center" width={250}>
            <Box mt={5}>
              <Typography
                className={`gtc-uppercase`}
                variant="subtitle2"
                style={{ color: "#000", fontWeight: "bolder" }}
              >
                {`${orderDetails.data[0].medtech}`}
              </Typography>
              <Box borderTop={1}>
                <b style={{ color: "#000" }}> Medical Technologist </b>
              </Box>
              <Box>
                <Typography
                  className={`gtc-uppercase`}
                  variant="subtitle2"
                  style={{ color: "#000", fontWeight: "bolder" }}
                >
                  {`${orderDetails.data[0].lic_no}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Footer;
