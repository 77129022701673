import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Check, Send, Trash, X } from "react-feather";
import AccountingCreatePurchaseOrder from "./AccountingCreatePurchaseOrder";
import IsMountedRef from "src/utils/IsMountedRef";
import {
  faDotCircle,
  faPlusSquare,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabPanel from "src/utils/TabPanel";
import AccountingWaitingList from "./AccountingWaitingList";
import AccountingApproveList from "./AccountingApproveList";
import AccountingDisapproveList from "./AccountingDisapproveList";

const AccountingSelectedRequestDetails = ({ selectedUnpaid }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { users } = useContext(UsersData);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const mounted = IsMountedRef();
  const [tempProduct, setTempProduct] = useState({ data: [], ready: false });
  const [remove, setRemove] = useState({ dialog: false, id: null });
  const [category, setCategory] = useState(0);
  // let total = 0;

  const calculateTotal = (qty, srp) => {
    // total += parseInt(qty) * parseFloat(srp);
    return parseInt(qty) * parseFloat(srp);
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  const getTempDrProductsList = React.useCallback(async () => {
    try {
      let response = await axios.get(
        "accounting/warehouse/account/deliver/list-producttotemp",
        {
          params: {
            management_id: users.management_id,
            main_mgmt_id: users.main_mgmt_id,
            status: "create",
            request_id: selectedUnpaid.data?.request_id,
          },
        }
      );

      let result = response.data;
      if (mounted.current) {
        setTempProduct({ data: result, ready: true });
      }
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users, mounted, selectedUnpaid.data?.request_id]);

  const handleRemoveProductItem = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", remove.id);

    let response = await axios.post(
      "accounting/warehouse/inventory/product/item-removeintemp",
      formdata
    );

    let result = response.data;

    if (result.message === "success") {
      getTempDrProductsList();
      setRemove({ dialog: false, id: null });
      Notify.successRequest("item remove.");
    }
  };

  const handleConfirmRequest = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("request_id", selectedUnpaid.data?.request_id);

    axios
      .post("accounting/confirm/request-item-to-haptech", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getTempDrProductsList();
          setConfirmationDialog(false);
          Notify.successRequest("confirm request");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    getTempDrProductsList();
  }, [getTempDrProductsList]);

  return (
    <>
      <Box mb={2} m={0} p={0}>
        <Box component={Paper} variant="outlined">
          <Tabs
            value={category}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab
              wrapped
              label="Create"
              icon={<FontAwesomeIcon icon={faPlusSquare} size={"2x"} />}
            />

            <Tab
              wrapped
              label="Waiting"
              icon={<FontAwesomeIcon icon={faDotCircle} size={"2x"} />}
            />

            <Tab
              wrapped
              label="Approved"
              icon={<FontAwesomeIcon icon={faThumbsUp} size={"2x"} />}
            />

            <Tab
              wrapped
              label="Disapproved"
              icon={<FontAwesomeIcon icon={faThumbsDown} size={"2x"} />}
            />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={category} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box mb={2}>
                <Typography color="primary" variant="h5">
                  <strong>Create Purchase Order</strong>
                </Typography>
              </Box>

              <AccountingCreatePurchaseOrder
                getTempDrProductsList={() => getTempDrProductsList()}
                request_id={selectedUnpaid.data?.request_id}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box mb={2}>
                <Typography color="primary" variant="h5">
                  <strong>Selected Order</strong>
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        <b>Brand</b>
                      </TableCell>
                      <TableCell>
                        <b>Description</b>
                      </TableCell>
                      <TableCell>
                        <b>Unit</b>
                      </TableCell>
                      <TableCell>
                        <b>Batch</b>
                      </TableCell>
                      <TableCell>
                        <b>Exp. Date</b>
                      </TableCell>
                      <TableCell>
                        <b>Mfg. Date</b>
                      </TableCell>
                      <TableCell>
                        <b>Dr. Qty</b>
                      </TableCell>
                      <TableCell>
                        <b>Srp</b>
                      </TableCell>
                      <TableCell>
                        <b>Amount</b>
                      </TableCell>
                      <TableCell>
                        <b>Action</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tempProduct.ready ? (
                      tempProduct.data.length > 0 ? (
                        tempProduct.data.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              {parseInt(data.item_check) === 1 ? (
                                <Check style={{ color: "green" }} />
                              ) : parseInt(data.item_check) === 0 ? (
                                <X style={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell> {data.product_name} </TableCell>
                            <TableCell>{data.product_generic}</TableCell>
                            <TableCell> {data.unit} </TableCell>
                            <TableCell> {data.batch_number} </TableCell>
                            <TableCell>{data.expiration_date}</TableCell>
                            <TableCell>{data.manufactured_date}</TableCell>
                            <TableCell align="right">{data.qty}</TableCell>
                            <TableCell align="right">
                              {Notify.convertToNumber(data.srp)}
                            </TableCell>
                            <TableCell align="center">
                              {Notify.convertToNumber(
                                calculateTotal(data.qty, data.srp)
                              )}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  setRemove({
                                    dialog: true,
                                    id: data.id,
                                  })
                                }
                              >
                                <Trash />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={10}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          <Typography color="primary">
                            Loading, Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Box mt={2} hidden={tempProduct.data.length > 0 ? false : true}>
                  <Box
                    display={"flex"}
                    alignContent="center"
                    alignItems={"center"}
                  >
                    <Box flexGrow={1} />
                    <Box>
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<Send />}
                        onClick={() => setConfirmationDialog(true)}
                        color="primary"
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={category} index={1}>
        <AccountingWaitingList request_id={selectedUnpaid.data?.request_id} />
      </TabPanel>

      <TabPanel value={category} index={2}>
        <AccountingApproveList request_id={selectedUnpaid.data?.request_id} />
      </TabPanel>

      <TabPanel value={category} index={3}>
        <AccountingDisapproveList
          list={() => getTempDrProductsList()}
          request_id={selectedUnpaid.data?.request_id}
        />
      </TabPanel>

      {/* dialog remove item */}
      <Dialog
        open={remove.dialog}
        onClose={() => setRemove({ dialog: false, id: null })}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography>Are you sure to remove this item?</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant="contained"
                color="default"
                onClick={() => setRemove({ dialog: false, id: null })}
              >
                No
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRemoveProductItem()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* confirm items by account */}
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
      >
        <DialogContent>
          <Typography>Are you sure to send this request to haptech?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setConfirmationDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleConfirmRequest()}
            disabled={isSubmitting}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountingSelectedRequestDetails;
