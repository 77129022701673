import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const IshiharaOrderDetails = ({
  queue,
  formheader,
  order,
  getPsyIshiharaOrder,
  resetDisplay,
  getPatientWithOrder,
  order_count,
  process_for,
}) => {
  const { users } = React.useContext(UsersData);
  const history = useHistory();
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);
  const [selectedValueRad, setSelectedValueRad] = useState(null);
  const [selectedValueTan, setSelectedValueTan] = useState(null);
  const [selectedBoxCount, setSelectedBoxCount] = useState([]);

  const setAllToFalse = () => {
    setValues({
      ...values,
      one_1: false,
      one_2: false,
      one_3: false,
      two_1: false,
      two_2: false,
      two_3: false,
      three_1: false,
      three_2: false,
      three_3: false,
      four_1: false,
      four_2: false,
      four_3: false,
      five_1: false,
      five_2: false,
      five_3: false,
      six_1: false,
      six_2: false,
      six_3: false,
      seven_1: false,
      seven_2: false,
      seven_3: false,
      eight_1: false,
      eight_2: false,
      eight_3: false,
      nine_1: false,
      nine_2: false,
      nine_3: false,
      ten_1: false,
      ten_2: false,
      ten_3: false,
      eleven_1: false,
      eleven_2: false,
      eleven_3: false,
      twelve_1: false,
      twelve_2: false,
      twelve_3: false,
      thirteen_1: false,
      thirteen_2: false,
      thirteen_3: false,
      fourteen_1: false,
      fourteen_2: false,
      fourteen_3: false,
      fifteen_1: false,
      fifteen_2: false,
      fifteen_3: false,
      sixteen_1: false,
      sixteen_2: false,
      sixteen_3: false,
      seventeen_1: false,
      seventeen_2: false,
      seventeen_3: false,
      eighteen_1: false,
      eighteen_2: false,
      eighteen_3: false,
      nineteen_1: false,
      nineteen_2: false,
      nineteen_3: false,
      twenty_1: false,
      twenty_2: false,
      twenty_3: false,
      twentyone_1: false,
      twentyone_2: false,
      twentyone_3: false,

      twentytwo_1: false,
      twentytwo_2_1: false,
      twentytwo_2_2: false,
      twentytwo_2_1_1: false,
      twentytwo_2_1_2: false,
      twentytwo_3: false,

      twentythree_1: false,
      twentythree_2_1: false,
      twentythree_2_2: false,
      twentythree_2_1_1: false,
      twentythree_2_1_2: false,
      twentythree_3: false,

      twentyfour_1: false,
      twentyfour_2_1: false,
      twentyfour_2_2: false,
      twentyfour_2_1_1: false,
      twentyfour_2_1_2: false,
      twentyfour_3: false,

      twentyfive_1: false,
      twentyfive_2_1: false,
      twentyfive_2_2: false,
      twentyfive_2_1_1: false,
      twentyfive_2_1_2: false,
      twentyfive_3: false,
    });
  };

  const setAllToFalseFew = () => {
    setValues({
      ...values,
      twentytwo_2_1: false,
      twentytwo_2_2: false,
      twentytwo_2_1_1: false,
      twentytwo_2_1_2: false,

      twentythree_2_1: false,
      twentythree_2_2: false,
      twentythree_2_1_1: false,
      twentythree_2_1_2: false,

      twentyfour_2_1: false,
      twentyfour_2_2: false,
      twentyfour_2_1_1: false,
      twentyfour_2_1_2: false,

      twentyfive_2_1: false,
      twentyfive_2_2: false,
      twentyfive_2_1_1: false,
      twentyfive_2_1_2: false,
    });
  };

  const [values, setValues] = useState({
    one_1: false,
    one_2: false,
    one_3: false,
    two_1: false,
    two_2: false,
    two_3: false,
    three_1: false,
    three_2: false,
    three_3: false,
    four_1: false,
    four_2: false,
    four_3: false,
    five_1: false,
    five_2: false,
    five_3: false,
    six_1: false,
    six_2: false,
    six_3: false,
    seven_1: false,
    seven_2: false,
    seven_3: false,
    eight_1: false,
    eight_2: false,
    eight_3: false,
    nine_1: false,
    nine_2: false,
    nine_3: false,
    ten_1: false,
    ten_2: false,
    ten_3: false,
    eleven_1: false,
    eleven_2: false,
    eleven_3: false,
    twelve_1: false,
    twelve_2: false,
    twelve_3: false,
    thirteen_1: false,
    thirteen_2: false,
    thirteen_3: false,
    fourteen_1: false,
    fourteen_2: false,
    fourteen_3: false,
    fifteen_1: false,
    fifteen_2: false,
    fifteen_3: false,
    sixteen_1: false,
    sixteen_2: false,
    sixteen_3: false,
    seventeen_1: false,
    seventeen_2: false,
    seventeen_3: false,
    eighteen_1: false,
    eighteen_2: false,
    eighteen_3: false,
    nineteen_1: false,
    nineteen_2: false,
    nineteen_3: false,
    twenty_1: false,
    twenty_2: false,
    twenty_3: false,
    twentyone_1: false,
    twentyone_2: false,
    twentyone_3: false,

    twentytwo_1: false,
    twentytwo_2_1: false,
    twentytwo_2_2: false,
    twentytwo_2_1_1: false,
    twentytwo_2_1_2: false,
    twentytwo_3: false,

    twentythree_1: false,
    twentythree_2_1: false,
    twentythree_2_2: false,
    twentythree_2_1_1: false,
    twentythree_2_1_2: false,
    twentythree_3: false,

    twentyfour_1: false,
    twentyfour_2_1: false,
    twentyfour_2_2: false,
    twentyfour_2_1_1: false,
    twentyfour_2_1_2: false,
    twentyfour_3: false,

    twentyfive_1: false,
    twentyfive_2_1: false,
    twentyfive_2_2: false,
    twentyfive_2_1_1: false,
    twentyfive_2_1_2: false,
    twentyfive_3: false,
  });

  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [processDialog, setProcessDialog] = useState({
    data: null,
    dialog: false,
  });

  const getLabOrderDetails = () => {
    var params = { user_id: users.user_id, trace_number: order.trace_number };
    Axios.get("psychology/order/ordernew-ishihara/details", { params })
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  const handleChangeRadio = (event) => {
    setSelectedValueRad(event.target.value);
    setSelectedValueTan(null);
    setAllToFalse();
    setSelectedBoxCount([]);
  };

  const handleChangeTan = (event) => {
    setSelectedValueTan(event.target.value);
    setAllToFalseFew();

    let test = selectedBoxCount;
    if (
      test.includes(
        "twentytwo_2_1",
        "twentytwo_2_2",
        "twentythree_2_1",
        "twentythree_2_2",
        "twentyfour_2_1",
        "twentyfour_2_2",
        "twentyfive_2_1",
        "twentyfive_2_2"
      )
    ) {
      let testx = test.filter(
        (item) =>
          item !== "twentytwo_2_1" &&
          item !== "twentytwo_2_2" &&
          item !== "twentythree_2_1" &&
          item !== "twentythree_2_2" &&
          item !== "twentyfour_2_1" &&
          item !== "twentyfour_2_2" &&
          item !== "twentyfive_2_1" &&
          item !== "twentyfive_2_2"
      );
      setSelectedBoxCount(testx);
    }

    if (
      test.includes(
        "twentytwo_2_1_1",
        "twentytwo_2_1_2",
        "twentythree_2_1_1",
        "twentythree_2_1_2",
        "twentyfour_2_1_1",
        "twentyfour_2_1_2",
        "twentyfive_2_1_1",
        "twentyfive_2_1_2"
      )
    ) {
      let testx = test.filter(
        (item) =>
          item !== "twentytwo_2_1_1" &&
          item !== "twentytwo_2_1_2" &&
          item !== "twentythree_2_1_1" &&
          item !== "twentythree_2_1_2" &&
          item !== "twentyfour_2_1_1" &&
          item !== "twentyfour_2_1_2" &&
          item !== "twentyfive_2_1_1" &&
          item !== "twentyfive_2_1_2"
      );
      setSelectedBoxCount(testx);
    }
  };

  useEffect(() => {
    getLabOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", order.trace_number);
    formdata.set("queue", queue);
    formdata.set("process_for", process_for);

    formdata.set("test_score", selectedBoxCount.length);
    formdata.set(
      "one",
      selectedValueRad === "normal"
        ? values.one_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.one_2
          ? 1
          : 0
        : values.one_3
        ? 1
        : 0
    );
    formdata.set(
      "two",
      selectedValueRad === "normal"
        ? values.two_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.two_2
          ? 1
          : 0
        : values.two_3
        ? 1
        : 0
    );
    formdata.set(
      "three",
      selectedValueRad === "normal"
        ? values.three_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.three_2
          ? 1
          : 0
        : values.three_3
        ? 1
        : 0
    );
    formdata.set(
      "four",
      selectedValueRad === "normal"
        ? values.four_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.four_2
          ? 1
          : 0
        : values.four_3
        ? 1
        : 0
    );
    formdata.set(
      "five",
      selectedValueRad === "normal"
        ? values.five_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.five_2
          ? 1
          : 0
        : values.five_3
        ? 1
        : 0
    );
    formdata.set(
      "six",
      selectedValueRad === "normal"
        ? values.six_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.six_2
          ? 1
          : 0
        : values.six_3
        ? 1
        : 0
    );
    formdata.set(
      "seven",
      selectedValueRad === "normal"
        ? values.seven_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.seven_2
          ? 1
          : 0
        : values.seven_3
        ? 1
        : 0
    );
    formdata.set(
      "eight",
      selectedValueRad === "normal"
        ? values.eight_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.eight_2
          ? 1
          : 0
        : values.eight_3
        ? 1
        : 0
    );
    formdata.set(
      "nine",
      selectedValueRad === "normal"
        ? values.nine_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.nine_2
          ? 1
          : 0
        : values.nine_3
        ? 1
        : 0
    );
    formdata.set(
      "ten",
      selectedValueRad === "normal"
        ? values.ten_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.ten_2
          ? 1
          : 0
        : values.ten_3
        ? 1
        : 0
    );
    formdata.set(
      "eleven",
      selectedValueRad === "normal"
        ? values.eleven_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.eleven_2
          ? 1
          : 0
        : values.eleven_3
        ? 1
        : 0
    );
    formdata.set(
      "twelve",
      selectedValueRad === "normal"
        ? values.twelve_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.twelve_2
          ? 1
          : 0
        : values.twelve_3
        ? 1
        : 0
    );
    formdata.set(
      "thirteen",
      selectedValueRad === "normal"
        ? values.thirteen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.thirteen_2
          ? 1
          : 0
        : values.thirteen_3
        ? 1
        : 0
    );
    formdata.set(
      "fourteen",
      selectedValueRad === "normal"
        ? values.fourteen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.fourteen_2
          ? 1
          : 0
        : values.fourteen_3
        ? 1
        : 0
    );
    formdata.set(
      "fifteen",
      selectedValueRad === "normal"
        ? values.fifteen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.fifteen_2
          ? 1
          : 0
        : values.fifteen_3
        ? 1
        : 0
    );
    formdata.set(
      "sixteen",
      selectedValueRad === "normal"
        ? values.sixteen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.sixteen_2
          ? 1
          : 0
        : values.sixteen_3
        ? 1
        : 0
    );
    formdata.set(
      "seventeen",
      selectedValueRad === "normal"
        ? values.seventeen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.seventeen_2
          ? 1
          : 0
        : values.seventeen_3
        ? 1
        : 0
    );
    formdata.set(
      "eighteen",
      selectedValueRad === "normal"
        ? values.eighteen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.eighteen_2
          ? 1
          : 0
        : values.eighteen_3
        ? 1
        : 0
    );
    formdata.set(
      "nineteen",
      selectedValueRad === "normal"
        ? values.nineteen_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.nineteen_2
          ? 1
          : 0
        : values.nineteen_3
        ? 1
        : 0
    );
    formdata.set(
      "twenty",
      selectedValueRad === "normal"
        ? values.twenty_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.twenty_2
          ? 1
          : 0
        : values.twenty_3
        ? 1
        : 0
    );
    formdata.set(
      "twentyone",
      selectedValueRad === "normal"
        ? values.twentyone_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? values.twentyone_2
          ? 1
          : 0
        : values.twentyone_3
        ? 1
        : 0
    );

    formdata.set(
      "twentytwo",
      selectedValueRad === "normal"
        ? values.twentytwo_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? selectedValueTan === "protan"
          ? values.twentytwo_2_1
            ? 1
            : values.twentytwo_2_2
            ? 2
            : 0
          : selectedValueTan === "deutan"
          ? values.twentytwo_2_1_1
            ? 1
            : values.twentytwo_2_1_2
            ? 2
            : 0
          : 0
        : values.twentytwo_3
        ? 1
        : 0
    );
    formdata.set(
      "twentythree",
      selectedValueRad === "normal"
        ? values.twentythree_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? selectedValueTan === "protan"
          ? values.twentythree_2_1
            ? 1
            : values.twentythree_2_2
            ? 2
            : 0
          : selectedValueTan === "deutan"
          ? values.twentythree_2_1_1
            ? 1
            : values.twentythree_2_1_2
            ? 2
            : 0
          : 0
        : values.twentythree_3
        ? 1
        : 0
    );
    formdata.set(
      "twentyfour",
      selectedValueRad === "normal"
        ? values.twentyfour_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? selectedValueTan === "protan"
          ? values.twentyfour_2_1
            ? 1
            : values.twentyfour_2_2
            ? 2
            : 0
          : selectedValueTan === "deutan"
          ? values.twentyfour_2_1_1
            ? 1
            : values.twentyfour_2_1_2
            ? 2
            : 0
          : 0
        : values.twentyfour_3
        ? 1
        : 0
    );
    formdata.set(
      "twentyfive",
      selectedValueRad === "normal"
        ? values.twentyfive_1
          ? 1
          : 0
        : selectedValueRad === "deficiencies"
        ? selectedValueTan === "protan"
          ? values.twentyfive_2_1
            ? 1
            : values.twentyfive_2_2
            ? 2
            : 0
          : selectedValueTan === "deutan"
          ? values.twentyfive_2_1_1
            ? 1
            : values.twentyfive_2_1_2
            ? 2
            : 0
          : 0
        : values.twentyfive_3
        ? 1
        : 0
    );
    formdata.set("order_count", parseFloat(order_count));

    var error = [];
    setResultSubmitting(true);
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      Axios.post(
        "psychology/order/ordernew-ishihara/save-process-result",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added. Redirect to receipt.");
            setTimeout(() => {
              history.push(
                `/bmcdc/app/psychology/record/print/order/${order.trace_number}`
              );
            }, 5000);
            getPatientWithOrder();
            setSavebtnDisabled(true);
          }
          if (data === "pass-invalid") {
            console.log("tae mali");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setResultSubmitting(false));
    }
  };

  const handleProcessOrder = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("order_id", processDialog.data.order_id);
    formdata.set("trace_number", processDialog.data.trace_number);

    var error = [];
    setProcessSubmitting(true);

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      let response = await Axios.post(
        "psychology/ishihara/order-setprocess",
        formdata
      );

      if (response.data.message === "pass-invalid") {
        Notify.fieldInvalid("password");
      }

      if (response.data.message === "success") {
        Notify.successRequest("process");
        setProcessDialog({ data: null, dialog: false });
        getLabOrderDetails();
      }
    }

    setTimeout(() => {
      setProcessSubmitting(false);
    }, 2000);
  };

  const handleProcessSelectedOrder = async (data) => {
    setProcessDialog({ data: data, dialog: true });
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
    let test = selectedBoxCount;
    if (test.includes(event.target.value)) {
      let testx = test.filter((item) => item !== event.target.value);
      setSelectedBoxCount(testx);
    } else {
      setSelectedBoxCount(test.concat(event.target.value));
    }
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={
                <Box>
                  <Typography>{formheader && formheader.address}</Typography>
                  <Typography>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      PATIENT NAME:{" "}
                    </Typography>
                    {`${order.lastname}, ${order.firstname} ${
                      order.middle !== null ? order.middle : null
                    }`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:{" "}
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        GENDER:{" "}
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      PATIENT ADDRESS:{" "}
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} `}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:{" "}
                    </Typography>
                    {Notify.createdAt(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            {orderDetails.ready
              ? orderDetails.data.length > 0
                ? orderDetails.data.map((data, index) => (
                    <Fragment key={index}>
                      <Box>
                        <Box mb={2} display="flex">
                          <Box flexGrow={1} />
                          <Box>
                            <Button
                              disabled={Boolean(parseInt(data.is_processed))}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleProcessSelectedOrder(data);
                              }}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box
                          mb={2}
                          display="flex"
                          borderBottom={1}
                          borderColor={"#AEAEAE"}
                        >
                          <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                              <b> ISHIHARA TEST </b>
                            </Typography>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    <b>PLATE Nos.</b>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Radio
                                      checked={selectedValueRad === "normal"}
                                      onChange={handleChangeRadio}
                                      value="normal"
                                      name="type_person_1_2_3"
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                    <b>NORMAL PERSON</b>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Radio
                                      checked={
                                        selectedValueRad === "deficiencies"
                                      }
                                      onChange={handleChangeRadio}
                                      value="deficiencies"
                                      name="type_person_1_2_3"
                                      inputProps={{ "aria-label": "B" }}
                                    />
                                    <b>
                                      PERSON WITH RED-GREEN <br /> DEFICIENCIES
                                    </b>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Radio
                                      checked={selectedValueRad === "blindness"}
                                      onChange={handleChangeRadio}
                                      value="blindness"
                                      name="type_person_1_2_3"
                                      inputProps={{ "aria-label": "C" }}
                                    />
                                    <b>
                                      PERSON WITH TOTAL COLOR <br /> BLINDNESS
                                      AND WEAKNESS
                                    </b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      1
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box>
                                      <TextField
                                        margin="dense"
                                        fullWidth
                                        name="order_id[]"
                                        label="order_id"
                                        hidden
                                        value={data.order_id}
                                      />
                                      <TextField
                                        margin="dense"
                                        fullWidth
                                        name="patient_id"
                                        label="Result"
                                        hidden
                                        value={data.patient_id}
                                      />
                                      <TextField
                                        margin="dense"
                                        fullWidth
                                        name="doctors_id"
                                        label="doctor_id"
                                        hidden
                                        value={
                                          data.doctor_id === null
                                            ? ""
                                            : data.doctor_id
                                        }
                                      />
                                    </Box>
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.one_1}
                                              onChange={handleChange}
                                              name="one_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"one_1"}
                                            />
                                          }
                                          label="12"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.one_2}
                                              onChange={handleChange}
                                              name="one_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"one_2"}
                                            />
                                          }
                                          label="12"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.one_3}
                                              onChange={handleChange}
                                              name="one_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"one_3"}
                                            />
                                          }
                                          label="12"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      2
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.two_1}
                                              onChange={handleChange}
                                              name="two_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"two_1"}
                                            />
                                          }
                                          label="8"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.two_2}
                                              onChange={handleChange}
                                              name="two_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"two_2"}
                                            />
                                          }
                                          label="3"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.two_3}
                                              onChange={handleChange}
                                              name="two_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"two_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      3
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.three_1}
                                              onChange={handleChange}
                                              name="three_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"three_1"}
                                            />
                                          }
                                          label="6"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.three_2}
                                              onChange={handleChange}
                                              name="three_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"three_2"}
                                            />
                                          }
                                          label="5"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.three_3}
                                              onChange={handleChange}
                                              name="three_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"three_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      4
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.four_1}
                                              onChange={handleChange}
                                              name="four_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"four_1"}
                                            />
                                          }
                                          label="29"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.four_2}
                                              onChange={handleChange}
                                              name="four_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"four_2"}
                                            />
                                          }
                                          label="70"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.four_3}
                                              onChange={handleChange}
                                              name="four_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"four_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      5
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.five_1}
                                              onChange={handleChange}
                                              name="five_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"five_1"}
                                            />
                                          }
                                          label="57"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.five_2}
                                              onChange={handleChange}
                                              name="five_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"five_2"}
                                            />
                                          }
                                          label="35"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.five_3}
                                              onChange={handleChange}
                                              name="five_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"five_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      6
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.six_1}
                                              onChange={handleChange}
                                              name="six_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"six_1"}
                                            />
                                          }
                                          label="5"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.six_2}
                                              onChange={handleChange}
                                              name="six_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"six_2"}
                                            />
                                          }
                                          label="2"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.six_3}
                                              onChange={handleChange}
                                              name="six_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"six_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      7
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.seven_1}
                                              onChange={handleChange}
                                              name="seven_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"seven_1"}
                                            />
                                          }
                                          label="3"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.seven_2}
                                              onChange={handleChange}
                                              name="seven_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"seven_2"}
                                            />
                                          }
                                          label="5"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.seven_3}
                                              onChange={handleChange}
                                              name="seven_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"seven_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      8
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eight_1}
                                              onChange={handleChange}
                                              name="eight_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"eight_1"}
                                            />
                                          }
                                          label="15"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eight_2}
                                              onChange={handleChange}
                                              name="eight_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"eight_2"}
                                            />
                                          }
                                          label="17"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eight_3}
                                              onChange={handleChange}
                                              name="eight_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"eight_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      9
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.nine_1}
                                              onChange={handleChange}
                                              name="nine_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"nine_1"}
                                            />
                                          }
                                          label="74"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.nine_2}
                                              onChange={handleChange}
                                              name="nine_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"nine_2"}
                                            />
                                          }
                                          label="21"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.nine_3}
                                              onChange={handleChange}
                                              name="nine_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"nine_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      10
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.ten_1}
                                              onChange={handleChange}
                                              name="ten_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"ten_1"}
                                            />
                                          }
                                          label="2"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.ten_2}
                                              onChange={handleChange}
                                              name="ten_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"ten_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.ten_3}
                                              onChange={handleChange}
                                              name="ten_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"ten_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      11
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eleven_1}
                                              onChange={handleChange}
                                              name="eleven_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"eleven_1"}
                                            />
                                          }
                                          label="6"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eleven_2}
                                              onChange={handleChange}
                                              name="eleven_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"eleven_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eleven_3}
                                              onChange={handleChange}
                                              name="eleven_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"eleven_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      12
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twelve_1}
                                              onChange={handleChange}
                                              name="twelve_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twelve_1"}
                                            />
                                          }
                                          label="97"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twelve_2}
                                              onChange={handleChange}
                                              name="twelve_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"twelve_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twelve_3}
                                              onChange={handleChange}
                                              name="twelve_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twelve_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      13
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.thirteen_1}
                                              onChange={handleChange}
                                              name="thirteen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"thirteen_1"}
                                            />
                                          }
                                          label="45"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.thirteen_2}
                                              onChange={handleChange}
                                              name="thirteen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"thirteen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.thirteen_3}
                                              onChange={handleChange}
                                              name="thirteen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"thirteen_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      14
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.fourteen_1}
                                              onChange={handleChange}
                                              name="fourteen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"fourteen_1"}
                                            />
                                          }
                                          label="5"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.fourteen_2}
                                              onChange={handleChange}
                                              name="fourteen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"fourteen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.fourteen_3}
                                              onChange={handleChange}
                                              name="fourteen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"fourteen_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      15
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.fifteen_1}
                                              onChange={handleChange}
                                              name="fifteen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"fifteen_1"}
                                            />
                                          }
                                          label="7"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.fifteen_2}
                                              onChange={handleChange}
                                              name="fifteen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"fifteen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.fifteen_3}
                                              onChange={handleChange}
                                              name="fifteen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"fifteen_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      16
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.sixteen_1}
                                              onChange={handleChange}
                                              name="sixteen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"sixteen_1"}
                                            />
                                          }
                                          label="16"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.sixteen_2}
                                              onChange={handleChange}
                                              name="sixteen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"sixteen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.sixteen_3}
                                              onChange={handleChange}
                                              name="sixteen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"sixteen_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      17
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.seventeen_1}
                                              onChange={handleChange}
                                              name="seventeen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"seventeen_1"}
                                            />
                                          }
                                          label="73"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.seventeen_2}
                                              onChange={handleChange}
                                              name="seventeen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"seventeen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.seventeen_3}
                                              onChange={handleChange}
                                              name="seventeen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"seventeen_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      18
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eighteen_1}
                                              onChange={handleChange}
                                              name="eighteen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"eighteen_1"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eighteen_2}
                                              onChange={handleChange}
                                              name="eighteen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"eighteen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.eighteen_3}
                                              onChange={handleChange}
                                              name="eighteen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"eighteen_3"}
                                            />
                                          }
                                          label=" "
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      19
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.nineteen_1}
                                              onChange={handleChange}
                                              name="nineteen_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"nineteen_1"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.nineteen_2}
                                              onChange={handleChange}
                                              name="nineteen_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"nineteen_2"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.nineteen_3}
                                              onChange={handleChange}
                                              name="nineteen_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"nineteen_3"}
                                            />
                                          }
                                          label=" "
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      20
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twenty_1}
                                              onChange={handleChange}
                                              name="twenty_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twenty_1"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twenty_2}
                                              onChange={handleChange}
                                              name="twenty_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"twenty_2"}
                                            />
                                          }
                                          label="45"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twenty_3}
                                              onChange={handleChange}
                                              name="twenty_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twenty_3"}
                                            />
                                          }
                                          label=" "
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      21
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyone_1}
                                              onChange={handleChange}
                                              name="twentyone_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyone_1"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyone_2}
                                              onChange={handleChange}
                                              name="twentyone_2"
                                              disabled={
                                                selectedValueRad !==
                                                "deficiencies"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyone_2"}
                                            />
                                          }
                                          label="73"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyone_3}
                                              onChange={handleChange}
                                              name="twentyone_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyone_3"}
                                            />
                                          }
                                          label=" "
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell colSpan={2} />
                                  <TableCell>
                                    <Box display="flex">
                                      <Box ml={2}>
                                        <Radio
                                          checked={
                                            selectedValueTan === "protan"
                                          }
                                          onChange={handleChangeTan}
                                          value="protan"
                                          name="blindness_type"
                                          inputProps={{ "aria-label": "D" }}
                                          disabled={
                                            selectedValueRad !== "deficiencies"
                                              ? true
                                              : false
                                          }
                                        />
                                        <b>Protan</b>
                                      </Box>

                                      <Box ml={6}>
                                        <Radio
                                          checked={
                                            selectedValueTan === "deutan"
                                          }
                                          onChange={handleChangeTan}
                                          value="deutan"
                                          name="blindness_type"
                                          inputProps={{ "aria-label": "E" }}
                                          disabled={
                                            selectedValueRad !== "deficiencies"
                                              ? true
                                              : false
                                          }
                                        />
                                        <b>Deutan</b>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell />
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      22
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentytwo_1}
                                              onChange={handleChange}
                                              name="twentytwo_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentytwo_1"}
                                            />
                                          }
                                          label="26"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3} display="flex">
                                      <FormGroup row>
                                        <Box
                                          borderRight={1}
                                          borderColor={"#AAA"}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentytwo_2_1}
                                                onChange={handleChange}
                                                name="twentytwo_2_1"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentytwo_2_1"}
                                              />
                                            }
                                            label="6"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentytwo_2_2}
                                                onChange={handleChange}
                                                name="twentytwo_2_2"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentytwo_2_2"}
                                              />
                                            }
                                            label="(2) 6"
                                          />
                                        </Box>

                                        <Box ml={2}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentytwo_2_1_1}
                                                onChange={handleChange}
                                                name="twentytwo_2_1_1"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentytwo_2_1_1"}
                                              />
                                            }
                                            label="2"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentytwo_2_1_2}
                                                onChange={handleChange}
                                                name="twentytwo_2_1_2"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentytwo_2_1_2"}
                                              />
                                            }
                                            label="(2) 6"
                                          />
                                        </Box>
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentytwo_3}
                                              onChange={handleChange}
                                              name="twentytwo_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentytwo_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      23
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentythree_1}
                                              onChange={handleChange}
                                              name="twentythree_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentythree_1"}
                                            />
                                          }
                                          label="42"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3} display="flex">
                                      <FormGroup row>
                                        <Box
                                          borderRight={1}
                                          borderColor={"#AAA"}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentythree_2_1}
                                                onChange={handleChange}
                                                name="twentythree_2_1"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentythree_2_1"}
                                              />
                                            }
                                            label="2"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentythree_2_2}
                                                onChange={handleChange}
                                                name="twentythree_2_2"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentythree_2_2"}
                                              />
                                            }
                                            label="(4) 2"
                                          />
                                        </Box>

                                        <Box ml={2}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.twentythree_2_1_1
                                                }
                                                onChange={handleChange}
                                                name="twentythree_2_1_1"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentythree_2_1_1"}
                                              />
                                            }
                                            label="4"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.twentythree_2_1_2
                                                }
                                                onChange={handleChange}
                                                name="twentythree_2_1_2"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentythree_2_1_2"}
                                              />
                                            }
                                            label="(4) 2"
                                          />
                                        </Box>
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentythree_3}
                                              onChange={handleChange}
                                              name="twentythree_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentythree_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      24
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyfour_1}
                                              onChange={handleChange}
                                              name="twentyfour_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyfour_1"}
                                            />
                                          }
                                          label="35"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3} display="flex">
                                      <FormGroup row>
                                        <Box
                                          borderRight={1}
                                          borderColor={"#AAA"}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentyfour_2_1}
                                                onChange={handleChange}
                                                name="twentyfour_2_1"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfour_2_1"}
                                              />
                                            }
                                            label="5"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentyfour_2_2}
                                                onChange={handleChange}
                                                name="twentyfour_2_2"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfour_2_2"}
                                              />
                                            }
                                            label="(3) 5"
                                          />
                                        </Box>

                                        <Box ml={2}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.twentyfour_2_1_1
                                                }
                                                onChange={handleChange}
                                                name="twentyfour_2_1_1"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfour_2_1_1"}
                                              />
                                            }
                                            label="3"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.twentyfour_2_1_2
                                                }
                                                onChange={handleChange}
                                                name="twentyfour_2_1_2"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfour_2_1_2"}
                                              />
                                            }
                                            label="(3) 5"
                                          />
                                        </Box>
                                      </FormGroup>
                                    </Box>
                                  </TableCell>

                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyfour_3}
                                              onChange={handleChange}
                                              name="twentyfour_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyfour_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow hidden={data.ishihara_test === null}>
                                  <TableCell align="center">
                                    <Typography
                                      variant="subtitle2"
                                      className={`gtc-uppercase`}
                                    >
                                      25
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyfive_1}
                                              onChange={handleChange}
                                              name="twentyfive_1"
                                              disabled={
                                                selectedValueRad !== "normal"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyfive_1"}
                                            />
                                          }
                                          label="96"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3} display="flex">
                                      <FormGroup row>
                                        <Box
                                          borderRight={1}
                                          borderColor={"#AAA"}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentyfive_2_1}
                                                onChange={handleChange}
                                                name="twentyfive_2_1"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfive_2_1"}
                                              />
                                            }
                                            label="6"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={values.twentyfive_2_2}
                                                onChange={handleChange}
                                                name="twentyfive_2_2"
                                                disabled={
                                                  selectedValueTan !== "protan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfive_2_2"}
                                              />
                                            }
                                            label="(9) 6"
                                          />
                                        </Box>

                                        <Box ml={2}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.twentyfive_2_1_1
                                                }
                                                onChange={handleChange}
                                                name="twentyfive_2_1_1"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfive_2_1_1"}
                                              />
                                            }
                                            label="9"
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.twentyfive_2_1_2
                                                }
                                                onChange={handleChange}
                                                name="twentyfive_2_1_2"
                                                disabled={
                                                  selectedValueTan !== "deutan"
                                                    ? true
                                                    : false
                                                }
                                                value={"twentyfive_2_1_2"}
                                              />
                                            }
                                            label="(9) 6"
                                          />
                                        </Box>
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box ml={3}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.twentyfive_3}
                                              onChange={handleChange}
                                              name="twentyfive_3"
                                              disabled={
                                                selectedValueRad !== "blindness"
                                                  ? true
                                                  : false
                                              }
                                              value={"twentyfive_3"}
                                            />
                                          }
                                          label="x"
                                        />
                                      </FormGroup>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </Fragment>
                  ))
                : Notify.noRecord()
              : Notify.loading()}
          </CardContent>
          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={savebtnDisabled}
                  startIcon={
                    resultSubmitting && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      <Dialog
        open={processDialog.dialog}
        onClose={() => setProcessDialog({ data: null, dialog: false })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
        maxWidth={"xs"}
        fullWidth
      >
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <Typography>Are you sure to process?</Typography>
            </Box>

            <Box>
              <TextField
                fullWidth
                required
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ data: null, dialog: false })}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default IshiharaOrderDetails;
