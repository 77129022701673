import { Grid, Typography, Box, Button } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { BarChart2 } from "react-feather";
import { useHistory } from "react-router";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const ClinicalMicroscopy = ({ order_id, patient_id, formheader }) => {
  const [details, setDetails] = useState([]);
  const { users } = React.useContext(UsersData);
  const history = useHistory();

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_microscopy");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box display="flex" mb={2}>
              <Box flexGrow={1}>
                <Typography variant="h6" align="center">
                  Clinical Microscopy Order
                </Typography>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="text"
                  startIcon={<BarChart2 />}
                  onClick={() =>
                    history.push(
                      `/bmcdc/app/doctor/patient/laboratory/microscopy/${patient_id}`
                    )
                  }
                  className={`d-print-none`}
                >
                  Graph
                </Button>
              </Box>
            </Box>

            <Box ml={2}>
              <Box mt={1} display="flex">
                <Typography variant="caption">
                  <b> SPECIMEN: </b>
                </Typography>
                <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                  {checkResult(details[0].spicemen)}
                </Box>
              </Box>

              {/* clinical microscopy */}
              {details.map((data, index) => (
                <Fragment key={index}>
                  <Box mb={2}>
                    {Boolean(parseInt(data.chemical_test)) && (
                      <>
                        {parseInt(data.chemical_test) === 2 ? ( // 2 is refund
                          <Box display={"flex"} mt={1}>
                            <Box>
                              <Typography variant="caption">
                                <b> CHEMICAL TEST </b>
                              </Typography>
                            </Box>
                            <Box
                              mx={2}
                              align="center"
                              minWidth={100}
                              borderBottom={1}
                            >
                              <Label color="warning"> refund </Label>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Box mt={1}>
                              <Typography variant="caption">
                                <b> CHEMICAL TEST </b>
                              </Typography>
                            </Box>
                            <Box ml={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <Box mt={1} display="flex">
                                    <Typography variant="caption">
                                      COLOR:
                                    </Typography>
                                    <Box
                                      mx={2}
                                      align="center"
                                      minWidth={100}
                                      borderBottom={1}
                                    >
                                      {checkResult(data.chemical_test_color)}
                                    </Box>
                                  </Box>

                                  <Box mt={1} display="flex">
                                    <Typography variant="caption">
                                      TRANSPARENCY:
                                    </Typography>
                                    <Box
                                      mx={2}
                                      align="center"
                                      minWidth={100}
                                      borderBottom={1}
                                    >
                                      {checkResult(
                                        data.chemical_test_transparency
                                      )}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <Box mt={1} display="flex">
                                    <Typography variant="caption">
                                      PH:
                                    </Typography>
                                    <Box
                                      mx={2}
                                      align="center"
                                      minWidth={100}
                                      borderBottom={1}
                                    >
                                      {checkResult(data.chemical_test_ph)}
                                    </Box>
                                  </Box>

                                  <Box mt={1} display="flex">
                                    <Typography variant="caption">
                                      SPECIFIC GRAVITY:
                                    </Typography>
                                    <Box
                                      mx={2}
                                      align="center"
                                      minWidth={100}
                                      borderBottom={1}
                                    >
                                      {checkResult(
                                        data.chemical_test_spicific_gravity
                                      )}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <Box mt={1} display="flex">
                                    <Typography variant="caption">
                                      GLUCOSE:
                                    </Typography>
                                    <Box
                                      mx={2}
                                      align="center"
                                      minWidth={100}
                                      borderBottom={1}
                                    >
                                      {checkResult(data.chemical_test_glucose)}
                                    </Box>
                                  </Box>

                                  <Box mt={1} display="flex">
                                    <Typography variant="caption">
                                      ALBUMIN:
                                    </Typography>
                                    <Box
                                      mx={2}
                                      align="center"
                                      minWidth={100}
                                      borderBottom={1}
                                    >
                                      {checkResult(data.chemical_test_albumin)}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  <Box mb={2}>
                    {/* clinical microscopy */}
                    {Boolean(parseInt(data.microscopic_test)) && (
                      <>
                        {parseInt(data.microscopic_test) === 2 ? ( // 2 is refund
                          <Box display={"flex"}>
                            <Box>
                              <Typography variant="caption">
                                <b> MICROSCOPIC TEST </b>
                              </Typography>
                            </Box>
                            <Box
                              mx={2}
                              align="center"
                              minWidth={100}
                              borderBottom={1}
                            >
                              <Label color="warning"> refund </Label>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Box>
                              <Typography variant="caption">
                                <b> MICROSCOPIC TEST </b>
                              </Typography>
                            </Box>
                            <Box ml={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                  <>
                                    <Box mt={1}>
                                      <Typography variant="caption">
                                        <b> CELLS </b>
                                      </Typography>
                                    </Box>
                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        SQUAMOUS CELLS
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_squamous
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        PUS CELLS
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(data.microscopic_test_pus)}
                                      </Box>
                                      <Typography variant="caption">
                                        /HPF
                                      </Typography>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        RED BLOOD CELLS
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_redblood
                                        )}
                                      </Box>
                                      <Typography variant="caption">
                                        /HPF
                                      </Typography>
                                    </Box>
                                  </>
                                  <>
                                    <Box mt={1}>
                                      <Typography variant="caption">
                                        <b> CASTS </b>
                                      </Typography>
                                    </Box>
                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        HYALINE CAST
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_hyaline
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        WBC CAST
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(data.microscopic_test_wbc)}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        RBC CAST
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(data.microscopic_test_rbc)}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        FINE GRANUALAR CAST
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_fine_granular
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        COARSE GRANUALAR CAST
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_coarse_granular
                                        )}
                                      </Box>
                                    </Box>
                                  </>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                  <>
                                    <Box mt={1}>
                                      <Typography variant="caption">
                                        <b> CRYSTALS </b>
                                      </Typography>
                                    </Box>
                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        CALCIUM OXALATE
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_calcium_oxalate
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        TRIPLE PHOSPHATE
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_triple_phospahte
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        LEUCINE/TYROSINE
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_leucine_tyrosine
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        AMMONIUM BIURATE
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_ammonium_biurate
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        AMORPHOUS URATES
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_amorphous_urates
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        AMORPHOUS PHOSPHATES
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_amorphous_phosphates
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        URIC ACID
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_uricacid
                                        )}
                                      </Box>
                                    </Box>
                                  </>
                                  <>
                                    <Box mt={1}>
                                      <Typography variant="caption">
                                        <b> OTHERS </b>
                                      </Typography>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        MUCUS THREAD
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_mucus_thread
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        BACTERIA
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_bacteria
                                        )}
                                      </Box>
                                    </Box>

                                    <Box mt={1} display="flex">
                                      <Typography variant="caption">
                                        YEAST
                                      </Typography>
                                      <Box
                                        mx={2}
                                        align="center"
                                        minWidth={100}
                                        borderBottom={1}
                                      >
                                        {checkResult(
                                          data.microscopic_test_yeast
                                        )}
                                      </Box>
                                    </Box>
                                  </>
                                </Grid>
                              </Grid>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  <Box mb={2}>
                    {/* clinical microscopy */}
                    {Boolean(parseInt(data.pregnancy_test_hcg)) && (
                      <>
                        <Box mt={1} display="flex">
                          <Typography variant="caption">
                            <b> PREGNANCY TEST (HCG): </b>
                          </Typography>
                          {parseInt(data.pregnancy_test_hcg) === 2 ? ( // 2 is refund
                            <>
                              <Box
                                mx={2}
                                align="center"
                                minWidth={100}
                                borderBottom={1}
                              >
                                <Label color="warning"> refund </Label>
                              </Box>
                            </>
                          ) : (
                            <Box
                              mx={2}
                              align="center"
                              minWidth={100}
                              borderBottom={1}
                            >
                              {checkResult(data.pregnancy_test_hcg_result)}
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </Fragment>
              ))}
              <Box mb={2}>
                {/* clinical microscopy */}
                <>
                  <Box mt={1} display="flex">
                    <Typography variant="caption">
                      <b> REMARKS: </b>
                    </Typography>
                    <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                      {checkResult(details[0].result_remarks)}
                    </Box>
                  </Box>
                </>
              </Box>
            </Box>
          </Box>

          {/* <Footer formheader={formheader} /> */}
        </>
      )}
    </>
  );
};

export default ClinicalMicroscopy;
