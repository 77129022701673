import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Logout from "../auth/Logout";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import {
  UsersHeader,
  UsersData,
  FormInformation,
  // PharmacyRoleAndId
} from "../ContextAPI";
import ImagingDashboard from "src/imaging/ImagingDashboard";
import Account from "src/imaging/account/Account";
// import ImagingTest from "../imaging/test/ImagingTest";
import ImagingOrderVirtual from "src/imaging/virtual/ImagingOrderVirtual";
// import CheckInternet from "src/utils/CheckInternet";
import ImagingPatients from "src/imaging/ImagingPatients";
// import { Badge } from "@material-ui/core";
import ImagingReport from "src/imaging/ImagingReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faFolderOpen,
  faHome,
  faScroll,
  faSpellCheck,
  faUserInjured,
  faUserLock,
  // faXRay,
} from "@fortawesome/free-solid-svg-icons";
import LeaveForm from "src/imaging/leave/LeaveForm";
import { Badge } from "@material-ui/core";
import IsMountedRef from "src/utils/IsMountedRef";
// import ImagingToEdit from "src/imaging/toedit";
import ImagingToAddResult from "src/imaging/toaddresult";
import ImagingPatientForUltraSound from "src/imaging/ImagingPatientForUltraSound";
import PrintLayout from "src/imaging/printlayout/index";

const RouteImaging = () => {
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const userContext = useContext(UsersData);
  // const [badgeData, setBadgeData] = useState([]);
  const [newPatientCountLocal, setNewPatientCountLocal] = useState(0);
  const [newPatientCountLocalUltra, setNewPatientCountLocalUltra] = useState(0);
  // const [newPatientCountVirtual, setNewPatientCountVirtual] = useState(0);
  const [patient, setPatients] = useState({ data: [], ready: false });
  const [patientUltra, setPatientsUltra] = useState({ data: [], ready: false });
  // const [patientOnline, setPatientOnline] = useState({
  //   data: [],
  //   ready: false,
  // });
  // const [patientLocal, setPatientLocal] = useState({ data: [], ready: false });
  const [patientToAddResult, setPatientToAddResult] = useState({
    data: [],
    ready: false,
  });
  const mounted = IsMountedRef();
  // const [patientToEditOnline, setPatientToEditOnline] = useState(0);
  // const [patientToEditLocal, setPatientToEditLocal] = useState(0);
  const [patientCountToAddResult, setPatientCountToAddResult] = useState(0);
  var interval = null;

  const [formInfo, setFormInfo] = useState([]);

  const getCurrentImagingPrintLayout = React.useCallback(async () => {
    try {
      var params = {
        management_id: userContext.users.management_id,
        main_mgmt_id: userContext.users.main_mgmt_id,
      };
      const response = await axios.get("imaging/get/result/print-layout", {
        params,
      });
      setFormInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [userContext.users]);

  const getSidebarHeaderInfo = React.useCallback(async () => {
    try {
      var params = { user_id: userContext.users.user_id };
      const response = await axios.get("imaging/sidebar/header-infomartion", {
        params,
      });
      if (mounted.current) {
        setSidebarHeader(response.data);
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  }, [userContext.users, mounted]);

  // const getImagingVirtualOrder = async () => {
  //   try {
  //     var params = { user_id: userContext.users.user_id };
  //     const response = await axios.get(
  //       "imaging/virtual/get/patient/forimaging",
  //       { params }
  //     );
  //     setBadgeData(response.data);
  //     setNewPatientCountVirtual(response.data.length);
  //   } catch (error) {
  //     console.log("Unable to get badge for virtual.", error);
  //   }
  // };

  const getNewPatientForImagingXray = useCallback(async () => {
    var params = {
      user_id: userContext.users.user_id,
      management_id: userContext.users.management_id,
      type: "xray",
    };
    axios
      .get("imaging/get/patient/xray/forimaging", { params })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setPatients({ data, ready: true });
          if (data.length > 0) {
            setNewPatientCountLocal(data.length);
          } else {
            setNewPatientCountLocal(0);
          }
        }
      });
  }, [userContext.users, mounted]);

  const getNewPatientForUltraSound = useCallback(async () => {
    var params = {
      user_id: userContext.users.user_id,
      management_id: userContext.users.management_id,
      type: "ultra-sound",
    };
    axios
      .get("imaging/get/patient/ultra-sound/forimaging", { params })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setPatientsUltra({ data, ready: true });
          if (data.length > 0) {
            setNewPatientCountLocalUltra(data.length);
          } else {
            setNewPatientCountLocalUltra(0);
          }
        }
      });
  }, [userContext.users, mounted]);

  // const getDocumentationQueueOnline = React.useCallback(async () => {
  //   var params = {
  //     user_id: userContext.users.user_id,
  //     management_id: userContext.users.management_id,
  //     main_mgmt_id: userContext.users.main_mgmt_id,
  //   };
  //   axios
  //     .get("imaging/get/online/result-to-edit", { params })
  //     .then((response) => {
  //       const data = response.data;
  //       if (mounted.current) {
  //         setPatientOnline({ data, ready: true });
  //         if (data.length > 0) {
  //           setPatientToEditOnline(data.length);
  //         } else {
  //           setPatientToEditOnline(0);
  //         }
  //       }
  //     });
  // }, [userContext.users, mounted]);

  // const getDocumentationQueueLocal = React.useCallback(async () => {
  //   var params = {
  //     user_id: userContext.users.user_id,
  //     management_id: userContext.users.management_id,
  //     main_mgmt_id: userContext.users.main_mgmt_id,
  //   };
  //   axios
  //     .get("documentation/get/local/result-to-edit", { params })
  //     .then((response) => {
  //       const data = response.data;
  //       if (mounted.current) {
  //         setPatientLocal({ data, ready: true });
  //         if (data.length > 0) {
  //           setPatientToEditLocal(data.length);
  //         } else {
  //           setPatientToEditLocal(0);
  //         }
  //       }
  //     });
  // }, [userContext.users, mounted]);

  const getAllToAddResult = React.useCallback(async () => {
    var params = {
      user_id: userContext.users.user_id,
      management_id: userContext.users.management_id,
      main_mgmt_id: userContext.users.main_mgmt_id,
      radiologist: "email_mode",
      radiologist_type: "email_mode",
    };
    axios.get("imaging/get/local/add-result", { params }).then((response) => {
      const data = response.data;
      if (mounted.current) {
        setPatientToAddResult({ data, ready: true });
        if (data.length > 0) {
          setPatientCountToAddResult(data.length);
        } else {
          setPatientCountToAddResult(0);
        }
      }
    });
  }, [userContext.users, mounted]);

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  // const checkinternet = () => {
  //   interval = setInterval(() => {
  //     CheckInternet.online()
  //       .then(() => {
  //         getImagingVirtualOrder();
  //       })
  //       .catch(() => {
  //         clearInterval(interval);
  //       });

  //     getNewPatientForImagingXray();
  //     getNewPatientForUltraSound()
  //   }, 10000);
  // };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/bmcdc/app/imaging",
      subitem: [],
    },
    // {
    //   name: "Patients",
    //   show: true,
    //   icon: (
    //     <Badge
    //       badgeContent={
    //         parseInt(newPatientCountLocal) + parseInt(newPatientCountVirtual)
    //       }
    //       color="error"
    //     >
    //       <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
    //     </Badge>
    //   ),
    //   path: "/bmcdc/app/imaging/patients",
    //   subitem: [],
    // },
    {
      name: "Patients for X-ray",
      show: true,
      icon: (
        <Badge color="error" badgeContent={newPatientCountLocal}>
          <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
        </Badge>
      ),
      path: "/bmcdc/app/imaging/patients",
      subitem: [],
    },
    {
      name: "Patients for UTZ",
      show: true,
      icon: (
        <Badge color="error" badgeContent={newPatientCountLocalUltra}>
          <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
        </Badge>
      ),
      path: "/bmcdc/app/imaging/patients/ultra-sound",
      subitem: [],
    },

    // {
    //   name: "To Edit",
    //   show: true,
    //   icon: (
    //     <Badge
    //       color="error"
    //       badgeContent={
    //         parseFloat(patientToEditLocal) + parseFloat(patientToEditOnline)
    //       }
    //     >
    //       <FontAwesomeIcon icon={faSpellCheck} color="white" size={"2x"} />,
    //     </Badge>
    //   ),

    //   path: "/bmcdc/app/imaging/to-edit",
    //   subitem: [],
    // },
    {
      name: "To Add Result",
      show: true,
      icon: (
        <Badge color="error" badgeContent={parseFloat(patientCountToAddResult)}>
          <FontAwesomeIcon icon={faSpellCheck} color="white" size={"2x"} />,
        </Badge>
      ),
      path: "/bmcdc/app/imaging/add-result",
      subitem: [],
    },
    // {
    //   name: "Imaging Test",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faXRay} color="white" size={"2x"} />,
    //   path: "/bmcdc/app/imaging/test",
    //   subitem: [],
    // },
    {
      name: "Report",
      show: true,
      icon: <FontAwesomeIcon icon={faFolderOpen} color="white" size={"2x"} />,
      path: "/bmcdc/app/imaging/reports",
      subitem: [],
    },

    {
      name: "Print Layout",
      show: true,
      icon: <FontAwesomeIcon icon={faScroll} color="white" size={"2x"} />,
      path: "/bmcdc/app/imaging/print-layout",
      subitem: [],
    },

    {
      name: "Leave Application",
      show: true,
      icon: <FontAwesomeIcon icon={faAddressCard} color="white" size={"2x"} />,
      path: "/bmcdc/app/imaging/leave-application",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} color="white" size={"2x"} />,
      path: "/bmcdc/app/imaging/account",
      subitem: [],
    },
  ];

  const initializeInterval = () => {
    interval = setInterval(() => {
      getNewPatientForImagingXray();
      getNewPatientForUltraSound();
      // getDocumentationQueueOnline();
      // getDocumentationQueueLocal();

      getAllToAddResult();
    }, 20000);
  };

  useEffect(() => {
    getCurrentImagingPrintLayout();
    getSidebarHeaderInfo();
    getNewPatientForImagingXray();
    getNewPatientForUltraSound();
    // getDocumentationQueueOnline();
    // getDocumentationQueueLocal();

    getAllToAddResult();
  }, [
    getCurrentImagingPrintLayout,
    getSidebarHeaderInfo,
    getNewPatientForImagingXray,
    getNewPatientForUltraSound,
    // getDocumentationQueueOnline,
    // getDocumentationQueueLocal,

    getAllToAddResult,
  ]);

  useEffect(() => {
    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {/* <PharmacyRoleAndId.Provider
        value={{
          badgeCount: badgeData,
        }}
      > */}
      <UsersHeader.Provider
        value={{
          sidebarHeader: sidebarHeader,
          renderPharmacyInfo: handleRenderInfo,
          queue: {
            patient: patient,
            getPatientsOnQueue: () => getNewPatientForImagingXray(),
            patientUltra: patientUltra,
            getPatientsOnQueueUS: () => getNewPatientForUltraSound(),
            // patientOnline: patientOnline,
            // getPatientsOnQueueOnline: () => getDocumentationQueueOnline(),
            // patientLocal: patientLocal,
            // getPatientsOnQueueLocal: () => getDocumentationQueueLocal(),
            patientToAddResult: patientToAddResult,
            getAllToAddResult: () => getAllToAddResult(),
          },
        }}
      >
        <FormInformation.Provider
          value={{
            formInfo: formInfo,
            renderFormInfo: () => getCurrentImagingPrintLayout(),
          }}
        >
          <BrowserRouter>
            <Sidebar
              notification={{
                enable: true,
                owner: "imaging",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"imaging"}
            />

            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/bmcdc/app/imaging" />}
              />

              <Route
                exact
                path="/bmcdc"
                component={() => <Redirect to="/bmcdc/app/imaging" />}
              />

              <Route
                exact
                path="/bmcdc/app"
                component={() => <Redirect to="/bmcdc/app/imaging" />}
              />

              <Route
                exact
                path="/bmcdc/app/imaging"
                component={ImagingDashboard}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/patients"
                component={ImagingPatients}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/patients/ultra-sound"
                component={ImagingPatientForUltraSound}
              />

              {/* <Route
              exact
              path="/bmcdc/app/imaging/test"
              component={ImagingTest}
            /> */}

              {/* <Route
              exact
              path="/bmcdc/app/imaging/to-edit"
              component={ImagingToEdit}
            /> */}

              <Route
                exact
                path="/bmcdc/app/imaging/add-result"
                component={ImagingToAddResult}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/leave-application"
                component={LeaveForm}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/account"
                component={Account}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/reports"
                component={ImagingReport}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/virtual"
                component={ImagingOrderVirtual}
              />

              <Route
                exact
                path="/bmcdc/app/imaging/print-layout"
                component={PrintLayout}
              />

              <Route exact path="/bmcdc/app/logout" component={Logout} />

              <Route render={() => <PageNotFound title="Page not found" />} />
            </Switch>
          </BrowserRouter>
        </FormInformation.Provider>
      </UsersHeader.Provider>
      {/* </PharmacyRoleAndId.Provider> */}
    </Fragment>
  );
};

export default RouteImaging;
