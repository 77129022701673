import {
  Box,
  Grid,
  Typography,
  Paper,
  TextField,
  CircularProgress,
  Button,
  useTheme,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import {
  CheckCircleOutline,
  // HighlightOff
} from "@material-ui/icons";
import Select from "react-select";
import { ArrowLeft, ArrowRight, Send, Trash } from "react-feather";

const LaboratoryRequestItem = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { users } = useContext(UsersData);
  const [itemList, setItemList] = useState({ data: [], ready: false });
  const [itemTempList, setItemTempList] = useState({ data: [], ready: false });
  const [requestList, setRequestList] = useState({ data: [], ready: false });
  const [selectedItemInfo, setSelectedItemInfo] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [defaultView, setDefaultView] = useState("request-list");
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleSubmitCreateRequest = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);

    var error = [];

    if (
      formdata.get("item").length === 0 ||
      formdata.get("item").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("item");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("laboratory/create/request-item", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "already-existing") {
            Notify.fieldInvalid("item already added");
          }
          if (data === "success") {
            e.target.reset();
            getItemRequestTempList();
            Notify.successRequest("create request");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleRemoveTempItem = (temp_id) => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("lrit_id", temp_id);

    axios
      .post("laboratory/remove/request-item-temp", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getItemRequestTempList();
          Notify.successRequest("remove temp request");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleConfirmTempRequest = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);

    axios
      .post("laboratory/confirm/request-item-temp", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getItemRequestTempList();
          getRequestList();
          setConfirmationDialog(false);
          Notify.successRequest("confirm temp request");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getItemReagentsList = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const response = await axios.get("laboratory/test/items/get-itemlist", {
        params,
      });
      setItemList({ data: response.data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  const getItemRequestTempList = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const response = await axios.get(
        "laboratory/test/items/get-itemtemplist",
        {
          params,
        }
      );
      setItemTempList({ data: response.data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  const getRequestList = useCallback(async () => {
    try {
      var params = {
        user_id: users.user_id,
        management_id: users.management_id,
      };
      const response = await axios.get("laboratory/get/request-confirm", {
        params,
      });
      setRequestList({ data: response.data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  useEffect(() => {
    getItemReagentsList();
    getItemRequestTempList();
    getRequestList();
  }, [getItemReagentsList, getItemRequestTempList, getRequestList]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Request For Item",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={"Request For Item"}
      >
        <Grid container spacing={2}>
          {defaultView === "request-list" ? (
            <Grid item xs={12}>
              <Box component={Paper} variant="outlined" p={2}>
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                  mb={2}
                >
                  <Box flexGrow={1}>
                    <Typography color="primary" variant="h5">
                      <strong>Request List</strong>
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color="primary" variant="h5">
                      <strong>Create New</strong>
                      <IconButton
                        onClick={() => {
                          setDefaultView("create-request");
                        }}
                      >
                        <ArrowRight />
                      </IconButton>
                    </Typography>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowRight />}
                      onClick={() => setDefaultView("create-request")}
                    >
                    </Button> */}
                  </Box>
                </Box>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Request ID</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requestList.ready ? (
                        requestList.data.length > 0 ? (
                          requestList.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {Notify.createdAt(data.created_at)}
                              </TableCell>
                              <TableCell>
                                {data.request_id.replace("req-", "")}
                              </TableCell>
                              <TableCell>
                                {parseInt(data.mark_as_okay) === 1
                                  ? "OK"
                                  : "NO"}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3} align="center">
                              {Notify.noRecord()}
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            {Notify.loading()}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    component="div"
                    count={requestList.data.length}
                    rowsPerPageOptions={[
                      10,
                      50,
                      100,
                      { value: requestList.data.length, label: "All" },
                    ]}
                    page={page}
                    onChangePage={handlePageChange}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={handleLimitChange}
                    labelRowsPerPage="List"
                  />
                </TableContainer>
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4} md={3}>
                <Box component={Paper} variant="outlined" p={2}>
                  <Box
                    mb={2}
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Box flexGrow={1}>
                      <Typography color="primary" variant="h5">
                        <IconButton
                          onClick={() => {
                            setDefaultView("request-list");
                            setSelectedItemInfo(null);
                          }}
                        >
                          <ArrowLeft />
                        </IconButton>

                        <strong>Create List</strong>
                      </Typography>
                    </Box>
                    {/* <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowLeft />}
                        onClick={() => {
                          setDefaultView("request-list");
                          setSelectedItemInfo(null);
                        }}
                      >
                        Back
                      </Button>
                    </Box> */}
                  </Box>
                  <form onSubmit={handleSubmitCreateRequest}>
                    <Box>
                      <Box mb={2}>
                        <Select
                          options={itemList.data}
                          onChange={(data) => {
                            setSelectedItemInfo(data);
                          }}
                          placeholder="Select Product"
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 99999,
                              color: "#000",
                            }),
                            control: (base) => ({
                              ...base,
                              minHeight: 55,
                              backgroundColor: "transparent",
                            }),
                            singleValue: () => ({
                              color:
                                theme.palette.type === "dark" ? "#fff" : "#000",
                            }),
                          }}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          value={
                            selectedItemInfo && selectedItemInfo.item !== null
                              ? selectedItemInfo.item
                              : ""
                          }
                          name="item"
                          label={`Item Brand`}
                          variant="outlined"
                        />
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          required
                          value={
                            selectedItemInfo &&
                            selectedItemInfo.item_id !== null
                              ? selectedItemInfo.item_id
                              : ""
                          }
                          name="item_id"
                          label={`Item ID`}
                          variant="outlined"
                          hidden
                        />
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          required
                          value={
                            selectedItemInfo &&
                            selectedItemInfo.item_haptech_id !== null
                              ? selectedItemInfo.item_haptech_id
                              : ""
                          }
                          name="item_haptech_id"
                          variant="outlined"
                          hidden
                        />
                      </Box>

                      <Box mb={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <CheckCircleOutline />
                            )
                          }
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </Grid>

              <Grid item xs={12} sm={8} md={9}>
                <Box component={Paper} variant="outlined" p={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Item Brand</b>
                          </TableCell>
                          <TableCell>
                            <b>Item Description</b>
                          </TableCell>
                          <TableCell>
                            <b>Unit</b>
                          </TableCell>
                          <TableCell>
                            <b>Supplier</b>
                          </TableCell>
                          <TableCell align="center">
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemTempList ? (
                          itemTempList.data.length > 0 ? (
                            itemTempList.data.map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>{data.item}</TableCell>
                                <TableCell>{data.description}</TableCell>
                                <TableCell>{data.unit}</TableCell>
                                <TableCell>{data.supplier}</TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    title="Delete temp request item"
                                    size="small"
                                    color="secondary"
                                    lrit_id={data.lrit_id}
                                    onClick={(e) =>
                                      handleRemoveTempItem(
                                        e.currentTarget.getAttribute("lrit_id")
                                      )
                                    }
                                  >
                                    <Trash />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell align="center" colSpan={5}>
                                {Notify.noRecord()}
                              </TableCell>
                            </TableRow>
                          )
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              {Notify.loading()}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <Box
                      mt={2}
                      hidden={itemTempList.data.length > 0 ? false : true}
                    >
                      <Box
                        display={"flex"}
                        alignContent="center"
                        alignItems={"center"}
                      >
                        <Box flexGrow={1} />
                        <Box>
                          <Button
                            size="small"
                            variant="contained"
                            startIcon={<Send />}
                            onClick={() => setConfirmationDialog(true)}
                            color="primary"
                          >
                            Confirm
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </TableContainer>
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <Dialog
          open={confirmationDialog}
          onClose={() => setConfirmationDialog(false)}
        >
          <DialogContent>
            <Typography>
              Are you sure to send this request to accounting?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setConfirmationDialog(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleConfirmTempRequest()}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default LaboratoryRequestItem;
