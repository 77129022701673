import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import { XCircle } from "react-feather";
import PrintIcon from "@material-ui/icons/Print";
import AudiometryPrintAll from "./printall/forms/psychology/AudiometryPrintAll";
import IshiharaPrintAll from "./printall/forms/psychology/IshiharaPrintAll";
import NeurologyPrintAll from "./printall/forms/psychology/NeurologyPrintAll";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PrintMobileVanOrdersPsychology = ({
  close,
  selectedTraceNoData,
  handleSearchPatient,
  selectedBranch,
}) => {
  const classes = useStyles();
  const { users } = React.useContext(UsersData);
  const [print, setPrint] = useState("");
  const [printProcess, setPrintProcess] = useState(false);

  const [patients, setPatients] = useState({
    data: [],
    ready: false,
  });

  const [audiometryOrderDetails, setAudiometryOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [ishiharaOrderDetails, setIshiharaOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [neurologyOrderDetails, setNeurologyOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  const getLabFormHeader = () => {
    var params = { management_id: selectedBranch };
    Axios.get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getPatientInformation = () => {
    var params = { patient_id: selectedTraceNoData.patient_id };
    Axios.get("documentation/get/patient-info/patient-id", { params })
      .then((response) => {
        const data = response.data;
        setPatients({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getAudiometryOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
    };
    Axios.get("psychology/order/ordernew-audiology/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setAudiometryOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getIshiharaOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
    };
    Axios.get("psychology/order/ordernew-ishihara/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setIshiharaOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getNeurologyOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: selectedTraceNoData.trace_number,
    };
    Axios.get("psychology/order/ordernew-neurology/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setNeurologyOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInformation();
    getLabFormHeader();
    getAudiometryOrderDetails();
    getIshiharaOrderDetails();
    getNeurologyOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTraceNoData]);

  const handlePrint = (e) => {
    setPrint(e.currentTarget.id);
    setPrintProcess(true);
    setTimeout(() => {
      setPrintProcess(false);
      window.print();
    }, 4000);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        disableBackdropClick
        disableEscapeKeyDown
        open={true}
        onClose={() => console.log("dialog unclosable")}
      >
        <Box display="flex" className="gtc-hideonprint">
          <Box flexGrow={1}>
            <DialogTitle> Print Result Report </DialogTitle>
          </Box>
          <Box>
            <IconButton
              //   onClick={() => history.push("/bmcdc/app/psychology/report")}
              onClick={() => close()}
              color="secondary"
            >
              <XCircle />
            </IconButton>
          </Box>
        </Box>

        <DialogContent>
          <Box m={2}>
            <Backdrop open={printProcess} className={classes.backdrop}>
              <Box alignItems="center" align="center">
                <CircularProgress color="inherit" />
                <Typography>please wait...</Typography>
              </Box>
            </Backdrop>

            <Grid container spacing={2}>
              <Grid className="gtc-hideonprint" item xs={12} sm={3} lg={2}>
                <Box hidden={audiometryOrderDetails.data.length === 0}>
                  <Button
                    startIcon={<PrintIcon />}
                    variant="outlined"
                    id="audio"
                    size={"large"}
                    color="primary"
                    onClick={handlePrint}
                    fullWidth
                    component={Box}
                    my={1}
                  >
                    Audiometric Report
                  </Button>
                </Box>

                <Box hidden={ishiharaOrderDetails.data.length === 0}>
                  <Button
                    startIcon={<PrintIcon />}
                    variant="outlined"
                    id="ishihara"
                    size={"large"}
                    color="primary"
                    onClick={handlePrint}
                    fullWidth
                    component={Box}
                    my={1}
                  >
                    Ishihara Test
                  </Button>
                </Box>

                <Box hidden={neurologyOrderDetails.data.length === 0}>
                  <Button
                    startIcon={<PrintIcon />}
                    variant="outlined"
                    id="neuro-exam"
                    size={"large"}
                    color="primary"
                    onClick={handlePrint}
                    fullWidth
                    component={Box}
                    my={1}
                  >
                    Neurology Exam
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={9} lg={10}>
                {Object.keys(formHeader).length > 0 ? (
                  <>
                    <Box
                      mb={2}
                      className={
                        print === "audio"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {audiometryOrderDetails.ready &&
                        audiometryOrderDetails.data.length > 0 && (
                          <AudiometryPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            audiometryOrderDetails={audiometryOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "ishihara"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {ishiharaOrderDetails.ready &&
                        ishiharaOrderDetails.data.length > 0 && (
                          <IshiharaPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            ishiharaOrderDetails={ishiharaOrderDetails}
                          />
                        )}
                    </Box>

                    <Box
                      mb={2}
                      className={
                        print === "neuro-exam"
                          ? "gtc-showonprint"
                          : "gtc-hideonprint"
                      }
                    >
                      {neurologyOrderDetails.ready &&
                        neurologyOrderDetails.data.length > 0 && (
                          <NeurologyPrintAll
                            allowHeaderPatientInfo={"allowed"}
                            patients={patients}
                            neurologyOrderDetails={neurologyOrderDetails}
                          />
                        )}
                    </Box>
                  </>
                ) : (
                  "Psychology information not set in database."
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PrintMobileVanOrdersPsychology;
