import React, { Fragment } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const UrinalysisPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  urinalysicOrderDetails,
}) => {
  return (
    <>
      {urinalysicOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box mb={2}>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                URINALYSIS
              </span>
            </Typography>
          </Box>
          <Box>
            {urinalysicOrderDetails.data.map((data, index) => (
              <Grid container spacing={3} key={index}>
                <Grid item xs={6}>
                  <Box hidden={data.color === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        color :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.color}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.reaction === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        reaction :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.reaction}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.transparency === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        transparency :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.transparency}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.sp_gravity === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        sp gravity :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.sp_gravity}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.albumin === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        albumin (negative) :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.albumin}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.sugar === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        sugar (negative) :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.sugar}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.pus_cell === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        pus cells :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.pus_cell}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.rbc === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        R.B.C:
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.rbc}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.epithelial_cell === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        epithelial cells :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.epithelial_cell}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.mucus_threads === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        mucus threads :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.mucus_threads}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.renal_cell === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        Color :renal cells :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.renal_cell}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.yeast_cell === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        Color :yeast cells :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.yeast_cell}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.hyaline === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        hyaline :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.hyaline}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.rbc_cast === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        rbc cast :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.rbc_cast}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.wbc_cast === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        wbc cast :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.wbc_cast}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    hidden={data.coarse_granular_cast === null ? true : false}
                  >
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        coarse granular cast :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.coarse_granular_cast}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.fine_granular_cast === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        fine granular cast :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.fine_granular_cast}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.pus_in_clumps === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        pus in clumps :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.pus_in_clumps}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.rbc_in_clumps === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        rbc in clumps :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.rbc_in_clumps}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.calcium_oxalate === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        calcium oxalate :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.calcium_oxalate}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.uricacid === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        uric acid :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.uricacid}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.amorphous_urate === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        amorphous urate :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.amorphous_urate}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    hidden={data.amorphous_phosphate === null ? true : false}
                  >
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        amorphous phosphate :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.amorphous_phosphate}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.calcium_carbonate === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        calcium carbonate :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.calcium_carbonate}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.ammonium_biurate === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        ammonium biurate :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.ammonium_biurate}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.triple_phosphate === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        triple phosphate :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.triple_phosphate}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.spermatozoa === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        spermatozoa :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.spermatozoa}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    hidden={data.trichomonas_vaginalis === null ? true : false}
                  >
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        trichomonas vaginalis :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.trichomonas_vaginalis}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.micral_test === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        micral test :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.micral_test}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.urine_ketone === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        urine ketone :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.urine_ketone}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box hidden={data.others === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        Others :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.others}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={data.order_remarks === null ? true : false}>
                    <Box display={"flex"} flexGrow={1}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        Remarks :
                      </Typography>
                      <Typography
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.order_remarks}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
            <Box my={1} align="center">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {urinalysicOrderDetails.data.length > 0 &&
                          urinalysicOrderDetails.data[0].pathologist}
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}> Pathologist </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          {urinalysicOrderDetails.data.length > 0 &&
                            urinalysicOrderDetails.data[0].pathologist_lcn}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={4}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {urinalysicOrderDetails.data.length > 0 &&
                          urinalysicOrderDetails.data[0].chief_medtech}
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}>
                          {" "}
                          Chief Med Technologist{" "}
                        </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          {urinalysicOrderDetails.data.length > 0 &&
                            urinalysicOrderDetails.data[0].chief_medtech_lci}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {urinalysicOrderDetails.data.length > 0 &&
                            urinalysicOrderDetails.data[0].medtech}
                        </span>
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}> Medical Technologist </b>
                      </Box>

                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          <span
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                          >
                            {urinalysicOrderDetails.data.length > 0 &&
                              urinalysicOrderDetails.data[0].lic_no}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {console.log("urinalysicOrderDetails", urinalysicOrderDetails)}
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default UrinalysisPrintAll;
