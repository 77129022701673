import React, { Fragment } from "react";
import Container from "src/layout/Container";
import { Grid, Paper, Tab, Tabs, Box } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd, faBrain } from "@fortawesome/free-solid-svg-icons";
import OthersTest from "./OthersTest";
// import PsychologyTest from "./PsychologyTest";
// import PsychologySales from "./PsychologySales";

const HIMSOtherTestMonitor = () => {
  const [category, setCategory] = React.useState(0);

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "other test monitoring",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Other Test Monitoring"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component={Paper} variant="outlined" mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  wrapped
                  label="Other Test"
                  icon={<FontAwesomeIcon icon={faBrain} size={"2x"} />}
                />
                <Tab
                  wrapped
                  label="Sales"
                  icon={<FontAwesomeIcon icon={faHandHoldingUsd} size={"2x"} />}
                />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <OthersTest />
            </TabPanel>

            <TabPanel value={category} index={1}>
              {/* <PsychologySales /> */}
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default HIMSOtherTestMonitor;
