import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  // useTheme,
  Dialog,
  DialogContent,
  Zoom,
  Grid,
  Divider,
  DialogActions,
  Button,
} from "@material-ui/core";
import Container from "../../layout/Container";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Filter, Search, X } from "react-feather";
import Print from "@material-ui/icons/Print";
import CensusPrint from "./CensusPrint";

const AdmissionCensus = () => {
  // const themes = useTheme();
  const { users } = React.useContext(UsersData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [census, setCensus] = useState({ data: [], ready: false });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dialogPrint, setDialogPrint] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);

  const getPatientCount = useCallback(async () => {
    var params = { user_id: users.user_id, management_id: users.management_id };
    try {
      let response = await axios.get("admission/get/all-census", { params });
      const data = response.data;
      setCensus({ data, ready: true });
    } catch (error) {
      console.log("error: ,", error);
    }
  }, [users]);

  const handleFilteredReport = async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      date_from: dateFrom,
      date_to: dateTo,
    };
    if (dateFrom === null || dateTo === null) {
      Notify.customToast("Date Invalid", "Date is a required field.");
      return false;
    } else {
      try {
        let response = await axios.get("admission/get/filter-by-date", {
          params,
        });
        setCensus({ data: response.data, ready: true });
      } catch (error) {
        console.log("error: ", error);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getPatientCount();
  }, [getPatientCount]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "census",
          items: [{ name: "dashboard", path: "/bmcdc/app/registration" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Census</Box>
          </Box>
        }
      >
        <Paper>
          <Box p={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={1}
            >
              <Box flexGrow={1}>
                <Typography variant="h5" color="textSecondary">
                  <strong>Transaction List</strong>
                </Typography>
              </Box>

              <Box ml={1}>
                <IconButton
                  color={"primary"}
                  style={{
                    height: 40,
                    width: 40,
                  }}
                  onClick={() => setFilterByDate(true)}
                >
                  <Filter />
                </IconButton>
              </Box>

              <Box ml={1}>
                <IconButton
                  color={"primary"}
                  disabled={dateFrom === null && dateTo === null}
                  style={{
                    height: 40,
                    width: 40,
                  }}
                  onClick={() => setDialogPrint(true)}
                >
                  <Print />
                </IconButton>
              </Box>
            </Box>

            <Box mb={1}>
              <Divider light />
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Date</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Patient</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Transaction Type</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Symptoms</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {census.ready ? (
                    census.data.length > 0 ? (
                      (rowsPerPage > 0
                        ? census.data.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : census.data
                      ).map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell>
                            {`${data.lastname}, ${data.firstname}`}
                          </TableCell>
                          <TableCell>{data.transaction_type}</TableCell>
                          <TableCell>
                            {data.sickness !== null
                              ? data.sickness.replace(/,/g, ", ")
                              : "None"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography color="secondary">
                            No record found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <Typography color="primary">Please wait...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              labelRowsPerPage="list"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={census.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>

        <Dialog
          open={dialogPrint}
          TransitionComponent={Zoom}
          transitionDuration={800}
          fullScreen
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box display="flex">
                  <Box flexGrow={1} mb={2}>
                    <Typography
                      component={Box}
                      align="center"
                      variant="h5"
                      style={{ fontWeight: "bolder" }}
                    >
                      Patients Census
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <CensusPrint
                census={census}
                close={() => setDialogPrint(false)}
              />
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={filterByDate}
          TransitionComponent={Zoom}
          transitionDuration={800}
        >
          <Box m={2}>
            <Typography variant="h6" color="textSecondary">
              <strong>Filter By Date</strong>
            </Typography>
          </Box>
          <DialogContent>
            <Box mb={2}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                variant="outlined"
                label="From"
                name="date_from"
                // margin="dense"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                variant="outlined"
                label="To"
                name="date_to"
                // margin="dense"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box mr={1}>
                  <Button
                    startIcon={<X />}
                    variant="contained"
                    onClick={() => setFilterByDate(false)}
                    color="secondary"
                  >
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button
                    startIcon={<Search />}
                    variant="contained"
                    onClick={handleFilteredReport}
                    color="primary"
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default AdmissionCensus;
