import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import {
  Box,
  Divider,
  Grid,
  Typography,
  CardMedia,
  makeStyles,
} from "@material-ui/core";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  profile: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    maxWidth: theme.spacing(20),
    maxHeight: theme.spacing(20),
    // border: `1px solid ${grey[200]}`,
  },
}));

const ImagingPrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
}) => {
  const classes = useStyles();
  const { users } = useContext(UsersData);
  const [xrayOrderDetails, setXrayOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const getXrayOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: trace_number,
    };
    axios
      .get("imaging/get/order/ordernew-imaging/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setXrayOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getXrayOrderDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {xrayOrderDetails.ready &&
        xrayOrderDetails.data.length > 0 &&
        xrayOrderDetails.data.map((data, index) => (
          <Fragment key={index}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box mt={1}>
                  <Box>
                    <Typography
                      align="center"
                      variant="h5"
                      style={{
                        fontWeight: "bolder",
                        color: "#000",
                        textDecoration: "underline",
                      }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {data.imaging_type === "xray"
                          ? "ROENTGENOLOGIC INTERPRETATION"
                          : "SONOGRAPHIC REPORT"}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="center"
                      variant="subtitle1"
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      Examination : {data.imaging_order}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={9}>
                <Box>
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      style={{
                        whiteSpace: "pre-line",
                        fontWeight: "bolder",
                      }}
                    >
                      {data.imaging_result}
                    </Typography>
                  </Box>

                  <Box mt={1}>
                    <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                      IMPRESSION:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      style={{
                        whiteSpace: "pre-line",
                        fontWeight: "bolder",
                      }}
                    >
                      {data.imaging_results_remarks}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  {data.imaging_result_attachment !== null ? (
                    <CardMedia
                      component="img"
                      className={classes.profile}
                      src={`${imageLocation}imaging/${
                        data.imaging_result_attachment === null
                          ? "no-image.jpg"
                          : data.imaging_result_attachment
                      }`}
                      alt=""
                    />
                  ) : null}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box align="center">
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle1"
                        >
                          {data.radiologist_name}
                        </Typography>
                        <Box borderTop={1}>
                          <Typography variant="subtitle1">
                            <b> RADIOLOGIST </b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider light />
          </Fragment>
        ))}
    </>
  );
};

export default ImagingPrintAll;
