import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Table,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  TablePagination,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  FormHelperText,
  Divider,
  Zoom,
  CardContent,
} from "@material-ui/core";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { Edit, Search } from "@material-ui/icons";
import Container from "src/layout/Container";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import EditEmployeeDoctorsInfo from "src/hr/users-account/dialog/EditEmployeeDoctorsInfo";

const DoctorListDocumentation = () => {
  const [employeeInfoDiag, setEmployeeInfoDiag] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState(null);
  const { users } = useContext(UsersData);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [branchList, setBranchList] = useState({ data: [], ready: false });
  const [doctorList, setDoctorList] = useState({
    data: [],
    ready: false,
  });

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getAllBranches = React.useCallback(async () => {
    var params = {
      management_id: users.management_id,
      main_management_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("hr/get/all-branches", { params });
      const data = response.data;
      setBranchList({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [users]);

  const getDoctorList = async () => {
    try {
      var params = {
        user_id: users.user_id,
        main_mgmt_id: users.main_mgmt_id,
        management_id: users.management_id,
      };
      const request = await axios.get("van/get/all-doctor-list", {
        params,
      });
      setDoctorList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getDoctorList();
    getAllBranches();
    // eslint-disable-next-line
  }, []);

  const searchable = doctorList.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Doctor list",
        items: [{ name: "Dashboard", path: "/bmcdc/app/documentation" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Doctor List</Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8} md={9} lg={9}>
          <Card>
            <CardHeader
              title={
                <Box display="flex">
                  <Box flexGrow={1} />
                  <Box>
                    <TextField
                      label="Search doctor"
                      variant="outlined"
                      margin="dense"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              }
            />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong> Branch </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Doctor's Name </strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong> Action </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {doctorList.ready ? (
                      doctorList.data.length > 0 ? (
                        searchable.map((data, index) => (
                          <TableRow key={index} hover>
                            <TableCell> {data.branch} </TableCell>
                            <TableCell> {data.name} </TableCell>
                            <TableCell align="center">
                              <Tooltip arrow title="Edit Doctor's Service">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setEmployeeInfoDiag(true);
                                    setSelectedEmployee(data.user_id);
                                    setSelectedDepartmentType(data.type);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <Typography variant="subtitle2" color="secondary">
                              No doctor services added.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Typography variant="subtitle2" color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={searchable.length}
                rowsPerPageOptions={[10, 50, 100]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} sm={4} md={3} lg={3}>
          <Box component={Paper} p={2}>
            <Formik
              initialValues={{
                username: users.username,
                user_id: users.user_id,
                management_id: users.management_id,
                main_mgmt_id: users.main_mgmt_id,
                generateuser_id: "u-" + Date.now(),
                branch: "",
                date_birth: "",
                contact: "",
                email: "",
                fullname: "",
                address: "",
                gender: "",
                user_username: "",
                user_pass: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                branch: Yup.string().trim().required("Branch is required"),
                date_birth: Yup.date().required("Birth date is required."),
                contact: Yup.string().trim().required("Contact # is required"),
                email: Yup.string().trim().required("Email is required"),
                fullname: Yup.string().trim().required("Fullname is required"),
                address: Yup.string().trim().required("Address is required"),
                gender: Yup.string().trim().required("Gender is required"),
                user_username: Yup.string()
                  .trim()
                  .required("User's username is required"),
                user_pass: Yup.string()
                  .trim()
                  .required("User's password is required"),
                password: Yup.string().required("Your password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                var error = [];
                if (error.length > 0) {
                  Notify.consoleLog("form error.");
                } else {
                  try {
                    const request = await axios.post(
                      "haptech/add/new-account",
                      getFormData(values)
                    );
                    if (request.data === "pass-invalid") {
                      setErrors({ password: "Password is invalid." });
                      Notify.fieldInvalid("password");
                    }
                    if (request.data === "success") {
                      Notify.successRequest("add new imaging test");
                      resetForm();
                      setSubmitting(true);
                      getDoctorList();
                    }
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box mb={1}>
                    <Typography color="textPrimary" variant="subtitle1">
                      <strong>NEW DOCTOR</strong>
                    </Typography>
                  </Box>

                  <Box mb={2}>
                    <Divider light />
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.branch && errors.branch)}
                          helperText={touched.branch && errors.branch}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.branch}
                          fullWidth
                          required
                          name="branch"
                          label="Branch"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          {branchList.data.length > 0 &&
                            branchList.data.map((data, index) => (
                              <option key={index} value={data.management_id}>
                                {data.name}
                              </option>
                            ))}
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.date_birth && errors.date_birth
                          )}
                          helperText={touched.date_birth && errors.date_birth}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.date_birth}
                          fullWidth
                          required
                          name="date_birth"
                          label="Birth Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.contact && errors.contact)}
                          helperText={touched.contact && errors.contact}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact}
                          fullWidth
                          required
                          name="contact"
                          label="Contact #"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          required
                          name="email"
                          label="Email"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fullname}
                          fullWidth
                          required
                          name="fullname"
                          label="Fullname"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.user_username && errors.user_username
                          )}
                          helperText={
                            touched.user_username && errors.user_username
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.user_username}
                          fullWidth
                          required
                          name="user_username"
                          label="User's username"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.user_pass && errors.user_pass)}
                          helperText={touched.user_pass && errors.user_pass}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.user_pass}
                          fullWidth
                          required
                          name="user_pass"
                          label="User's password"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          label="Enter your password"
                          variant="outlined"
                          type="password"
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Divider />

                  <Box my={2} display="flex">
                    <Box flexGrow={1} />

                    <Button
                      variant="contained"
                      color="default"
                      type="reset"
                      startIcon={<ClearIcon />}
                    >
                      Clear
                    </Button>

                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Grid>

        <Dialog
          open={employeeInfoDiag}
          onClose={() => setEmployeeInfoDiag(false)}
          TransitionComponent={Zoom}
          transitionDuration={800}
        >
          <DialogContent>
            <Box mb={2}>
              <Typography color="textPrimary" variant="subtitle1">
                Edit Employee Information
              </Typography>
            </Box>
            <EditEmployeeDoctorsInfo
              close={() => setEmployeeInfoDiag(false)}
              selectedEmployee={selectedEmployee}
              selectedDepartmentType={selectedDepartmentType}
              getAllUsersAccount={() => getDoctorList()}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </Container>
  );
};

export default DoctorListDocumentation;
