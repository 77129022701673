import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import { UsersData } from "src/ContextAPI";
import { Card, Box, Typography, Grid, CardContent } from "@material-ui/core";
// import Footer from "src/laboratory/print/Footer";

const CBCOrderForm = ({ order_id, patient_id, formheader }) => {
  const { users } = React.useContext(UsersData);

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_cbc");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 &&
        details.map((data, key) => (
          <Fragment key={key}>
            <Card elevation={0} component={Box} className="pagebreaker-before">
              <CardContent>
                <Box>
                  {Boolean(parseInt(data.cbc)) && (
                    <Box>
                      <Box display="flex" my={2}>
                        <Box flexGrow={1}>
                          <Typography variant="h6" align="center">
                            <b> HEMATOLOGY </b>
                          </Typography>

                          <Typography variant="body1" align="center">
                            <b> COMPLETE BLOOD COUNT </b>
                          </Typography>
                        </Box>
                      </Box>

                      <Box hidden={!Boolean(parseInt(data.is_processed))}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> WBC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.wbc)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> LYM : </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {" "}
                                  <b> {checkResult(data.lym)} </b>{" "}
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MID : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mid)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> NEUT : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.neut)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box display="flex">
                              <Box width={90}>
                                <Typography> RBC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.rbc)} </b>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> HGB : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.hgb)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> HTC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.hct)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MCV : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mcv)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MCH : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mch)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MCHC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mchc)} </b>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> RDW-SD : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.rdw_sd)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> RDW-CV : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.rdw_cv)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MPV : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mpv)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> PDW : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.pdw)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> PCT : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.pct)} </b>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Box mt={2} display="flex">
                          <Box width={90}>
                            <Typography> P-LCR : </Typography>
                          </Box>
                          <Box>
                            <Typography align="center">
                              <b> {checkResult(data.p_lcr)} </b>
                            </Typography>
                          </Box>
                        </Box>

                        <Box mt={2} display="flex">
                          <Box width={90}>
                            <Typography> REMARKS : </Typography>
                          </Box>
                          <Box>
                            <Typography align="center">
                              <b> {checkResult(data.remarks)} </b>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {Boolean(parseInt(data.cbc_platelet)) && (
                    <Box>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography variant="h6" align="center">
                            <b> HEMATOLOGY </b>
                          </Typography>

                          <Typography variant="body1" align="center">
                            <b> COMPLETE BLOOD COUNT WITH PLATELET </b>
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        mt={3}
                        hidden={!Boolean(parseInt(data.is_processed))}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> WBC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.wbc)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> LYM : </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {" "}
                                  <b> {checkResult(data.lym)} </b>{" "}
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MID : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mid)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> NEUT : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.neut)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> RBC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.rbc)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> HGB : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.hgb)} </b>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> HTC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.hct)} </b>
                                </Typography>
                              </Box>
                            </Box>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MCV : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mcv)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MCH : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mch)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MCHC : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mchc)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> RDW-SD : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.rdw_sd)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> RDW-CV : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.rdw_cv)} </b>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> MPV : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.mpv)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> PDW : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.pdw)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mb={2} display="flex">
                              <Box width={90}>
                                <Typography> PCT : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.pct)} </b>
                                </Typography>
                              </Box>
                            </Box>
                            <Box mt={2} display="flex">
                              <Box width={90}>
                                <Typography> PLT : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.plt)} </b>
                                </Typography>
                              </Box>
                            </Box>

                            <Box mt={2} display="flex">
                              <Box width={90}>
                                <Typography> P-LCR : </Typography>
                              </Box>
                              <Box>
                                <Typography align="center">
                                  <b> {checkResult(data.p_lcr)} </b>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Box mt={2} display="flex">
                          <Box width={90}>
                            <Typography> REMARKS : </Typography>
                          </Box>
                          <Box>
                            <Typography align="center">
                              <b> {checkResult(data.remarks)} </b>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>

            <div className="pagebreaker-before" />

            {/* <Footer formheader={formheader} orderDetails={details} /> */}
          </Fragment>
        ))}
    </>
  );
};

export default CBCOrderForm;
