import React, { Fragment } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const StoolPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  stooltestOrderDetails,
}) => {
  return (
    <>
      {stooltestOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                FECALYSIS
              </span>
            </Typography>
          </Box>
          <Box>
            {stooltestOrderDetails.data.map((data, index) => (
              <Fragment key={index}>
                <Box hidden={!Boolean(parseInt(data.fecalysis))}>
                  <Box hidden={!Boolean(parseInt(data.is_processed))}>
                    <Box>
                      <Grid container spacing={2}>
                        {data.color !== null && (
                          <Grid item xs={4}>
                            <Box display="flex">
                              <Box width={110}>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Color :{" "}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  align="center"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.color}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )}

                        {data.consistency !== null && (
                          <Grid item xs={4}>
                            <Box display="flex">
                              <Box width={110}>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Consistency :{" "}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  align="center"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.consistency}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )}

                        {data.occult_blood_result !== null && (
                          <Grid item xs={4}>
                            <Box display="flex">
                              <Box width={110}>
                                <Typography
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  Occult Blood :{" "}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  align="center"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#000",
                                  }}
                                >
                                  {data.occult_blood_result}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>

                    <Box
                      hidden={
                        data.kk_ascaris === null &&
                        data.kt_ascaris === null &&
                        data.dfs_ascaris === null &&
                        data.kk_hookworm === null &&
                        data.kt_hookworm === null &&
                        data.dfs_hookworm === null &&
                        data.kk_blastocystis === null &&
                        data.kt_blastocystis === null &&
                        data.dfs_blastocystis === null &&
                        data.kk_giardia_lamblia_cyst === null &&
                        data.kt_giardia_lamblia_cyst === null &&
                        data.dfs_giardia_lamblia_cyst === null &&
                        data.kk_giardia_lamblia_trophozoite === null &&
                        data.kt_giardia_lamblia_trophozoite === null &&
                        data.dfs_giardia_lamblia_trophozoite === null
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Box>
                            <Typography>
                              <strong style={{ color: "#000" }}>
                                {" "}
                                Microscopy{" "}
                              </strong>
                            </Typography>
                          </Box>

                          <Box
                            mt={2}
                            hidden={
                              data.kk_ascaris === null &&
                              data.kt_ascaris === null &&
                              data.dfs_ascaris === null
                            }
                          >
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Ascaris Lumbricoides:
                            </Typography>
                          </Box>

                          <Box
                            mt={0.5}
                            hidden={
                              data.kk_hookworm === null &&
                              data.kt_hookworm === null &&
                              data.dfs_hookworm === null
                            }
                          >
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {" "}
                              Hookworm:{" "}
                            </Typography>
                          </Box>

                          <Box
                            mt={0.5}
                            hidden={
                              data.kk_blastocystis === null &&
                              data.kt_blastocystis === null &&
                              data.dfs_blastocystis === null
                            }
                          >
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Blastocystis Hominis:
                            </Typography>
                          </Box>

                          <Box
                            mt={2.1}
                            hidden={
                              data.kk_giardia_lamblia_cyst === null &&
                              data.kt_giardia_lamblia_cyst === null &&
                              data.dfs_giardia_lamblia_cyst === null &&
                              data.kk_giardia_lamblia_trophozoite === null &&
                              data.kt_giardia_lamblia_trophozoite === null &&
                              data.dfs_giardia_lamblia_trophozoite === null
                            }
                          >
                            <Typography>
                              <strong style={{ color: "#000" }}>
                                {" "}
                                Giardia Lamblia{" "}
                              </strong>
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kk_giardia_lamblia_cyst === null &&
                              data.kt_giardia_lamblia_cyst === null &&
                              data.dfs_giardia_lamblia_cyst === null
                            }
                          >
                            <Typography
                              align="right"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {" "}
                              Cyst:{" "}
                            </Typography>
                          </Box>

                          <Box
                            mt={0.5}
                            hidden={
                              data.kk_giardia_lamblia_trophozoite === null &&
                              data.kt_giardia_lamblia_trophozoite === null &&
                              data.dfs_giardia_lamblia_trophozoite === null
                            }
                          >
                            <Typography
                              align="right"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Trophozoite:
                            </Typography>
                          </Box>

                          <Box
                            mt={0.5}
                            hidden={
                              data.kk_trichusris_trichuira === null &&
                              data.kt_trichusris_trichuira === null &&
                              data.dfs_trichusris_trichuira === null
                            }
                          >
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {" "}
                              Trichuris Trichiura{" "}
                            </Typography>
                          </Box>

                          <Box
                            mt={0.5}
                            hidden={
                              data.kk_entamoeba_lamblia_cyst === null &&
                              data.kt_entamoeba_lamblia_cyst === null &&
                              data.dfs_entamoeba_lamblia_cyst === null &&
                              data.kk_entamoeba_lamblia_trophozoite === null &&
                              data.kt_entamoeba_lamblia_trophozoite === null &&
                              data.dfs_entamoeba_lamblia_trophozoite === null
                            }
                          >
                            <Typography>
                              <strong style={{ color: "#000" }}>
                                {" "}
                                Entamoeba Histolytica{" "}
                              </strong>
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kk_entamoeba_lamblia_cyst === null &&
                              data.kt_entamoeba_lamblia_cyst === null &&
                              data.dfs_entamoeba_lamblia_cyst === null
                            }
                          >
                            <Typography
                              align="right"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {" "}
                              Cyst:{" "}
                            </Typography>
                          </Box>

                          <Box
                            mt={0.5}
                            hidden={
                              data.kk_entamoeba_lamblia_trophozoite === null &&
                              data.kt_entamoeba_lamblia_trophozoite === null &&
                              data.dfs_entamoeba_lamblia_trophozoite === null
                            }
                          >
                            <Typography
                              align="right"
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              Trophozoite:
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box
                            align="center"
                            hidden={data.dfs === null ? true : false}
                          >
                            {data.dfs ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            <strong style={{ color: "#000" }}>
                              Direct Fecal Smear
                            </strong>
                          </Box>

                          <Box
                            mt={1.5}
                            hidden={data.dfs_ascaris === null ? true : false}
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_ascaris}
                            </Typography>
                          </Box>

                          <Box
                            hidden={data.dfs_hookworm === null ? true : false}
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_hookworm}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.dfs_blastocystis === null ? true : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_blastocystis}
                            </Typography>
                          </Box>

                          <Box
                            mt={4.8}
                            hidden={
                              data.dfs_giardia_lamblia_cyst === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_giardia_lamblia_cyst}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.dfs_giardia_lamblia_trophozoite === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_giardia_lamblia_trophozoite}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.dfs_trichusris_trichuira === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_trichusris_trichuira}
                            </Typography>
                          </Box>

                          <Box
                            mt={3.3}
                            hidden={
                              data.dfs_entamoeba_lamblia_cyst === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_entamoeba_lamblia_cyst}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.dfs_entamoeba_lamblia_trophozoite === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.dfs_entamoeba_lamblia_trophozoite}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box hidden={data.kt === null ? true : false}>
                            {data.kt ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            <strong style={{ color: "#000" }}>
                              Kato-Thick
                            </strong>
                          </Box>

                          <Box
                            mt={1.5}
                            hidden={data.kt_ascaris === null ? true : false}
                          >
                            <Typography variant="subtitle1">
                              {data.kt_ascaris}
                            </Typography>
                          </Box>

                          <Box
                            hidden={data.kt_hookworm === null ? true : false}
                          >
                            <Typography variant="subtitle1">
                              {data.kt_hookworm}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kt_blastocystis === null ? true : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kt_blastocystis}
                            </Typography>
                          </Box>

                          <Box
                            mt={4.8}
                            hidden={
                              data.kt_giardia_lamblia_cyst === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kt_giardia_lamblia_cyst}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kt_giardia_lamblia_trophozoite === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kt_giardia_lamblia_trophozoite}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kt_trichusris_trichuira === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kt_trichusris_trichuira}
                            </Typography>
                          </Box>

                          <Box
                            mt={3.3}
                            hidden={
                              data.kt_entamoeba_lamblia_cyst === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kt_entamoeba_lamblia_cyst}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kt_entamoeba_lamblia_trophozoite === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kt_entamoeba_lamblia_trophozoite}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box hidden={data.kk === null ? true : false}>
                            {data.kk ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            <strong style={{ color: "#000" }}>Kato-Katz</strong>
                          </Box>

                          <Box
                            mt={1.5}
                            hidden={data.kk_ascaris === null ? true : false}
                          >
                            <Typography variant="subtitle1">
                              {data.kk_ascaris}
                            </Typography>
                          </Box>

                          <Box
                            hidden={data.kk_hookworm === null ? true : false}
                          >
                            <Typography variant="subtitle1">
                              {data.kk_hookworm}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kk_blastocystis === null ? true : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kk_blastocystis}
                            </Typography>
                          </Box>

                          <Box
                            mt={4.8}
                            hidden={
                              data.kk_giardia_lamblia_cyst === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kk_giardia_lamblia_cyst}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant="subtitle1"
                              hidden={
                                data.kk_giardia_lamblia_trophozoite === null
                                  ? true
                                  : false
                              }
                            >
                              {data.kk_giardia_lamblia_trophozoite}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kk_trichusris_trichuira === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kk_trichusris_trichuira}
                            </Typography>
                          </Box>

                          <Box
                            mt={3.3}
                            hidden={
                              data.kk_entamoeba_lamblia_cyst === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kk_entamoeba_lamblia_cyst}
                            </Typography>
                          </Box>

                          <Box
                            hidden={
                              data.kk_entamoeba_lamblia_trophozoite === null
                                ? true
                                : false
                            }
                          >
                            <Typography variant="subtitle1">
                              {data.kk_entamoeba_lamblia_trophozoite}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box hidden={data.others === null ? true : false}>
                      <Box display={"flex"} flexGrow={1}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            textTransform: "capitalize",
                          }}
                        >
                          Other:
                        </Typography>
                        <Typography
                          style={{
                            marginLeft: 20,
                            fontWeight: "bold",
                            color: "#000",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.others}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Box hidden={data.pus_cells === null ? true : false}>
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Pus Cells:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.pus_cells}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            hidden={
                              data.reb_blood_cells === null ? true : false
                            }
                          >
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Red Blood Cells:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.reb_blood_cells}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            hidden={data.fat_globules === null ? true : false}
                          >
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Fat Globules:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.fat_globules}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            hidden={data.yeast_cells === null ? true : false}
                          >
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Yeast Cells:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.yeast_cells}
                              </Typography>
                            </Box>
                          </Box>
                          <Box hidden={data.bacteria === null ? true : false}>
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Bacteria:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.bacteria}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            hidden={data.oil_droplets === null ? true : false}
                          >
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Oil Droplets:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.oil_droplets}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            hidden={
                              data.undigested_foods_paticles === null
                                ? true
                                : false
                            }
                          >
                            <Box display={"flex"} flexGrow={1}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Undigested Food Particles:
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 20,
                                  fontWeight: "bold",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.undigested_foods_paticles}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      my={2}
                      hidden={
                        data.lab_remarks === null && data.lab_remarks === ""
                          ? true
                          : false
                      }
                    >
                      <Box display={"flex"} flexGrow={1}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            textTransform: "capitalize",
                          }}
                        >
                          Remarks:
                        </Typography>
                        <Typography
                          style={{
                            marginLeft: 20,
                            fontWeight: "bold",
                            color: "#000",
                            textTransform: "capitalize",
                          }}
                        >
                          {data.lab_remarks}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Fragment>
            ))}
          </Box>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {stooltestOrderDetails.data.length > 0 &&
                        stooltestOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {stooltestOrderDetails.data.length > 0 &&
                          stooltestOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {stooltestOrderDetails.data.length > 0 &&
                        stooltestOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {stooltestOrderDetails.data.length > 0 &&
                          stooltestOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {stooltestOrderDetails.data.length > 0 &&
                          stooltestOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {stooltestOrderDetails.data.length > 0 &&
                            stooltestOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default StoolPrintAll;
