import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const DocuPatientDetailsLabJabeliUrine = ({
  selectedBranch,
  patient_id,
  getUrinalysis,
  details,
  medTechList,
  updatePatientList,
}) => {
  const { users } = React.useContext(UsersData);
  const [savebtnUrineDisabled, setSavebtnUrineDisabled] = useState(false);

  const handleSaveUrineResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    var error = [];
    if (
      formdata.get("medtech").length === 0 ||
      formdata.get("medtech").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Medical Technologist");
    }
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setSavebtnUrineDisabled(true);
      axios
        .post("van/order/ordernew-urinalysis/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getUrinalysis();
            updatePatientList();
            Notify.successRequest("order result added.");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setSavebtnUrineDisabled(false);
        });
    }
  };

  React.useEffect(() => {}, [patient_id]);

  return (
    <Fragment>
      <form onSubmit={handleSaveUrineResult}>
        <Card elevation={0}>
          {details && (
            <Fragment>
              <CardContent>
                <Box hidden={!Boolean(parseInt(details.urinalysis))}>
                  <Box>
                    <Box>
                      <TextField
                        margin="dense"
                        fullWidth
                        name="order_id[]"
                        label="order_id"
                        value={details.order_id}
                        hidden
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="patient_id"
                        label="patient_id"
                        value={details.patient_id}
                        hidden
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="doctors_id"
                        label="doctor_id"
                        value={
                          details.doctor_id === null ? "" : details.doctor_id
                        }
                        hidden
                      />
                    </Box>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">color :</span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="color[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                reaction :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="reaction[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                transparency :
                              </span>
                            </Typography>
                          </Box>

                          <Box>
                            <TextField
                              fullWidth
                              name="transparency[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                sp gravity :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="sp_gravity[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                albumin (negative) :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="albumin[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                sugar (negative):
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="sugar[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                pus cells:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="pus_cell[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">R.B.C:</span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField fullWidth name="rbc[]" margin="dense" />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                epithelial cells :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="epithelial_cell[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                mucus threads :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="mucus_threads[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                renal cells :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="renal_cell[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                yeast cells :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="yeast_cell[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">hyaline :</span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="hyaline[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                rbc cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="rbc_cast[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                wbc cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="wbc_cast[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                coarse granular cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="coarse_granular_cast[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                fine granular cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="fine_granular_cast[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                pus in clumps :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="pus_in_clumps[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                rbc in clumps :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="rbc_in_clumps[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                calcium oxalate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="calcium_oxalate[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                uric acid :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="uric_acid[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                amorphous urate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="amorphous_urate[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                amorphous phosphate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="amorphous_phosphate[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                calcium carbonate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="calcium_carbonate[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                ammonium biurate:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="ammonium_biurate[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                triple phosphate:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="triple_phosphate[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                spermatozoa:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="spermatozoa[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                trichomonas vaginalis:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="trichomonas_vaginalis[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                micral test:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="micral_test[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                urine ketone:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="urine_ketone[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">others:</span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="others[]"
                              margin="dense"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box mt={2}>
                      <TextField
                        fullWidth
                        label="Remarks (Note: this remarks will be added in our clinical summary)"
                        name="remarks[]"
                        margin="dense"
                        multiline
                      />
                    </Box>

                    <Box>
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        name="medtech"
                        label="MedTech"
                      >
                        <option value={""}> Select </option>
                        {medTechList.data.length > 0 ? (
                          medTechList.data.map((data, index) => (
                            <option key={index} value={data.user_id}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            No medical technologist found.
                          </option>
                        )}
                      </TextField>
                    </Box>
                  </Box>
                </Box>
              </CardContent>

              <Box display="flex" m={1}>
                <Box flexGrow={1} />
                <Box>
                  <CardActions>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={savebtnUrineDisabled}
                    >
                      Save Result
                    </Button>
                  </CardActions>
                </Box>
              </Box>
            </Fragment>
          )}
        </Card>
      </form>
    </Fragment>
  );
};

export default DocuPatientDetailsLabJabeliUrine;
