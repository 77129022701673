import {
  Grid,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  TextField,
} from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const EcgOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card variant="outlined">
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={
                formheader && (
                  <strong style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader.name}
                  </strong>
                )
              }
              subheader={
                <Box>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.address}
                  </Typography>
                  <Typography style={{ fontWeight: "bolder", color: "#000" }}>
                    {formheader && formheader.contact_number}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <Box p={2}>
          <Header details={orderDetails} />
        </Box>

        <CardContent>
          {/* order details */}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bolder", color: "#000" }}
            >
              ECG INTERPRETATION
            </Typography>
          </Box>

          <Box my={2}>
            {/* clinical microscopy */}
            {orderDetails.data.map((data, index) => (
              <Box key={index}>
                <Box>
                  {data.order_image && (
                    <CardMedia
                      style={{ width: "100%" }}
                      component={"img"}
                      src={`${imageLocation}laboratory/${data.order_image}`}
                    />
                  )}
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          Atrial / Ventricular Rate:
                        </Typography>
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="atrial_ventricular_rate"
                          margin="dense"
                          value={data.atrial_ventricular_rate}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          Rhythm:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="rhythm"
                          margin="dense"
                          value={data.rhythm}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          Axis:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="axis"
                          margin="dense"
                          value={data.axis}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          P wave:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="p_wave"
                          margin="dense"
                          value={data.p_wave}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          Others:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="others"
                          margin="dense"
                          value={data.others}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          PR Interval:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="pr_interval"
                          margin="dense"
                          value={data.pr_interval}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          QRS:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="qrs"
                          margin="dense"
                          value={data.qrs}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          QT Interval:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="qt_interval"
                          margin="dense"
                          value={data.qt_interval}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          QRS Complex:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="qrs_complex"
                          margin="dense"
                          value={data.qrs_complex}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box flexGrow={1} mt={2}>
                        <Typography
                          style={{ fontWeight: "bolder", color: "#000" }}
                        >
                          ST Segment:
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="st_segment"
                          margin="dense"
                          value={data.st_segment}
                          InputProps={{ readOnly: true }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box my={2}>
                  <TextField
                    label="Interpretation"
                    fullWidth
                    required
                    name="interpretation"
                    variant="outlined"
                    value={data.interpretation}
                    InputProps={{ readOnly: true }}
                  />
                </Box>

                <Box my={2}>
                  <TextField
                    fullWidth
                    label="Remarks"
                    name="remarks"
                    variant="outlined"
                    value={data.order_remarks}
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          <Box>
            <Footer formheader={formheader} orderDetails={orderDetails} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default EcgOrder;
