import {
  Box,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Button,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import NewOrder from "./NewOrder";
import EditRate from "./EditRate";

const EditPackage = ({ details, getAllPackage, close }) => {
  const { users } = useContext(UsersData);
  const [orderList, setOrderList] = useState([]);
  const [neworderDialog, setNeworderDialog] = useState(false);
  const [editOrderDialog, setEditOrderDialog] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const getPackageOrderList = React.useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      package_id: details.package_id,
    };
    axios
      .get("accounting/get/details/package/by-id", { params })
      .then((res) => {
        setOrderList(res.data);
      })
      .catch((error) => {
        console.log(error);
        //   Notify.requestError(error);
      });
  }, [users, details]);

  useEffect(() => {
    getPackageOrderList();
  }, [getPackageOrderList]);

  return (
    <Box>
      <Box display="flex" mb={1}>
        <Box flexGrow={1}>
          <Typography variant="h6">{details.package_name}</Typography>
          <Typography variant="subtitle1">
            &#8369; {details.order_amount}
          </Typography>
        </Box>

        <Box mt={1} mr={1}>
          <Button variant="contained" onClick={() => setEditOrderDialog(true)}>
            Edit Rate
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setNeworderDialog(true)}
          >
            Add Order
          </Button>
        </Box>
      </Box>

      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Department</TableCell>
                <TableCell> Category </TableCell>
                <TableCell>Service/Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.length > 0 ? (
                orderList.map((datax, xindex) => (
                  <TableRow key={xindex}>
                    <TableCell>{datax.department}</TableCell>
                    <TableCell>{datax.category}</TableCell>
                    <TableCell>{datax.order_name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography color="secondary">No record found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={editOrderDialog}
        onClose={() => setEditOrderDialog(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle> Edit Rate </DialogTitle>
        <DialogContent>
          <EditRate
            details={details}
            getAllPackage={() => getAllPackage()}
            getPackageOrderList={() => getPackageOrderList()}
            close={() => setEditOrderDialog(false)}
            closeUpdate={() => close()}
          />
        </DialogContent>
      </Dialog>

      {/* dialog add order to package */}
      <Dialog
        open={neworderDialog}
        onClose={() => setNeworderDialog(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle> Add Order </DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <TextField
              onChange={(e) =>
                setSelectedDepartment(
                  e.target.value === "" ? null : e.target.value
                )
              }
              fullWidth
              required
              name="department"
              label="Category"
              variant="outlined"
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value={""}>Select</option>
              {/* <option value={"doctor"}>Doctor</option> */}
              <option value={"imaging"}>Imaging</option>
              <option value={"laboratory"}>Laboratory</option>
              <option value={"psychology"}>Psychology</option>
              <option value={"others"}>Others</option>
            </TextField>
          </Box>

          <NewOrder
            details={details}
            selectedDepartment={selectedDepartment}
            getPackageOrderList={() => getPackageOrderList()}
            close={() => setNeworderDialog(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditPackage;

// 11-24-2021 bmcdc-edit-package-jhomar
