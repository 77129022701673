import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  List,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  CardMedia,
  IconButton,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import DoctorWageViewTab from "./DoctorWageViewTab";
import { Search } from "react-feather";
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));
const DoctorsWage = () => {
  const { users } = useContext(UsersData);
  const [doctor, setDoctor] = useState({ data: [], ready: false });
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [searchDisplay, setSearchDisplay] = useState(false);
  const [stateOpenDetails, setStateOpenDetails] = useState(null);

  const [selectedUnpaid, setSelectedUnpaid] = useState({
    data: null,
    ready: false,
  });

  const getAllDoctors = useCallback(async () => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      main_mgmt_id: users.main_mgmt_id,
    };
    try {
      let response = await axios.get("cashier/get/doctor-list", { params });
      const data = response.data;
      setDoctor({ data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [users]);

  useEffect(() => {
    getAllDoctors();
  }, [getAllDoctors]);

  const searchable = doctor.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Box>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Doctor's Salary",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={
          <Box display={"flex"}>
            <Box flexGrow={1}>
              <Typography variant="h4">Daily Doctor's Salary</Typography>
            </Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={1}
              >
                <Box flexGrow={1}>
                  <Typography
                    color="primary"
                    variant="h5"
                    style={{ fontWeight: "bold" }}
                  >
                    Doctor's List
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    onClick={() => setSearchDisplay(!searchDisplay)}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Box>

              <Divider />

              <Box mt={1} hidden={!searchDisplay}>
                <TextField
                  label="Search Doctor"
                  variant="outlined"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  // margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <List component="div">
                  {searchable.length > 0
                    ? searchable.map((data, index) => (
                        <ListItem
                          button
                          key={index}
                          className="mb-2"
                          selected={
                            selectedUnpaid.data &&
                            selectedUnpaid.data.doctors_id === data.doctors_id
                          }
                          onClick={() => {
                            setStateOpenDetails("doctor-list");
                            setSelectedUnpaid({
                              data: data,
                              ready: true,
                            });
                          }}
                        >
                          <ListItemIcon>
                            <Avatar className={classes.primary}>
                              {data.name.charAt().toUpperCase()}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            className={`gtc-capitalize`}
                            primary={`${data.name}`}
                            secondary={`${
                              data.specialization !== null
                                ? data.specialization
                                : ""
                            }`}
                          />
                        </ListItem>
                      ))
                    : Notify.noRecord()}
                </List>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9}>
            {stateOpenDetails === null ? (
              <RenderSelectPatient />
            ) : (
              <DoctorWageViewTab
                doctors_id={selectedUnpaid.data.doctors_id}
                getAllDoctors={getAllDoctors}
                setStateOpenDetails={() => setStateOpenDetails(null)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DoctorsWage;

const RenderSelectPatient = () => (
  <Box align="center">
    <CardMedia
      component="img"
      src={"/gtc-logo.png"}
      alt=""
      style={{ width: 250 }}
    />
    <Typography color="secondary" variant="h3">
      NO DOCTOR SELECTED
    </Typography>

    <Typography color="secondary" variant="h6">
      Select doctor in the list to view details.
    </Typography>
  </Box>
);
