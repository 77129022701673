import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import CBCPrintAll from "./forms/CBCPrintAll";
import ChemistryPrintAll from "./forms/ChemistryPrintAll";
import ClinicalMicroscopyPrintAll from "./forms/ClinicalMicroscopyPrintAll";
import Covid19PrintAll from "./forms/Covid19PrintAll";
import DrugTestPrintAll from "./forms/DrugTestPrintAll";
import HematologyPrintAll from "./forms/HematologyPrintAll";
import HepatitisProfilePrintAll from "./forms/HepatitisProfilePrintAll";
import MiscellaneousPrintAll from "./forms/MiscellaneousPrintAll";
import SerologyPrintAll from "./forms/SerologyPrintAll";
import StoolPrintAll from "./forms/StoolPrintAll";
import ThyroidProfilePrintAll from "./forms/ThyroidProfilePrintAll";
import TumorMakerPrintAll from "./forms/TumorMakerPrintAll";
import UrinalysisPrintAll from "./forms/UrinalysisPrintAll";
import Divider from "@material-ui/core/Divider";

const LaboratoryPrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
  selectedBranch,
}) => {
  const { users } = useContext(UsersData);
  const [CBCTestOrderDetails, setCBCTestOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [chemistryOrderDetails, setChemistryOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [covidOrderDetails, setCovidOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [drugTestOrderDetails, setDrugTestOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [hemaOrderDetails, setHemaOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [hepatitisProfOrderDetails, setHepatitisProfOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [clinicalMicroscopyOrderDetails, setClinicalMicroscopyOrderDetails] =
    useState({
      data: [],
      ready: false,
    });

  const [miscellaneousOrderDetails, setMiscellaneousOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [serologyOrderDetails, setSerologyOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [stooltestOrderDetails, setStooltestOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [thyroidProfOrderDetails, setThyroidProfOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [tumorMakerOrderDetails, setTumorMakerOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [urinalysicOrderDetails, setUrinalysicOrderDetails] = useState({
    data: [],
    ready: false,
  });

  //laboratory_cbc //okay
  //laboratory_chemistry //okay
  //laboratory_covid19_test //okay
  //laboratory_drug_test //okay
  //laboratory_hematology //okay
  //laboratory_hepatitis_profile //okay
  //laboratory_microscopy //okay
  //laboratory_miscellaneous //okay
  //laboratory_sorology //okay
  //laboratory_stooltest //okay
  //laboratory_thyroid_profile //okay
  //laboratory_tumor_maker //okay
  //laboratory_urinalysis //okay

  const getCBCOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      management_id: selectedBranch,
      trace_number: trace_number,
    };
    axios
      .get("laboratory/order/ordernew-cbc/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setCBCTestOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getChemistryOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-chemistry/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setChemistryOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getCovidTestOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-covidtest/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setCovidOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getDrugTestOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-drugtest/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setDrugTestOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getHemaOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-hemathology/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setHemaOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getHepatitisProfileOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get(
        "laboratory/order/ordernew-hepatitisprofile/complete/details-print",
        {
          params,
        }
      )
      .then((response) => {
        const data = response.data;
        setHepatitisProfOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getClinicalMicroscopyOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get(
        "laboratory/order/ordernew-clinicalmicroscopy/complete/details-print",
        { params }
      )
      .then((response) => {
        const data = response.data;
        setClinicalMicroscopyOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getMiscellaneousOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-miscellaneous/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setMiscellaneousOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getSerologyOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-sorology/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setSerologyOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getStooltestOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-stooltest/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setStooltestOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getThyroidProfileOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-thyroidprofile/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setThyroidProfOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getTumorMakerOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };
    axios
      .get("laboratory/order/ordernew-tumormaker/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setTumorMakerOrderDetails({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getUrinalysisOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      trace_number: trace_number,
      management_id: selectedBranch,
    };

    axios
      .get("laboratory/order/ordernew-urinalysis/complete/details-print", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setUrinalysicOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getCBCOrderDetails();
    getChemistryOrderDetails();
    getCovidTestOrderDetails();
    getDrugTestOrderDetails();
    getHemaOrderDetails();
    getHepatitisProfileOrderDetails();
    getClinicalMicroscopyOrderDetails();
    getMiscellaneousOrderDetails();
    getSerologyOrderDetails();
    getStooltestOrderDetails();
    getThyroidProfileOrderDetails();
    getTumorMakerOrderDetails();
    getUrinalysisOrderDetails();

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {CBCTestOrderDetails.ready && CBCTestOrderDetails.data.length > 0 && (
        <>
          <CBCPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            CBCTestOrderDetails={CBCTestOrderDetails}
          />
          <Divider light />
        </>
      )}

      {chemistryOrderDetails.ready && chemistryOrderDetails.data.length > 0 && (
        <>
          <ChemistryPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            chemistryOrderDetails={chemistryOrderDetails}
          />
          <Divider light />
        </>
      )}

      {covidOrderDetails.ready && covidOrderDetails.data.length > 0 && (
        <>
          <Covid19PrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            covidOrderDetails={covidOrderDetails}
          />
          <Divider light />
        </>
      )}

      {drugTestOrderDetails.ready && drugTestOrderDetails.data.length > 0 && (
        <>
          <DrugTestPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            drugTestOrderDetails={drugTestOrderDetails}
          />
          <Divider light />
        </>
      )}

      {hemaOrderDetails.ready && hemaOrderDetails.data.length > 0 && (
        <>
          <HematologyPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            hemaOrderDetails={hemaOrderDetails}
          />
          <Divider light />
        </>
      )}

      {hepatitisProfOrderDetails.ready &&
        hepatitisProfOrderDetails.data.length > 0 && (
          <>
            <HepatitisProfilePrintAll
              allowHeaderPatientInfo={allowHeaderPatientInfo}
              patients={patients}
              hepatitisProfOrderDetails={hepatitisProfOrderDetails}
            />
            <Divider light />
          </>
        )}

      {clinicalMicroscopyOrderDetails.ready &&
        clinicalMicroscopyOrderDetails.data.length > 0 && (
          <>
            <ClinicalMicroscopyPrintAll
              allowHeaderPatientInfo={allowHeaderPatientInfo}
              patients={patients}
              clinicalMicroscopyOrderDetails={clinicalMicroscopyOrderDetails}
            />
            <Divider light />
          </>
        )}

      {miscellaneousOrderDetails.ready &&
        miscellaneousOrderDetails.data.length > 0 && (
          <>
            <MiscellaneousPrintAll
              allowHeaderPatientInfo={allowHeaderPatientInfo}
              patients={patients}
              miscellaneousOrderDetails={miscellaneousOrderDetails}
            />
            <Divider light />
          </>
        )}

      {serologyOrderDetails.ready && serologyOrderDetails.data.length > 0 && (
        <>
          <SerologyPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            serologyOrderDetails={serologyOrderDetails}
          />
          <Divider light />
        </>
      )}

      {stooltestOrderDetails.ready && stooltestOrderDetails.data.length > 0 && (
        <>
          <StoolPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            stooltestOrderDetails={stooltestOrderDetails}
          />
          <Divider light />
        </>
      )}

      {thyroidProfOrderDetails.ready &&
        thyroidProfOrderDetails.data.length > 0 && (
          <>
            <ThyroidProfilePrintAll
              allowHeaderPatientInfo={allowHeaderPatientInfo}
              patients={patients}
              thyroidProfOrderDetails={thyroidProfOrderDetails}
            />
            <Divider light />
          </>
        )}

      {tumorMakerOrderDetails.ready && tumorMakerOrderDetails.data.length > 0 && (
        <>
          <TumorMakerPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            tumorMakerOrderDetails={tumorMakerOrderDetails}
          />
          <Divider light />
        </>
      )}

      {urinalysicOrderDetails.ready && urinalysicOrderDetails.data.length > 0 && (
        <>
          <UrinalysisPrintAll
            allowHeaderPatientInfo={allowHeaderPatientInfo}
            patients={patients}
            urinalysicOrderDetails={urinalysicOrderDetails}
          />
          <Divider light />
        </>
      )}
    </>
  );
};

export default LaboratoryPrintAll;
