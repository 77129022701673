import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Label from "src/utils/Label";
import ChemistryRef from "src/laboratory/references/Ref_Chemistry";
import { useHistory } from "react-router";
import { BarChart2 } from "react-feather";
import { UsersData } from "src/ContextAPI";
// import Footer from "src/laboratory/print/Footer";

const ChemistryOrder = ({ order_id, patient_id, formheader }) => {
  const [details, setDetails] = useState([]);
  const history = useHistory();
  const { users } = React.useContext(UsersData);

  const getpaidLaboratoryOrderDetails = async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", order_id);
    formdata.set("table", "laboratory_chemistry");
    formdata.set("connection", "local");

    try {
      let response = await axios.post(
        "doctor/patient/laboratory/order/paid-detailsbytable",
        formdata
      );
      const data = response.data;

      setDetails(data);
    } catch (error) {
      console.log("lab form order laboratory_hematology table error", error);
    }
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details.length > 0 && (
        <>
          <Box my={2} p={2}>
            <Box display="flex" mb={2}>
              <Box flexGrow={1}>
                <Typography variant="h6" align="center">
                  Clinical Chemistry Order
                </Typography>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="text"
                  startIcon={<BarChart2 />}
                  onClick={() =>
                    history.push(
                      `/bmcdc/app/doctor/patient/laboratory/chemistry/${patient_id}`
                    )
                  }
                  className={`d-print-none`}
                >
                  Graph
                </Button>
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> Test Request </TableCell>
                    <TableCell align="center"> Result </TableCell>
                    <TableCell align="center"> Reference Values </TableCell>
                    <TableCell align="center"> Remarks </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((data, index) => (
                    <Fragment key={index}>
                      {details.fbs && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              FBS
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.fbs)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.fbs()}
                          </TableCell>
                          <TableCell align="center">
                            {data.fbs_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {details.glucose && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              GLUCOSE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.glucose)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.glucose()}
                          </TableCell>
                          <TableCell align="center">
                            {data.glucose_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.creatinine && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              CREATININE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.creatinine)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.creatinine()}
                          </TableCell>
                          <TableCell align="center">
                            {data.creatinine_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.uric_acid && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              URIC ACID
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.uric_acid)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.uric()}
                          </TableCell>
                          <TableCell align="center">
                            {data.uric_acid_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.cholesterol && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              CHOLESTEROL
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.cholesterol)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.cholesterol()}
                          </TableCell>
                          <TableCell align="center">
                            {data.cholesterol_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.triglyceride && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              TRIGLYCERIDE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.triglyceride)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.triglyceride()}
                          </TableCell>
                          <TableCell align="center">
                            {data.triglyceride_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.hdl_cholesterol && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              HDL CHOLESTEROL
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.hdl_cholesterol)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.hdlcholesterol()}
                          </TableCell>
                          <TableCell align="center">
                            {data.hdl_cholesterol_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.ldl_cholesterol && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              LDL CHOLESTEROL
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.ldl_cholesterol)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.ldlcholesterol()}
                          </TableCell>
                          <TableCell align="center">
                            {data.ldl_cholesterol_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.sgot && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              SGOT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.sgot)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.sgot()}
                          </TableCell>
                          <TableCell align="center">
                            {data.sgot_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.sgpt && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              SGPT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.sgpt)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {ChemistryRef.sgpt()}
                          </TableCell>
                          <TableCell align="center">
                            {data.sgpt_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.bun && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              bun
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.bun)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.bun_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.soduim && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              soduim
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.soduim)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.soduim_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.potassium && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              potassium
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.potassium)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.potassium_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.hba1c && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              hba1c
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.hba1c)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.hba1c_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.alkaline_phosphatase && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              alkaline_phosphatase
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.alkaline_phosphatase)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.alkaline_phosphatase_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.albumin && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              albumin
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.albumin)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.albumin_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.calcium && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              calcium
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{checkResult(data.calcium)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.calcium_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.magnesium && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              magnesium
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.magnesium)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.magnesium_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.chloride && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              className={`gtc-uppercase`}
                            >
                              chloride
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>
                              {checkResult(data.chloride)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell align="center">
                            {data.chloride_remarks}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.serum_uric_acid && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              SERUM URIC ACID
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.serum_uric_acid}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.serum_uric_acid_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.lipid_profile && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              LIPID PROFILE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.lipid_profile}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.lipid_profile_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.ldh && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              LDH
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.ldh}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.ldh_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.tpag_ratio && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              TPAG RATIO
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.tpag_ratio}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.tpag_ratio_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.bilirubin && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              BILIRUBIN
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.bilirubin}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.bilirubin_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.total_protein && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              TOTAL PROTEIN
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.total_protein}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.total_protein_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.potassium_kplus && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              POTASSIUM K+
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.potassium_kplus}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.potassium_kplus_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.na_plus_kplus && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              NA+/K+
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.na_plus_kplus}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.na_plus_kplus_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.ggt && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              GGT
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.ggt}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.ggt_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.cholinesterase && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              CHOLINESTERASE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.cholinesterase}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.cholinesterase_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.phosphorous && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              PHOSPHOROUS
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.phosphorous}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.phosphorous_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.rbs && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              RBS
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.rbs}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.rbs_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.vldl && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              VLDL
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.vldl}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.vldl_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.rbc_cholinesterase && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              RBC CHOLINESTERASE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.rbc_cholinesterase}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>
                            {data.rbc_cholinesterase_remarks}
                          </TableCell>
                        </TableRow>
                      )}

                      {data.crp && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              CRP
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.crp}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.crp_remarks}</TableCell>
                        </TableRow>
                      )}
                      {data.pro_calcitonin && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              PRO CALCITONIN
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{data.pro_calcitonin}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* reference not set */}
                          </TableCell>
                          <TableCell>{data.pro_calcitonin_remarks}</TableCell>
                        </TableRow>
                      )}

                      {data.ogct_take_one_50grm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              OGCT TAKE 1 (50GRM)
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box>
                              <Typography>Baseline</Typography>
                              <Typography>
                                {data.ogct_take_one_50grm_baseline}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>First Hour</Typography>
                              <Typography>
                                {data.ogct_take_one_50grm_first_hour}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>Second Hour</Typography>
                              <Typography>
                                {data.ogct_take_one_50grm_second_hour}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ogct_take_one_75grm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              OGCT TAKE 1 (75GRM)
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box>
                              <Typography>Baseline</Typography>
                              <Typography>
                                {data.ogct_take_one_75grm_baseline}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>First Hour</Typography>
                              <Typography>
                                {data.ogct_take_one_75grm_first_hour}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>Second Hour</Typography>
                              <Typography>
                                {data.ogct_take_one_75grm_second_hour}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ogct_take_two_100grm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              OGTT TAKES 2 (100GRM)
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box>
                              <Typography>Baseline</Typography>
                              <Typography>
                                {data.ogct_take_two_100grm_baseline}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>First Hour</Typography>
                              <Typography>
                                {data.ogct_take_two_100grm_first_hour}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>Second Hour</Typography>
                              <Typography>
                                {data.ogct_take_two_100grm_second_hour}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ogct_take_two_75grm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              OGCT TAKES 2 (75GRM)
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box>
                              <Typography>Baseline</Typography>
                              <Typography>
                                {data.ogct_take_two_75grm_baseline}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>First Hour</Typography>
                              <Typography>
                                {data.ogct_take_two_75grm_first_hour}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>Second Hour</Typography>
                              <Typography>
                                {data.ogct_take_two_75grm_second_hour}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ogct_take_three_100grm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              OGTT TAKES 3 (100GRM)
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box>
                              <Typography>Baseline</Typography>
                              <Typography>
                                {data.ogct_take_three_100grm_baseline}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>First Hour</Typography>
                              <Typography>
                                {data.ogct_take_three_100grm_first_hour}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>Second Hour</Typography>
                              <Typography>
                                {data.ogct_take_three_100grm_second_hour}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.ogct_take_four_100grm && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              OGTT TAKES 4 (100GRM)
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Box>
                              <Typography>Baseline</Typography>
                              <Typography>
                                {data.ogct_take_four_100grm_baseline}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>First Hour</Typography>
                              <Typography>
                                {data.ogct_take_four_100grm_first_hour}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>Second Hour</Typography>
                              <Typography>
                                {data.ogct_take_four_100grm_second_hour}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* <Footer formheader={formheader} /> */}
        </>
      )}
    </>
  );
};

export default ChemistryOrder;
