import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  // Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
  CardMedia,
  CardHeader,
  Grid,
  makeStyles,
  Fab,
  createStyles,
  Collapse,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { Fragment } from "react";
import { Close, Print } from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const BillingReceipt = () => {
  const { receipt_number } = useParams();
  const { users } = React.useContext(UsersData);
  // const [receiptHeader, setReceiptHeader] = useState(false);
  const [details, setDetails] = useState({ data: [], ready: false });
  const [packages, setPackages] = useState({ data: [], ready: false });
  const history = useHistory();
  // const [totalChange, setTotalChange] = useState(0);
  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });
  const classes = useStyles();

  let totalorder = 0;
  let totaldiscount = 0;

  const calculateOrder = (amount) => {
    totalorder += parseFloat(amount !== null ? amount : 0);
    return Notify.convertToNumber(amount !== null ? amount : 0);
  };

  const calculateOrderDiscount = (amount) => {
    totaldiscount += parseFloat(amount);
    return Notify.convertToNumber(amount);
  };

  const getReceiptDetails = useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      receipt_number: receipt_number,
    };
    axios
      .get("cashier/patient/billing/receipt/details", { params })
      .then((response) => {
        const data = response.data;
        setDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.management_id, users.user_id, receipt_number]);

  const getAllChargePackages = useCallback(() => {
    var params = {
      management_id: users.management_id,
    };
    axios
      .get("cashier/get/composition/package", { params })
      .then((response) => {
        const data = response.data;
        setPackages({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.management_id]);

  // const calculateChange = (data) => {
  //   var total = 0;
  //   var total2 = 0;
  //   var totalchange = 0;
  //   if (data.length > 0) {
  //     if (data[0].discount !== null) {
  //       total = parseFloat(data[0].bill_total) * parseFloat(data[0].discount);
  //       total2 = parseFloat(data[0].bill_total) - total;
  //       totalchange = parseFloat(data[0].bill_payment) - total2;
  //     } else {
  //       totalchange =
  //         parseFloat(data[0].bill_payment) - parseFloat(data[0].bill_total);
  //     }
  //   }

  //   return totalchange;
  // };

  // const getRecieptHeaderInformation = useCallback(() => {
  //   var params = {
  //     user_id: users.user_id,
  //     management_id: users.management_id,
  //     receipt_number: receipt_number,
  //   };
  //   axios
  //     .get("/cashier/receipt/information/header-receipt", { params })
  //     .then((response) => {
  //       const data = response.data;
  //       setReceiptHeader(data);
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // }, [users, receipt_number]);

  const getLabFormHeader = () => {
    var params = { management_id: users.management_id };
    axios
      .get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getReceiptDetails();
    getAllChargePackages();
    // getRecieptHeaderInformation();
    getLabFormHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReceiptDetails, getAllChargePackages]);

  return (
    <Dialog
      open={true}
      TransitionComponent={Zoom}
      transitionDuration={500}
      fullScreen
      onClose={() => console.log("dialog cannot be close")}
    >
      <DialogContent>
        <Box m={2}>
          <Box display="flex" justifyContent="center">
            <Box mt={2}>
              {formHeader.ready
                ? formHeader.data && (
                    <CardMedia
                      style={{ width: 70 }}
                      component={"img"}
                      src={`${imageLocation}laboratory/logo/${formHeader.data.logo}`}
                    />
                  )
                : null}
            </Box>
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={formHeader.data && formHeader.data.name}
                subheader={
                  <Box>
                    <Typography>
                      {formHeader.data && formHeader.data.address}
                    </Typography>
                    <Typography>
                      {formHeader.data && formHeader.data.contact_number}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          <Box>
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={
                  <Box>
                    <Typography variant={"h6"} style={{ fontWeight: "bolder" }}>
                      ORDER SLIP
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Order No.:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 &&
                            details.data[0].receipt_number.replace(
                              "receipt-",
                              ""
                            )}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Name:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 &&
                            `${details.data[0].lname}, ${details.data[0].fname}`}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Birth Date:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 &&
                            Notify.birthday(details.data[0].birthday)}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Date:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {new Date().toLocaleString()}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box>
                            <Typography style={{ fontWeight: "bolder" }}>
                              Age:{" "}
                              <span style={{ textTransform: "uppercase" }}>
                                {details.data.length > 0 &&
                                  Notify.calculateAge(details.data[0].birthday)}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <Typography style={{ fontWeight: "bolder" }}>
                              Gender:{" "}
                              <span style={{ textTransform: "uppercase" }}>
                                {details.data.length > 0 &&
                                  details.data[0].gender}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Req. Physician:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 &&
                            details.data[0]?.request_physician}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Payment Method:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 &&
                          Boolean(parseInt(details.data[0].is_charged))
                            ? `CHARGE - ${
                                details.data[0].hmo_category === "clinic-hmo"
                                  ? details.data[0].clinic_hmo_complete_name
                                  : details.data[0].hmo_category === "hmo"
                                  ? details.data[0].hmo_complete_name
                                  : ""
                              }`
                            : "CASH "}
                          {details.data.length > 0 &&
                          details.data[0].transaction_category ===
                            "home-service"
                            ? `HOME SERVICE`
                            : null}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b> Description </b>
                  </TableCell>
                  <TableCell>
                    <b> Amount </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.ready ? (
                  details.data.length > 0 ? (
                    details.data.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow hover>
                          <TableCell>
                            <Typography
                              variant={"caption"}
                              style={{ textTransform: "uppercase" }}
                            >
                              <b>{data.bill_name}</b>
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {data.transaction_category === "home-service"
                              ? Notify.convertToNumber(
                                  parseFloat(data.bill_amount) +
                                    parseFloat(data.bill_amount) *
                                      parseFloat(data.home_service)
                                )
                              : Notify.convertToNumber(data.bill_amount)}

                            {/* discount total */}
                            <span hidden>
                              {calculateOrderDiscount(
                                Boolean(parseInt(data.can_be_discounted))
                                  ? data.bill_amount
                                  : 0
                              )}
                            </span>

                            {/* total amount */}
                            <span hidden>
                              {calculateOrder(data.bill_amount)}
                            </span>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ paddingBottom: 0 }} colSpan={2}>
                            <Collapse
                              in={data.bill_from === "packages"}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <Box>
                                  <Table size="small">
                                    <TableBody>
                                      {packages.data.length > 0 ? (
                                        packages.data
                                          .filter(
                                            (name) =>
                                              name.package_name ===
                                              data.bill_name
                                          )
                                          .map((item, keys) => (
                                            <TableRow key={keys}>
                                              <TableCell>
                                                {item.order_name}
                                              </TableCell>
                                              <TableCell>Package</TableCell>
                                            </TableRow>
                                          ))
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={2}>
                                            <Typography color="secondary">
                                              No record found.
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color="primary">Please wait...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableBody>
                {details.data.length > 0 && details.data[0].discount !== null && (
                  <Fragment>
                    <TableRow>
                      <TableCell align="right">
                        <b> Total </b>
                      </TableCell>
                      <TableCell align="right">
                        <b>
                          {details.data.length > 0 &&
                            Notify.convertToNumber(details.data[0].bill_total)}
                        </b>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="right">
                        <b>
                          Discount{" "}
                          {details.data.length > 0 &&
                            (parseFloat(details.data[0].discount) === 0.05
                              ? "(5%)"
                              : parseFloat(details.data[0].discount) === 0.1
                              ? "(10%)"
                              : parseFloat(details.data[0].discount) === 0.15
                              ? "(15%)"
                              : parseFloat(details.data[0].discount) === 0.2
                              ? "(20%)"
                              : parseFloat(details.data[0].discount) === 0.25
                              ? "(25%)"
                              : parseFloat(details.data[0].discount) === 0.3
                              ? "(30%)"
                              : parseFloat(details.data[0].discount) === 0.35
                              ? "(35%)"
                              : parseFloat(details.data[0].discount) === 0.4
                              ? "(40%)"
                              : parseFloat(details.data[0].discount) === 0.45
                              ? "(45%)"
                              : parseFloat(details.data[0].discount) === 0.5
                              ? "(50%)"
                              : null)}
                        </b>
                      </TableCell>
                      <TableCell align="right">
                        <b>
                          {details.data.length > 0 &&
                            Notify.convertToNumber(
                              totaldiscount * details.data[0].discount
                            )}
                        </b>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                )}

                {details.data.length > 0 &&
                  details.data[0].home_service !== null && (
                    <Fragment>
                      {/* <TableRow>
                        <TableCell align="right">
                          <b> Total </b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {details.data.length > 0 &&
                            details.data[0].transaction_category ===
                              "home-service"
                              ? Notify.convertToNumber(
                                  parseFloat(details.data[0].bill_total) +
                                    parseFloat(details.data[0].bill_total) *
                                      parseFloat(details.data[0].home_service)
                                )
                              : Notify.convertToNumber(
                                  details.data[0].bill_total
                                )}
                          </b>
                        </TableCell>
                      </TableRow> */}

                      {/* <TableRow>
                        <TableCell align="right">
                          <b>
                            Home Service Rate{" "}
                            {details.data.length > 0 &&
                              (parseFloat(details.data[0].home_service) === 0.2
                                ? "(20%)"
                                : parseFloat(details.data[0].home_service) ===
                                  0.35
                                ? "(35%)"
                                : null)}
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {details.data.length > 0 &&
                              Notify.convertToNumber(
                                details.data[0].bill_total *
                                  details.data[0].home_service
                              )}
                          </b>
                        </TableCell>
                      </TableRow> */}
                    </Fragment>
                  )}

                <TableRow>
                  <TableCell align="right">
                    <b> Grand Total </b>
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {details.data.length > 0 &&
                        Notify.convertToNumber(
                          totalorder -
                            totaldiscount * details.data[0].discount +
                            totalorder * details.data[0].home_service
                        )}
                    </b>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="right">
                    <b> Payment </b>
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {details.data.length > 0 &&
                        Notify.convertToNumber(details.data[0].bill_payment)}
                    </b>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="right">
                    <b> Change </b>
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {details.data.length > 0 &&
                        Notify.convertToNumber(
                          details.data[0].bill_payment -
                            (totalorder -
                              totaldiscount * details.data[0].discount +
                              totalorder * details.data[0].home_service)
                        )}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {details.data.length > 0 && details.data[0].note !== null && (
                <Box mt={1}>
                  <Typography style={{ fontWeight: "bolder" }}>
                    Note: {details.data[0].note}
                  </Typography>
                </Box>
              )}
              <Box mt={1}>
                <Typography color="primary" style={{ fontWeight: "bolder" }}>
                  FOR IMMEDIATE RESPONSE ON
                </Typography>
                <Typography color="primary" style={{ fontWeight: "bolder" }}>
                  COMPLIMENTS OR CONCERNS,
                </Typography>
                <Typography color="primary" style={{ fontWeight: "bolder" }}>
                  PLS CONTACT THE OPERATIONS
                </Typography>
                <Typography color="primary" style={{ fontWeight: "bolder" }}>
                  MANAGER AT 09189010460
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mt={1}>
                <Box align="center">
                  <Box mt={5}>
                    <Typography className={`gtc-uppercase`} variant="subtitle2">
                      {/* {formheader && formheader.chief_medtech} */}
                      {details.data.length > 0 && details.data[0].cashierFName}
                    </Typography>
                    <Box borderTop={1}>
                      <b> Cashier </b>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Box className="d-print-none">
        <Fab
          aria-label={"Print"}
          className={classes.fab2}
          color={"secondary"}
          onClick={() => history.push("/bmcdc/app/cashier/patient-on-queue")}
        >
          <Close />
        </Fab>
        <Fab
          aria-label={"Print"}
          className={classes.fab}
          color={"primary"}
          onClick={() => window.print()}
        >
          <Print />
        </Fab>
      </Box>
    </Dialog>
  );
};

export default BillingReceipt;

const useStyles = makeStyles((theme) =>
  createStyles({
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);
