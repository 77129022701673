import {
  Box,
  Grid,
  List,
  Typography,
  Paper,
  ListItem,
  ListItemText,
  makeStyles,
  Avatar,
  ListItemIcon,
  TextField,
  InputAdornment,
  Divider,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import { UsersData, UsersHeader } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import BillingDetails from "../billing/BillingDetails";
import Report from "../billing/Report";
// import SearchIcon from "@material-ui/icons/Search";
import { Search } from "react-feather";
import axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";

const imageLocation = process.env.REACT_APP_API_IMAGE;
const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const PatientQueue = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [searchDisplay, setSearchDisplay] = useState(false);
  const [stateOpenDetails, setStateOpenDetails] = useState("all-orders");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [branches, setBranches] = useState([]);

  const {
    queue: { patient, getPatientsOnQueue },
  } = useContext(UsersHeader);

  const [selectedUnpaid, setSelectedUnpaid] = useState({
    data: null,
    ready: false,
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  const getBranchesByMainMngtId = React.useCallback(async () => {
    var params = { main_management_id: users.main_mgmt_id, type: "clinic" };
    let response = await axios.get("general/management/get-branches", {
      params,
    });
    if (mounted.current) {
      setBranches(response.data);
    }
  }, [users, mounted]);

  React.useEffect(() => {
    getBranchesByMainMngtId();
  }, [getBranchesByMainMngtId]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Patient on Queue",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title={"Patient on Queue"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={1}
              >
                <Box flexGrow={1}>
                  <Typography
                    color="primary"
                    variant="h5"
                    // style={{ textTransform: "uppercase" }}
                  >
                    <strong>Unpaid Bill</strong>
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    onClick={() => setSearchDisplay(!searchDisplay)}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Box>

              <Box mb={1}>
                <Divider light />
              </Box>

              <Box hidden={!searchDisplay}>
                <TextField
                  label="Search Patient"
                  variant="outlined"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  // margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <List component="div">
                  {patient.ready
                    ? searchable.length > 0
                      ? searchable.map((data, index) => (
                          <ListItem
                            button
                            key={index}
                            className="mb-2"
                            selected={
                              selectedUnpaid.data &&
                              selectedUnpaid.data.patient_id === data.patient_id
                            }
                            onClick={() => {
                              setStateOpenDetails("patient-order");
                              setSelectedUnpaid({
                                data: data,
                                ready: true,
                              });
                            }}
                          >
                            <ListItemIcon>
                              {data.image === null ? (
                                <Avatar className={classes.primary}>
                                  {data.lastname.charAt().toUpperCase()}
                                </Avatar>
                              ) : (
                                <Avatar
                                  className={classes.primary}
                                  src={`${imageLocation}patients/${data.image}`}
                                  alt=""
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.lastname}, ${data.firstname}`}
                              secondary={Notify.dateTimeConvert(
                                data.created_at
                              )}
                            />
                          </ListItem>
                        ))
                      : Notify.noRecord()
                    : Notify.loading()}
                </List>

                <TablePagination
                  component="div"
                  count={searchable.length}
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="List"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9}>
            {stateOpenDetails === "all-orders" && <Report />}
            {stateOpenDetails === "patient-order" && (
              <BillingDetails
                details={selectedUnpaid.data}
                getAllCashierOnQueue={getPatientsOnQueue}
                closePatientDetails={() => setStateOpenDetails("all-orders")}
                getPatientsOnQueue={() => getPatientsOnQueue()}
                branches={branches}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default PatientQueue;
