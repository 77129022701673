import {
  Box,
  Dialog,
  Grid,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Notify from "../../notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "../../utils/DraggableDialog";
import { useHistory } from "react-router-dom";
// import FormvalidationClinicMicroscopy from "./validation/FormvalidationClinicMicroscopy";
import { UsersData } from "src/ContextAPI";
import Specimen from "./Specimen";
import { CheckSquare, XCircle } from "react-feather";

const ClinicalMicroscopyOrderDetails = ({
  queue,
  formheader,
  order,
  getLabCliniclMicroscopyOrder,
  resetDisplay,
  process_for,
}) => {
  const { users } = React.useContext(UsersData);
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState({
    data: null,
    dialog: false,
  });

  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);

  const [orderItems, setOrderItems] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const saveButtonRef = useRef();

  const getLabCMicroscopyOrderDetails = () => {
    var params = { user_id: users.user_id, trace_number: order.trace_number };
    Axios.get("laboratory/order/ordernew-clinicalmicroscopy/details", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    getLabCMicroscopyOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("trace_number", order.trace_number);
    formdata.set("queue", queue);
    formdata.set("process_for", process_for);

    setResultSubmitting(true);
    try {
      let response = await Axios.post(
        "laboratory/order/ordernew-clinicalmicroscopy/save-process-result",
        formdata
      );

      const data = response.data;
      if (data === "success") {
        Notify.successRequest("order result added.");
        setTimeout(() => {
          history.push(
            `/bmcdc/app/laboratory/record/print/order/${order.trace_number}`
          );
        }, 2000);
        setSavebtnDisabled(true);
      }
    } catch (error) {
      console.log("error:", error);
    }

    setResultSubmitting(false);
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("order_id", order.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-clinicmicroscopy/save-setpending",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabCliniclMicroscopyOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", users.user_id);
    formdata.set("username", users.username);
    formdata.set("order_id", processDialog.data.order_id);
    formdata.set("trace_number", processDialog.data.trace_number);

    var error = [];
    setProcessSubmitting(true);

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      let response = await Axios.post(
        "laboratory/microscopy/order-setprocess/custom-qty",
        formdata
      );

      if (response.data.message === "reagent-error") {
        Notify.customToast("Reagent Not Error", "Reagent/items not available.");
      }

      if (response.data.message === "pass-invalid") {
        Notify.fieldInvalid("password");
      }

      if (response.data.message === "success") {
        Notify.successRequest("process");
        setProcessDialog({ data: null, dialog: false });
        getLabCMicroscopyOrderDetails();
      }
    }

    setTimeout(() => {
      setProcessSubmitting(false);
    }, 2000);
  };

  const handleProcessSelectedOrder = async (data) => {
    let response = await Axios.get(
      "laboratory/items/laborder/list-available-items",
      {
        params: {
          user_id: users.user_id,
          management_id: users.management_id,
          order_id: data.order_id,
        },
      }
    );

    setOrderItems(response.data);

    setProcessDialog({ data: data, dialog: true });
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <Box display="flex" justifyContent="center">
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={formheader && formheader.name}
                subheader={
                  <Box>
                    <Typography>{formheader && formheader.address}</Typography>
                    <Typography>
                      {formheader && formheader.contact_number}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          <Box>
            <Typography variant="h6" align="center">
              <b> CLINICAL MICROSCOPY </b>
            </Typography>
          </Box>

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      PATIENT NAME:{" "}
                    </Typography>
                    {`${order.lastname}, ${order.firstname} ${
                      order.middle === null ? "" : order.middle
                    }`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:{" "}
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        GENDER:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      PATIENT ADDRESS:{" "}
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} `}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="caption" className="font-weight-bold">
                    PATIENT CONDITION:{" "}
                  </Typography>
                  {order.patient_condition === null
                    ? " none"
                    : order.patient_condition}
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:{" "}
                    </Typography>
                    {Notify.createdAt(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            <Specimen
              patient_id={order.patient_id}
              trace_number={order.trace_number}
            />
          </CardContent>

          <CardContent>
            {/* order details */}
            <Box>
              {orderDetails.ready ? (
                orderDetails.data.length > 0 ? (
                  <>
                    {orderDetails.data.map((data, index) => (
                      <Box key={index}>
                        {/* starttttttttttttttt */}
                        <>
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="order_id[]"
                              label="order_id"
                              hidden
                              value={data.order_id}
                            />
                            <TextField
                              margin="dense"
                              fullWidth
                              name="patient_id"
                              label="patient_id"
                              hidden
                              value={data.patient_id}
                            />

                            <TextField
                              margin="dense"
                              fullWidth
                              name="doctors_id"
                              label="doctor_id"
                              hidden
                              value={
                                data.doctor_id === null ? "" : data.doctor_id
                              }
                            />
                          </Box>

                          <Box mb={2}>
                            {/* clinical microscopy */}
                            <Box
                              hidden={
                                Boolean(parseInt(data.chemical_test))
                                  ? false
                                  : true
                              }
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography variant="subtitle1">
                                    <b> CHEMICAL TEST </b>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    disabled={Boolean(
                                      parseInt(data.is_processed)
                                    )}
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleProcessSelectedOrder(data)
                                    }
                                  >
                                    Process
                                  </Button>
                                </Box>
                              </Box>

                              {/* show only when process is done */}
                              <Box
                                ml={2}
                                hidden={
                                  Boolean(parseInt(data.is_processed))
                                    ? false
                                    : true
                                }
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={4} sm={4}>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> COLOR: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="chemecal_test[]"
                                          margin="dense"
                                          variant="outlined"
                                          hidden
                                          value={"chemical_order"}
                                        />
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="color[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> TRANPARENCY: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="transparency[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> PH: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="ph[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> SPECIFIC GRAVITY: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="specific_gravity[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> GLUCOSE: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="glucose[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                    <Box display="flex">
                                      <Box
                                        mt={2}
                                        mr={1}
                                        flexGrow={1}
                                        align="right"
                                      >
                                        <Typography variant="caption">
                                          <b> ALBUMIN: </b>
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <TextField
                                          fullWidth
                                          label="Result"
                                          name="albumin[]"
                                          margin="dense"
                                          variant="outlined"
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>

                          <Box mb={2}>
                            {/* clinical microscopy */}
                            <Box
                              hidden={
                                Boolean(parseInt(data.microscopic_test))
                                  ? false
                                  : true
                              }
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography variant="subtitle1">
                                    <b> MICROSCOPIC TEST </b>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    disabled={Boolean(
                                      parseInt(data.is_processed)
                                    )}
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleProcessSelectedOrder(data)
                                    }
                                  >
                                    process
                                  </Button>
                                </Box>
                              </Box>

                              <Box
                                ml={2}
                                hidden={
                                  Boolean(parseInt(data.is_processed))
                                    ? false
                                    : true
                                }
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6}>
                                    <>
                                      <Box mt={2}>
                                        <Typography
                                          variant="subtitle2"
                                          color="textSecondary"
                                        >
                                          CELLS
                                        </Typography>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                          mt={2}
                                        >
                                          <Typography variant="caption">
                                            <b> SQUAMOUS CELLS: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Enable microscopic test"
                                            name="microscopic_test[]"
                                            margin="dense"
                                            variant="outlined"
                                            hidden
                                            value={"microscopic_order"}
                                          />
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="squamous[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> PUS CELLS: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="pus[]"
                                            margin="dense"
                                            variant="outlined"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  /HPF
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> RED BLOOD CELLS: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="redblood[]"
                                            margin="dense"
                                            variant="outlined"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  /HPF
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </>

                                    <>
                                      <Box mt={2}>
                                        <Typography
                                          variant="subtitle2"
                                          color="textSecondary"
                                        >
                                          CASTS
                                        </Typography>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                          mt={2}
                                        >
                                          <Typography
                                            variant="caption"
                                            className={`gtc-uppercase`}
                                          >
                                            <b> Hyaline Cast: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="hyaline[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography
                                            variant="caption"
                                            className={`gtc-uppercase`}
                                          >
                                            <b> WBC Cast: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="wbc_cast[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography
                                            variant="caption"
                                            className={`gtc-uppercase`}
                                          >
                                            <b> RBC Cast: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="rbc_cast[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography
                                            variant="caption"
                                            className={`gtc-uppercase`}
                                          >
                                            <b> Fine Granualar Cast: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="fine_granualar[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography
                                            variant="caption"
                                            className={`gtc-uppercase`}
                                          >
                                            <b>Coarse Granualar Cast:</b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="coarse_granualar[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                    </>
                                  </Grid>

                                  <Grid item xs={6} sm={6}>
                                    <>
                                      <Box mt={2}>
                                        <Typography
                                          variant="subtitle2"
                                          color="textSecondary"
                                        >
                                          CRYSTALS
                                        </Typography>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                          mt={2}
                                        >
                                          <Typography variant="caption">
                                            <b> CALCIUM OXALATE: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="crystal_oxalate[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> TRIPLE PHOSPHATE: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="triple_phosphate[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> LEUCINE/TYROCINE: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="leucine_tyrocine[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>

                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> AMMONIUME BIURATE: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="ammoniume[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>

                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> AMORPHOUS URATES: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="amorphous_urates[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>

                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b>AMORPHOUS PHOSPHATES:</b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="amorphous_phosphate[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>

                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> URIC ACID: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="uric_acid[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                    </>
                                    <>
                                      <Box mt={2}>
                                        <Typography
                                          variant="subtitle2"
                                          color="textSecondary"
                                        >
                                          OTHERS
                                        </Typography>
                                      </Box>

                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> MUCUS THREAD: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="mucus_thread[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> BACTERIA: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="bacteria[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>

                                      <Box display="flex">
                                        <Box
                                          mt={2}
                                          mr={1}
                                          flexGrow={1}
                                          align="right"
                                        >
                                          <Typography variant="caption">
                                            <b> YEAST: </b>
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <TextField
                                            fullWidth
                                            label="Result"
                                            name="yeast[]"
                                            margin="dense"
                                            variant="outlined"
                                          />
                                        </Box>
                                      </Box>
                                    </>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>

                          <Box mb={2}>
                            {/* clinical microscopy */}
                            <>
                              <Box
                                hidden={
                                  Boolean(parseInt(data.pregnancy_test_hcg))
                                    ? false
                                    : true
                                }
                              >
                                <Box display="flex">
                                  <Box flexGrow={1}>
                                    <Typography variant="subtitle1">
                                      <b> PREGNANCY TEST (HCG) </b>
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Button
                                      disabled={Boolean(
                                        parseInt(data.is_processed)
                                      )}
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        handleProcessSelectedOrder(data)
                                      }
                                    >
                                      process
                                    </Button>
                                  </Box>
                                </Box>
                                {Boolean(parseInt(data.is_processed)) && (
                                  <Box>
                                    <TextField
                                      fullWidth
                                      label="Enable pregnancy test"
                                      name="pregnancy_test_enable[]"
                                      margin="dense"
                                      variant="outlined"
                                      hidden
                                      value={"pregnancy_order"}
                                    />
                                    <TextField
                                      fullWidth
                                      label="Result"
                                      name="pregnancy_test[]"
                                      margin="dense"
                                      variant="outlined"
                                      required={Boolean(
                                        parseInt(data.pregnancy_test_hcg)
                                      )}
                                      multiline
                                      rows={3}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </>
                          </Box>

                          <Box mb={2}>
                            <Box hidden={!Boolean(parseInt(data.micral_test))}>
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography variant="subtitle1">
                                    <b> MICRAL TEST </b>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    disabled={Boolean(
                                      parseInt(data.is_processed)
                                    )}
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleProcessSelectedOrder(data)
                                    }
                                  >
                                    Process
                                  </Button>
                                </Box>
                              </Box>

                              {Boolean(parseInt(data.is_processed)) && (
                                <Box mt={2}>
                                  <TextField
                                    fullWidth
                                    label="Result"
                                    name="micral_test_result[]"
                                    variant="outlined"
                                    multiline
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>

                          <Box mb={2}>
                            <Box
                              hidden={
                                !Boolean(parseInt(data.occult_blood_test))
                              }
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography variant="subtitle1">
                                    <b> OCCULT BLOOD </b>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    disabled={Boolean(
                                      parseInt(data.is_processed)
                                    )}
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleProcessSelectedOrder(data)
                                    }
                                  >
                                    Process
                                  </Button>
                                </Box>
                              </Box>

                              {Boolean(parseInt(data.is_processed)) && (
                                <Box mt={2}>
                                  <TextField
                                    fullWidth
                                    label="Result"
                                    name="occult_blood_test_result[]"
                                    variant="outlined"
                                    multiline
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>

                          <Box mb={2}>
                            <Box
                              hidden={!Boolean(parseInt(data.seminalysis_test))}
                            >
                              <Box display="flex">
                                <Box flexGrow={1}>
                                  <Typography variant="subtitle1">
                                    <b> SEMINALYSIS TEST </b>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    disabled={Boolean(
                                      parseInt(data.is_processed)
                                    )}
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      handleProcessSelectedOrder(data)
                                    }
                                  >
                                    Process
                                  </Button>
                                </Box>
                              </Box>

                              {Boolean(parseInt(data.is_processed)) && (
                                <Box mt={2}>
                                  <TextField
                                    fullWidth
                                    label="Result"
                                    name="seminalysis_result[]"
                                    variant="outlined"
                                    multiline
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </>

                        {/* endddddddddddddddd */}

                        <Box my={2}>
                          <Divider />
                        </Box>
                      </Box>
                    ))}

                    <Box mb={2}>
                      {/* clinical microscopy */}
                      {!savebtnDisabled && (
                        <Box>
                          <Typography variant="subtitle1">
                            <b> REMARKS </b>
                          </Typography>
                          <Box>
                            <TextField
                              fullWidth
                              label="Result"
                              name="result_remarks"
                              margin="dense"
                              variant="outlined"
                              required
                              multiline
                              rows={3}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  "Order not found."
                )
              ) : (
                "please wait..."
              )}
            </Box>
          </CardContent>
          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  hidden
                  ref={saveButtonRef}
                  disabled={!confirmDialog}
                >
                  Save Result Button
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setConfirmDialog(true)}
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>

          {/* confirmation dialog */}
          <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
            <DialogContent>
              <Typography>Are you sure to continue and save result?</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                startIcon={<XCircle />}
                onClick={() => setConfirmDialog(false)}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={resultSubmitting}
                startIcon={
                  resultSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
                onClick={() => saveButtonRef.current.click()}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="outlined"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.dialog}
        onClose={() => setProcessDialog({ data: null, dialog: false })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="draggable-handle">Process order</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            {orderItems.length > 0
              ? orderItems.map((data, index) => (
                  <Box key={index} mb={2}>
                    <Box>
                      <TextField
                        fullWidth
                        name="item_id[]"
                        value={data.item_id}
                        variant="outlined"
                        hidden
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        required
                        label={`${data.description} qty to process`}
                        name="qty[]"
                        variant="outlined"
                        defaultValue={1}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // InputProps={{
                        //   inputProps: {
                        //     min: 0,
                        //     max: data._total_qty_available,
                        //   },
                        // }}
                        type="number"
                      />
                    </Box>
                  </Box>
                ))
              : "No reagent/item found."}

            <Box mt={2}>
              <TextField
                fullWidth
                required
                defaultValue="ok"
                name="remarks"
                label={`Remarks`}
                variant="outlined"
              />
            </Box>

            <Box mt={2}>
              <TextField
                fullWidth
                required
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ data: null, dialog: false })}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ClinicalMicroscopyOrderDetails;
