import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Button,
  TableBody,
  Menu,
  Zoom,
  Dialog,
  Tooltip,
  //   Tab,
  //   Tabs,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Search } from "react-feather";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
import { FilterList, Print } from "@material-ui/icons";
import ClinicSalePrint from "./ClinicSalePrint";
// import PrintReport from "./PrintReport";
// import TabPanel from "src/utils/TabPanel";
// import CashierSOATemp from "./CashierSOATemp";
// import CashierSOAList from "./CashierSOAList";
// import IsMountedRef from "src/utils/IsMountedRef";
// import CashierSalesPrint from "./CashierSalesPrint";

const ClinicSaleTable = ({ selectedBranch }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { users } = useContext(UsersData);
  const [total, setTotal] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printDialog, setPrintDialog] = useState(false);
  const [dateFromz, setDateFromz] = useState(null);
  const [dateToz, setDateToz] = useState(null);
  //group
  const [record, setRecord] = useState({
    data: [],
    ready: false,
  });

  const [recordNotGroup, setRecordNotGroup] = useState({
    data: [],
    ready: false,
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const calculateAmount = (data) => {
    var total = 0;
    for (let x = 0; x < data.length; x++) {
      total +=
        parseInt(data[x].status) === 1
          ? data[x].discount !== null
            ? parseFloat(data[x].totalpayment) -
              parseFloat(data[x].totalpayment) * parseFloat(data[x].discount) +
              parseFloat(data[x].totalnotdiscount) *
                parseFloat(data[x].discount)
            : data[x].home_service !== null
            ? parseFloat(data[x].totalpayment) +
              parseFloat(data[x].totalpayment) *
                parseFloat(data[x].home_service)
            : parseFloat(data[x].totalpayment)
          : 0;
    }
    return total;
  };

  const getBillingRecords = useCallback(() => {
    var params = { management_id: selectedBranch.management_id };
    axios
      .get("cashier/billing/records/list", { params })
      .then((response) => {
        const data = response.data;
        setRecord({ data: data, ready: true });
        setTotal(calculateAmount(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [selectedBranch.management_id]);

  const getBillingRecordsGroup = useCallback(() => {
    var params = { management_id: selectedBranch.management_id };
    axios
      .get("cashier/billing/records/list/not-group-receipt", { params })
      .then((response) => {
        const data = response.data;
        setRecordNotGroup({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [selectedBranch.management_id]);

  const handleReportByReport = async (e) => {
    e.preventDefault();
    e.persist();

    const formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", selectedBranch.management_id);
    const dateFrom = formdata.get("date_from");
    const dateTo = formdata.get("date_to");

    var error = [];
    if (
      formdata.get("category").length === 0 ||
      formdata.get("category").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("category");
    }
    if (
      formdata.get("cash_charge").length === 0 ||
      formdata.get("cash_charge").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("cash or charge");
    }
    if (
      formdata.get("date_from").length === 0 ||
      formdata.get("date_from").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date from");
    }
    if (
      formdata.get("date_to").length === 0 ||
      formdata.get("date_to").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date to");
    }
    if (
      parseInt(new Date(dateFrom).getTime()) >
      parseInt(new Date(dateTo).getTime())
    ) {
      error = "error";
      Notify.fieldInvalid("date is invalid");
    }
    if (error.length > 0) {
      console.warn("Form has an error.");
    } else {
      setIsSubmitting(true);
      setRecord({ data: [], ready: false });
      axios
        .post("cashier/get/filter-by-date/report", formdata)
        .then((res) => {
          const data = res.data;
          setRecord({ data, ready: true });
          setTotal(calculateAmount(data));
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    getBillingRecordsGroup();
    getBillingRecords();
    // eslint-disable-next-line
  }, [getBillingRecordsGroup, getBillingRecords]);

  //   const searchable = record.data.filter((data) => {
  //     return (
  //       data.fname.toLowerCase().indexOf(search.trim()) !== -1 ||
  //       data.lname.toLowerCase().indexOf(search.trim()) !== -1
  //     );
  //   });

  return (
    <>
      <Box component={Paper} p={2}>
        <Box display="flex" justifyContent={"center"} alignItems="center">
          <Box flexGrow={1}>
            {/* <Box maxWidth={230}>
              <TextField
                fullWidth
                label="Search Patient"
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                margin="dense"
              />
            </Box> */}
            <Typography variant="h6">
              Total Report: <b>₱{Notify.convertToNumber(total)}</b>
            </Typography>
          </Box>

          <Box>
            <Box mt={1}>
              <Box display="flex">
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    // color="primary"
                    type="submit"
                    startIcon={<FilterList />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    FILTER
                  </Button>
                </Box>

                <Box ml={1}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<Print />}
                    onClick={() => setPrintDialog(true)}
                  >
                    PRINT
                  </Button>
                </Box>
              </Box>

              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={(e) => setAnchorEl(null)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                getContentAnchorEl={null}
              >
                <form onSubmit={handleReportByReport}>
                  <Box m={2}>
                    <Box mb={1}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        style={{ fontWeight: "bolder" }}
                      >
                        Search Filter
                      </Typography>
                    </Box>

                    <Box mb={1}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        label="Category"
                        name="category"
                        fullWidth
                        margin="dense"
                        defaultValue={"all"}
                        hidden
                      />

                      <TextField
                        label="Cash/Charge"
                        variant="outlined"
                        fullWidth
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="cash_charge"
                        margin="dense"
                      >
                        <option value=""> -- Select -- </option>
                        <option
                          value="all"
                          // hidden={selectedData.category === "walkin"}
                        >
                          All
                        </option>
                        <option value="cash">Cash</option>
                        <option
                          value="charge"
                          // hidden={selectedData.category === "walkin"}
                        >
                          Charge
                        </option>
                      </TextField>
                    </Box>

                    <Box mb={1}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="Date From"
                        name="date_from"
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                          setDateFromz(e.target.value);
                        }}
                        value={dateFromz !== null ? dateFromz : ""}
                      />
                    </Box>

                    <Box mb={1}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        label="Date To"
                        name="date_to"
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                          setDateToz(e.target.value);
                        }}
                        value={dateToz !== null ? dateToz : ""}
                      />
                    </Box>

                    <Box mb={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<Search />}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Menu>
            </Box>
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b> Date </b>
                </TableCell>
                <TableCell align="center">
                  <b> Patient </b>
                </TableCell>
                <TableCell align="center">
                  <b> Processed </b>
                </TableCell>
                <TableCell align="center">
                  <b> Status </b>
                </TableCell>
                <TableCell align="center">
                  <b> Category </b>
                </TableCell>
                <TableCell align="center">
                  <b> Amount </b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {record.ready ? (
                record.data.length > 0 ? (
                  (limit > 0
                    ? record.data.slice(page * limit, page * limit + limit)
                    : record.data
                  ).map((data, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor:
                          parseInt(data.status) === 0 ? "#FDAAAA" : null,
                      }}
                    >
                      <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                      <TableCell>
                        {data.lname}, {data.fname}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            data.order_from === "mobile-van"
                              ? "BMCDC VAN"
                              : "None"
                          }
                          arrow
                        >
                          <span
                            style={{
                              color:
                                data.order_from === "mobile-van"
                                  ? "green"
                                  : "blue",
                              fontSize: 10,
                            }}
                          >
                            {data.order_from === "mobile-van"
                              ? "VAN"
                              : "CLINIC"}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            data.discount !== null ? data.discount_reason : ""
                          }
                          arrow
                        >
                          <span
                            style={{
                              color: data.discount !== null ? "red" : "green",
                              fontSize: 10,
                            }}
                          >
                            {data.discount !== null
                              ? "Discounted"
                              : data.home_service !== null
                              ? "Home Service"
                              : null}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {data.is_charged !== 0
                          ? data.hmo_used !== null
                            ? `Charge(HMO)`
                            : `Charge(Corporate)`
                          : "Cash"}
                      </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(
                          parseInt(data.status) === 1
                            ? data.totalpayment -
                                data.totalpayment * data.discount +
                                data.totalnotdiscount * data.discount +
                                data.totalpayment * data.home_service
                            : 0
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>{Notify.noRecord()}</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>{Notify.loading()}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component={Box}
            count={record.data.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[
              10,
              20,
              50,
              { value: record.data.length, label: "All" },
            ]}
          />
        </TableContainer>
      </Box>

      <Dialog
        open={printDialog}
        onClose={() => setPrintDialog(false)}
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={800}
      >
        <ClinicSalePrint
          recordNotGroup={recordNotGroup}
          report={record}
          close={() => setPrintDialog(false)}
          grandTotal={total}
          dateFrom={dateFromz}
          dateTo={dateToz}
        />
      </Dialog>
    </>
  );
};

export default ClinicSaleTable;
