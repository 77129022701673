import {
  Grid,
  Box,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Trash } from "react-feather";
import { UsersData } from "src/ContextAPI";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import { AddCircleOutline, Cancel } from "@material-ui/icons";
import { isMobile } from "react-device-detect";

const AccriditedCompany = () => {
  const { users } = useContext(UsersData);
  const mounted = IsMountedRef();
  const [list, setList] = useState({
    data: [],
    ready: false,
  });
  const [_inputHMO, setInputHMO] = useState({
    data: [],
    ready: true,
  });

  const [remove, setRemove] = useState({ data: null, dialog: false });
  const [edit, setEdit] = useState({ data: "", enable: false });

  const addField = () => {
    setInputHMO({
      data: _inputHMO.data.concat({
        hmo_name: "hmo_name[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputHMO.data;
    setInputHMO({
      ..._inputHMO,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputHMO({
      data: list,
      ready: true,
    });
  };

  const getAccreditedCompanyList = React.useCallback(async () => {
    let response = await axios.get(
      "accounting/management/accredited/company-list",
      {
        params: {
          management_id: users.management_id,
        },
      }
    );

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [users, mounted]);

  const handleNewCompany = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);
    var error = [];

    if (formdata.get("company").trim() === "") {
      error = "error";
      Notify.fieldRequired("company");
    }

    if (formdata.get("address").trim() === "") {
      error = "error";
      Notify.fieldRequired("address");
    }

    if (formdata.get("contact_person").trim() === "") {
      error = "error";
      Notify.fieldRequired("contact person");
    }

    if (formdata.get("contact").trim() === "") {
      error = "error";
      Notify.fieldRequired("contact");
    }

    if (formdata.get("contact_position").trim() === "") {
      error = "error";
      Notify.fieldRequired("contact position");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      let response = await axios.post(
        "accounting/management/accredited/company-save",
        formdata
      );

      const data = response.data;

      if (data.message === "success") {
        getAccreditedCompanyList();
        e.target.reset();
        Notify.successRequest("company");
      }
    }
  };

  const handleRemoveCompany = async () => {
    var formdata = new FormData();
    formdata.set("remove_id", remove.data.id);

    let response = await axios.post(
      "accounting/management/accredited/company-remove",
      formdata
    );

    const data = response.data;
    if (data.message === "success") {
      getAccreditedCompanyList();
      setRemove({ data: null, dialog: false });
      Notify.successRequest("remove");
    }
  };

  const handleEditCompany = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("management_id", users.management_id);
    formdata.set("edit_id", edit.data.id);
    var error = [];

    if (formdata.get("company").trim() === "") {
      error = "error";
      Notify.fieldRequired("company");
    }

    if (formdata.get("address").trim() === "") {
      error = "error";
      Notify.fieldRequired("address");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      let response = await axios.post(
        "accounting/management/accredited/company-edit",
        formdata
      );

      const data = response.data;

      if (data.message === "success") {
        getAccreditedCompanyList();
        setEdit({ data: "", enable: false });
        Notify.successRequest("updated company");
      }
    }
  };

  useEffect(() => {
    getAccreditedCompanyList();
  }, [getAccreditedCompanyList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "company",
        items: [{ name: "Dashboard", path: "/bmcdc/app/accounting" }],
      }}
      title={"Company List"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7} md={9}>
          <Box>
            <Card>
              <CardHeader title="Company List" />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> <strong>Company</strong>  </TableCell>
                        <TableCell> <strong>Address</strong>  </TableCell>
                        <TableCell> <strong>Contact</strong>  </TableCell>
                        <TableCell align="center"> <strong>Action</strong>  </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {list.ready ? (
                        list.data.length > 0 ? (
                          list.data.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell> {data.company} </TableCell>
                              <TableCell> {data.address} </TableCell>
                              <TableCell> {data.contact} </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    setEdit({ data: data, enable: true })
                                  }
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  onClick={() =>
                                    setRemove({ data: data, dialog: true })
                                  }
                                >
                                  <Trash />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <Typography color="secondary">
                                No record found.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            <Typography color="primary">
                              Loading, Please wait...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} md={3}>
          <Box>
            {/* add new company */}
            <Card hidden={edit.enable}>
              <CardHeader title="New company" />
              <CardContent>
                <form onSubmit={handleNewCompany}>
                  <Box mb={2}>
                    <TextField
                      name="company"
                      fullWidth
                      label="Company Name"
                      variant="outlined"
                      required
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="address"
                      fullWidth
                      label="Company Address"
                      variant="outlined"
                      required
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="contact_person"
                      fullWidth
                      label="Company Contact Person"
                      variant="outlined"
                      required
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="contact"
                      fullWidth
                      label="Company Contact No."
                      variant="outlined"
                      required
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="contact_position"
                      fullWidth
                      label="Company Contact Position"
                      variant="outlined"
                      required
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={1}
                    borderRadius={4}
                    borderColor={"#AEAEAE"}
                    mb={2}
                  >
                    <Box flexGrow={1}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontWeight: "bolder" }}
                      >
                        <Box ml={1}>HMO info</Box>
                      </Typography>
                    </Box>

                    <Box>
                      <IconButton
                        color="secondary"
                        onClick={
                          _inputHMO.data.length > 0
                            ? () => removeField(1, _inputHMO.data.length)
                            : (e) => e.preventDefault()
                        }
                      >
                        <Cancel fontSize={isMobile ? "small" : "default"} />
                      </IconButton>
                    </Box>

                    <Box>
                      <IconButton color="primary" onClick={addField}>
                        <AddCircleOutline
                          fontSize={isMobile ? "small" : "default"}
                        />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box>
                    {_inputHMO.ready &&
                      _inputHMO.data.map((data, index) => {
                        return (
                          <Box
                            key={index}
                            border={1}
                            borderRadius={4}
                            borderColor={"#AEAEAE"}
                            mb={2}
                          >
                            <Grid component={Box} p={1} container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name={data.hmo_name}
                                    label="HMO Name"
                                    variant="outlined"
                                    required
                                    margin={"dense"}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box display="flex">
                    <Box mr={2}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        size="large"
                        variant="contained"
                        color="default"
                        type="reset"
                      >
                        No
                      </Button>
                    </Box>
                  </Box>
                </form>
              </CardContent>
            </Card>

            {/* edit company */}
            <Card hidden={!edit.enable}>
              <CardHeader title="Edit company" />
              <CardContent>
                <form onSubmit={handleEditCompany}>
                  <Box mb={2}>
                    <TextField
                      name="company"
                      fullWidth
                      label="Company"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                      value={edit.enable ? edit.data.company : ""}
                      onChange={(e) =>
                        setEdit({
                          ...edit,
                          data: { ...edit.data, company: e.target.value },
                        })
                      }
                    />
                  </Box>

                  {/* {console.log("edit:", edit)} */}

                  <Box mb={2}>
                    <TextField
                      name="address"
                      fullWidth
                      label="Address"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                      value={edit.enable ? edit.data.address : ""}
                      onChange={(e) =>
                        setEdit({
                          ...edit,
                          data: { ...edit.data, address: e.target.value },
                        })
                      }
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="contact_person"
                      fullWidth
                      label="Contact Person"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={edit.enable ? edit.data.contact_person : ""}
                      onChange={(e) =>
                        setEdit({
                          ...edit,
                          data: {
                            ...edit.data,
                            contact_person: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="contact"
                      fullWidth
                      label="Contact"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={edit.enable ? edit.data.contact : ""}
                      onChange={(e) =>
                        setEdit({
                          ...edit,
                          data: { ...edit.data, contact: e.target.value },
                        })
                      }
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      name="contact_position"
                      fullWidth
                      label="Contact Position"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={edit.enable ? edit.data.contact_position : ""}
                      onChange={(e) =>
                        setEdit({
                          ...edit,
                          data: {
                            ...edit.data,
                            contact_position: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>

                  <Box display="flex">
                    <Box mr={2}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        size="large"
                        variant="contained"
                        color="default"
                        onClick={() => setEdit({ data: "", enable: false })}
                      >
                        No
                      </Button>
                    </Box>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      {/* remove dialog */}
      <Dialog
        open={remove.dialog}
        onClose={() => setRemove({ data: null, dialog: false })}
      >
        <DialogContent>Are you sure to remove this company?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setRemove({ data: null, dialog: false })}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleRemoveCompany()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AccriditedCompany;
