import React, { useEffect, useState, useContext } from "react";
import Container from "src/layout/Container";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { FormInformation, UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import { CancelOutlined, SaveAlt } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  inputComponent: {
    height: "30px",
    width: "350px",
  },
  inputComponentLeft: {
    height: "30px",
    width: "450px",
  },
  inputComponentCenter: {
    height: "30px",
    width: "250px",
  },
  inputComponentRight: {
    height: "30px",
    width: "250px",
  },
}));

const PrintLayout = () => {
  const { users } = useContext(UsersData);
  const { formInfo, renderFormInfo } = useContext(FormInformation);
  const [data, setData] = useState({});
  const classes = useStyles();

  const handleFromEdit = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);

    formdata.set("user_id", users.user_id);
    formdata.set("management_id", users.management_id);
    formdata.set("main_mgmt_id", users.main_mgmt_id);

    let response = await axios.post(
      "laboratory/edit/result-print-layout",
      formdata
    );

    if (response.data === "success") {
      e.target.reset();
      renderFormInfo();
      Notify.successRequest("edit print layout");
    }
  };

  const onInputchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const renderB = React.useCallback(() => {
    setTimeout(() => {
      setData(formInfo);
    }, 2000);
  }, [formInfo]);

  useEffect(() => {
    renderB();
  }, [renderB]);
  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "print layout",
          items: [{ name: "Dashboard", path: "/bmcdc/app" }],
        }}
        title="Print Layout"
      >
        <Box m={2} border={1} borderColor="#AEAEAE">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form onSubmit={handleFromEdit} noValidate>
                <Box m={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={100}
                  >
                    <Box flexGrow={1} width={"100%"}>
                      <Box hidden>
                        <TextField
                          required
                          name="lfh_id"
                          value={
                            data.lfh_id !== null || data.lfh_id !== undefined
                              ? data.lfh_id
                              : ""
                          }
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          onChange={onInputchange}
                        />
                      </Box>
                      <Box align="center">
                        <Box>
                          <TextField
                            required
                            name="name"
                            value={data.name}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            className={classes.inputComponent}
                            onChange={onInputchange}
                          />
                        </Box>
                        <Box maxWidth={500} />
                      </Box>

                      <Box align="center">
                        <Box>
                          <TextField
                            required
                            name="address"
                            value={data.address}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            className={classes.inputComponent}
                            onChange={onInputchange}
                          />
                        </Box>
                        <Box maxWidth={500} />
                      </Box>

                      <Box align="center">
                        <Box>
                          <TextField
                            required
                            name="contact_number"
                            value={data.contact_number}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            className={classes.inputComponent}
                            onChange={onInputchange}
                          />
                        </Box>
                        <Box maxWidth={600} />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box m={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={100}
                    borderColor={"#AEAEAE"}
                    borderRadius={4}
                    border={1}
                  >
                    <Typography variant="h3"> CONTENT </Typography>
                  </Box>
                  <Box my={2} mt={5}>
                    <Box display="flex">
                      <Box flexGrow={1} width={"33%"}>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="pathologist"
                              value={data.pathologist}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={250} />
                        </Box>
                        <Typography align="center"> Pathologist </Typography>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="pathologist_lcn"
                              value={data.pathologist_lcn}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={250} />
                        </Box>
                      </Box>
                      <Box width={"33%"}>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="chief_medtech"
                              value={data.chief_medtech}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={350} />
                        </Box>
                        <Typography align="center">
                          Chief Med Technologist
                        </Typography>
                        <Box align="center">
                          <Box>
                            <TextField
                              required
                              name="chief_medtech_lci"
                              value={data.chief_medtech_lci}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            />
                          </Box>
                          <Box maxWidth={250} />
                        </Box>
                      </Box>
                      <Box flexGrow={1} width={"33%"}>
                        <Box align="center">
                          <Box mt={1} borderBottom={1} borderColor={"#AEAEAE"}>
                            {/* <TextField
                              required
                              name="medtech"
                              value={data.medtech}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            /> */}
                            <Typography>Processed By</Typography>
                          </Box>
                          <Box maxWidth={250} />
                        </Box>
                        <Typography align="center">
                          Medical Technologist
                        </Typography>

                        <Box align="center">
                          <Box mt={1} borderBottom={1} borderColor={"#AEAEAE"}>
                            {/* <TextField
                              required
                              name="medtect_lci"
                              value={data.medtect_lci}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              className={classes.inputComponent}
                              onChange={onInputchange}
                            /> */}
                            <Typography>Processed By Lic. No.</Typography>
                          </Box>
                          <Box maxWidth={250} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" mt={5}>
                    <Box flexGrow={1} />
                    <Box mr={2}>
                      <Button
                        className={"d-print-none"}
                        variant="contained"
                        color="default"
                        startIcon={<CancelOutlined />}
                        // onClick={() => close()}
                      >
                        Close
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        className={"d-print-none"}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveAlt />}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PrintLayout;
