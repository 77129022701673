import React, { Fragment } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const ECGPrintAll = ({ allowHeaderPatientInfo, patients, ECGOrderDetails }) => {
  return (
    <>
      {ECGOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                ECG INTERPRETATION
              </span>
            </Typography>
          </Box>
          <Box mt={5}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box height={100}>
                  <Typography>
                    <strong>I</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>AVR</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>V1</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>V4</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box height={100}>
                  <Typography>
                    <strong>II</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>AVL</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>V2</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>V5</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box height={100}>
                  <Typography>
                    <strong>III</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>AVF</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>V3</strong>
                  </Typography>
                </Box>
                <Box height={100}>
                  <Typography>
                    <strong>V6</strong>
                  </Typography>
                </Box>
              </Grid>
              {ECGOrderDetails.data.map((data, index) => (
                <Fragment key={index}>
                  <Grid item xs={6}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>Atrial/ ventricular Rate: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.atrial_ventricular_rate}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>Rhythm: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.rhythm}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>Axis: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.axis}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>P wave: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.p_wave}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>PR interval: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.pr_interval}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>QRS: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.qrs}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>QT interval: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.qt_interval}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>QRS complex: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.qrs_complex}</strong>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>ST segment: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.st_segment}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>Others: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.others}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography style={{ whiteSpace: "pre" }}>
                        <strong>Interpretation: </strong>
                      </Typography>
                      <Box borderBottom={1} width={"100%"}>
                        <Typography style={{ whiteSpace: "pre" }}>
                          <strong> {data.interpretation}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Fragment>
              ))}
            </Grid>

            <Box mt={3} align="right">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          Victorio C. Angus, M.D., DPCP
                        </span>
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}> Internal Medicine </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          <span
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                          >
                            Lic. No. 85318
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default ECGPrintAll;
