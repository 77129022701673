import React, { Fragment, useContext, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import axios from "axios";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const ProcessToAddResult = ({
  selectedResult,
  getAllPatientAlreadyRead,
  close,
}) => {
  const { users } = useContext(UsersData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPrintCat, setSelectedPrintCat] = useState("");
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  const handleEditSaveResult = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("user_id", users.user_id);
    formdata.append("username", users.username);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("management_id", users.management_id);
    formdata.append("imaging_center_id", selectedResult.data.imaging_center_id);

    formdata.append("print_category", selectedPrintCat);
    formdata.append("template_name", selectedTemplateName);

    var error = [];
    if (
      formdata.get("new_result").length === 0 ||
      formdata.get("new_result").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new result");
    }

    if (
      formdata.get("new_impression").length === 0 ||
      formdata.get("new_impression").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("new impression");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      axios
        .post("imaging/save/new-flow/edited-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            getAllPatientAlreadyRead();
            close();
            e.target.reset();
            Notify.successRequest("edit result");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Fragment>
      <DialogTitle>Edit Result</DialogTitle>
      <DialogContent>
        {selectedResult.ready && (
          <form onSubmit={handleEditSaveResult}>
            <Box mb={2}>
              <TextField
                fullWidth
                required
                name="print_category"
                label="Print Category"
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setSelectedPrintCat(e.target.value)}
                value={selectedPrintCat}
              >
                <option value={""}>Select</option>
                <option value={"template"}>Template</option>
                <option value={"non-template"}>Non-Template</option>
              </TextField>
            </Box>

            {selectedPrintCat === "template" && (
              <>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    required
                    name="template_name"
                    label="Template Name"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setSelectedTemplateName(e.target.value)}
                    value={selectedTemplateName}
                  >
                    <option value={""}>Select</option>
                    <option value={"x-ray"}>X-RAY</option>
                    <option value={"neck-utz"}>NECK ULTRA SOUND</option>
                    <option value={"bps-utz"}>BPS ULTRA SOUND</option>
                    <option value={"kub-utz"}>KUB ULTRA SOUND</option>
                    <option value={"kub-prostate-utz"}>KUB + PROSTATE</option>
                    <option value={"tvs"}>TVS</option>
                    <option value={"tvs-utz"}>TVS ULTRA SOUND</option>
                    <option value={"preg-utz"}>PREG. EVAL. ULTRA SOUND</option>
                    <option value={"whole-abdomen-pelvis"}>
                      WHOLE ABDOMEN W/ PELVIS
                    </option>
                    <option value={"prostate-utz"}>PROSTATE ULTRASOUND</option>
                    <option value={"whole-abdomen"}>
                      WHOLE ABDOMEN ULTRASOUND
                    </option>
                  </TextField>
                </Box>

                {selectedTemplateName === "x-ray" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`Hazy densities are seen on the right base. The rest of the lungs are clear. The heart is not enlarged. The right hemidiaphragm is blunted. The left hemidiaphragm and costophrenic sulcus are intact. The rest of the included structures are unremarkable.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`~RIGHT BASAL PNEUMONIA`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "neck-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={12}
                        multiline
                        defaultValue={`Right lobe: cm(CC x W x AP)\nLeft lobe: cm\nIsthmus: \n\nThe thyroid gland has an intact external outline. It has a normal size and configuration. The thyroid parenchyma appears heterogenous with calcification in the left inferior pole measuring up to cm. Increase vascularity is noted in Doppler imaging.  There is no other  focal lesion.\n\nThere is no cervical lymphadenopathy.\nThe cervical vessels are unremarkable.\nThe skeletal muscles, subcutaneous tissues, and skin are intact.
                        `}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`~`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "bps-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`The placenta is located anterior to the right. It exhibits Grade III maturity echopattern. The amniotic fluid is adequate in amount. No gross fetal deformity noted. Gender is not yet seen. The stomach and urinary bladder are fluid-filled suggesting orogastric patency and functional kidneys, respectively. No other significant findings.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`> SINGLE, LIVE, INTRAUTERINE PREGNANCY IN CEPHALIC  PRESENTATION WITH AOG OF WEEKS AND DAYS ( +/- 2 WEEKS ) BASED ON BPD, AC, FL, AND HC.\n> BPS: 8/8`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "kub-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={6}
                        multiline
                        defaultValue={`The right kidney measures cm (LWT) with a cortical thickness of cm while the Left measures cm (LWT) with a cortical thickness of cm. Both show normal parenchymal orticomedullary echopattern. No mass or cysts noted in both kidneys. The collecting systems and proximal ureters are not dilated.\n\nThe urinary bladder is adequately distended with smooth walls. No unusual intraluminal echoes observed.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`ULTRASONICALLY NORMAL KIDNEYS AND URINARY BLADDER`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "kub-prostate-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={8}
                        multiline
                        defaultValue={`The right kidney measures cm (LWT) with a cortical thickness of cm while the Left measures cm (LWT) with a cortical thickness of cm. Both show normal parenchymal orticomedullary echopattern. No mass or cysts noted in both kidneys. The collecting systems and proximal ureters are not dilated.\n\nThe urinary bladder is adequately distended with smooth walls. No unusual intraluminal echoes observed.\n\nThe prostate gland is normal in size and measures cm with an approximate volume of grams. It exhibits a homogenous parenchymal echopattern. No hypoechoic and hyperechoic focus appreciated. External outline is smooth.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`ULTRASONICALLY NORMAL KIDNEYS, URINARY BLADDER AND PROSTATE`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "tvs" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={6}
                        multiline
                        defaultValue={`The uterus is in the midline and anteverted. It measures x x cm (Cervicofundal x W x T) with homogeneous parenchyma. The endometrial stripe is echogenic and measures cm.\nThe cervix is long and closed. It measures X cm (LW). No cervical mass is seen.\nThe right ovary measures X cm and the left ovary measures X cm. Multiple cystic foci are noted within the right ovary.\nNo abnormal fluid collection is noted in the cul-de-sacs.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`> SONOGRAPHICALLY NORMAL UTERUS  WITH NON SPECIFIC, NON THICKENED ENDOMETRIUM.\n> SONOGRAPHICALLY NORMAL CERVIX  AND LEFT  OVARY`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "tvs-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`The placenta is wrapped around. It exhibits an early Grade 0 maturity echopattern. The amniotic fluid is adequate in amount.No subchorionic bleed. No other significant findings.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`EARLY, INTRAUTERINE PREGNANCY WITH AOG 4 WEEKS AND 6 DAYs ( +/- 1 WEEK )  BASED ON GS. FOLLOW-UP CAN SUGGESTED AFTER 2 WEEKS.`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "preg-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`The placenta is located posteriorly. It exhibits Grade II maturity echopattern. The amniotic fluid is adequate in amount. No gross fetal deformity noted. Gender is female. The stomach and urinary bladder are fluid-filled suggesting orogastric patency and functional kidneys, respectively. No other significant findings.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`> SINGLE, LIVE, INTRAUTERINE PREGNANCY IN CEPHALIC PRESENTATION WITH AOG OF WEEKS AND DAYS ( +/- 2 WEEKS ) BASED ON BPD, AC, FL, AND HC.`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "whole-abdomen-pelvis" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={16}
                        multiline
                        defaultValue={`The liver is normal in size with homogenous parenchyma. A No focal mass nor calcifications noted. The outline is smooth. The intrahepatic and  extrahepatic ducts are not dilated.\n\nGallbladder is adequately distended and shows non-thickened walls. No intraluminal echoes seen. CBD = 0.2 cm in caliber.\n\nThe pancreas and spleen are unremarkable. Both  show homogenous parenchymal echopattern and regular external outline. No focal nor diffuse lesions.\n\nThe right  kidney measures cm (LWT) with a cortical thickness of cm while the  Left measures cm (LWT) with a cortical thickness of cm. Both show normal parenchymal orticomedullary echopattern. No mass or cysts noted in both kidneys. The collecting systems and proximal ureters are not dilated.\n\nThe urinary bladder is adequately distended with smooth walls. No unusual intraluminal echoes observed.\n\nThe uterus is normal in size with no definite focal mass lesion. Parenchyma is homogeneous. External outline is smooth.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`ULTRASONICALLY NORMAL LIVER, BILIARY TREE, GALLBLADDER, PANCREAS, SPLEEN, KIDNEYS, URINARY BLADDER AND UTERUS.`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "prostate-utz" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`The prostate gland is normal in size and  measures cm  with an  approximate volume of grams. It exhibits a homogenous parenchymal echopattern. No hypoechoic and hyperechoic focus appreciated. External outline is smooth.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`ULTRASONICALLY NORMAL PROSTATE GLAND.`}
                      />
                    </Box>
                  </>
                )}

                {selectedTemplateName === "whole-abdomen" && (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="new_result"
                        label="New Result"
                        variant="outlined"
                        fullWidth
                        rows={18}
                        multiline
                        defaultValue={`The liver is normal in size with homogenous parenchyma. A No focal mass nor calcifications noted. The outline is smooth. The intrahepatic and extrahepatic ducts are not dilated.\n\nGallbladder is adequately distended and shows non-thickened walls. No intraluminal echoes seen. CBD = 0.2 cm in caliber.\n\nThe pancreas and spleen are unremarkable. Both show homogenous parenchymal echopattern and regular external outline. No focal nor diffuse lesions.\n\nThe right kidney measures cm (LWT) with a cortical thickness of cm while the Left measures cm (LWT) with a cortical thickness of cm. Both show normal parenchymal orticomedullary echopattern. No mass or cysts noted in both kidneys. The collecting systems and proximal ureters are not dilated.\n\nThe urinary bladder is adequately distended with smooth walls. No unusual intraluminal echoes observed.\n\nThe prostate gland is normal in size and measures cm with an approximate volume of grams. It exhibits a homogenous parenchymal echopattern. No hypoechoic and hyperechoic focus appreciated. External outline is smooth.`}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="new_impression"
                        label="New Impression"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        multiline
                        defaultValue={`ULTRASONICALLY NORMAL LIVER, BILIARY TREE, GALLBLADDER, PANCREAS, SPLEEN, KIDNEYS, URINARY BLADDER AND PROSTATE.`}
                      />
                    </Box>
                  </>
                )}
              </>
            )}

            {selectedPrintCat === "non-template" && (
              <>
                <Box mb={2}>
                  <TextField
                    name="new_result"
                    label="New Result"
                    variant="outlined"
                    fullWidth
                    rows={4}
                    multiline
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    name="new_impression"
                    label="New Impression"
                    variant="outlined"
                    fullWidth
                    rows={4}
                    multiline
                  />
                </Box>
              </>
            )}

            <Box mb={3}>
              <TextField
                type="password"
                required
                name="password"
                label="Password"
                autoComplete="off"
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                startIcon={<ClearIcon />}
                onClick={() => close()}
              >
                Cancel
              </Button>

              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </Fragment>
  );
};

export default ProcessToAddResult;
