// import axios from "axios";
import React, {
  Fragment,
  // useContext, useEffect, useState
} from "react";
// import { UsersData } from "src/ContextAPI";
// import Notify from "src/notification/Notify";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { Divider } from "@material-ui/core";
import HeaderPrintAll from "../printall/HeaderPrintAll";
import PatientInfoPrintAll from "../printall/PatientInfoPrintAll";

const MedicalExamPrintAll = ({
  allowHeaderPatientInfo,
  patient_id,
  trace_number,
  patients,
  medicalExamOrderDetails,
}) => {
  // const { users } = useContext(UsersData);

  // const [medicalExamOrderDetails, setMedicalExamOrderDetails] = useState({
  //   data: [],
  //   ready: false,
  // });

  // const getMedicalExamOrderDetails = () => {
  //   var params = {
  //     user_id: users.user_id,
  //     trace_number: trace_number,
  //   };
  //   axios
  //     .get("laboratory/order/ordernew-medicalexam/complete/details-print", {
  //       params,
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       setMedicalExamOrderDetails({ data: data, ready: true });
  //     })
  //     .catch((error) => {
  //       Notify.requestError(error);
  //     });
  // };

  // useEffect(() => {
  //   getMedicalExamOrderDetails();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      {medicalExamOrderDetails.ready &&
        medicalExamOrderDetails.data.length > 0 && (
          <Fragment>
            {allowHeaderPatientInfo !== "notallowed" && (
              <>
                <HeaderPrintAll />
                <PatientInfoPrintAll details={patients} />
              </>
            )}
            <Box>
              <Typography
                variant="h6"
                align="center"
                style={{
                  fontWeight: "bolder",
                  color: "#000",
                  textDecoration: "underline",
                }}
              >
                <span
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                >
                  MEDICAL EXAMINATION REPORT
                </span>
              </Typography>
            </Box>

            <Box my={2}>
              {medicalExamOrderDetails.data.map((data, index) => (
                <Box key={index}>
                  <Box display="flex" alignItems={"center"}>
                    <Box>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Nature of Examimination:
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.nature_of_exam.includes("Pre-employment") ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Pre-employment
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.nature_of_exam.includes("Annual PE") ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Annual PE
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {!data.nature_of_exam.includes("Annual PE") &&
                      !data.nature_of_exam.includes("Pre-employment") ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Other
                    </Box>
                    {data.nature_of_exam &&
                      !data.nature_of_exam.includes("Pre-employment") &&
                      !data.nature_of_exam.includes("Annual PE") && (
                        <Box mt={1}>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            <u> {data.nature_of_exam} </u>
                          </Typography>
                        </Box>
                      )}
                  </Box>

                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      I. MEDICAL HISTORY
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        A.Past Medical History:
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.pmh !== null ? (
                        data.pmh.includes("hypertension") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Hypertension
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.pmh !== null ? (
                        data.pmh.includes("diabetes_melitus") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Diabetes Mellitus
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.pmh !== null ? (
                        data.pmh.includes("ptb") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      PTB
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.pmh !== null ? (
                        data.pmh.includes("others") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Others
                    </Box>
                    {data.pmh !== null && data.pmh.includes("others") && (
                      <Box mt={1.2}>
                        <Typography
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "bolder",
                            color: "#000",
                          }}
                        >
                          <u> {data.pmh_other_specify} </u>
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        B.Family History:
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("hypertension") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Hypertension
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("asthma") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Asthma
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("cancers") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Cancers
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("cardiac_disease") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Cardiac Disease
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("ptb") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      PTB
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("hyperthyroidism") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Hyperthyroidism
                    </Box>
                    <Box display="flex" alignItems={"center"} mx={2}>
                      {data.fam_history !== null ? (
                        data.fam_history.includes("others") ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      Others
                    </Box>
                    {data.fam_history !== null &&
                      data.fam_history.includes("others") && (
                        <Box mt={1.2}>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "bolder",
                              color: "#000",
                            }}
                          >
                            <u> {data.fam_history_other_specify} </u>
                          </Typography>
                        </Box>
                      )}
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        C.Previous Operation/Hospitalization:{" "}
                        {data.prev_operation}
                      </span>
                    </Box>
                  </Box>

                  <Box ml={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems={"center"}>
                          <Box mt={0.5}>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              D.Personal History:
                            </span>
                          </Box>

                          <Box display="flex" alignItems={"center"} mx={2}>
                            {data.personal_history_smoke === "yes" ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Smoking
                          </Box>

                          <Box
                            display="flex"
                            alignItems={"center"}
                            mx={2}
                            mt={0.5}
                          >
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Quantity/day: {data.personal_history_smoke_qty}
                            </span>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems={"center"}>
                          <Box mt={0.5}>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              # of yrs.: {data.personal_history_smoke_no_year}
                            </span>
                          </Box>
                          <Box display="flex" alignItems={"center"} mx={2}>
                            {data.personal_history_alchohol === "yes" ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Alcohol
                          </Box>
                          <Box display="flex" alignItems={"center"} mx={2}>
                            {data.personal_history_drug === "yes" ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                            Drug Abuse
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        E.Allergies:
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Food: {data.allergy_foods}
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Drugs: {data.allergy_drugs}
                      </span>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        F.Menstrual/Obstetrical History:
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        LMP: {data.menstrual_hist_lmp}
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        PMP: {data.menstrual_hist_pmp}
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        G: {data.menstrual_hist_g}
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        P: {data.menstrual_hist_p}
                      </span>
                    </Box>

                    <Box display="flex" alignItems={"center"} mx={2} mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        Other: {data.menstrual_hist_other}
                      </span>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box mt={0.5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        G.Medications: {data.medication}
                      </span>
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      II. PHYSICAL EXAMINATION
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={2}>
                    <Box mx={5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        BP: {data.pe_bp}
                      </span>
                    </Box>

                    <Box mx={5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        PR: {data.pe_pr}
                      </span>
                    </Box>

                    <Box mx={5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        HT: {data.pe_ht}
                      </span>
                    </Box>

                    <Box mx={5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        WT: {data.pe_wt}
                      </span>
                    </Box>

                    <Box mx={5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        BMI: {data.pe_bmi}
                      </span>
                    </Box>

                    <Box mx={5}>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        RANGE: {data.pe_range}
                      </span>
                    </Box>
                  </Box>

                  <Box ml={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box alignItems={"center"}>
                          <Box mt={0.5}>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              A.VISUAL AQUITY:
                            </span>
                          </Box>
                          <Box>
                            <TableContainer>
                              <Table size="small" className="table-bordered">
                                <TableHead>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">FAR</TableCell>
                                    <TableCell align="center">NEAR</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>OD</TableCell>
                                    <TableCell>
                                      {data.visual_acuity_od_far}
                                    </TableCell>
                                    <TableCell>
                                      {data.visual_acuity_od_near}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>OS</TableCell>
                                    <TableCell>
                                      {data.visual_acuity_os_far}
                                    </TableCell>
                                    <TableCell>
                                      {data.visual_acuity_os_near}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box alignItems={"center"}>
                          <Box mt={0.5}>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              B.DENTAL SURVEY:
                            </span>
                          </Box>
                          <Box mt={1.5}>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              UPPER: &nbsp;8 &nbsp; 7 &nbsp; 6 &nbsp; 5 &nbsp; 4
                              &nbsp; 3 &nbsp; 2 &nbsp; 1 &nbsp; &nbsp; 1 &nbsp;
                              2 &nbsp; 3 &nbsp; 4 &nbsp; 5 &nbsp; 6 &nbsp; 7
                              &nbsp; 8
                            </Typography>
                          </Box>

                          <Box mt={1.5}>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              LOWER: &nbsp;8 &nbsp; 7 &nbsp; 6 &nbsp; 5 &nbsp; 4
                              &nbsp; 3 &nbsp; 2 &nbsp; 1 &nbsp; &nbsp; 1 &nbsp;
                              2 &nbsp; 3 &nbsp; 4 &nbsp; 5 &nbsp; 6 &nbsp; 7
                              &nbsp; 8
                            </Typography>
                          </Box>

                          <Box mt={1.5}>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              SUGGEST: (&nbsp;&nbsp;&nbsp;&nbsp;) PROPHY:
                              (&nbsp;&nbsp;&nbsp;&nbsp;) EXO:
                              (&nbsp;&nbsp;&nbsp;&nbsp;) RESTO, INDICATE NO:
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display={"flex"} alignItems={"center"}>
                          <Box mt={0.5}>
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              C.HEARING:
                            </span>
                          </Box>
                          <Box
                            display="flex"
                            alignItems={"center"}
                            mx={2}
                            mt={0.5}
                          >
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              AD: {data.pe_hearing_ad}
                            </span>
                          </Box>

                          <Box
                            display="flex"
                            alignItems={"center"}
                            mx={2}
                            mt={0.5}
                          >
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              AS: {data.pe_hearing_as}
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <TableContainer>
                            <Table size="small" className="table-bordered">
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell align="center">
                                    <strong>NORMAL</strong>
                                  </TableCell>
                                  <TableCell align="center">
                                    <strong>ABNORMAL</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Skin</TableCell>
                                  <TableCell align="center">
                                    {data.pe_skin === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_skin === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>HEENT</TableCell>
                                  <TableCell align="center">
                                    {data.pe_heent === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_heent === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Neck</TableCell>
                                  <TableCell align="center">
                                    {data.pe_neck === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_neck === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Chest/Lungs</TableCell>
                                  <TableCell align="center">
                                    {data.pe_chest === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_chest === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Cardiovascular</TableCell>
                                  <TableCell align="center">
                                    {data.pe_cardio === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_cardio === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Abdomen</TableCell>
                                  <TableCell align="center">
                                    {data.pe_abdomen === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_abdomen === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Genitourinary Tract</TableCell>
                                  <TableCell align="center">
                                    {data.pe_genitourinary === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_genitourinary === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Genitalia</TableCell>
                                  <TableCell align="center">
                                    {data.pe_genitalia === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_genitalia === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Inguinal</TableCell>
                                  <TableCell align="center">
                                    {data.pe_linguinal === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_linguinal === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Extremities</TableCell>
                                  <TableCell align="center">
                                    {data.pe_extremities === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_extremities === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Reflexes</TableCell>
                                  <TableCell align="center">
                                    {data.pe_reflexes === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_reflexes === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Neurology</TableCell>
                                  <TableCell align="center">
                                    {data.pe_neurology === "normal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.pe_neurology === "abnormal" ? (
                                      <CheckBox />
                                    ) : (
                                      <CheckBoxOutlineBlank />
                                    )}
                                  </TableCell>
                                </TableRow>
                                {/* <TableRow>
                                <TableCell colSpan={3}>Others:</TableCell>
                              </TableRow> */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bolder", color: "#000" }}
                    >
                      III. CLASSIFICATION:
                    </Typography>
                  </Box>

                  <Box ml={2}>
                    <Box>
                      {data.pe_skin === "normal" ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        A:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Physically fit to work.
                      </span>
                    </Box>
                    <Box>
                      {data.pe_skin === "normal" ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        B:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Physically to work with
                        findings but offers no handicap to the job applied.
                      </span>
                    </Box>
                    <Box>
                      {data.pe_skin === "normal" ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        C:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With abnormal findings
                        (employment upon discretion of employer)
                      </span>
                    </Box>
                    <Box>
                      {data.pe_skin === "normal" ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        D:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unfit for any type of
                        employment
                      </span>
                    </Box>
                    <Box>
                      {data.pe_skin === "normal" ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Classification
                        pending due to:
                      </span>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        IMPRESSION/DIAGNOSIS: {data.impression}
                      </span>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Box>
                      <span style={{ fontWeight: "bolder", color: "#000" }}>
                        RECOMMENDATIONS: {data.recommendations}
                      </span>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems={"center"} ml={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Box>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            EXAMINING PHYSICIAN:
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box align="center">
                          ______________________________________________M.D.
                        </Box>
                        <Box align="center">
                          License No._______________________________________
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Box align="center">
                            __________________________________________________
                          </Box>
                          <Box align="center">Patient's Signature</Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Box>
            <Divider light />
          </Fragment>
        )}
    </>
  );
};

export default MedicalExamPrintAll;
