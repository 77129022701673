import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
// import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import TablePagination from "@material-ui/core/TablePagination";
import { UsersData } from "src/ContextAPI";
import PerfectScrollbar from "react-perfect-scrollbar";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Print from "@material-ui/icons/Print";
import BMCDCRXDoc from "src/utils/Rx/BMCDCRXDoc";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";

function NSRXPrescriptionList({ patient_id, connection }) {
  const { users } = useContext(UsersData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedid, setselectedid] = useState(null);
  const [prescription, setPrescription] = useState({ data: [], ready: false });
  const [prescriptionDetails, setPrescriptionDetails] = useState({
    data: [],
    ready: false,
  });

  const [selectedPrescription, setSelectedPrescription] = useState({
    data: [],
    open: false,
  });

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPrescription = useCallback(async () => {
    var formdata = new FormData();
    formdata.set("user_id", users.user_id);
    formdata.set("patient_id", patient_id);

    await Axios.post("nurse/get/local/prescriptionlist", formdata)
      .then((response) => {
        const data = response.data;
        setPrescription({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users, patient_id]);

  const handleSelectedPresc = (e) => {
    var claim_id = e.currentTarget.id;
    var doctors_id = e.currentTarget.getAttribute("doctors_id");

    if (claim_id === selectedid) {
      setselectedid(null);
    } else {
      var formdata = new FormData();
      formdata.set("user_id", doctors_id);
      formdata.set("patient_id", patient_id);
      formdata.set("claim_id", claim_id);

      Axios.post("nurse/get/local/prescriptiondetailslist", formdata)
        .then((response) => {
          const data = response.data;
          setselectedid(claim_id);
          setPrescriptionDetails({ data, ready: true });
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  useEffect(() => {
    getPrescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPrescription]);

  return (
    <Fragment>
      <Grid item xs={12} sm={12}>
        <Box elevation={2}>
          <Box display="flex" mb={2}>
            <Box flexGrow={1}>
              <Typography variant="h6" color="textSecondary">
                <strong>Prescription List</strong>
              </Typography>
            </Box>
          </Box>
          <TableContainer>
            <PerfectScrollbar>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <b> Date </b>
                    </TableCell>
                    <TableCell>
                      <b> Claim ID </b>
                    </TableCell>
                    <TableCell>
                      <b> Type </b>
                    </TableCell>
                    <TableCell>
                      <b> Action </b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {prescription.ready ? (
                    prescription.data.length > 0 ? (
                      (rowsPerPage > 0
                        ? prescription.data.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : prescription.data
                      ).map((data, index) => (
                        <Fragment key={index}>
                          <TableRow
                            className={
                              selectedid === data.claim_id ? "gtc-bg-light" : ""
                            }
                            key={index}
                          >
                            <TableCell>
                              <IconButton
                                color="primary"
                                size="small"
                                doctors_id={data.doctors_id}
                                id={data.claim_id}
                                onClick={handleSelectedPresc}
                                disabled={selectedid === data.claim_id}
                              >
                                {selectedid === data.claim_id ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              {Notify.dateTimeConvert(data.created_at)}
                            </TableCell>
                            <TableCell>
                              {data.claim_id.replace("claim-", "")}
                            </TableCell>
                            <TableCell>{data.prescription_type}</TableCell>
                            <TableCell>
                              <IconButton
                                claimid={data.claim_id}
                                doctorsid={data.doctors_id}
                                patientid={data.patients_id}
                                order_from={
                                  data.prescription_type === "virtual"
                                    ? "online"
                                    : "local"
                                }
                                // onClick={handlePrintableRx}
                                onClick={() =>
                                  setSelectedPrescription({ data, open: true })
                                }
                                color="primary"
                              >
                                <Print fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>

                          {/* collapse */}
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={5}
                            >
                              <Collapse
                                in={selectedid === data.claim_id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box m={1}>
                                  <Typography variant="caption" color="primary">
                                    PRESCRIPTION DETAILS
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <b> Date</b>
                                        </TableCell>
                                        <TableCell>
                                          <b> Prescription</b>
                                        </TableCell>
                                        <TableCell>
                                          <b> Type</b>
                                        </TableCell>
                                        {/* <TableCell>
                                          <b> Dosage</b>
                                        </TableCell> */}
                                        <TableCell>
                                          <b> Qty</b>
                                        </TableCell>
                                        <TableCell>
                                          <b> Sig</b>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {prescriptionDetails.ready ? (
                                        prescriptionDetails.data.length > 0 ? (
                                          prescriptionDetails.data.map(
                                            (presc, vindex) => {
                                              return (
                                                <TableRow key={vindex}>
                                                  <TableCell>
                                                    {Notify.dateTimeConvert(
                                                      presc.created_at
                                                    )}
                                                  </TableCell>
                                                  <TableCell>
                                                    {presc.product_name}
                                                  </TableCell>
                                                  <TableCell>
                                                    {presc.type}
                                                  </TableCell>
                                                  {/* <TableCell>
                                                    {presc.dosage}
                                                  </TableCell> */}
                                                  <TableCell align="right">
                                                    {presc.quantity}
                                                  </TableCell>
                                                  <TableCell>
                                                    {presc.remarks}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            }
                                          )
                                        ) : (
                                          <TableRow>
                                            <TableCell colSpan={5}>
                                              <Typography
                                                color="secondary"
                                                align="center"
                                              >
                                                No record found.
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={5}>
                                            <Typography
                                              color="primary"
                                              align="center"
                                            >
                                              Please wait...
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Typography color="secondary" align="center">
                            No prescription record.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color="primary" align="center">
                          Please wait...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                labelRowsPerPage="List"
                rowsPerPageOptions={[5, 20, 50, 100]}
                component="div"
                count={prescription.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </PerfectScrollbar>
          </TableContainer>
        </Box>

        <Dialog
          open={selectedPrescription.open}
          onClose={() => setSelectedPrescription({ data: [], open: false })}
          fullScreen
          TransitionComponent={Zoom}
          transitionDuration={800}
        >
          <BMCDCRXDoc
            selectedPrescription={selectedPrescription}
            close={() => setSelectedPrescription({ data: [], open: false })}
          />
        </Dialog>
      </Grid>
    </Fragment>
  );
}

export default NSRXPrescriptionList;
