import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  Fab,
  createStyles,
} from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";
import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import { UsersData } from "src/ContextAPI";
import Notify from "src/notification/Notify";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const XRayPrint = ({ formheader, selectedImagingId, close }) => {
  const { users } = useContext(UsersData);
  const [printResult, setPrintResult] = useState({ data: [], ready: false });
  const classes = useStyles();

  const getAllPrintOrder = useCallback(async () => {
    var params = {
      managament_id: users.managament_id,
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      imaging_center_id: selectedImagingId,
    };
    try {
      let response = await axios.get("imaging/get/print-order", { params });
      const data = response.data;
      setPrintResult({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  }, [users, selectedImagingId]);

  useEffect(() => {
    getAllPrintOrder();
  }, [selectedImagingId, getAllPrintOrder]);

  return (
    <>
      <Fragment>
        {printResult.ready ? (
          printResult.data && (
            <Fragment>
              <Card elevation={0}>
                <Box display="flex" justifyContent="center">
                  <Box mt={2}>
                    {formheader && (
                      <CardMedia
                        style={{ width: 70 }}
                        component={"img"}
                        src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                      />
                    )}
                  </Box>
                  <Box>
                    <CardHeader
                      component={Box}
                      align="center"
                      title={
                        <Typography variant="h5">
                          {formheader && formheader.name}
                        </Typography>
                      }
                      subheader={
                        <Box>
                          <Typography variant="h5">
                            {formheader && formheader.address}
                          </Typography>
                          <Typography variant="h5">
                            {formheader && formheader.contact_number}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>

                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box>
                        <Box>
                          <Typography className="font-weight-bold" variant="h5">
                            Name:{" "}
                            {`${printResult.data.lastname}, ${
                              printResult.data.firstname
                            } ${
                              printResult.data.middle === null
                                ? ""
                                : printResult.data.middle
                            }`}
                          </Typography>
                        </Box>

                        <Box display="flex">
                          <Box>
                            <Typography
                              className="font-weight-bold"
                              variant="h5"
                            >
                              Age:{" "}
                              {printResult.data.birthday === null
                                ? "None"
                                : Notify.calculateAge(
                                    printResult.data.birthday
                                  )}
                            </Typography>
                          </Box>
                          <Box ml={5}>
                            <Typography
                              className="font-weight-bold"
                              variant="h5"
                            >
                              Sex:{" "}
                              {printResult.data.gender === null
                                ? "None"
                                : printResult.data.gender}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography className="font-weight-bold" variant="h5">
                            Examination: {printResult.data.imaging_order}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography className="font-weight-bold" variant="h5">
                            Referring Physician:{" "}
                            {printResult.data.referring_physician}
                          </Typography>
                        </Box>

                        {printResult.data.imaging_type === "xray" ? (
                          <>
                            <Box>
                              <Typography
                                className="font-weight-bold"
                                variant="h5"
                              >
                                Clinical Data: {printResult.data.clinical_data}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                className="font-weight-bold"
                                variant="h5"
                              >
                                File Number: {printResult.data.file_no}
                              </Typography>
                            </Box>
                          </>
                        ) : null}

                        <Box>
                          <Typography className="font-weight-bold" variant="h5">
                            Date:{" "}
                            {Notify.createdAt(printResult.data.created_at)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center">
                        <Box flexGrow={1} />
                        <Box>
                          {printResult.data.image !== null ? (
                            <CardMedia
                              style={{ width: 120 }}
                              component={"img"}
                              src={`${imageLocation}patients/${printResult.data.image}`}
                            />
                          ) : (
                            <CardMedia
                              style={{ width: 110 }}
                              component={"img"}
                              src={`${imageLocation}doctor/no-image.jpg`}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={3}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            style={{
                              fontWeight: "bolder",
                              textDecoration: "underline",
                            }}
                          >
                            <span
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                            >
                              {printResult.data.imaging_type === "xray"
                                ? "ROENTGENOLOGIC INTERPRETATION"
                                : "SONOGRAPHIC REPORT"}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mt={5}>
                        <Typography
                          variant="h5"
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                          }}
                        >
                          {printResult.data.imaging_result}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mt={5}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "bolder" }}
                        >
                          IMPRESSION:
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          style={{
                            whiteSpace: "pre-line",
                            fontWeight: "bolder",
                          }}
                        >
                          {printResult.data.imaging_results_remarks}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box my={4}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item xs={4}>
                        <Box align="center">
                          <Box mt={10}>
                            <Typography
                              className={`gtc-uppercase`}
                              variant="h5"
                            >
                              <span
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                              >
                                {/* {formheader && formheader.radiologist} */}
                                {printResult.data.radiologistName !== null
                                  ? printResult.data.radiologistName
                                  : ""}
                              </span>
                            </Typography>
                            <Box borderTop={1}>
                              <Typography variant="h5">
                                <b> RADIOLOGIST </b>
                              </Typography>
                            </Box>
                            {formheader && (
                              <Box>
                                <Typography
                                  className={`gtc-uppercase`}
                                  variant="h5"
                                >
                                  {formheader.radiologist_lcn !== null
                                    ? formheader.radiologist_lcn
                                    : ""}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="d-print-none">
                    <Fab
                      aria-label={"Print"}
                      className={classes.fab2}
                      color={"secondary"}
                      onClick={() => close()}
                    >
                      <Close />
                    </Fab>
                    <Fab
                      aria-label={"Print"}
                      className={classes.fab}
                      color={"primary"}
                      onClick={() => window.print()}
                    >
                      <Print />
                    </Fab>
                  </Box>
                </CardContent>
              </Card>
              <Box className="d-print-none"></Box>
            </Fragment>
          )
        ) : (
          <Box
            display="flex"
            height={250}
            justifyContent="center"
            alignItems="center"
          >
            {Notify.loading()}
          </Box>
        )}
      </Fragment>
    </>
  );
};

export default XRayPrint;

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: "calc(100vw - 50px)",
      height: "calc(100vh - 90px)",
      justifyContent: "center",
      alignItems: "center",
    },
    idprint: {
      display: "block",
      width: "234px",
      height: "auto",
    },
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },

    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    picturepatient: {
      width: 110,
      height: 110,
    },
    thumbnail: {
      height: 106,
    },
    infopatient: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    [`@media print`]: {
      body: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
      },
      idprint: {
        width: 275,
        height: 410,
      },
      titleCity: {
        fontSize: 30,
        fontWeight: "bolder",
      },
      qrSize: {
        width: 150,
        height: 150,
      },
      picturepatient: {
        width: 120,
        height: 120,
      },
      thumbnail: {
        height: 116,
      },
      infopatient: {
        padding: 0,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      infopatient2: {
        fontSize: 11,
      },
    },
  })
);
