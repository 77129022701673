import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  // Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
  CardMedia,
  CardHeader,
  Grid,
  makeStyles,
  Fab,
  createStyles,
  // Collapse,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Notify from "src/notification/Notify";
import axios from "axios";
import { UsersData } from "src/ContextAPI";
// import { Fragment } from "react";
import { Close, Print } from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const CashierSOAListPrint = () => {
  const { soa_id } = useParams();
  const { users } = React.useContext(UsersData);
  const [details, setDetails] = useState({ data: [], ready: false });
  const history = useHistory();
  const classes = useStyles();
  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  let totalorder = 0;
  // let totaldiscount = 0;

  const calculateOrder = (amount) => {
    totalorder += parseFloat(amount !== null ? amount : 0);
    return Notify.convertToNumber(amount !== null ? amount : 0);
  };

  // const calculateOrderDiscount = (amount) => {
  //   totaldiscount += parseFloat(amount);
  //   return Notify.convertToNumber(amount);
  // };

  const getSOADetails = useCallback(() => {
    var params = {
      user_id: users.user_id,
      management_id: users.management_id,
      soa_id: soa_id,
    };
    axios
      .get("cashier/get/soa/details-by-id", { params })
      .then((response) => {
        const data = response.data;
        setDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [users.management_id, users.user_id, soa_id]);

  const getLabFormHeader = () => {
    var params = { management_id: users.management_id };
    axios
      .get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getSOADetails();
    // getRecieptHeaderInformation();
    getLabFormHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSOADetails]);

  return (
    <Dialog
      open={true}
      TransitionComponent={Zoom}
      transitionDuration={500}
      fullScreen
      onClose={() => console.log("dialog cannot be close")}
    >
      <DialogContent>
        <Box m={2}>
          <Box display="flex" justifyContent="center">
            <Box mt={2}>
              {formHeader.ready
                ? formHeader.data && (
                    <CardMedia
                      style={{ width: 70 }}
                      component={"img"}
                      src={`${imageLocation}laboratory/logo/${formHeader.data.logo}`}
                    />
                  )
                : null}
            </Box>
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={formHeader.data && formHeader.data.name}
                subheader={
                  <Box>
                    <Typography>
                      {formHeader.data && formHeader.data.address}
                    </Typography>
                    <Typography>
                      {formHeader.data && formHeader.data.contact_number}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          {/* //here// */}
          <Box>
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={
                  <Box>
                    <Typography variant={"h6"} style={{ fontWeight: "bolder" }}>
                      STATEMENT OF ACCOUNT
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        THE MANAGER
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: "bolder" }}>
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 &&
                            details.data[0].branch_name}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display={"flex"}>
                      <Box flexGrow={1} />
                      <Typography style={{ fontWeight: "bolder" }}>
                        Statement No:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {details.data.length > 0 && details.data[0].soa_id}
                        </span>
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Box flexGrow={1} />
                      <Typography style={{ fontWeight: "bolder" }}>
                        Statement Date:{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {new Date().toLocaleString()}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> Date </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Control No. </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Name </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Amount </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.ready ? (
                  details.data.length > 0 ? (
                    details.data.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {Notify.createdAt(data.created_at)}
                        </TableCell>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell>
                          {data.lname}, {data.fname}
                          <br />
                          <span style={{ marginLeft: 5 }}>
                            <b>&bull;{data.bill_name}</b>
                          </span>
                          <span hidden>{calculateOrder(data.bill_amount)}</span>
                        </TableCell>
                        <TableCell align="right">
                          {Notify.convertToNumber(data.bill_amount)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No record
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Loading, Please wait...
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell colSpan={3} align="right">
                    <b>GRAND TOTAL</b>
                  </TableCell>
                  <TableCell align="right">
                    {Notify.convertToNumber(totalorder)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box my={2} mt={5}>
            <Box display="flex">
              <Box flexGrow={1} width={"50%"}>
                <Typography
                  suppressContentEditableWarning={true}
                  contentEditable={true}
                >
                  <b>Prepared by:</b>
                </Typography>
                <Box align="center">
                  {/* {Boolean(formInfo)
                    ? formInfo.prepared_by !== null
                      ? formInfo.prepared_by
                      : ""
                    : ""} */}
                </Box>
                <Box align="center">
                  <Box style={{ borderTop: "1px solid #888" }} maxWidth={350}>
                    <Typography
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                    >
                      <b>Cashier</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box width={"50%"}>
                <Typography
                  suppressContentEditableWarning={true}
                  contentEditable={true}
                >
                  <b>Verified by:</b>
                </Typography>
                <Box align="center">
                  {/* {Boolean(formInfo)
                    ? formInfo.verified_by !== null
                      ? formInfo.verified_by
                      : ""
                    : ""} */}
                </Box>
                <Box align="center">
                  <Box style={{ borderTop: "1px solid #888" }} maxWidth={350}>
                    <Typography
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                    >
                      <b>Accounting In-Charge:</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box display="flex">
              <Box flexGrow={1} width={"50%"}>
                <Typography
                  suppressContentEditableWarning={true}
                  contentEditable={true}
                >
                  <b>Checked by:</b>
                </Typography>
                <Box align="center">
                  {/* {Boolean(formInfo)
                    ? formInfo.checked_by !== null
                      ? formInfo.checked_by
                      : ""
                    : ""} */}
                </Box>
                <Box align="center">
                  <Box style={{ borderTop: "1px solid #888" }} maxWidth={350}>
                    <Typography
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                    >
                      <b>Clinic Coordinator</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box width={"50%"}>
                <Typography
                  suppressContentEditableWarning={true}
                  contentEditable={true}
                >
                  <b>Approved by:</b>
                </Typography>
                <Box align="center">
                  {/* {Boolean(formInfo)
                    ? formInfo.noted_by !== null
                      ? formInfo.noted_by
                      : ""
                    : ""} */}
                </Box>
                <Box align="center">
                  <Box style={{ borderTop: "1px solid #888" }} maxWidth={350}>
                    <Typography
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                    >
                      <b>Operation Manager</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* //here// */}
        </Box>
      </DialogContent>
      <Box className="d-print-none">
        <Fab
          aria-label={"Print"}
          className={classes.fab2}
          color={"secondary"}
          onClick={() => history.push("/bmcdc/app/cashier/report")}
        >
          <Close />
        </Fab>
        <Fab
          aria-label={"Print"}
          className={classes.fab}
          color={"primary"}
          onClick={() => window.print()}
        >
          <Print />
        </Fab>
      </Box>
    </Dialog>
  );
};

export default CashierSOAListPrint;

const useStyles = makeStyles((theme) =>
  createStyles({
    fab2: {
      position: "absolute",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);
