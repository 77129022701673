import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import { UsersData } from "src/ContextAPI";
import { XCircle } from "react-feather";
import HeaderPrintAll from "./printall/HeaderPrintAll";
import PatientInfoPrintAll from "./printall/PatientInfoPrintAll";
import PrintIcon from "@material-ui/icons/Print";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  profile: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    maxWidth: theme.spacing(20),
    maxHeight: theme.spacing(20),
    // border: `1px solid ${grey[200]}`,
  },
}));

const PrintMobileVanOrdersImaging = ({
  close,
  selectedTraceNoData,
  handleSearchPatient,
  selectedBranch,
}) => {
  const classes = useStyles();
  const { users } = React.useContext(UsersData);
  const [print, setPrint] = useState("");
  const [printProcess, setPrintProcess] = useState(false);

  const [imagingOrderDetails, setImagingOrderDetails] = useState({
    data: [],
    ready: false,
  });

  const [patients, setPatients] = useState({
    data: [],
    ready: false,
  });

  const [formHeader, setFormHeader] = useState({
    data: [],
    ready: false,
  });

  const getImagingOrderDetails = () => {
    var params = {
      user_id: users.user_id,
      main_mgmt_id: users.main_mgmt_id,
      trace_number: selectedTraceNoData.trace_number,
    };
    Axios.get("imaging/get/order/ordernew-imaging/complete/details-print", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setImagingOrderDetails({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getLabFormHeader = () => {
    var params = { management_id: selectedBranch };
    Axios.get("laboratory/order/formheader-details", { params })
      .then((response) => {
        const data = response.data;
        setFormHeader(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getPatientInformation = () => {
    var params = { patient_id: selectedTraceNoData.patient_id };
    Axios.get("documentation/get/patient-info/patient-id", { params })
      .then((response) => {
        const data = response.data;
        setPatients({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getLabFormHeader();
    getImagingOrderDetails();
    getPatientInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTraceNoData]);

  const handlePrint = (e) => {
    setPrint(e.currentTarget.id);
    setPrintProcess(true);
    setTimeout(() => {
      setPrintProcess(false);
      window.print();
    }, 4000);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        disableBackdropClick
        disableEscapeKeyDown
        open={true}
        onClose={() => console.log("dialog unclosable")}
      >
        <Box display="flex" className="gtc-hideonprint">
          <Box flexGrow={1}>
            <DialogTitle> Print Result Report </DialogTitle>
          </Box>
          <Box>
            <IconButton onClick={() => close()} color="secondary">
              <XCircle />
            </IconButton>
          </Box>
        </Box>

        <DialogContent>
          <Box m={2}>
            <Backdrop open={printProcess} className={classes.backdrop}>
              <Box alignItems="center" align="center">
                <CircularProgress color="inherit" />
                <Typography>please wait...</Typography>
              </Box>
            </Backdrop>

            <Grid container spacing={2}>
              <Grid className="gtc-hideonprint" item xs={12} sm={3} lg={2}>
                {imagingOrderDetails.ready &&
                  imagingOrderDetails.data.length > 0 &&
                  imagingOrderDetails.data.map((data, index) => (
                    <Box>
                      <Button
                        startIcon={<PrintIcon />}
                        variant="outlined"
                        id={data.imaging_order}
                        size={"large"}
                        color="primary"
                        onClick={handlePrint}
                        fullWidth
                        component={Box}
                        my={1}
                      >
                        {data.imaging_order}
                      </Button>
                    </Box>
                  ))}
              </Grid>

              <Grid item xs={12} sm={9} lg={10}>
                {Object.keys(formHeader).length > 0 ? (
                  <>
                    {imagingOrderDetails.ready &&
                      imagingOrderDetails.data.length > 0 &&
                      imagingOrderDetails.data.map((data, index) => (
                        <>
                          <Box
                            mb={2}
                            className={
                              print === data.imaging_order
                                ? "gtc-showonprint"
                                : "gtc-hideonprint"
                            }
                          >
                            {imagingOrderDetails.ready &&
                              imagingOrderDetails.data.length > 0 && (
                                <>
                                  <Fragment key={index}>
                                    <HeaderPrintAll />

                                    <PatientInfoPrintAll details={patients} />

                                    <Grid container spacing={1}>
                                      <Grid item xs={12} sm={12}>
                                        <Box mt={1}>
                                          <Box>
                                            <Typography
                                              align="center"
                                              variant="h5"
                                              style={{
                                                fontWeight: "bolder",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              <span
                                                contentEditable={true}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {data.imaging_type === "xray"
                                                  ? "ROENTGENOLOGIC INTERPRETATION"
                                                  : "SONOGRAPHIC REPORT"}
                                              </span>
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography
                                              align="center"
                                              variant="subtitle1"
                                              style={{
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              Examination : {data.imaging_order}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={9}>
                                        <Box>
                                          <Box mt={1}>
                                            <Typography
                                              variant="h6"
                                              style={{
                                                whiteSpace: "pre-line",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              {data.imaging_result}
                                            </Typography>
                                          </Box>
                                          <Box mt={1}>
                                            <Typography
                                              variant="h6"
                                              style={{ fontWeight: "bolder" }}
                                            >
                                              IMPRESSION:
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography
                                              variant="h6"
                                              style={{
                                                whiteSpace: "pre-line",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              {data.imaging_results_remarks}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Box>
                                          {data.imaging_result_attachment !==
                                          null ? (
                                            <CardMedia
                                              component="img"
                                              className={classes.profile}
                                              src={`${imageLocation}imaging/${
                                                data.imaging_result_attachment ===
                                                null
                                                  ? "no-image.jpg"
                                                  : data.imaging_result_attachment
                                              }`}
                                              alt=""
                                            />
                                          ) : null}
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                          <Grid item xs={6}>
                                            <Box align="center">
                                              <Box>
                                                <Typography
                                                  className={`gtc-uppercase`}
                                                  variant="subtitle1"
                                                >
                                                  {data.radiologist_name}
                                                </Typography>
                                                <Box borderTop={1}>
                                                  <Typography variant="subtitle1">
                                                    <b> RADIOLOGIST </b>
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Fragment>
                                </>
                              )}
                          </Box>
                        </>
                      ))}
                  </>
                ) : (
                  "imaging information not set in database."
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PrintMobileVanOrdersImaging;
