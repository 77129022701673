import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Avatar,
  Dialog,
  Zoom,
  IconButton,
  makeStyles,
  Button,
  Typography,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Paper } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {
  AddCircle,
  CancelOutlined,
  KeyboardArrowLeft,
  Cancel,
} from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import { UsersData } from "src/ContextAPI";
import axios from "axios";
import { Check, X } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 30px",
    width: "100%",
  },
  image: {
    marginTop: 25,
    marginBottom: 55,
    height: isMobile ? 250 : 400,
    border: "1px solid #aaa",
    margin: "auto",
    display: "block",
  },
  tag: {
    zIndex: 1,
    position: "absolute",
    paddingTop: 26,
    marginLeft: 38,
  },
  tag_label: {
    backgroundColor: "#00000094",
    color: "#fff",
    padding: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
}));

const PatientProfileHeader = ({
  patient_id,
  info,
  close,
  addonsTab,
  closePatientDetails,
  billingDetails,
  getPatientsOnQueue,
}) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const { users } = useContext(UsersData);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCancelQueue = () => {
    var formdata = new FormData();
    formdata.append("user_id", users.user_id);
    formdata.append("management_id", users.management_id);
    formdata.append("main_mgmt_id", users.main_mgmt_id);
    formdata.append("patient_id", patient_id);

    axios
      .post("cashier/delete/patient-queue/by-id", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setOpenDialog(false);
          getPatientsOnQueue();
          closePatientDetails();
          Notify.successRequest("cancel");
        } else {
          Notify.customToast("Something went wrong", "Please try again...");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {}, [billingDetails.data]);
  return (
    <>
      <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
        {info.ready ? (
          info.data ? (
            <div className="clearfix">
              <div className="float-left">
                <Avatar
                  style={{
                    width: "120px",
                    height: "120px",
                    cursor: "pointer",
                  }}
                  src={
                    info.data.image !== null
                      ? imageLocation + "patients/" + info.data.image
                      : ""
                  }
                  onClick={() => {
                    setSelectedImage(info.data.image);
                    setOpenLightbox(true);
                  }}
                />
              </div>
              <div className="ml-sm-4 float-left">
                <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
                  {info.data.lastname}, {info.data.firstname} {info.data.middle}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Age:
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {Notify.calculateAge(info.data.birthday)} years old
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Gender:
                  </span>
                  &nbsp;&nbsp; {info.data.gender}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Birthday:
                  </span>
                  {Notify.birthday(info.data.birthday)}
                </p>
              </div>

              <div className="ml-sm-4 float-right">
                <Box display={"flex"}>
                  <Box mr={1}>
                    <Button
                      startIcon={
                        addonsTab === "bills" ? (
                          <AddCircle />
                        ) : (
                          <KeyboardArrowLeft />
                        )
                      }
                      color={addonsTab === "bills" ? "primary" : "secondary"}
                      variant="contained"
                      onClick={() => close()}
                    >
                      {addonsTab === "bills" ? "Add-ons" : "Back"}
                    </Button>
                  </Box>
                  <Box hidden={billingDetails.data.length > 0 ? true : false}>
                    <Button
                      startIcon={<Cancel />}
                      color={"secondary"}
                      variant="contained"
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>

      <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton
            color="secondary"
            onClick={() => {
              setOpenLightbox(false);
            }}
          >
            <CancelOutlined />
          </IconButton>
        </Box>
        <Box>
          <SwipeableViews
            className={classes.root}
            slideStyle={{ padding: "0 10px" }}
            enableMouseEvents
            index={current}
            onChangeIndex={(e) => setCurrent(e)}
          >
            <Box>
              <img
                alt="attach-file-pt"
                src={`${imageLocation}/patients/${selectedImage}`}
                className={classes.image}
              />
            </Box>
          </SwipeableViews>
        </Box>
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Box>
            <Typography>Are you sure to cancel this queue</Typography>
            <Typography>to the patient?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Check style={{ width: 15 }} />}
            onClick={handleCancelQueue}
          >
            Yes
          </Button>

          <Button
            variant="contained"
            color="default"
            startIcon={<X style={{ width: 15 }} />}
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PatientProfileHeader;
