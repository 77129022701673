import React, { Fragment } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Grid,
} from "@material-ui/core";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const SarsCovPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  sarsCovOrderDetails,
}) => {
  return (
    <>
      {sarsCovOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          {/* <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                SARS COV-2 ANTIGEN
              </span>
            </Typography>
          </Box> */}
          <Box mt={2}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <b style={{ color: "#000" }}> Test </b>
                    </TableCell>
                    <TableCell align="center">
                      <b style={{ color: "#000" }}> RESULT </b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sarsCovOrderDetails.data.map((data, index) => (
                    <Fragment key={index}>
                      {data.sars_cov && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              SARS COV - 2 ANTIGEN
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{ color: "#000", fontWeight: "bolder" }}
                            >
                              {data.sars_cov_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={5} align="center">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box align="center" width={350}>
                    <Box mt={1}>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          VICTORIO C. ANGUS, M.D., DPCP
                        </span>
                      </Typography>
                      <Box borderTop={1}>
                        <b style={{ color: "#000" }}> Internal Medicine </b>
                      </Box>
                      <Box>
                        <Typography
                          className={`gtc-uppercase`}
                          variant="subtitle2"
                          style={{ color: "#000", fontWeight: "bolder" }}
                        >
                          <span
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                          >
                            Lic. No. 85318
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default SarsCovPrintAll;
