import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Grid,
} from "@material-ui/core";
import ChemistryRef from "src/laboratory/references/Ref_Chemistry";
import HeaderPrintAll from "./../../printall/HeaderPrintAll";
import PatientInfoPrintAll from "./../../printall/PatientInfoPrintAll";

const ChemistryPrintAll = ({
  allowHeaderPatientInfo,
  patients,
  chemistryOrderDetails,
}) => {
  return (
    <>
      {chemistryOrderDetails.data.length > 0 && (
        <Fragment>
          {allowHeaderPatientInfo !== "notallowed" && (
            <>
              <HeaderPrintAll />
              <PatientInfoPrintAll details={patients} />
            </>
          )}
          <Box>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bolder",
                color: "#000",
                textDecoration: "underline",
              }}
            >
              <span
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                BLOOD CHEMISTRY REPORT
              </span>
            </Typography>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      TEST REQUEST
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      RESULT
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      REFERENCE VALUES
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ fontWeight: "bolder", color: "#000" }}>
                      REMARKS
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chemistryOrderDetails.data.map((data, index) => (
                  <Fragment key={index}>
                    {data.fbs && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            FBS
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.fbs}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.fbs()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.fbs_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.glucose && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            GLUCOSE
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.glucose}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.glucose()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.glucose_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.creatinine && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            CREATININE
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.creatinine}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.creatinine()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.creatinine_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.uric_acid && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            URIC ACID
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.uric_acid}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.uric()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.uric_acid_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.cholesterol && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.cholesterol}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.cholesterol()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.cholesterol_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.triglyceride && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            TRIGLYCERIDE
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.triglyceride}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.triglyceride()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.triglyceride_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.hdl_cholesterol && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            HDL CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.hdl_cholesterol}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.hdlcholesterol()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hdl_cholesterol_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.ldl_cholesterol && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            LDL CHOLESTEROL
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.ldl_cholesterol}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.ldlcholesterol()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.ldl_cholesterol_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.sgot && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            SGOT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.sgot}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.sgot()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.sgot_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.sgpt && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            SGPT
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.sgpt}
                          </Typography>
                        </TableCell>
                        <TableCell>{ChemistryRef.sgpt()}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.sgpt_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.bun && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            bun
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.bun}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.bun_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.soduim && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            soduim
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.soduim}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.soduim_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.potassium && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            potassium
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.potassium}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.potassium_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.hba1c && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            hba1c
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.hba1c}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.hba1c_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.alkaline_phosphatase && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            alkaline phosphatase
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.alkaline_phosphatase}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.alkaline_phosphatase_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.albumin && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            albumin
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.albumin}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.albumin_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.calcium && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            calcium
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.calcium}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.calcium_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.magnesium && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            magnesium
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.magnesium}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.magnesium_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.chloride && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            chloride
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.chloride}
                          </Typography>
                        </TableCell>
                        <TableCell>{/* reference not set */}</TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.chloride_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.serum_uric_acid && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            SERUM URIC ACID
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.serum_uric_acid}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.serum_uric_acid_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.lipid_profile && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            LIPID PROFILE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.lipid_profile}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.lipid_profile_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ldh && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            LDH
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.ldh}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.ldh_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.tpag_ratio && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            TPAG RATIO
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.tpag_ratio}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.tpag_ratio_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.bilirubin && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            BILIRUBIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.bilirubin}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.bilirubin_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.total_protein && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            TOTAL PROTEIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.total_protein}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.total_protein_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.potassium_kplus && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            POTASSIUM K+
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.potassium_kplus}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.potassium_kplus_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.na_plus_kplus && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            NA+/K+
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.na_plus_kplus}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.na_plus_kplus_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ggt && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            GGT
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.ggt}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.ggt_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.cholinesterase && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            CHOLINESTERASE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.cholinesterase}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.cholinesterase_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.phosphorous && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            PHOSPHOROUS
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.phosphorous}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.phosphorous_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.rbs && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RBS
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.rbs}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.rbs_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.vldl && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            VLDL
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.vldl}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.vldl_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.rbc_cholinesterase && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            RBC CHOLINESTERASE
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.rbc_cholinesterase}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.rbc_cholinesterase_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.crp && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            CRP
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.crp}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.crp_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.pro_calcitonin && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            PRO CALCITONIN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            {data.pro_calcitonin}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* reference not set */}
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bolder", color: "#000" }}>
                            {data.pro_calcitonin_remarks}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ogct_take_one_50grm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            OGCT TAKE 1 (50GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Baseline
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_one_50grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              First Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_one_50grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Second Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_one_50grm_second_hour}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ogct_take_one_75grm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            OGCT TAKE 1 (75GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Baseline
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_one_75grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              First Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_one_75grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Second Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_one_75grm_second_hour}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ogct_take_two_100grm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            OGTT TAKES 2 (100GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Baseline
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_two_100grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              First Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_two_100grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Second Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_two_100grm_second_hour}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ogct_take_two_75grm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            OGTT TAKES 2 (75GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Baseline
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_two_75grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              First Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_two_75grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Second Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_two_75grm_second_hour}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ogct_take_three_100grm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            OGTT TAKES 3 (100GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Baseline
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_three_100grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              First Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_three_100grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              Second Hour
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {data.ogct_take_three_100grm_second_hour}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              <b> Baseline : </b>
                              {data.ogct_take_four_100grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              <b> First Hour : </b>
                              {data.ogct_take_four_100grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              <b> Second Hour : </b>
                              {data.ogct_take_four_100grm_second_hour}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.ogct_take_four_100grm && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`gtc-uppercase`}
                            style={{ fontWeight: "bolder", color: "#000" }}
                          >
                            OGTT TAKES 4 (100GRM)
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              <b> Baseline : </b>
                              {data.ogct_take_four_100grm_baseline}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              <b> First Hour : </b>
                              {data.ogct_take_four_100grm_first_hour}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              <b> Second Hour : </b>
                              {data.ogct_take_four_100grm_second_hour}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={1} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {chemistryOrderDetails.data.length > 0 &&
                        chemistryOrderDetails.data[0].pathologist}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Pathologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {chemistryOrderDetails.data.length > 0 &&
                          chemistryOrderDetails.data[0].pathologist_lcn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <Box align="center" width={250}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      {chemistryOrderDetails.data.length > 0 &&
                        chemistryOrderDetails.data[0].chief_medtech}
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Chief Med Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        {chemistryOrderDetails.data.length > 0 &&
                          chemistryOrderDetails.data[0].chief_medtech_lci}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Box align="center" width={350}>
                  <Box mt={1}>
                    <Typography
                      className={`gtc-uppercase`}
                      variant="subtitle2"
                      style={{ color: "#000", fontWeight: "bolder" }}
                    >
                      <span
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        {chemistryOrderDetails.data.length > 0 &&
                          chemistryOrderDetails.data[0].medtech}
                      </span>
                    </Typography>
                    <Box borderTop={1}>
                      <b style={{ color: "#000" }}> Medical Technologist </b>
                    </Box>
                    <Box>
                      <Typography
                        className={`gtc-uppercase`}
                        variant="subtitle2"
                        style={{ color: "#000", fontWeight: "bolder" }}
                      >
                        <span
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {chemistryOrderDetails.data.length > 0 &&
                            chemistryOrderDetails.data[0].lic_no}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default ChemistryPrintAll;
